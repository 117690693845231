<template>
  <div class="PerruquesMedicalesFemme">
     <header class='header-image__image' id="parallax">

      <div class="firstContainerWidget">
        <div class="containerCTA">
            <h1 class="titleCTA" style="margin-bottom:10px; ">Venez decouvrir notre salon</h1>
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight" style="user-select: none; pointer-events:none;">
                <h6 class="titleCTA" style=" font-weight: 800 !important;">Prendre rendez-vous</h6>
                <h5 class="titleCTA" style=" font-weight: 800 !important;">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
        </div>
      </div>

    <div class='header-image__content'>
      <div class='header-image__content__container'>
      </div>
    </div>
  </header>
  <div class="container_full_intro">
    <div class="container_intro_pmf">
</div>
</div>
    <!-- <img :src="images[0].url" alt="">
    <img :src="images[1].url" alt=""> -->
     <div class="container_body">
        <input style="margin-top:30px; border:2px solid black; padding:5px;" placeholder="rechercher une perruque" type="text" v-model="rechercher">
          <div class="container_coupe_courte" >
            <h3>Choix titre</h3>
            <div class="sub_container_coupe_courte">
              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in filteredNames" :key="item.id">
                <img :src="item.url" :alt="item.alt" :id="item.id" @click="toggleModal(item.id)">
                <div v-if="current !== item.id " class="container_name_price">
                  <div class="name">{{ item.name }}</div>
                  <!-- <div  class="price">{{ item.price }}€</div> -->
                </div>
                <!-- if isShowModal est associé à id de l'image clickée alors la modale et le contenu associée à cette image s'affiche -->
                <!-- @close="closeModal(item.id)" -->
                <base-modal-lace  v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :priceToto = item.price :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal-lace>
              </div>
            </div>
          </div>
          <span class="transition"></span>
      </div>

  </div>
</template>

<script>
import BaseModalLace from '@/components/baseModalLace.vue'
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - un vaste choix de postiches',
      description: 'Un large choix de postiches, une multitude de couleurs et de formes',
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/postiches'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      rechercher: '',
      images: [
        { url: require('../assets/category_primepower.webp'), alt: 'shooting femme' },
        { url: require('../assets/category_primepower_small.jpg'), alt: 'shooting femme' },
        { url: require('../assets/Sans-titre-7.png'), alt: 'shooting femme' }
      ],
      test: false,
      current: false,
      isShowModal: false,
      propFixed: false,
      imagest: [
        { url: require('../assets/imgTurbans1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans3.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans4.jpg'), alt: 'turbans femme' }
      ],
      postiches: [
        {
          id: 1,
          url: require('../assets/postiches/aqua.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Aqua',
          lenth: '40 - 47 cm',
          price: 44,
          toto2: 'Existe en differents coloris. Cheveux synthétiques',
          slide: [
            { url: require('../assets/postiches/photos/aqua-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/postiches/photos/aqua_4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/aqua/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/postiches/colors/aqua/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/postiches/colors/aqua/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/aqua/ginger blonde.jpg'), alt: 'perruque femme blond', name: 'ginger blonde' },
            { url: require('../assets/postiches/colors/aqua/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/aqua/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/aqua/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/postiches/colors/aqua/mahagony brown.jpg'), alt: 'perruque femme blond', name: 'mahagony brown' },
            { url: require('../assets/postiches/colors/aqua/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/postiches/colors/aqua/natural blonde.jpg'), alt: 'perruque femme blond', name: 'natural blonde' },
            { url: require('../assets/postiches/colors/aqua/nut brown.jpg'), alt: 'perruque femme blond', name: 'nut brown' },
            { url: require('../assets/postiches/colors/aqua/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' }
          ]
        },
        {
          id: 2,
          url: require('../assets/postiches/champagner.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Champagne',
          lenth: '50 - 55 cm',
          price: 55,
          toto2: 'Existe en differents coloris. Cheveux synthétiques',
          slide: [
            { url: require('../assets/postiches/photos/champagner_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/postiches/photos/champagner_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/champagne/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/postiches/colors/champagne/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/postiches/colors/champagne/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/champagne/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/champagne/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/champagne/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/postiches/colors/champagne/mahagony brown.jpg'), alt: 'perruque femme blond', name: 'mahagony brown' },
            { url: require('../assets/postiches/colors/champagne/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/postiches/colors/champagne/natural blonde.jpg'), alt: 'perruque femme blond', name: 'natural blonde' },
            { url: require('../assets/postiches/colors/champagne/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' }
          ]
        },
        {
          id: 3,
          url: require('../assets/postiches/ew_powerpieces_tonic.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Tonic',
          lenth: '20 - 30 cm',
          price: 44,
          toto2: 'Existe en differents coloris. Cheveux synthétiques',
          slide: [
            { url: require('../assets/postiches/photos/ew_powerpieces_tonic_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/postiches/photos/tonic_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/tonic/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/postiches/colors/tonic/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/postiches/colors/tonic/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/tonic/ginger blonde.jpg'), alt: 'perruque femme blond', name: 'ginger blonde' },
            { url: require('../assets/postiches/colors/tonic/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/tonic/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/tonic/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/postiches/colors/tonic/mahagony brown.jpg'), alt: 'perruque femme blond', name: 'mahagony brown' },
            { url: require('../assets/postiches/colors/tonic/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/postiches/colors/tonic/natural blonde.jpg'), alt: 'perruque femme blond', name: 'natural blonde' },
            { url: require('../assets/postiches/colors/tonic/nut brown.jpg'), alt: 'perruque femme blond', name: 'nut brown' },
            { url: require('../assets/postiches/colors/tonic/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' }
          ]
        },
        {
          id: 4,
          url: require('../assets/postiches/gin.jpg'),
          alt: 'perruque femme gris ambre cheveux courte',
          name: 'Gin',
          lenth: '8 - 14 cm',
          price: 18,
          toto2: 'Existe en differents coloris. Cheveux synthétiques',
          slide: [
            { url: require('../assets/postiches/photos/gin_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/postiches/photos//gin_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/gin/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/postiches/colors/gin/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/postiches/colors/gin/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/gin/ginger blonde.jpg'), alt: 'perruque femme blond', name: 'ginger blonde' },
            { url: require('../assets/postiches/colors/gin/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/gin/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/gin/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/postiches/colors/gin/light grey.jpg'), alt: 'perruque femme blond', name: 'light grey' },
            { url: require('../assets/postiches/colors/gin/mahagony brown.jpg'), alt: 'perruque femme blond', name: 'mahagony brown' },
            { url: require('../assets/postiches/colors/gin/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/postiches/colors/gin/medium grey.jpg'), alt: 'perruque femme blond', name: 'medium grey' },
            { url: require('../assets/postiches/colors/gin/natural blonde.jpg'), alt: 'perruque femme blond', name: 'natural blonde' },
            { url: require('../assets/postiches/colors/gin/nut brown.jpg'), alt: 'perruque femme blond', name: 'nut brown' },
            { url: require('../assets/postiches/colors/gin/pearl grey.jpg'), alt: 'perruque femme blond', name: 'pearl grey' },
            { url: require('../assets/postiches/colors/gin/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' },
            { url: require('../assets/postiches/colors/gin/silver grey.jpg'), alt: 'perruque femme blond', name: 'silver grey' }
          ]
        },
        {
          id: 5,
          url: require('../assets/postiches/hair_in_1-1.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Hair In',
          lenth: '45 - 48 cm',
          price: 320,
          toto2: 'Existe en differents coloris. Cheveux naturels',
          slide: [
            { url: require('../assets/postiches/photos/hair_in_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/postiches/photos/hair_in_4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/hair in/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/hair in/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/hair in/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/hair in/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/postiches/colors/hair in/natural blonde.jpg'), alt: 'perruque femme blond', name: 'natural blonde' },
            { url: require('../assets/postiches/colors/hair in/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' }
          ]
        },
        {
          id: 6,
          url: require('../assets//postiches/lemon.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Lemon',
          lenth: 'Size: 12 - 25 cm',
          price: 40,
          toto2: 'Existe en differents coloris. Cheveux synthétiques',
          slide: [
            { url: require('../assets/postiches/photos/lemon_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/postiches/photos/lemon_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/lemon/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/postiches/colors/lemon/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/postiches/colors/lemon/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/lemon/ginger blonde.jpg'), alt: 'perruque femme blond', name: 'ginger blonde' },
            { url: require('../assets/postiches/colors/lemon/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/lemon/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/lemon/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/postiches/colors/lemon/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/postiches/colors/lemon/natural blonde.jpg'), alt: 'perruque femme blond', name: 'natural blonde' },
            { url: require('../assets/postiches/colors/lemon/nut brown.jpg'), alt: 'perruque femme blond', name: 'nut brown' },
            { url: require('../assets/postiches/colors/lemon/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' }
          ]
        },
        {
          id: 7,
          url: require('../assets/postiches/pinot.jpg'),
          alt: 'perruque femme blond ambre cheveux long',
          name: 'Pinot',
          size: '7 x 12 cm',
          lenth: '13 - 17 cm',
          price: 42,
          toto2: 'Existe en differents coloris. Cheveux synthétiques',
          slide: [
            { url: require('../assets/postiches/photos/pinot_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/postiches/photos/pinot_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/pinot/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/postiches/colors/pinot/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/postiches/colors/pinot/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/pinot/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/pinot/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/pinot/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/postiches/colors/pinot/mahagony brown.jpg'), alt: 'perruque femme blond', name: 'mahagony brown' },
            { url: require('../assets/postiches/colors/pinot/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/postiches/colors/pinot/natural blonde.jpg'), alt: 'perruque femme blond', name: 'natural blonde' },
            { url: require('../assets/postiches/colors/pinot/nut brown.jpg'), alt: 'perruque femme blond', name: 'nut brown' },
            { url: require('../assets/postiches/colors/pinot/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' }
          ]
        },
        {
          id: 8,
          url: require('../assets/postiches/quick_in.jpg'),
          alt: 'perruque femme ambre coupe courte',
          name: 'Quick_in',
          lenth: '40 - 45 cm',
          price: 110,
          toto2: 'Existe en differents coloris. Cheveux synthétiques',
          slide: [
            { url: require('../assets/postiches/photos/quick_in_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/postiches/photos/quick_in_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/quick in/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/quick in/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/quick in/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/quick in/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/postiches/colors/quick in/mahagony brown.jpg'), alt: 'perruque femme blond', name: 'mahagony brown' },
            { url: require('../assets/postiches/colors/quick in/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/postiches/colors/quick in/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' }
          ]
        },
        {
          id: 9,
          url: require('../assets/postiches/rum_3.jpg'),
          alt: 'perruque femme ambre coupe courte gris',
          name: 'Rum',
          lenth: '15 - 25 cm',
          price: 30,
          toto2: 'Existe en differents coloris. Cheveux synthétiques',
          slide: [
            { url: require('../assets/postiches/photos/rhum.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/postiches/photos/rum_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/rum/beaujolais.jpg'), alt: 'perruque femme blond', name: 'beaujolais' },
            { url: require('../assets/postiches/colors/rum/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/postiches/colors/rum/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/postiches/colors/rum/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/rum/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/rum/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/rum/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/postiches/colors/rum/natural blonde.jpg'), alt: 'perruque femme blond', name: 'natural blonde' },
            { url: require('../assets/postiches/colors/rum/nut brown.jpg'), alt: 'perruque femme blond', name: 'nut brown' },
            { url: require('../assets/postiches/colors/rum/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' }
          ]
        },
        {
          id: 10,
          url: require('../assets/postiches/sangria.jpg'),
          alt: 'perruque femme ambre coupe courte gris',
          name: 'Sangria',
          lenth: '35 - 60 cm',
          price: 85,
          toto2: 'Existe en differents coloris. Cheveux synthétiques',
          slide: [
            { url: require('../assets/postiches/photos/ew_powerpieces_sangria_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/postiches/photos/sangria_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/sangria/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/postiches/colors/sangria/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/postiches/colors/sangria/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/sangria/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/sangria/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/sangria/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/postiches/colors/sangria/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/postiches/colors/sangria/natural blonde.jpg'), alt: 'perruque femme blond', name: 'natural blonde' },
            { url: require('../assets/postiches/colors/sangria/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' }
          ]
        },
        {
          id: 11,
          url: require('../assets/postiches/tricky.jpg'),
          alt: 'perruque femme ambre coupe courte gris',
          name: 'Tricky',
          lenth: '45 cm',
          price: 42,
          toto2: 'Existe en differents coloris. Cheveux synthétiques',
          slide: [
            { url: require('../assets/postiches/photos/tricky_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/postiches/photos/tricky_4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/tricky/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/postiches/colors/tricky/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/postiches/colors/tricky/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/tricky/ginger blonde.jpg'), alt: 'perruque femme blond', name: 'ginger blonde' },
            { url: require('../assets/postiches/colors/tricky/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/tricky/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/tricky/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/postiches/colors/tricky/mahagony brown.jpg'), alt: 'perruque femme blond', name: 'mahagony brown' },
            { url: require('../assets/postiches/colors/tricky/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/postiches/colors/tricky/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' }
          ]
        },
        {
          id: 12,
          url: require('../assets/postiches/wodka.jpg'),
          alt: 'perruque femme ambre coupe courte gris',
          name: 'Wodka',
          lenth: '55 - 63 cm',
          price: 70,
          toto2: 'Existe en differents coloris. Cheveux synthétiques',
          slide: [
            { url: require('../assets/postiches/photos/wodka_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/postiches/photos/wodka_4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/wodka/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/postiches/colors/wodka/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/postiches/colors/wodka/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/wodka/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/wodka/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/wodka/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/postiches/colors/wodka/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/postiches/colors/wodka/nut brown.jpg'), alt: 'perruque femme blond', name: 'nut brown' },
            { url: require('../assets/postiches/colors/wodka/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' }
          ]
        }
      ]
    }
  },
  components: {
    BaseModalLace
  },
  mounted () {
    const parallax = document.getElementById('parallax')
    window.addEventListener('scroll', function () {
      const offset = window.scrollY
      parallax.style.backgroundPositionY = offset * 0.2 + 'px'
    })
  },
  methods: {
    // openModal () {
    //   this.modalOpen = !this.modalOpen
    // },
    toggleModal (id) {
      if (this.isShowModal !== id) {
        this.isShowModal = true
        this.isShowModal = id
        const body = document.querySelector('body')
        // const containerBody = document.querySelector('.container_body')
        if (this.isShowModal === id | id === this.filteredNames.id) {
          body.style.overflow = 'hidden'
          // containerBody.style.visibility = 'hidden'
          // console.log('la popup est ouverte !')
        }
      } else {
        const body = document.querySelector('body')
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
        body.style.overflow = 'visible'
      }
    },
    closePopup (id) {
      const body = document.querySelector('body')
      if (this.isShowModal !== id) {
        body.style.overflow = 'visible'
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
      }
    },
    //   if (this.isShowModal !== id) {
    //     console.log('cono')
    //     // console.log(id)
    //     console.log(this.isShowModal)
    //   }
    // },
    setCurrent (id) {
      this.current = id
      // console.log(id)
    }
  },
  computed: {
    filteredNames () {
      return this.postiches.filter(item => item.name.toLowerCase().includes(this.rechercher.toLowerCase()))
    }
  }
})
</script>

<style lang="scss" scoped>

// html,
// body {
//   height: 100%;
// }
// $base-header-height: 100%;
.PerruquesMedicalesFemme {
      width: 80%;
    right: 0;
    text-align: center;
    float: right;
}
.header-image__image {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/category_primepower.webp');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
// .header-image {
//   position: relative; // 2.
//   height: 100%;
//   overflow: auto; // 1.
//   -webkit-overflow-scrolling: touch; // 5.
//   @at-root .header-image__image {
//     height: $base-header-height;
//     background-position: center center; // 3.
//     background-repeat: no-repeat; // 3.
//     background-size: cover; // 3.
//   }
// }
.container_intro_pmf {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: 80%;
}
.title_tab {
  margin: 0px 0px 40px 0px;
    padding-top: 40px;
}
.container_full_intro {
 width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
}
table {
  width: 20%;
  border-collapse: collapse;
  color: white;
}
td {
  color: black;
}
tr {
 background-color: #eee;
}
.entry-content thead th, .entry-content tr th {
 color: white;

}
th,
td {
  border: 1px solid white;
  padding: 10px;
}
th {
  background: #949393;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  font-family: sans-serif;
 line-height:1.7em;
}
table {
  width: 100%;
  border-collapse: collapse;
      margin-bottom: 60px;
}
th,
td {
  padding: 10px;
  text-align: left;
  border: solid 1px #ccc;
}
th {
  background-color: #000;
  color: #fff;
}
tr:nth-child(odd) {
  background-color: #eee;
}
.container_stars {
  margin-bottom: 80px;
  img {
    max-width:100%;
  }
}

.PerruquesRelooking {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    margin-bottom: 48px;
}

.fixed {
  height: 100vh;
  overflow: hidden;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
.container_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.sub_container_coupe_courte{
 display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}
.container_coupe_courte{
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // z-index: 0;
}
// h1 {
//   font-size: 40px;
//   margin: 10px 10px;

// }
// h2 {
//   font-size: 40px;
//   margin: 10px 10px;

// }
// h3 {
//   font-size: 40px;
//   margin: 10px 10px;

// }
// h4 {
//   font-size: 30px;
//   margin: 10px 10px;

// }
.container_img_name {
 margin: 5px;
    padding: 0;
    display: inline-grid;
    width: 30%;
    position: relative;
    cursor: pointer;
  img {
    width: 100%;
  }
  .container_name_price {
    position: absolute;
    background: white;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    color: black;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    padding: 1px 0px;
    .name, .price {
    font-size: 22px;
  }
  }
}
.transition {
border-top: 1px solid black;
    width: 80%;
    margin: 50px;
}
@media only screen and (max-width: 700px) {
  table,
  thead,
  tbody,
  tr,
  th,
  td {
    display: block;
  }
  .entry-content table:not(.variations),
  body.et-pb-preview #main-content .container table {
    border: 1px solid black;
  }
  thead {
    display: none;
  }
  td {
    padding-left: 150px !important;
    position: relative;
    margin-top: -1px;
    background: #fff;
    font-size: 10px;
    line-height: 15px;
    color: black;
  }
  td:nth-child(odd) {
    background-color: #eee;
  }

  td::before {
    padding: 10px;
    content: attr(data-label);
    position: absolute;
    top: 0;
    left: 0;
    width: 130px;
    bottom: 0;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  tr {
    margin-bottom: 1rem;
  }
}
@media (max-width: 550px) {
.header-image__image {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/category_primepower_small.jpg')
}
.PerruquesMedicalesFemme {
  width:100%;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans4.jpg')
}
.container_img_name {
  width: 47%;
.name, .price {
  font-size: 15px;
}
}
.PerruquesRelooking {
  width:100%;
}
}
@media (max-width: 990px) {

.PerruquesMedicalesFemme {
  width:100%;
}
.PerruquesRelooking {
  width:100%;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg')
}
}

@media (min-width: 990px) {
  .container_img_name {
  max-width: 15%;
}
}
</style>
