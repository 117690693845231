<template>
  <div class="QuiSommesNous">
    <h1 style="height:100vh; display: flex; justify-content: center; align-items:center; background:#00f9ff24">Erreur 404 ! La page que vous tentez d'accéder n'existe pas ou plus !</h1>
  </div>
</template>
<script>
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - Not-found',
      description: ''
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        }
      ]
    })
  }
})
</script>
<style lang="scss" scoped>
.QuiSommesNous {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    float: right;
}
@media(max-width: 990px) {
  .QuiSommesNous {
    width:100%;
  }
}
</style>
