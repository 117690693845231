<template>
  <body style="display:block;">
    <div class="PerruquesCheveuxNaturels">
      <header class='header-image__image' id="parallax">

        <div class="firstContainerWidget">
          <div class="containerLogo">
            <img class="img_logo" src="https://lhairderien-perruque.fr/lhairderien/src/assets/logo_hdr_2.webp" alt="">
          </div>
          <div class="containerCTA">
            <h1 class="titleCTA" style="margin-bottom:10px; ">Venez decouvrir notre salon</h1>
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight" style="user-select: none; pointer-events:none;">
                <h6 class="titleCTA" style=" font-weight: 800 !important;">Prendre rendez-vous</h6>
                <h5 class="titleCTA" style=" font-weight: 800 !important;">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
        </div>
      </div>

      <div class='header-image__content'>
        <div class='header-image__content__container'>
        </div>
      </div>
    </header>
    <div class="container_full_intro">
      <div class="container_intro_pmf">
        <p class="paragraph_intro">
          <br>
          Chez l'hair de rien, nous avons sélectionné pour vous des perruques en cheveux naturels hautes gammes. Implantés sur des bonnets fins, légers et confortables, les cheveux remy hair ou européens sont des gages de qualités. <br>
          Les cheveux remy hair, qualité AAAA+, sont des cheveux selectionnés qui ont gardé leurs cuticules intactes, et sont implantés dans le même sens ce qui garanti leur longévité tout en restant de bonne qualité: doux et brillants. <br>
          Les cheveux europpéens sont le haut de gamme du cheveux. Grâce aux multitudes de couleurs de cheveux en europe, ces cheveux ne sont peu ou pas traités chimiquement. <br> Ils sont alors d'une excellente qualité qui leur donne une longévité supérieure à n'importe quelle autre gamme. <br>  Les cheveux naturels sont complétement personnnalisables: couleur, ombré, mèches, boucles. <br>
        </p><br>
        <h3 class="title_tab">
        Modalité de remboursement pour les Perruques synthétiques et naturelles
        </h3>
        <p class="paragraph_intro">
          Notre institut capillaire est un établissement agrée par la sécurité sociale. <br> Tous nos modèles répondent aux dernières normes médicales définies par le Ministère de la Santé. Une majorité des prothèses capillaires est remboursée à 100% par la sécurité sociale et votre mutuelle. N'hésitez pas à vous renseigner auprès ce cette dernière pour connaître vos droits. br

Le ministère de la santé à définit qu'il y avait deux classes pour ces prothèses capillaires tête entière. La classe I, des modèles à 350€ entièrement prit en charge par la sécurité sociale. La classe II, avec des modèles à plus de 350€, est remboursée à hauteur de 250€ par la sécurité sociale mais est souvent complétée par votre mutuelle. <br> <br>

        </p>
        <div class="containerTableau">
  <!-- <div class="tableau1">
  <h4 style="text-align:left; font-size:22px; margin-left:30px;font-family:roboto;">PROTHESE CAPILLAIRE TOTALE Classe I <br>
  + un bonnet easy fit</h4><br>
  <ul style="padding-left:40px">
    <li class="list1">Prix de vente forfaitaire : 350 euros</li> <br>
    <li class="list1">Montant remboursé par la sécurité Sociale : 350 euros</li><br>
    <li class="list1">Aucun reste à charge / Prise en charge 100%</li><br>
  </ul>
  <div>
    <h4 style="font-weight:bold">Perruques 100% remboursées</h4>
  </div>
  </div> -->
  <div class="tableau2_alone">
  <h4 style="text-align:left; margin-left:30px; font-family:roboto;">PROTHESE CAPILLAIRE TOTALE Classe II<br>
  + un bonnet easy fit</h4><br>
  <ul style="padding-left:40px">
    <li class="list1">Prix de vente forfaitaire : entre 350 et 700 euros</li> <br>
    <li class="list1">Montant remboursé par la sécurité Sociale : 250 euros</li><br>
    <li class="list1">Votre mutuelle peut prendre tout ou en partie le reste à charge</li><br>
  </ul>
  <div>
    <h4 style="font-weight:bold">Perruques remboursées à 250 euros</h4>
  </div>
  </div>
</div>
      </div>
    </div>

    <div class="container_body">
      <input style="margin-top:30px; border:2px solid black; padding:5px;" placeholder="rechercher une perruque" type="text" v-model="rechercher">
          <div class="container_pur_europe" >
            <div style="width:100%; background:#b2b2b2; margin-top:30px; padding-left:30px; padding-right:30px;">
            <h5 style="font-family:Amatic sc; color: white;">Cheveux Européens</h5>
            </div>
            <div class="sub_container_pur_europe">
              <div class="container_img_name" @mouseover="setCurrent(item.id)"  v-for="item in filteredNames.slice(0,7)" :key="item.id" >
                <img :src="item.url" :alt="item.alt" @click="toggleModal(item.id)">
                <div v-if="current !== item.id "  class="container_name_price">
                  <div  class="name">{{ item.name }}</div>
                  <!-- <div  class="price">{{ item.price }}€</div> -->
                </div>
                <base-modal  v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :priceToto = item.price :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal>
              </div>
            <!-- </div> -->
          <!-- </div> -->
          <div class="transitionV2">
                  <div class="leftBarV3"></div>
                  <span style="width: 30%;"><h1>Remy Hair</h1></span>
                  <div class="rightBarV3"></div>
                </div>
          <!-- <div class="container_pur_europe" > -->
            <!-- <div class="sub_container_pur_europe"> -->
              <div class="container_img_name" @mouseover="setCurrent(item.id)"  v-for="item in filteredNames.slice(7,26)" :key="item.id" >
                <img :src="item.url" :alt="item.alt" @click="toggleModal(item.id)">
                <div v-if="current !== item.id "  class="container_name_price">
                  <div   class="name">{{ item.name }}</div>
                  <!-- <div   class="price">{{ item.price }}€</div> -->
                </div>
                <base-modal  v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :priceToto = item.price :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal>
              </div>
              <div class="containerTitleCatalogue">
                <div class="transitionV2">
                  <div class="leftBarV3"></div>
                  <span style="width: 30%;"><h6>{{title[9]}}</h6></span>
                  <div class="rightBarV3"></div>
                </div>
                <div class="container-carousel">
                  <carousel
                    :slides="slides"
                    :loop="true"
                    @drag="onDrag"
                  >
                  </carousel>
                </div>
                <div class="containerCTAback">
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight">
                <h6 class="titleCTA">Prendre rendez-vous</h6>
                <h5 class="titleCTA">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
          </div>
  </div>
            </div>
          </div>

        <div class="container_pur_europe" >
          </div>
          <span class="transition"></span>
        </div>

</div>
  </body>

</template>

<script>
import Carousel from './vuejs3-components-carousel.vue'
import BaseModal from '@/components/BaseModal.vue'
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - une varieté de perruques à base de cheveux naturels',
      description: 'Une majorité des prothèses capillaires est remboursée à 100% par la sécurité sociale ',
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/perruques-cheveux-naturels'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      rechercher: '',
      isShowModal: false,
      propFixed: false,
      current: false,
      title: ['Gamme ambre - 350€ - intégralement remboursé par la sécu', 'Gamme amethyste - 450€ à 470€ - partiellement remboursé par la sécu', 'Gamme Rubis - 550€ - Monofilament', 'Gamme Emeraude - 590€ - 100% fait main', 'Gamme Saphir - de 600€ à 630€ - Monofilament - 100% fait main', 'Gamme ambre - 350€ - intégralement remboursé par la sécu', 'Gamme amethyste - 450€ à 460€ - partiellement remboursé par la sécu', 'Gamme rubis - 550€ à 560€ - Monofilament', 'Gamme Emeraude - 590€ - 100% fait main', 'Nos catalogues '],
      imagest: [
        { url: require('../assets/imgTurbans1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans3.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans4.jpg'), alt: 'turbans femme' }
      ],
      slides: [
        {
          id: 1,
          url: require('../assets/images_perruques/perruque_medicale.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques medicales',
          price: 350,
          description: 'Remboursées par la sécurité sociale',
          to: '/perruques-medicales-femmes'
        },
        {
          id: 2,
          url: require('../assets/images_perruques/perruque_naturelle.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruque naturelles',
          price: 350,
          description: 'perruque en vrais cheveux 100% naturels Remy Hair pour une longevité maximum',
          to: '/perruques-cheveux-naturels'
        },
        {
          id: 3,
          url: require('../assets/images_perruques/perruque_prime.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques fibre mixte',
          price: 350,
          description: "L'avantage du chebveux naturel et de la fibre synthétique",
          to: '/perruques-fibre-prime'
        },
        {
          id: 4,
          url: require('../assets/images_perruques/perruque_relooking.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques petits prix',
          price: 350,
          description: 'Sans remboursement sécu pour des budgets plus modérés',
          to: '/perruques-relooking'
        },
        {
          id: 5,
          url: require('../assets/images_perruques/perruque_lace.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques type lace wig',
          price: 350,
          description: 'chevelure ultra réaliste et indetectable 100% personnalisable',
          to: '/perruques-type-lace'
        },
        {
          id: 6,
          url: require('../assets/perruque_homme/ew_hairformance_justin_4.webp'),
          alt: 'perruque homme cheveux poivre et sel coupe courte',
          name: 'Perruques homme',
          description: 'Ligne de perruques dediée aux hommes remboursées par la sécu. Coupe personnalisable.',
          to: '/perruques-homme'
        }
      ],
      imagesPurEurope: [
        {
          id: 1,
          url: require('../assets/purEurope/cosmo_2.jpg'),
          alt: 'perruques cheveux naturels',
          name: 'Cosmo',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1300,
          slide: [
            { url: require('../assets/purEurope/cosmo_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purEurope/cosmo_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purEurope/cosmo_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purEurope/colors/cosmo/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/purEurope/colors/cosmo/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/purEurope/colors/cosmo/cosmochocolate mix.jpg'), alt: 'perruque femme blond', name: 'cosmochocolate mix' },
            { url: require('../assets/purEurope/colors/cosmo/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purEurope/colors/cosmo/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purEurope/colors/cosmo/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/purEurope/colors/cosmo/sandyblonde mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' }
          ]
        },
        {
          id: 2,
          url: require('../assets/purEurope/juvia_3_1.jpg'),
          alt: 'perruques cheveux naturels',
          name: 'Juvia',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1600,
          slide: [
            { url: require('../assets/purEurope/juvia_3_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purEurope/juvia_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purEurope/colors/juvia/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/purEurope/colors/juvia/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/purEurope/colors/juvia/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purEurope/colors/juvia/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purEurope/colors/juvia/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purEurope/colors/juvia/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/purEurope/colors/juvia/sandyblonde mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' }
          ]
        },
        {
          id: 3,
          url: require('../assets/purEurope/mondo_1.jpg'),
          alt: 'perruques cheveux naturels',
          name: 'Mondo',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1000,
          slide: [
            { url: require('../assets/purEurope/mondo_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purEurope/mondo_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purEurope/colors/mondo/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/purEurope/colors/mondo/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/purEurope/colors/mondo/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purEurope/colors/mondo/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purEurope/colors/mondo/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purEurope/colors/mondo/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/purEurope/colors/mondo/sandyblonde mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' }
          ]
        },
        {
          id: 4,
          url: require('../assets/purEurope/sole_1.jpg'),
          alt: 'perruques cheveux naturels',
          name: 'Sole',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1450,
          slide: [
            { url: require('../assets/purEurope/sole_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purEurope/sole_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purEurope/colors/sole/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/purEurope/colors/sole/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/purEurope/colors/sole/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purEurope/colors/sole/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purEurope/colors/sole/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purEurope/colors/sole/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/purEurope/colors/sole/sandyblonde mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' }
          ]
        },
        {
          id: 5,
          url: require('../assets/purPower/xenita.jpg'),
          alt: 'bonnet femme',
          name: 'Xenita Hi',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 2988,
          slide: [
            { url: require('../assets/purPower/xenita.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/xenita2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/xenita3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/xenita4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/xenita5.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/xenita6.jpg'), alt: 'perruque femme blond' }

          ],
          mozaique: [
            { url: require('../assets/purPower/colors/xenitaHi/xenita_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/xenitaHi/xenita_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/xenitaHi/xenita_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/xenitaHi/xenita_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/xenitaHi/xenita_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'pastelblonde mix' },
            { url: require('../assets/purPower/colors/xenitaHi/xenita_lighthoney_rooted.jpg'), alt: 'perruque femme blond', name: 'safranred mix' },
            { url: require('../assets/purPower/colors/xenitaHi/xenita_mocca_mix.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/purPower/colors/xenitaHi/xenita_sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 6,
          url: require('../assets/purPower/yara.jpg'),
          alt: 'bonnet femme',
          name: 'Yara',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1944,
          slide: [
            { url: require('../assets/purPower/yara.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/yara2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/yara3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/yara/yara_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/yara/yara_chestnut_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/yara/yara_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/yara/yara_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/yara/yara_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'pastelblonde mix' },
            { url: require('../assets/purPower/colors/yara/yara_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'safranred mix' },
            { url: require('../assets/purPower/colors/yara/yara_lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/purPower/colors/yara/yara_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 7,
          url: require('../assets/purPower/zora.jpg'),
          alt: 'bonnet femme',
          name: 'Zora',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 2340,
          slide: [
            { url: require('../assets/purPower/zora.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/zora2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/zora3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/zora4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/zora5.jpg'), alt: 'perruque femme blond' }

          ],
          mozaique: [
            { url: require('../assets/purPower/colors/zora/zora_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/zora/zora_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/zora/zora_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/zora/zora_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/zora/zora_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'pastelblonde mix' },
            { url: require('../assets/purPower/colors/zora/zora_lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'safranred mix' },
            { url: require('../assets/purPower/colors/zora/zora_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' }
          ]
        },
        // ],
        // imagesPurPower: [
        {
          id: 8,
          url: require('../assets/purPower/appeal_2.jpg'),
          alt: 'bonnet femme',
          name: 'Appeal',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 2203,
          slide: [
            { url: require('../assets/purPower/appeal_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/appeal_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/appeal/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/appeal/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/appeal/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/appeal/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/appeal/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/purPower/colors/appeal/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/purPower/colors/appeal/pastelblonde rooted.jpg'), alt: 'perruque femme blond', name: 'pastelblonde rooted' },
            { url: require('../assets/purPower/colors/appeal/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/purPower/colors/appeal/winered rooted.jpg'), alt: 'perruque femme blond', name: 'winered rooted' }
          ]
        },
        {
          id: 9,
          url: require('../assets/purPower/award_1.jpg'),
          alt: 'bonnet femme',
          name: 'Award',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1656,
          slide: [
            { url: require('../assets/purPower/award_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/award_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/award_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/award/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/purPower/colors/award/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/award/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/award/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/award/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/award/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/purPower/colors/award/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/purPower/colors/award/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }

          ]
        },
        {
          id: 10,
          url: require('../assets/purPower/breeze-mono.jpg'),
          alt: 'perruques cheveux naturels',
          name: 'Breeze Mono',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1800,
          slide: [
            { url: require('../assets/purPower/breeze-mono.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/breeze-mono2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/breeze-mono-2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/breeze-mono/breeze-mono-champagne-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/purPower/colors/breeze-mono/breeze-mono-chocolate-mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/purPower/colors/breeze-mono/breeze-mono-darkchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/breeze-mono/breeze-mono-espresso-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/breeze-mono/breeze-mono-lightbernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/breeze-mono/breeze-mono-mocca-rooted.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/purPower/colors/breeze-mono/breeze-mono-sandyblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' }
          ]
        },
        {
          id: 11,
          url: require('../assets/purPower/brilliance_3.jpg'),
          alt: 'bonnet femme',
          name: 'Brilliance Plus',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 2174,
          slide: [
            { url: require('../assets/purPower/brilliance_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/brilliance_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/brilliance_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/brilliance/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'auburnrooted' },
            { url: require('../assets/purPower/colors/brilliance/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'bernsteinrooted' },
            { url: require('../assets/purPower/colors/brilliance/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'champagnemix' },
            { url: require('../assets/purPower/colors/brilliance/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolatemix' },
            { url: require('../assets/purPower/colors/brilliance/espresso mix.jpg'), alt: 'perruque femme blond', name: 'chocolatemix' },
            { url: require('../assets/purPower/colors/brilliance/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'bernsteinrooted' },
            { url: require('../assets/purPower/colors/brilliance/sand mix.jpg'), alt: 'perruque femme blond', name: 'champagnemix' },
            { url: require('../assets/purPower/colors/brilliance/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'chocolatemix' }
          ]
        },
        {
          id: 12,
          url: require('../assets/purPower/cascade_3.jpg'),
          alt: 'bonnet femme',
          name: 'Cascade',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 3690,
          slide: [
            { url: require('../assets/purPower/cascade_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/cascade_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/cascade/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/purPower/colors/cascade/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/cascade/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/cascade/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/cascade/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/cascade/hotchocolate mix.jpg'), alt: 'perruque femme blond', name: 'hotchocolate mix' },
            { url: require('../assets/purPower/colors/cascade/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/purPower/colors/cascade/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 13,
          url: require('../assets/purPower/collect_mono_part.jpg'),
          alt: 'bonnet femme',
          name: 'Collect Mono Part',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 3024,
          slide: [
            { url: require('../assets/purPower/collect_mono_part.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/collect_mono_part2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/collect_mono_part3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/collect-mono-part/collect-champagne-tipped.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/purPower/colors/collect-mono-part/collect-chocolate-tipped.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/collect-mono-part/collect-darkchocolate-tipped.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/collect-mono-part/collect-espresso-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/collect-mono-part/collect-lightbernstein-tipped.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/collect-mono-part/collect-lighthoney-tipped.jpg'), alt: 'perruque femme blond', name: 'hotchocolate mix' },
            { url: require('../assets/purPower/colors/collect-mono-part/collect-sandyblonde-tipped.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' }
          ]
        },
        {
          id: 14,
          url: require('../assets/purPower/delicate_1.jpg'),
          alt: 'bonnet femme',
          name: 'Delicate Plus',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 2865,
          slide: [
            { url: require('../assets/purPower/delicate_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/delicate_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/delicate_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/delicate/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/purPower/colors/delicate/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/delicate/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/delicate/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/delicate/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/delicate/nougat rooted.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' },
            { url: require('../assets/purPower/colors/delicate/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 15,
          url: require('../assets/purPower/emotion_2.jpg'),
          alt: 'bonnet femme',
          name: 'Emotion',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 3398,
          slide: [
            { url: require('../assets/purPower/emotion_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/emotion_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/emotion/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/purPower/colors/emotion/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/emotion/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/emotion/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/emotion/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/emotion/hotchocolate mix.jpg'), alt: 'perruque femme blond', name: 'hotchocolate mix' },
            { url: require('../assets/purPower/colors/emotion/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/purPower/colors/emotion/pastelblonde rooted.jpg'), alt: 'perruque femme blond', name: 'pastelblonde rooted' },
            { url: require('../assets/purPower/colors/emotion/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 16,
          url: require('../assets/purPower/marvelmono.jpg'),
          alt: 'bonnet femme',
          name: 'Marvel Mono',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1828,
          slide: [
            { url: require('../assets/purPower/marvelmono.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/marvelmono2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/marvelmono3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/marvelmono4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/marvelMono/marvel-mono-bernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/purPower/colors/marvelMono/marvel-mono-chocolate-mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/marvelMono/marvel-mono-darkchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/marvelMono/marvel-mono-espresso-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/marvelMono/marvel-mono-pastelblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/marvelMono/marvel-mono-sand-rooted.jpg'), alt: 'perruque femme blond', name: 'hotchocolate mix' },
            { url: require('../assets/purPower/colors/marvelMono/marvel-mono-sandyblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' }
          ]
        },
        {
          id: 17,
          url: require('../assets/purPower/flavour_1.jpg'),
          alt: 'bonnet femme',
          name: 'Flavour',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1400,
          slide: [
            { url: require('../assets/purPower/flavour_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/flavour_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/flavour_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/flavour/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/flavour/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/flavour/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/flavour/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/flavour/lightbernstein mix.jpg'), alt: 'perruque femme blond', name: 'lightbernstein mix' },
            { url: require('../assets/purPower/colors/flavour/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/purPower/colors/flavour/pearl mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/purPower/colors/flavour/sand rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/purPower/colors/flavour/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/purPower/colors/flavour/stonegrey mix.jpg'), alt: 'perruque femme blond', name: 'stonegrey mix' }
          ]
        },
        {
          id: 18,
          url: require('../assets/purPower/inspire_2.jpg'),
          alt: 'bonnet femme',
          name: 'Inspire',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 0,
          slide: [
            { url: require('../assets/purPower/inspire_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/inspire_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/inspire/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/inspire/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/inspire/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/inspire/hotchocolate mix.jpg'), alt: 'perruque femme blond', name: 'hotchocolate mix' },
            { url: require('../assets/purPower/colors/inspire/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/purPower/colors/inspire/pastelblonde mix.jpg'), alt: 'perruque femme blond', name: 'pastelblonde mix' },
            { url: require('../assets/purPower/colors/inspire/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/purPower/colors/inspire/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 19,
          url: require('../assets/purPower/ivory_3.jpg'),
          alt: 'bonnet femme',
          name: 'Ivory',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1000,
          slide: [
            { url: require('../assets/purPower/ivory_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/ivory_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/ivory_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/ivory/chocolate rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/purPower/colors/ivory/cinnamon mix.jpg'), alt: 'perruque femme blond', name: 'cinnamon mix' },
            { url: require('../assets/purPower/colors/ivory/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/ivory/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/ivory/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/purPower/colors/ivory/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/purPower/colors/ivory/pearlgrey mix.jpg'), alt: 'perruque femme blond', name: 'pearlgrey mix' },
            { url: require('../assets/purPower/colors/ivory/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/purPower/colors/ivory/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/purPower/colors/ivory/stonegrey mix.jpg'), alt: 'perruque femme blond', name: 'stonegrey mix' }

          ]
        },
        {
          id: 20,
          url: require('../assets/purPower/obsession_1.jpg'),
          alt: 'bonnet femme',
          name: 'Obsession',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 4377,
          slide: [
            { url: require('../assets/purPower/obsession_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/obsession_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/obsession_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/obsession/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/purPower/colors/obsession/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/obsession/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/obsession/cinnamon rooted.jpg'), alt: 'perruque femme blond', name: 'cinnamon rooted' },
            { url: require('../assets/purPower/colors/obsession/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/obsession/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/obsession/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/purPower/colors/obsession/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 21,
          url: require('../assets/purPower/passion_3.jpg'),
          alt: 'bonnet femme',
          name: 'Passion',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1850,
          slide: [
            { url: require('../assets/purPower/passion_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/passion_1.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/passion/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/passion/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/passion/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/passion/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/passion/hotchocolate mix.jpg'), alt: 'perruque femme blond', name: 'hotchocolate mix' },
            { url: require('../assets/purPower/colors/passion/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/purPower/colors/passion/pastelblonde rooted.jpg'), alt: 'perruque femme blond', name: 'pastelblonde rooted' },
            { url: require('../assets/purPower/colors/passion/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 22,
          url: require('../assets/purPower/prestige_3.jpg'),
          alt: 'bonnet femme',
          name: 'Prestige',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1200,
          slide: [
            { url: require('../assets/purPower/prestige_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/prestige_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/prestige/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/prestige/winered rooted.jpg'), alt: 'perruque femme blond', name: 'winered rooted' }
          ]
        },
        {
          id: 23,
          url: require('../assets/purPower/spectra_1.jpg'),
          alt: 'bonnet femme',
          name: 'Spectra plus',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 3542,
          slide: [
            { url: require('../assets/purPower/spectra_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/spectra_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/spectra_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/spectra/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/spectra/chocolate rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/purPower/colors/spectra/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/spectra/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/spectra/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/purPower/colors/spectra/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 24,
          url: require('../assets/purPower/trinity_3.jpg'),
          alt: 'bonnet femme',
          name: 'Trinity plus',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 3153,
          slide: [
            { url: require('../assets/purPower/trinity_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/trinity_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/trinity_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/trinity/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/purPower/colors/trinity/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/trinity/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/trinity/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/trinity/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/trinity/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/purPower/colors/trinity/pastelblonde rooted.jpg'), alt: 'perruque femme blond', name: 'pastelblonde rooted' },
            { url: require('../assets/purPower/colors/trinity/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/purPower/colors/trinity/softcopper rooted.jpg'), alt: 'perruque femme blond', name: 'softcopper rooted' }
          ]
        },
        {
          id: 25,
          url: require('../assets/purPower/wish_3.jpg'),
          alt: 'bonnet femme',
          name: 'Wish',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 2361,
          slide: [
            { url: require('../assets/purPower/wish_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/wish_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/wish/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/wish/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/wish/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/wish/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/purPower/colors/wish/pastelblonde rooted.jpg'), alt: 'perruque femme blond', name: 'pastelblonde rooted' },
            { url: require('../assets/purPower/colors/wish/pearlgrey mix.jpg'), alt: 'perruque femme blond', name: 'pearlgrey mix' },
            { url: require('../assets/purPower/colors/wish/safranred mix.jpg'), alt: 'perruque femme blond', name: 'safranred mix' },
            { url: require('../assets/purPower/colors/wish/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/purPower/colors/wish/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/purPower/colors/wish/stonegrey mix.jpg'), alt: 'perruque femme blond', name: 'stonegrey mix' }
          ]
        },
        {
          id: 26,
          url: require('../assets/purPower/xela_2.jpg'),
          alt: 'bonnet femme',
          name: 'Xela',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 800,
          slide: [
            { url: require('../assets/purPower/xela_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/purPower/xela_1.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/purPower/colors/xela/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/purPower/colors/xela/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/purPower/colors/xela/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/purPower/colors/xela/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/purPower/colors/xela/pastelblonde mix.jpg'), alt: 'perruque femme blond', name: 'pastelblonde mix' },
            { url: require('../assets/purPower/colors/xela/safranred mix.jpg'), alt: 'perruque femme blond', name: 'safranred mix' },
            { url: require('../assets/purPower/colors/xela/sand rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/purPower/colors/xela/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        }
      ]
    }
  },
  components: {
    BaseModal,
    Carousel
  },
  mounted () {
    const parallax = document.getElementById('parallax')
    window.addEventListener('scroll', function () {
      const offset = window.scrollY
      parallax.style.backgroundPositionY = offset * 0.2 + 'px'
    })
  },
  methods: {
    // openModal () {
    //   this.modalOpen = !this.modalOpen
    // },
    toggleModal (id) {
      if (this.isShowModal !== id) {
        this.isShowModal = true
        this.isShowModal = id
        // window.scrollTo(0, 600)
        const body = document.querySelector('body')
        // const containerBody = document.querySelector('.container_body')
        if (this.isShowModal === id | id === this.filteredNames.id) {
          body.style.overflow = 'hidden'
          // containerBody.style.visibility = 'hidden'
          // console.log('la popup est ouverte !')
        }
      } else {
        const body = document.querySelector('body')
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
        body.style.overflow = 'visible'
      }
    },
    closePopup (id) {
      const body = document.querySelector('body')
      if (this.isShowModal !== id) {
        body.style.overflow = 'visible'
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
      }
    },
    setCurrent (id) {
      this.current = id
      // console.log(id)
    }
  },
  computed: {
    filteredNames () {
      return this.imagesPurEurope.filter(item => item.name.toLowerCase().includes(this.rechercher.toLowerCase()))
    }
  }
})
</script>

<style lang="scss" scoped>
.containerTitleCatalogue {
  display: flex;
  flex-direction: column;
  align-items: center;
  h6 {
    margin: 20px;
  }
}
.container_body {
  margin-bottom:50px;
}
.container_baby_doll {
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.sub_container_pur_europe {
 display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}
.container_img_name {
 margin: 5px;
    padding: 0;
    display: inline-grid;
    width: 30%;
    position: relative;
    cursor: pointer;
  img {
    width: 100%;
  }
  .container_name_price {
    position: absolute;
    background: white;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    color: black;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    .name, .price {
    font-size: 15px;
    @media(min-width: 550px ) {
      font-size:20px;
    }
  }
  }
}
.transition {
border-top: 1px solid black;
    width: 80%;
    margin: 25px;
}
.PerruquesCheveuxNaturels {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
     float: right;
}
.header-image__image {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/category_primepower.jpg');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
.container_intro_pmf {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: 80%;
}
.title_tab {
  margin: 0px 0px 40px 0px;
    padding-top: 40px;
}
.container_full_intro {
 width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
    margin-bottom: 40px;
}
table {
  width: 20%;
  border-collapse: collapse;
  color: white;
}
td {
  color: black;
}
tr {
 background-color: #eee;
}
.entry-content thead th, .entry-content tr th {
 color: white;

}
th,
td {
  border: 1px solid white;
  padding: 10px;
}
th {
  background: #949393;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  font-family: sans-serif;
 line-height:1.7em;
}
table {
  width: 100%;
  border-collapse: collapse;
      margin-bottom: 60px;
}
th,
td {
  padding: 10px;
  text-align: left;
  border: solid 1px #ccc;
}
th {
  background-color: #000;
  color: #fff;
}
tr:nth-child(odd) {
  background-color: #eee;
}
.container_stars {
  margin-bottom: 80px;
  img {
    max-width:100%;
  }
}

@media only screen and (max-width: 700px) {
  table,
  thead,
  tbody,
  tr,
  th,
  td {
    display: block;
  }
  .entry-content table:not(.variations),
  body.et-pb-preview #main-content .container table {
    border: 1px solid black;
  }
  thead {
    display: none;
  }
  td {
    padding-left: 150px !important;
    position: relative;
    margin-top: -1px;
    background: #fff;
    font-size: 10px;
    line-height: 15px;
    color: black;
  }
  td:nth-child(odd) {
    background-color: #eee;
  }

  td::before {
    padding: 10px;
    content: attr(data-label);
    position: absolute;
    top: 0;
    left: 0;
    width: 130px;
    bottom: 0;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  tr {
    margin-bottom: 1rem;
  }
}
@media (max-width: 550px) {
.header-image__image {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/category_primepower_small.jpg')
}
.PerruquesCheveuxNaturels {
  width:100%;
}
.container_img_name {
  width: 47%;
.name {
  font-size: 18px;
}
}
}
@media (max-width: 990px) {

.PerruquesCheveuxNaturels {
  width:100%;
}
}
@media (min-width: 990px) {
  .container_img_name {
  max-width: 30%;
.name {
  font-size: 30px;
}
}
}
</style>
