<template>
  <div class="PerruquesFibrePrime">
      <header class='header-image__image1' id="parallax">

        <div class="firstContainerWidget">
          <div class="containerLogo">
            <img class="img_logo" src="https://lhairderien-perruque.fr/lhairderien/src/assets/logo_hdr_2.webp" alt="">
          </div>
          <div class="containerCTA">
            <h1 class="titleCTA" style="margin-bottom:10px; ">Venez decouvrir notre salon</h1>
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight" style="user-select: none; pointer-events:none;">
                <h6 class="titleCTA" style=" font-weight: 800 !important;">Prendre rendez-vous</h6>
                <h5 class="titleCTA" style=" font-weight: 800 !important;">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
        </div>
      </div>
      <div class='header-image__content'>
        <div class='header-image__content__container'>
        </div>
      </div>
    </header>
    <div class="container_full_intro" style="display:flex; justify-content:center; align-items:center">
      <div class="container_intro_pmf" style="width:80%;">
        <h2 class="title_tab"> Nos Perruques Fibre Prime </h2>
         <p class="paragraph_intro">
          <br>
          La fibre prime hair est parfaite pour les clientes exigeantes. Les avantages des cheveux syntétiques et naturels sont réunis ici. En effet ces cheveux 50% en cheveux naturels et 50% en cheveux synthétiques haute température se recoiffent aussi facilement que des cheveux synthétiques. Ils se recoiffents sans avoir besoin de refaire de brushing mais tout en ayant l'aspect et la légèreté des cheveux naturels. <br> Peuvent être chauffé jusqu'à 150°c.
        </p><br>
        <h3 class="title_tab">
        Modalité de remboursement pour les Perruques synthétiques et naturelles
        </h3>
        <p class="paragraph_intro">
          Notre institut capillaire est un établissement agrée par la sécurité sociale. <br> Tous nos modèles répondent aux dernières normes médicales définies par le Ministère de la Santé. Une majorité des prothèses capillaires est remboursée à 100% par la sécurité sociale et votre mutuelle. N'hésitez pas à vous renseigner auprès ce cette dernière pour connaître vos droits. br

Le ministère de la santé à définit qu'il y avait deux classes pour ces prothèses capillaires tête entière. La classe I, des modèles à 350€ entièrement prit en charge par la sécurité sociale. La classe II, avec des modèles à plus de 350€, est remboursée à hauteur de 250€ par la sécurité sociale mais est souvent complétée par votre mutuelle. <br> <br>

        </p>
        <div class="containerTableau">
  <div class="tableau2_alone">
  <h4 style="text-align:left; margin-left:30px; font-family:roboto;">PROTHESE CAPILLAIRE TOTALE Classe II<br>
  + un bonnet easy fit</h4><br>
  <ul style="padding-left:40px">
    <li class="list1">Prix de vente forfaitaire : entre 350 et 700 euros</li> <br>
    <li class="list1">Montant remboursé par la sécurité Sociale : 250 euros</li><br>
    <li class="list1">Votre mutuelle peut prendre tout ou en partie le reste à charge</li><br>
  </ul>
  <div>
    <h4 style="font-weight:bold">Perruques remboursées à 250 euros</h4>
  </div>
  </div>
</div>
      </div>
    </div>

    <div class="container_body">
      <input style="margin-top:30px; border:2px solid black; padding:5px; margin-bottom:30px;" placeholder="rechercher une perruque" type="text" v-model="rechercher">
          <div class="container_Fibre_Prime" >
            <div style="width:100%; background:#93dbd8 ;">
              <h5 style="font-family: Amatic sc; color: white;">Perruques Fibre Prime</h5>
            </div>
            <div class="sub_container_Fibre_Prime">
              <div class="container_img_name" @mouseover="setCurrent(item.id)"  v-for="item in filteredNames" :key="item.id">
                <img :src="item.url" :alt="item.alt" @click="toggleModal(item.id)">
                <div v-if="current !== item.id " class="container_name_price">
                  <div  class="name">{{ item.name }}</div>
                  <div   class="price">{{ item.price }}€</div>
                </div>
                <base-modal v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique"  :priceToto = item.price :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal>
              </div>
              <div class="transitionV2">
                  <div class="leftBarV3"></div>
                  <span style="width: 30%;"><h6>{{title[9]}}</h6></span>
                  <div class="rightBarV3"></div>
                </div>
                <div class="container-carousel">
                  <carousel
                    :slides="slides"
                    :loop="true"
                    @drag="onDrag"
                  >
                  </carousel>
                </div>
            </div>
          </div>
          <div class="containerCTAback">
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight">
                <h6 class="titleCTA">Prendre rendez-vous</h6>
                <h5 class="titleCTA">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
          </div>
    </div>
</div>

</template>
<script>
import Carousel from './vuejs3-components-carousel.vue'
import BaseModal from '@/components/BaseModal.vue'
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - un large choix de perruque type fibre prime',
      description: "Nos perruques euvent être chauffé jusqu'à 150°c",
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/perruques-fibre-prime'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      // item: 'slides',
      loop: true,
      // loop: true,
      rechercher: '',
      current: false,
      isShowModal: false,
      propFixed: false,
      title: ['Gamme ambre - 350€ - intégralement remboursé par la sécu', 'Gamme amethyste - 450€ à 470€ - partiellement remboursé par la sécu', 'Gamme Rubis - 550€ - Monofilament', 'Gamme Emeraude - 590€ - 100% fait main', 'Gamme Saphir - de 600€ à 630€ - Monofilament - 100% fait main', 'Gamme ambre - 350€ - intégralement remboursé par la sécu', 'Gamme amethyste - 450€ à 460€ - partiellement remboursé par la sécu', 'Gamme rubis - 550€ à 560€ - Monofilament', 'Gamme Emeraude - 590€ - 100% fait main', 'Nos catalogues '],
      imagest: [
        { url: require('../assets/imgTurbans1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans3.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans4.jpg'), alt: 'turbans femme' }
      ],
      imagesPerruques: [
        {
          id: 1,
          url: require('../assets/images_perruques/perruque_medicale.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques medicales',
          price: 350,
          to: '/perruques-medicales-femmes'
        },
        {
          id: 2,
          url: require('../assets/images_perruques/perruque_naturelle.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruque naturelles',
          price: 350,
          to: '/perruques-cheveux-naturels'
        },
        {
          id: 3,
          url: require('../assets/images_perruques/perruque_prime.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques fibre prime',
          price: 350,
          to: '/perruques-fibre-prime'
        },
        {
          id: 4,
          url: require('../assets/images_perruques/perruque_relooking.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques relooking',
          price: 350,
          to: '/perruques-relooking'
        },
        {
          id: 5,
          url: require('../assets/images_perruques/perruque_lace.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques type lace',
          price: 350,
          to: '/perruques-type-lace'
        },
        {
          id: 6,
          url: require('../assets/perruque_homme/ew_hairformance_justin_4.jpg'),
          alt: 'perruque homme cheveux poivre et sel coupe courte',
          name: 'Perruques homme',
          to: '/perruques-homme'
        }
      ],
      slides: [
        {
          id: 1,
          url: require('../assets/images_perruques/perruque_medicale.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques medicales',
          price: 350,
          description: 'Remboursées par la sécurité sociale',
          to: '/perruques-medicales-femmes'
        },
        {
          id: 2,
          url: require('../assets/images_perruques/perruque_naturelle.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruque naturelles',
          price: 350,
          description: 'perruque en vrais cheveux 100% naturels Remy Hair pour une longevité maximum',
          to: '/perruques-cheveux-naturels'
        },
        {
          id: 3,
          url: require('../assets/images_perruques/perruque_prime.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques fibre mixte',
          price: 350,
          description: "L'avantage du chebveux naturel et de la fibre synthétique",
          to: '/perruques-fibre-prime'
        },
        {
          id: 4,
          url: require('../assets/images_perruques/perruque_relooking.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques petits prix',
          price: 350,
          description: 'Sans remboursement sécu pour des budgets plus modérés',
          to: '/perruques-relooking'
        },
        {
          id: 5,
          url: require('../assets/images_perruques/perruque_lace.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques type lace wig',
          price: 350,
          description: 'chevelure ultra réaliste et indetectable 100% personnalisable',
          to: '/perruques-type-lace'
        },
        {
          id: 6,
          url: require('../assets/perruque_homme/ew_hairformance_justin_4.webp'),
          alt: 'perruque homme cheveux poivre et sel coupe courte',
          name: 'Perruques homme',
          description: 'Ligne de perruques dediée aux hommes remboursées par la sécu. Coupe personnalisable.',
          to: '/perruques-homme'
        }
      ],
      imagesFibrePrime: [
        {
          id: 1,
          url: require('../assets/fibrePrime/adore_1.jpg'),
          alt: 'perruque fibre femme',
          name: 'Adore',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 861.6,
          slide: [
            { url: require('../assets/fibrePrime/adore_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/adore_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/adore_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/fibrePrime/colors/adore/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/fibrePrime/colors/adore/caramel mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/fibrePrime/colors/adore/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/adore/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/fibrePrime/colors/adore/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/fibrePrime/colors/adore/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/fibrePrime/colors/adore/hotchilli mix.jpg'), alt: 'perruque femme blond', name: 'hotchilli mix' },
            { url: require('../assets/fibrePrime/colors/adore/lightchampagne mix.jpg'), alt: 'perruque femme blond', name: 'lightchampagne mix' },
            { url: require('../assets/fibrePrime/colors/adore/moccarooted.jpg'), alt: 'perruque femme blond', name: 'moccarooted' },
            { url: require('../assets/fibrePrime/colors/adore/nutbrown mix.jpg'), alt: 'perruque femme blond', name: 'nutbrown mix' },
            { url: require('../assets/fibrePrime/colors/adore/safranred rooted.jpg'), alt: 'perruque femme blond', name: 'safranred rooted' },
            { url: require('../assets/fibrePrime/colors/adore/silverblonde rooted.jpg'), alt: 'perruque femme blond', name: 'silverblonde rooted' }
          ]
        },
        {
          id: 2,
          url: require('../assets/fibrePrime/allure_2.jpg'),
          alt: 'perruque fibre femme',
          name: 'Allure',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 909.6,
          slide: [
            { url: require('../assets/fibrePrime/allure_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/allure_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/allure_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/fibrePrime/colors/allure/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/fibrePrime/colors/allure/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/allure/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/fibrePrime/colors/allure/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/fibrePrime/colors/allure/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/fibrePrime/colors/allure/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/fibrePrime/colors/allure/smoke mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/fibrePrime/colors/allure/stonegrey mix.jpg'), alt: 'perruque femme blond', name: 'stonegrey mix' }
          ]
        },
        {
          id: 3,
          url: require('../assets/fibrePrime/amaze_1.jpg'),
          alt: 'perruque fibre femme',
          name: 'Amaze',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 885.6,
          slide: [
            { url: require('../assets/fibrePrime/amaze_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/amaze_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/amaze_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/fibrePrime/colors/amaze/auburn rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/amaze/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/amaze/caramel mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/amaze/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/amaze/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/amaze/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/amaze/espresso mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/amaze/lightbernstein mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/amaze/lightchampagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/amaze/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/amaze/silverblonde rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' }
          ]
        },
        {
          id: 4,
          url: require('../assets/fibrePrime/catch_1.jpg'),
          alt: 'perruque fibre femme',
          name: 'Catch',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1125.6,
          slide: [
            { url: require('../assets/fibrePrime/catch_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/catch_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/fibrePrime/colors/catch/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/catch/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/catch/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/catch/espresso mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/catch/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/catch/salt pepper mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/catch/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' }
          ]
        },
        {
          id: 5,
          url: require('../assets/fibrePrime/encore_2.jpg'),
          alt: 'perruque fibre femme',
          name: 'Encore',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 957.6,
          slide: [
            { url: require('../assets/fibrePrime/encore_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/encore_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/encore_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/fibrePrime/colors/encore/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/fibrePrime/colors/encore/caramel mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/fibrePrime/colors/encore/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/encore/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/fibrePrime/colors/encore/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/fibrePrime/colors/encore/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/fibrePrime/colors/encore/hotchilli mix.jpg'), alt: 'perruque femme blond', name: 'hotchilli mix' },
            { url: require('../assets/fibrePrime/colors/encore/lightchampagne mix.jpg'), alt: 'perruque femme blond', name: 'lightchampagne mix' },
            { url: require('../assets/fibrePrime/colors/encore/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/fibrePrime/colors/encore/naturewhite mix.jpg'), alt: 'perruque femme blond', name: 'naturewhite mix' },
            { url: require('../assets/fibrePrime/colors/encore/nutbrown mix.jpg'), alt: 'perruque femme blond', name: 'nutbrown mix' },
            { url: require('../assets/fibrePrime/colors/encore/salt pepper mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/fibrePrime/colors/encore/silverblonde rooted.jpg'), alt: 'perruque femme blond', name: 'silverblonde rooted' },
            { url: require('../assets/fibrePrime/colors/encore/smoke mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' }
          ]
        },
        {
          id: 6,
          url: require('../assets/fibrePrime/focus_2.jpg'),
          alt: 'perruque fibre femme',
          name: 'Focus',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 940.8,
          slide: [
            { url: require('../assets/fibrePrime/focus_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/focus_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/focus_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/fibrePrime/colors/focus/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/fibrePrime/colors/focus/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/focus/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/fibrePrime/colors/focus/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/fibrePrime/colors/focus/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/fibrePrime/colors/focus/naturewhite mix.jpg'), alt: 'perruque femme blond', name: 'naturewhite mix' },
            { url: require('../assets/fibrePrime/colors/focus/pearlblonde mix.jpg'), alt: 'perruque femme blond', name: 'pearlblonde mix' },
            { url: require('../assets/fibrePrime/colors/focus/sand rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' }
          ]
        },
        {
          id: 7,
          url: require('../assets/fibrePrime/illusion_1.jpg'),
          alt: 'perruque fibre femme',
          name: 'Illusion',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1332,
          slide: [
            { url: require('../assets/fibrePrime/illusion_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/illusion_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/illusion_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/fibrePrime/colors/illusion/auburn rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/fibrePrime/colors/illusion/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/fibrePrime/colors/illusion/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/illusion/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/fibrePrime/colors/illusion/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/fibrePrime/colors/illusion/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/fibrePrime/colors/illusion/lightbernstein mix.jpg'), alt: 'perruque femme blond', name: 'lightbernstein mix' },
            { url: require('../assets/fibrePrime/colors/illusion/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/fibrePrime/colors/illusion/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' }
          ]
        },
        {
          id: 8,
          url: require('../assets/fibrePrime/image_2.jpg'),
          alt: 'perruque fibre femme',
          name: 'Image',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1680,
          slide: [
            { url: require('../assets/fibrePrime/image_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/image_1.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/fibrePrime/colors/image/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/image/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/fibrePrime/colors/image/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/fibrePrime/colors/image/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/fibrePrime/colors/image/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/fibrePrime/colors/image/lightchampagne rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/fibrePrime/colors/image/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/fibrePrime/colors/image/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 9,
          url: require('../assets/fibrePrime/impulse_3.jpg'),
          alt: 'perruque fibre femme',
          name: 'Impulse',
          description: '100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1101,
          slide: [
            { url: require('../assets/fibrePrime/impulse_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/impulse_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/fibrePrime/colors/impulse/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/fibrePrime/colors/impulse/caramel mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/fibrePrime/colors/impulse/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/impulse/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/fibrePrime/colors/impulse/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/fibrePrime/colors/impulse/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/fibrePrime/colors/impulse/smoke mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' }
          ]
        },
        {
          id: 10,
          url: require('../assets/fibrePrime/interest_1.jpg'),
          alt: 'perruque fibre femme',
          name: 'Interest',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1005.6,
          slide: [
            { url: require('../assets/fibrePrime/interest_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/interest_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/interest_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/fibrePrime/colors/interest/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/fibrePrime/colors/interest/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/interest/chocolate rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/fibrePrime/colors/interest/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/fibrePrime/colors/interest/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/fibrePrime/colors/interest/lightchampagne mix.jpg'), alt: 'perruque femme blond', name: 'lightchampagne mix' }
          ]
        },
        {
          id: 11,
          url: require('../assets/fibrePrime/mood_1.jpg'),
          alt: 'perruque fibre femme',
          name: 'Mood',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1140,
          slide: [
            { url: require('../assets/fibrePrime/mood_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/mood_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/mood_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/fibrePrime/colors/mood/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/fibrePrime/colors/mood/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/mood/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/fibrePrime/colors/mood/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/fibrePrime/colors/mood/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/fibrePrime/colors/mood/lightbernstein mix.jpg'), alt: 'perruque femme blond', name: 'lightbernstein mix' },
            { url: require('../assets/fibrePrime/colors/mood/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/fibrePrime/colors/mood/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' }
          ]
        },
        {
          id: 12,
          url: require('../assets/fibrePrime/promise_1.jpg'),
          alt: 'perruque fibre femme',
          name: 'Promise',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 987,
          slide: [
            { url: require('../assets/fibrePrime/promise_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/promise_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/promise_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/fibrePrime/colors/promise/auburn rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/fibrePrime/colors/promise/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/promise/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/fibrePrime/colors/promise/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/fibrePrime/colors/promise/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/fibrePrime/colors/promise/pearlblonde rooted.jpg'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/fibrePrime/colors/promise/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' }
          ]
        },
        {
          id: 13,
          url: require('../assets/fibrePrime/spirit_1.jpg'),
          alt: 'perruque fibre femme',
          name: 'Spirit',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 1404,
          slide: [
            { url: require('../assets/fibrePrime/spirit_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/spirit_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/fibrePrime/spirit_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/fibrePrime/colors/spirit/auburn rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/fibrePrime/colors/spirit/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/fibrePrime/colors/spirit/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/fibrePrime/colors/spirit/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/fibrePrime/colors/spirit/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/fibrePrime/colors/spirit/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/fibrePrime/colors/spirit/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/fibrePrime/colors/spirit/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' }
          ]
        }
      ]
    }
  },
  components: {
    BaseModal,
    Carousel
  },
  mounted () {
    const parallax = document.getElementById('parallax')
    window.addEventListener('scroll', function () {
      const offset = window.scrollY
      parallax.style.backgroundPositionY = offset * 0.2 + 'px'
    })
  },
  methods: {
    toggleModal (id) {
      if (this.isShowModal !== id) {
        this.isShowModal = true
        this.isShowModal = id
        const body = document.querySelector('body')
        // const containerBody = document.querySelector('.container_body')
        if (this.isShowModal === id | id === this.imagesFibrePrime.id) {
          body.style.overflow = 'hidden'
          // containerBody.style.visibility = 'hidden'
          // console.log('la popup est ouverte !')
        }
      } else {
        const body = document.querySelector('body')
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
        body.style.overflow = 'visible'
      }
    },
    closePopup (id) {
      const body = document.querySelector('body')
      if (this.isShowModal !== id) {
        body.style.overflow = 'visible'
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
      }
    },
    setCurrent (id) {
      this.current = id
      // console.log(id)
    }
  },
  computed: {
    filteredNames () {
      return this.imagesFibrePrime.filter(item => item.name.toLowerCase().includes(this.rechercher.toLowerCase()))
    },
    add () {
      return this.title
    }
  }
})
</script>
<style lang="scss" scoped>
.PerruquesFibrePrime {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    margin-bottom: 48px;
     float: right;
}
.containerTitleCatalogue {
  align-items: center;
    flex-wrap: wrap;
    /* justify-items: center; */
    display: flex;
    flex-direction: column;
}
.title_tab {
  margin: 0px 0px 40px 0px;
    padding-top: 40px;
}

.fixed {
  height: 100vh;
  overflow: hidden;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
.container_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.sub_container_Fibre_Prime{
 display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}
.container_Fibre_Prime {
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom:70px;
    // z-index: 0;
}
.container_img_name {
 margin: 5px;
    padding: 0;
    display: inline-grid;
    width: 30%;
    position: relative;
    cursor: pointer;
  img {
    width: 100%;
  }
  .container_name_price {
    position: absolute;
    background: white;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    color: black;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    .name, .price {
    font-size: 15px;
    @media(min-width: 550px ) {
      font-size:20px;
    }
  }
  }
}
.transition {
border-top: 1px solid black;
    width: 80%;
    margin: 25px;
}
@media (max-width: 550px) {
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans4.jpg')
}
.container_img_name {
  width: 47%;
.name {
  font-size: 18px;
}
}
.PerruquesFibrePrime {
  width:100%;
}
}
@media (max-width: 990px) {

.PerruquesFibrePrime {
  width:100%;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg')
}
}
@media (min-width: 990px) {
  .container_img_name {
  max-width: 30%;
.name {
  font-size: 30px;
}
}
}

// @media only screen and (max-width: 700px) {
//   table,
//   thead,
//   tbody,
//   tr,
//   th,
//   td {
//     display: block;
//   }
//   .entry-content table:not(.variations),
//   body.et-pb-preview #main-content .container table {
//     border: 1px solid black;
//   }
//   thead {
//     display: none;
//   }
//   td {
//     padding-left: 150px !important;
//     position: relative;
//     margin-top: -1px;
//     background: #fff;
//     font-size: 10px;
//     line-height: 15px;
//     color: black;
//   }
//   td:nth-child(odd) {
//     background-color: #eee;
//   }

//   td::before {
//     padding: 10px;
//     content: attr(data-label);
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 130px;
//     bottom: 0;
//     background-color: #000;
//     color: #fff;
//     display: flex;
//     align-items: center;
//     font-weight: bold;
//   }
//   tr {
//     margin-bottom: 1rem;
//   }
// }

</style>
