<template>
  <div class="GestionCookies">
    <h1>Gestion des cookies</h1>
    <div class="containerPageCookies">
    <div class="containerCookies">
    <p>
        LIENS HYPERTEXTES ET COOKIES <br>
Le site www.lhairderien.fr contient un certain nombre de liens hypertextes vers d’autres
sites, mis en place avec l’autorisation de Mme Cornier Floriane et Leatitia Delaye. Cependant,
Mme Cornier Floriane et Leatitia Delaye n’ont pas la possibilité de vérifier le contenu des sites ainsi
visités, et n’assumeront en conséquence aucune responsabilité de ce fait. <br>
Dans l’hypothèse où un utilisateur ou visiteur souhaiterait mettre en place un hyperlien en
direction du site internet www.lhairderien.fr, il lui appartiendra d’adresser
un email accessible sur le site afin de formuler sa demande de mise en place d’un hyperlien. <br>
www.lhairderien.fr se réserve le droit d’accepter ou de refuser un hyperlien sans
avoir à en justifier sa décision. <br>
La navigation sur le site www.lhairderien.fr est susceptible de provoquer l’installation de
cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier de petite taille, qui ne
permet pas l’identification de l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un ordinateur sur un site. Nous collectons ainsi de manière automatique
certaines informations vous concernant lors d’une simple navigation sur notre site Internet,
notamment : des informations concernant l’utilisation de notre site, comme les zones que
vous visitez et les services auxquels vous accédez, votre adresse IP, le type de votre
navigateur, vos temps d’accès. De telles informations sont utilisées exclusivement à des fins
de statistiques internes et de sécurité du site lhairderien.fr, ou pour améliorer
la qualité des services qui vous sont proposés. Les bases de données sont protégées par
les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996
relative à la protection juridique des bases de données. <br>
Le site lhairderien.fr utilise des solutions de société tierces qui nécessitent
l’utilisation de cookies récoltant des informations personnelles : <br>
– Solution statistique Google Analytics. <br>
– Solution de sécurité Wordfence <br>
Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. <br>
L’utilisateur peut toutefois configurer son ordinateur de la manière suivante, pour refuser
l’installation des cookies : <br>
Sous Internet Explorer : onglet outil (pictogramme en forme de rouage en haut a droite) /
options internet. Cliquez sur Confidentialité et choisissez Bloquer tous les cookies. <br> Validez
sur Ok.
Sous Firefox : en haut de la fenêtre du navigateur, cliquez sur le bouton Firefox, puis aller
dans l’onglet Options. Cliquer sur l’onglet Vie privée. Paramétrez les Règles de conservation
sur : utiliser les paramètres personnalisés pour l’historique. Enfin décochez-la pour
désactiver les cookies. <br>
Sous Safari : Cliquez en haut à droite du navigateur sur le pictogramme de menu (symbolisé
par un rouage). Sélectionnez Paramètres. Cliquez sur Afficher les paramètres avancés. <br>
Dans la section « Confidentialité », cliquez sur Paramètres de contenu. Dans la section «
Cookies », vous pouvez bloquer les cookies. <br>
Sous Chrome : Cliquez en haut à droite du navigateur sur le pictogramme de menu
(symbolisé par trois lignes horizontales). Sélectionnez Paramètres. Cliquez sur Afficher les
paramètres avancés. Dans la section « Confidentialité », cliquez sur préférences. <br> Dans
l’onglet « Confidentialité », vous pouvez bloquer les cookies.
    </p>
</div>
</div>
  </div>
</template>
<script>
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - Gestion-cookies',
      description: '',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/gestion-cookies'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  }
})

</script>

<style lang="scss" scoped>
.GestionCookies {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: left;
     float: right;
     background: gray;
     height: 100vh;
}
.containerPageCookies {
    width:100%;
    display:flex;
    justify-content: center;
}
.containerCookies {
    // display: flex;
    width: 90%;
    background: white;
    // height: 100vh;
    padding: 20px;
}
@media (max-width:990px) {
  .GestionCookies {
    width: 100%;
  }
}
</style>
