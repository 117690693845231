<template>
   <head>
  <link rel="canonical" href="https://lhairderien-perruque.fr/bonnets-turbans">
  </head>
  <!-- <div class="BonnetsEtTurbans" :class="{fixed:propFixed}"> -->
    <div class="BonnetsEtTurbans">
      <header class='header-image__image1' id="parallax">

        <div class="firstContainerWidget">
          <div class="containerLogo">
            <img class="img_logo" src="https://lhairderien-perruque.fr/lhairderien/src/assets/logo_hdr_2.webp" alt="">
          </div>
          <div class="containerCTA">
            <h1 class="titleCTA" style="margin-bottom:10px; ">Venez decouvrir notre salon</h1>
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight" style="user-select: none; pointer-events:none;">
                <h6 class="titleCTA" style=" font-weight: 800 !important;">Prendre rendez-vous</h6>
                <h5 class="titleCTA" style=" font-weight: 800 !important;">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
        </div>
      </div>

      <div class='header-image__content'>
        <div class='header-image__content__container'>
        </div>
      </div>
      </header>

          <!-- <img :src="imagest[0].url" alt="">
          <img :src="imagest[1].url" alt="">
          <img :src="imagest[2].url" alt=""> -->
        <div class="container_body">
          <div class="container_baby_doll" >
            <div class="container_full_intro">
              <div class="container_intro_pmf">
                <p style="margin-bottom:20px;"> Lors de votre chimiothérapie, le cuir chevelu peut démanger, c’est pourquoi nous vous proposons différents turbans qui sont synonyme de confort. <br> <br>

                En toute légèreté, ayez du style ! <br> <br>

                Depuis 2019, les turbans sont remboursés à hauteur de 125€, n'hésitez pas à venir en profiter. <br>
                En essayage en cabine avec conseils du professionnel qui est là pour vous accompagner, nous pourront vous donner des conseils de nouages, également sur les différentes matières de turbans et sur leur entretien. Bonnet thermorégulant et anti UV quand il fait chaud, bonnet en bambou pour un maximum de confort, en coton pour une respiration de la peau et des prix mini. <br> <br>

                Également mis à disposition : un showroom cocooning pour essayer en toute autonomie. <br> <br>

                Neutres à motifs ou colorés, n'hésitez pas à jouer avec les turbans, Les accessoires sont nos amis ! <br>
                Franges, couronnes, foulard, bijoux, de quoi mettre en valeur votre joli turban. </p>
                <div class="containerTableau">
                  <div class="tableau1">
                    <h4 style="text-align:left; margin-left:30px; font-family: roboto;">Prothèse capillaire partielle<br>
                      + un accessoire textile</h4><br>
                    <ul style="padding-left:40px">
                      <li class="list1">Prix de vente forfaitaire : 125 euros</li> <br>
                      <li class="list1">Montant remboursé par la Sécurité Sociale : 125 euros</li><br>
                      <li class="list1">Aucun reste à charge / Prise en charge 100%</li><br>
                    </ul>
                    <!-- <div>
                      <h4 style="font-weight:bold">Perruques 100% remboursées</h4>
                    </div> -->
                  </div>
                  <div class="tableau2">
                    <h4 style="text-align:left; margin-left:30px; font-family: roboto;">3 accessoires capillaires <br>
                      + un bonnet easy fit</h4><br>
                    <ul style="padding-left:40px">
                      <li class="list1">Prix de vente forfaitaire  : 40 euros</li> <br>
                      <li class="list1">Montant remboursé par la Sécurité Sociale : 20 euros</li><br>
                      <li class="list1">Votre mutuelle / complémentaire santé peut prendre tout ou en partie le reste à charge</li><br>
                    </ul>
                    <div>
                      <h4 >Notre Pack Santé Accessoires contient deux basics coton et un bandeau karma de votre choix.</h4>
                    </div>
                  </div>
                </div>

                <input style="margin-top:30px; border:2px solid black; padding:5px; margin-bottom:30px; " placeholder="rechercher une perruque" type="text" v-model="rechercher">
              </div>
            </div>
              <div class="sub_container_baby_doll">
                <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in filteredNames" :key="item.id" >
                  <img :src="item.url" :alt="item.alt" @click="toggleModal(item.id)">
                  <div class="container_name_price">
                    <div v-if="current === item.id "  class="name">{{ item.name }}</div>
                    <div v-if="current === item.id "  class="price">{{ item.price }}€</div>
                  </div>
                  <base-modal-lace v-if="isShowModal === item.id" :imagesBabyDoll="item.slide" :priceToto = item.price :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :descriptionTitle3 = item.description3 :descriptionTitle4 = item.description4 :totoTest3= item.toto3 :totoTest4= item.toto4 :nameCell = item.name @close="closePopup()" ></base-modal-lace>
                </div>
              </div>
            </div>
            <span class="transition"></span>
              <!-- </div>
            </div> -->
          </div>
    </div>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
import BaseModalLace from '@/components/baseModalLace.vue'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - bonnets et turbans avec un large choix de coloris',
      description: '',
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/bonnets-turbans'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      rechercher: '',
      myModal: false,
      current: false,
      isShowModal: false,
      propFixed: false,
      imagest: [
        { url: require('../assets/imgTurbans1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans3.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans4.jpg'), alt: 'turbans femme' }
      ],
      imagesBabyDoll: [
        {
          id: 1,
          url: require('../assets/babydoll/bonnet-baby-doll-dena-sable.jpg'),
          alt: 'bonnet femme',
          name: 'Baby Doll Sable',
          description2: 'Bonnet style Baby doll noué façon turban en Coton disposant d’une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ',
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          toto4: '*** Sur la photo nous avons associé le Dena Baby Doll à la frange Sophia',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          price: 60,
          slide: [
            { url: require('../assets/babydoll/bonnet-baby-doll-dena-sable.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-dena-sable_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-dena-sable_2.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 2,
          url: require('../assets/babydoll/bonnet-baby-doll-aqui-d-aia-blanc4.jpg'),
          alt: 'bonnet femme',
          name: 'Baby Doll Blanc',
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          price: 60,
          slide: [
            { url: require('../assets/babydoll/bonnet-baby-doll-aqui-d-aia-blanc4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-aqui-d-aia-blanc4_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-aqui-d-aia-blanc4_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 3,
          url: require('../assets/babydoll/bonnet-baby-doll-hateya-vert.jpg'),
          alt: 'bonnet femme',
          name: 'Baby Doll Vert',
          price: 60,
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/babydoll/bonnet-baby-doll-hateya-vert.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-hateya-vert_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-hateya-vert_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 4,
          url: require('../assets/babydoll/bonnet-baby-doll-boufaisse-fuchsia.jpg'),
          alt: 'bonnet femme',
          name: 'Baby Doll Fuschia',
          price: 60,
          description2: "Bonnet baby doll façon turban fuchsia assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. Se porte de trois façons différentes (cf tutos).",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/babydoll/bonnet-baby-doll-boufaisse-fuchsia.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-boufaïsse-fuchsia_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-boufaïsse-fuchsia_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 5,
          url: require('../assets/babydoll/bonnet-baby-doll-nissa-bleu.jpg'),
          alt: 'bonnet femme',
          name: 'Baby Doll Bleu',
          price: 60,
          description2: "Bonnet baby doll façon turban bleu klein assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. Se porte de trois façons différentes (cf tutos).",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/babydoll/bonnet-baby-doll-nissa-bleu.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-nissa-bleu_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-nissa-bleu_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 6,
          url: require('../assets/babydoll/bonnet-baby-doll-aqui-d-aia-blanc.jpg'),
          alt: 'bonnet femme',
          name: 'Baby Doll Rouge',
          price: 60,
          description2: "Bonnet baby doll façon turban rouge assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/babydoll/bonnet-baby-doll-aqui-d-aia-blanc.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-aqui-d-aia-blanc_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-aqui-d-aia-blanc_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 7,
          url: require('../assets/babydoll/bonnet-baby-doll-jeans-bleu.jpg'),
          alt: 'bonnet femme',
          name: 'Baby Doll Bleu Jeans',
          price: 60,
          description2: "Bonnet baby doll façon turban bleu jeans assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/babydoll/bonnet-baby-doll-jeans-bleu.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-jeans-bleu_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-jeans-bleu_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 8,
          url: require('../assets/babydoll/bonnet-baby-doll-lou-pantai-jaune.jpg'),
          alt: 'bonnet femme',
          name: 'Baby Doll Jaune',
          price: 60,
          description2: "Bonnet baby doll façon turban jaune assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/babydoll/bonnet-baby-doll-lou-pantai-jaune.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-lou-pantai-jaune_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-lou-pantai-jaune_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 9,
          url: require('../assets/babydoll/bonnet-baby-doll-rauba-capeu-noir.jpg'),
          alt: 'bonnet femme',
          name: 'Baby Doll Noir',
          price: 60,
          description2: "Bonnet baby doll façon turban noir assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/babydoll/bonnet-baby-doll-rauba-capeu-noir.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-rauba-capeu-noir_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-rauba-capeu-noir_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 10,
          url: require('../assets/babydoll/bonnet-baby-doll-rust-rouille.jpg'),
          alt: 'bonnet femme',
          name: 'Baby Doll Rouille',
          price: 60,
          description2: "Bonnet baby doll façon turban rouille assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/babydoll/bonnet-baby-doll-rust-rouille.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-rust-rouille_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/babydoll/bonnet-baby-doll-rust-rouille_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        // ],
        // imagesCross: [
        {
          id: 11,
          url: require('../assets/cross/bonnet-cross-milano-noir3-1.jpg'),
          alt: 'bonnet femme',
          name: 'Cross Noir',
          price: 60,
          description2: 'Bonnet croisé collection "intemporelle", cousu en fourreau (sans coutures) de couleur noir.',
          description: "(Peut aussi se porter en cas d'alopécie avec une fausse frange) Sur la photo le bonnet à été associé à la frange châtain miel coupe droite Romy brooks Lavage à la main préconisé.",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/cross/bonnet-cross-milano-noir3-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-milano-noir3_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-milano-noir3_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 12,
          url: require('../assets/cross/bonnet-cross-nico-bleu2-1.jpg'),
          alt: 'bonnet femme',
          name: 'Cross Bleu marine',
          price: 60,
          description2: 'Bonnet croisé collection "intemporelles", cousu en fourreau (sans coutures) de couleur bleu marine.',
          description: "Sur la photo le bonnet Nico Cross est associé aux franges rousse sophia et auburn jane. Associer une couleur froide à une couleur chaude permet d'apporter un joli équilibre.",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/cross/bonnet-cross-nico-bleu2-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-nico-bleu2_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-nico-bleu2_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 13,
          url: require('../assets/cross/bonnet-cross-axelle-rouge-1.jpg'),
          alt: 'bonnet femme',
          name: 'Cross rouge',
          price: 60,
          description2: 'Bonnet croisé collection "intemporelle", cousu en fourreau (sans coutures) couleur rouge.',
          description: "(En cas d'alopécie, peut se porter avec une fausse frange, voir rubrique Frange). Sur la photo le bonnet Axelle Cross est associé à la frange blanche coupe universelle Françoise Lavage à la main préconisé.",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/cross/bonnet-cross-axelle-rouge-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-axelle-rouge-2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-axelle-rouge-3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 14,
          url: require('../assets/cross/bonnet-cross-gile-moutarde3-1.jpg'),
          alt: 'bonnet femme',
          name: 'Cross Moutarde',
          price: 60,
          description2: 'Bonnet croisé collection "intemporelle", cousu en fourreau (sans coutures) couleur rouge.',
          description: "(En cas d'alopécie, peut se porter avec une fausse frange, voir rubrique Frange). Sur la photo le bonnet Axelle Cross est associé à la frange blanche coupe universelle Françoise Lavage à la main préconisé. Sur la photo le bonnet Gile Moutarde est associé à la frange droite Scarlette Brooks.",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/cross/bonnet-cross-gile-moutarde3-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-gile-moutarde3_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-gile-moutarde3_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 15,
          url: require('../assets/cross/bonnet-cross-camille-blanc-1.jpg'),
          alt: 'bonnet femme',
          name: 'Cross Blanc-écru',
          price: 60,
          description2: "Bonnet croisé collection cérémonie & soirée by Les FranJYnes, cousue en fourreau (sans coutures) de couleur blanc - écru (En cas d'alopécie, peut se porter avec une fausse frange)",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/cross/bonnet-cross-camille-blanc-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-camille-blanc-1_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-camille-blanc-1_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 16,
          url: require('../assets/cross/bonnet-cross-charlie-rose-1.jpg'),
          alt: 'bonnet femme',
          name: 'Cross Rose',
          price: 60,
          description2: 'Bonnet croisé collection intemporelle, cousu en fourreau (sans coutures) de couleur vieux Rose. Sur la photo le bonnet Charlie Cross est associé à la frange droite bloc foncé "julie". Lavage à la main préconisé.',
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/cross/bonnet-cross-charlie-rose-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-charlie-rose-1_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-charlie-rose-1_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 17,
          url: require('../assets/cross/bonnet-cross-christian-gris-fonce-1.jpg'),
          alt: 'bonnet femme',
          name: 'Cross Gris',
          price: 60,
          description2: "Bonnet Cross gris anthracite en Punto di Milano. Bonnet fabriqué par Les Franjynes dans l'atelier magique de Mme Fée (En cas d'alopécie, peut se porter avec une fausse frange)",
          description: 'Sur la photo nous avons associé le bonnet cross Christian à la frange "Françoise"',
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/cross/bonnet-cross-christian-gris-fonce-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-christian-gris-fonce-1_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-christian-gris-fonce-1_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 18,
          url: require('../assets/cross/bonnet-cross-cigale-vert-sapin2-1.jpg'),
          alt: 'bonnet femme',
          name: 'Cross Vert Sapin',
          price: 60,
          description2: 'Bonnet croisé, cousu en fourreau (sans coutures apparentes) en tissu crêpe vert.',
          description: 'Sur la photo, nous avons associé le bonnet Cross Cigale vert à la frange "Sophia roux". Lavage à la main préconisé.',
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/cross/bonnet-cross-cigale-vert-sapin2-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-cigale-vert-sapin2_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-cigale-vert-sapin2_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 19,
          url: require('../assets/cross/bonnet-cross-frhongrie-gris-perle2-1.jpg'),
          alt: 'bonnet femme',
          name: 'Cross Gris Perle',
          price: 60,
          description2: 'Bonnet croisé, cousu en fourreau (sans coutures) de couleur gris perle.',
          description: 'Sur la photo le bonnet Cross  Gris Perle est associé à la frange "Meryl" (poivre & sel). Le bonnet est en tissu crêpe. Lavage à la main préconisé.',
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/cross/bonnet-cross-frhongrie-gris-perle2-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-frhongrie-gris-perle2_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-frhongrie-gris-perle2_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 20,
          url: require('../assets/cross/bonnet-cross-prunelle-prune2-1.jpg'),
          alt: 'bonnet femme',
          name: 'Cross Prune',
          price: 60,
          description2: 'Bonnet croisé, cousu en fourreau (sans coutures) de couleur prune',
          description: "Sur la photo le bonnet Cross Prune est associé à la frange reverse Paloma. Associer une couleur froide à une couleur chaude permet d'apporter un joli équilibre. Lavage à la main préconisé.",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/cross/bonnet-cross-prunelle-prune2-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-prunelle-prune2_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/cross/bonnet-cross-prunelle-prune2_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        // ],
        // imagesPrimavera: [
        {
          id: 21,
          url: require('../assets/primavera/bonnet-primavera-black-noir.jpg'),
          alt: 'bonnet femme',
          name: 'Primavera Noir',
          price: 69,
          description: 'Bonnet Primavera noir 2 en 1. labellisé Oeko-tex, ne contenant aucune substance nuisible, sans couture apparente, fabriqué sous le soleil du sud de la france.',
          description2: 'Sur la photo nous avons associé le bonnet Primavera Black à la frange poivre et sel Meryl',
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/primavera/bonnet-primavera-black-noir.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/primavera/bonnet-primavera-black-noir_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/primavera/bonnet-primavera-black-noir_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 22,
          url: require('../assets/primavera/bonnet-primavera-marina-bleu-marine3.jpg'),
          alt: 'bonnet femme',
          name: 'Primavera Marine',
          price: 69,
          description: 'Bonnet "Primavera" bleu marine 3 en 1. labellisé Oeko-tex, ne contenant aucune substance nuisible, sans couture apparente, fabriqué sous le soleil du sud de la france.',
          description2: 'Sur la photo nous avons associé le bonnet Primavera Blue à la frange Lina (bouclée, blond dorée)',
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/primavera/bonnet-primavera-marina-bleu-marine3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/primavera/bonnet-primavera-marina-bleu-marine3_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/primavera/bonnet-primavera-marina-bleu-marine3_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 23,
          url: require('../assets/primavera/bonnet-primavera-blue-bleu2.jpg'),
          alt: 'bonnet femme',
          name: 'Primavera Bleu',
          price: 69,
          description: 'Bonnet "Primavera" bleu 3 en 1. labellisé Oeko-tex, ne contenant aucune substance nuisible, sans couture apparente, fabriqué sous le soleil du sud de la france.',
          description2: "sur la photo Julie a associé le Primavera Marina à la Frange reverse auburn moyen Julia ainsi qu'a la frange frontale de même couleur en coupe universelle Jane",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/primavera/bonnet-primavera-blue-bleu2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/primavera/bonnet-primavera-blue-bleu2_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/primavera/bonnet-primavera-blue-bleu2_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 24,
          url: require('../assets/primavera/bonnet-primavera-roma-kaki6.jpg'),
          alt: 'bonnet femme',
          name: 'Primavera Kaki',
          price: 69,
          description: 'Bonnet "Primavera" kaki 3 en 1. labellisé Oeko-tex, ne contenant aucune substance nuisible, sans couture apparente, fabriqué sous le soleil du sud de la france.',
          description2: 'Sur la photo nous avons associé le bonnet Primavera Kaki à la frange Alex Brooks',
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/primavera/bonnet-primavera-roma-kaki6.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/primavera/bonnet-primavera-roma-kaki6_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/primavera/bonnet-primavera-roma-kaki6_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 25,
          url: require('../assets/primavera/bonnet-primavera-angel-blanc.jpg'),
          alt: 'bonnet femme',
          name: 'Primavera Blanc',
          price: 69,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/primavera/bonnet-primavera-angel-blanc.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/primavera/bonnet-primavera-angel-blanc_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/primavera/bonnet-primavera-angel-blanc_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        // ],
        // imagesLili: [
        {
          id: 26,
          url: require('../assets/lili/bonnet-lili-nero-noir-1.jpg'),
          alt: 'bonnet femme',
          name: 'Lili Noir',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/lili/bonnet-lili-nero-noir-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/lili/bonnet-lili-nero-noir-1_2.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 27,
          url: require('../assets/lili/bonnet-lili-blue-bleu-1.jpg'),
          alt: 'bonnet femme',
          name: 'Lili Bleu Roi',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/lili/bonnet-lili-blue-bleu-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/lili/bonnet-lili-blue-bleu-1_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/lili/bonnet-lili-blue-bleu-1_2.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 28,
          url: require('../assets/lili/bonnet-lili-roma-kaki-1.jpg'),
          alt: 'bonnet femme',
          name: 'Lili Kaki',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/lili/bonnet-lili-roma-kaki-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/lili/bonnet-lili-roma-kaki-1_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/lili/bonnet-lili-roma-kaki-1_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 29,
          url: require('../assets/lili/bonnet-lili-angel-blanc-1.jpg'),
          alt: 'bonnet femme',
          name: 'Lili Blanc',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/lili/bonnet-lili-angel-blanc-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/lili/bonnet-lili-angel-blanc-1_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/lili/bonnet-lili-angel-blanc-1_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        // ],
        // imagesFebibi: [
        {
          id: 30,
          url: require('../assets/febibi/bonnet-febibi-nero-noir.jpg'),
          alt: 'bonnet femme',
          name: 'Febibi Noir',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/febibi/bonnet-febibi-nero-noir.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-nero-noir_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-nero-noir_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 31,
          url: require('../assets/febibi/bonnet-febibi-grey-gris-fonce2.jpg'),
          alt: 'bonnet femme',
          name: 'Febibi Gris',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/febibi/bonnet-febibi-grey-gris-fonce2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-grey-gris-fonce2_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-grey-gris-fonce2_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 32,
          url: require('../assets/febibi/bonnet-febibi-blue-bleu2.jpg'),
          alt: 'bonnet femme',
          name: 'Febibi Bleu Roi',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/febibi/bonnet-febibi-blue-bleu2.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 33,
          url: require('../assets/febibi/bonnet-febibi-rust-rouille.jpg'),
          alt: 'bonnet femme',
          name: 'Febibi Rouille',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/febibi/bonnet-febibi-rust-rouille.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-rust-rouille_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-rust-rouille_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 34,
          url: require('../assets/febibi/bonnet-febibi-rosa-rose4.jpg'),
          alt: 'bonnet femme',
          name: 'Febibi Rose',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/febibi/bonnet-febibi-rosa-rose4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-rosa-rose4_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-rosa-rose4_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 35,
          url: require('../assets/febibi/bonnet-febibi-mustard-moutarde1.jpg'),
          alt: 'bonnet femme',
          name: 'Febibi Jaune',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/febibi/bonnet-febibi-mustard-moutarde1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-mustard-moutarde1_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-mustard-moutarde1_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 36,
          url: require('../assets/febibi/bonnet-febibi-blue-jeans-1.jpg'),
          alt: 'bonnet femme',
          name: 'Febibi Bleu Jeans',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/febibi/bonnet-febibi-blue-jeans-1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-blue-jeans-2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-blue-jeans-3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 37,
          url: require('../assets/febibi/bonnet-febibi-hateya-vert.jpg'),
          alt: 'bonnet femme',
          name: 'Febibi Jeans Vert',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/febibi/bonnet-febibi-hateya-vert.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-hateya-vert_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/febibi/bonnet-febibi-hateya-vert_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 38,
          url: require('../assets/febibi/bonnet-febibi-dena-sable.jpg'),
          alt: 'bonnet femme',
          name: 'Febibi Sable',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/febibi/bonnet-febibi-dena-sable.jpg'), alt: 'perruque femme blond' }
          ]
        },
        // ],
        // imagesEllenWille: [
        {
          id: 39,
          url: require('../assets/ellenwille/easy_fit_3.jpg'),
          alt: 'bonnet femme',
          name: 'Ellen Wille Easy Fit',
          price: 20,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/ellenwille/easy_fit_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ellenwille/easy_fit_3_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ellenwille/easy_fit_3_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 40,
          url: require('../assets/ellenwille/magena_1.jpg'),
          alt: 'bonnet femme',
          name: 'Ellen Wille Magena',
          price: 40,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/ellenwille/magena_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ellenwille/magena_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ellenwille/magena_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 41,
          url: require('../assets/ellenwille/namida_1.jpg'),
          alt: 'bonnet femme',
          name: 'Ellen Wille Nemida',
          price: 35,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/ellenwille/namida_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ellenwille/namida_2.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 42,
          url: require('../assets/ellenwille/ama_fina_1.jpg'),
          alt: 'bonnet femme',
          name: 'Ellen Wille Ama Fina',
          price: 35,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/ellenwille/ama_fina_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ellenwille/ama_fina_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ellenwille/ama_fina_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 43,
          url: require('../assets/ellenwille/eh_devine_3.jpg'),
          alt: 'bonnet femme',
          name: 'Ellen Wille Eh Devine',
          price: 30,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/ellenwille/eh_devine_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ellenwille/eh_devine_4.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 44,
          url: require('../assets/ellenwille/avani_2.jpg'),
          alt: 'bonnet femme',
          name: 'Ellen Wille Avani',
          price: 40,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/ellenwille/avani_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ellenwille/avani_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ellenwille/avani_4.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 45,
          url: require('../assets/ellenwille/tala_2_1.jpg'),
          alt: 'bonnet femme',
          name: 'Ellen Wille Tala',
          price: 25,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/ellenwille/tala_2_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ellenwille/tala_2_2.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 46,
          url: require('../assets/ellenwille/eh_malou_3.jpg'),
          alt: 'bonnet femme',
          name: 'Ellen Wille Eh Malou',
          price: 60,
          description: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d' une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisée OEKO-TEX c’est a dire sans aucune substance nuisible et cousue en fourreau (sans couture)",
          description2: "Bonnet baby doll façon turban écru assortie à nos combis, robes et tops pour un ensemble méga chic. En tissus italien, disposant d'une doublure aux vertus thermorégulantes (qui se met à température corporelle) labellisées OEKO-TEX, cousue en fourreau afin de ne laisser aucune couture apparente, pour un confort maximal. ",
          toto2: '*** Vendu sans la frange***',
          toto3: '***Modèle déposé***',
          description3: "Le porter c'est être stylé mais c'est surtout soutenir et s'engager car ces turbans sont fabriqués dans un atelier de femmes malades en réinsertion professionnelle. Une partie de son prix de vente est reversée à la recherche contre le cancer !",
          slide: [
            { url: require('../assets/ellenwille/eh_malou_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ellenwille/eh_malou_3_2.jpg'), alt: 'perruque femme blond' }
          ]
        }
      ]

    }
  },
  components: {
    BaseModalLace
  },
  mounted () {
    const parallax = document.getElementById('parallax')
    window.addEventListener('scroll', function () {
      const offset = window.scrollY
      parallax.style.backgroundPositionY = offset * 0.2 + 'px'
    })
  },
  methods: {
    // openModal () {
    //   this.modalOpen = !this.modalOpen
    // },
    toggleModal (id) {
      this.isShowModal = id
      // window.scrollTo(0, 1800)
      const body = document.querySelector('body')
      // const containerBody = document.querySelector('.container_body')
      if (this.isShowModal === id | id === this.filteredNames.id) {
        body.style.overflow = 'hidden'
        // containerBody.style.visibility = 'hidden'
      } else {
        body.style.overflow = 'inherit'
        // containerBody.style.visibility = 'visible'
      }
    },
    closePopup (id) {
      const body = document.querySelector('body')
      if (this.isShowModal !== id) {
        body.style.overflow = 'visible'
        console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
      }
    },
    setCurrent (id) {
      this.current = id
      console.log(id)
    }
  },
  computed: {
    filteredNames () {
      return this.imagesBabyDoll.filter(item => item.name.toLowerCase().includes(this.rechercher.toLowerCase()))
    }
  }
})
</script>

<style lang="scss" scoped>
.BonnetsEtTurbans {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    margin-bottom: 48px;
    float: right;
}
.fixed {
  height: 100vh;
  overflow: hidden;
}
.header-image__image1 {
  background-image: url('http://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
.container_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.sub_container_baby_doll, .sub_container_cross, .sub_container_primavera, .sub_container_lili, .sub_container_febibi {
 display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;

}
.container_intro_pmf {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: 80%;
}
.container_full_intro {
 width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
}
.container_baby_doll, .container_cross, .container_primavera, .container_lili, .container_febibi {
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // z-index: 0;
}
.container_img_name {
 margin: 5px;
    padding: 0;
    display: inline-grid;
    width: 30%;
    position: relative;
    cursor: pointer;
  img {
    width: 100%;
  }
  .container_name_price {
    position: absolute;
    background: white;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    color: black;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    .name, .price {
    font-size: 22px;
  }
  }
}
.transition {
border-top: 1px solid black;
    width: 80%;
    margin: 25px;
}
@media (max-width: 550px) {
.header-image__image1 {
  background-image: url('http://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans4.jpg')
}
.container_img_name {
  width: 47%;
.name {
  font-size: 18px;
}
}
.BonnetsEtTurbans {
  width:100%;
}
}
@media (max-width: 990px) {

.BonnetsEtTurbans {
  width:100%;
}
.header-image__image1 {
  background-image: url('http://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg')
}
}
@media (min-width: 990px) {
  .container_img_name {
  max-width: 30%;
.name {
  font-size: 30px;
}
}
}

// @media only screen and (max-width: 700px) {
//   table,
//   thead,
//   tbody,
//   tr,
//   th,
//   td {
//     display: block;
//   }
//   .entry-content table:not(.variations),
//   body.et-pb-preview #main-content .container table {
//     border: 1px solid black;
//   }
//   thead {
//     display: none;
//   }
//   td {
//     padding-left: 150px !important;
//     position: relative;
//     margin-top: -1px;
//     background: #fff;
//     font-size: 10px;
//     line-height: 15px;
//     color: black;
//   }
//   td:nth-child(odd) {
//     background-color: #eee;
//   }

//   td::before {
//     padding: 10px;
//     content: attr(data-label);
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 130px;
//     bottom: 0;
//     background-color: #000;
//     color: #fff;
//     display: flex;
//     align-items: center;
//     font-weight: bold;
//   }
//   tr {
//     margin-bottom: 1rem;
//   }
// }

</style>
