<template>
  <div class="QuiSommesNous">
    <h1 style="height: 100vh; display: flex; justify-content: center; align-items: center; background: rgba(0, 249, 255, 0.14);">Cette page est actuellement en construction ! Encore un peu de patience !</h1>
  </div>
</template>
<script>
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - conseils specifiques pour les hommes',
      description: '',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/conseils-complements-capillaires-homme'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  }
})
</script>

<style lang="scss" scoped>
.QuiSommesNous {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    float: right;
}
@media(max-width: 990px) {
  .QuiSommesNous {
    width:100%;
  }
}
</style>
