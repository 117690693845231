<template>
  <div class="Volumateurs">
      <header class='header-image__image1' id="parallax">

        <div class="firstContainerWidget">
          <div class="containerLogo">
            <img class="img_logo" src="https://lhairderien-perruque.fr/lhairderien/src/assets/logo_hdr_2.webp" alt="">
          </div>
          <div class="containerCTA">
            <h1 class="titleCTA" style="margin-bottom:10px; ">Venez decouvrir notre salon</h1>
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight" style="user-select: none; pointer-events:none;">
                <h6 class="titleCTA" style=" font-weight: 800 !important;">Prendre rendez-vous</h6>
                <h5 class="titleCTA" style=" font-weight: 800 !important;">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
        </div>
      </div>

      <div class='header-image__content'>
        <div class='header-image__content__container'>
        </div>
      </div>
    </header>
    <div class="container_full_intro">
      <div class="container_intro_pmf">
        <h3 class="subtitle" >
        Modalité de remboursement pour les Perruques synthétiques et naturelles
        </h3>
        <!-- <p class="paragraph_intro">
          entrez le texte d'intoduction ici.
        </p>
        <p class="paragraph_intro">
          <br>
          Entrez le paragraphe ici.

        </p><br> -->
        <div class="containerTableau">
          <div class="tableau3">
            <h4 style="font-family:roboto; margin-left:30px; text-align:left;">Prothèse capillaire partielle <br>
              + un accessoire textile</h4><br>
            <ul style="padding-left:40px">
              <li class="list1">Prix de vente forfaitaire : 125 euros</li> <br>
              <li class="list1">Montant remboursé par la Sécurité Sociale : 125 euros</li><br>
              <li class="list1">Aucun reste à charge / Prise en charge 100%</li><br>
            </ul>
            <div>
              <!-- <h4 style="font-weight:bold">Perruques 100% remboursées</h4> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container_body">
      <input style="margin-top:30px; border:2px solid black; padding:5px; margin-bottom:30px;" placeholder="rechercher une perruque" type="text" v-model="rechercher">
          <div class="container_synthetic" >
            <div style="width:100%; background:#93dbd8 ;">
            <h4 style="font-family: Amatic sc;color: white;">Synthétiques</h4>
            </div>
            <!-- <h4>{{title[0]}}</h4> -->
            <div class="sub_container_synthetic">
              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="(item) in filteredNames.slice(0,12)" :key="item.id" >
                <img :src="item.url" :alt="item.alt"  @click="toggleModal(item.id)">
                 <ImagesSyntheticsBis :imagesSynthetics="item" @open="toggleModal(item.id)"/>
                <div v-if="current !== item.id " class="container_name_price">
                  <div class="name">{{ item.name }}</div>
                  <div  class="price">{{ item.price }}€</div>
                </div>
                <base-modal v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :descriptionTitle = item.description :priceToto = item.price :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name :size = item.size :lenth= item.lenth @close="closePopup()" ></base-modal>
              </div>
              <span class="transition">
                <div style="width:100%; background:#93dbd8 ;">
            <h4 style="font-family: Amatic sc;color: white;">Humans</h4>
            </div>
            <!-- <h4>{{title[1]}}</h4> -->
              </span>

              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="(item) in filteredNames.slice(12,20)" :key="item.id" >
                <img :src="item.url" :alt="item.alt"  @click="toggleModal(item.id)">
                 <ImagesHumanBis :imagesHuman="item" @open="toggleModal(item.id)"/>
                <div v-if="current !== item.id " class="container_name_price">
                  <div  class="name">{{ item.name }}</div>
                  <div  class="price">{{ item.price }}€</div>
                </div>
                <base-modal v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :priceToto = item.price :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name :size = item.size :lenth= item.lenth @close="closePopup()" ></base-modal>
              </div>
              <span class="transition">
                <div style="width:100%; background:#93dbd8 ;">
            <h4 style="font-family: Amatic sc;color: white;">Primes</h4>
            </div>
            <!-- <h4>{{title[2]}}</h4> -->
              </span>

              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="(item) in filteredNames.slice(20,21)" :key="item.id" >
                <img :src="item.url" :alt="item.alt"  @click="toggleModal(item.id)">
                 <ImagesPrimeHairBis :imagesPrimeHair="item" @open="toggleModal(item.id)"/>
                <div v-if="current !== item.id " class="container_name_price">
                  <div  class="name">{{ item.name }}</div>
                  <div  class="price">{{ item.price }}€</div>
                </div>
                <base-modal v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :descriptionTitle = item.description :priceToto = item.price :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name :size = item.size :lenth= item.lenth @close="closePopup()" ></base-modal>
              </div>
              <span class="transition">
                <!-- <span><h4>{{title[3]}}</h4></span> -->
              </span>
            </div>
          </div>
          <span class="transition"></span>
</div>
</div>

</template>
<script>
import BaseModal from '@/components/BaseModal.vue'
import ImagesHumanBis from '../components/ImagesHumanBis.vue'
import ImagesSyntheticsBis from '../components/ImagesSyntheticsBis.vue'
import ImagesPrimeHairBis from '../components/ImagesPrimeHairBis.vue'
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - un large choix de volumateurs adaptés à chaque situation',
      description: 'Un grand nombre de volumateurs remboursés par la sécurité sociale',
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/volumateurs'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      // title: ['sous-titre1', 'sous-titre2', 'sous-titre3'],
      rechercher: '',
      current: false,
      myModal: false,
      isShowModal: false,
      propFixed: false,
      imagest: [
        { url: require('../assets/imgTurbans1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans3.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans4.jpg'), alt: 'turbans femme' }
      ],
      imagesSynthetics: [

        {
          id: 1,
          url: require('../assets/perruqueSynthetique/close_1.jpg'),
          alt: 'volumateur synthetique',
          name: 'Close',
          size: 'Size: 29 x 22 cm',
          lenth: 'Length: 9 - 10 cm',
          price: 500,
          description: 'Les perruques en fibre synthétiques ellen wille sont reconnues pour leur qualité haut de gamme. La fibre capillaire synthétique procure en effet un éclat tellement naturel qu’elle ne se différencie pas, à première vue, de la fibre naturelle. Le coiffage est très simple car le mouvement du cheveu est intégré dans sa structure. La coiffure reste ainsi parfaite et peut se remettre en place simplement avec les doigts. Cette fibre permet en outre de réaliser une large palette de couleurs exceptionnelles et chatoyantes. Afin de préserver longtemps l’éclat de la chevelure, il est conseillé d‘utiliser des produits adaptés aux cheveux synthétiques, comme par exemple le set de soins (shampoing, balsam, conditionneur) avec notamment shampoing auto-nettoyant.',
          slide: [
            { url: require('../assets/perruqueSynthetique/close_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/close_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueSynthetique/colors/close/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/perruqueSynthetique/colors/close/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/perruqueSynthetique/colors/close/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/close/darksand mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/perruqueSynthetique/colors/close/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueSynthetique/colors/close/pearl mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/perruqueSynthetique/colors/close/salt pepper mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/perruqueSynthetique/colors/close/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/perruqueSynthetique/colors/close/smoke mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/perruqueSynthetique/colors/close/snow mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ],
          imagesSynthetics_bis: [
            { url: require('../assets/perruqueSynthetique/close_2.jpg'), alt: 'volumateur synthetique' }
          ]
        },
        {
          id: 2,
          url: require('../assets/perruqueSynthetique/couture.jpg'),
          alt: 'volumateur synthetique',
          name: 'Couture',
          size: 'Size: 29 x 22 cm',
          lenth: 'Length: 9 - 10 cm',
          price: 450,
          description: 'Les perruques en fibre synthétiques ellen wille sont reconnues pour leur qualité haut de gamme. La fibre capillaire synthétique procure en effet un éclat tellement naturel qu’elle ne se différencie pas, à première vue, de la fibre naturelle. Le coiffage est très simple car le mouvement du cheveu est intégré dans sa structure. La coiffure reste ainsi parfaite et peut se remettre en place simplement avec les doigts. Cette fibre permet en outre de réaliser une large palette de couleurs exceptionnelles et chatoyantes. Afin de préserver longtemps l’éclat de la chevelure, il est conseillé d‘utiliser des produits adaptés aux cheveux synthétiques, comme par exemple le set de soins (shampoing, balsam, conditionneur) avec notamment shampoing auto-nettoyant.',
          slide: [
            { url: require('../assets/perruqueSynthetique/couture.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/couture2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/couture3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/couture4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueSynthetique/colors/couture/auburn_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/perruqueSynthetique/colors/couture/chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/perruqueSynthetique/colors/couture/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/couture/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/perruqueSynthetique/colors/couture/lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueSynthetique/colors/couture/lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/perruqueSynthetique/colors/couture/mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/perruqueSynthetique/colors/couture/pearlblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/perruqueSynthetique/colors/couture/salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/perruqueSynthetique/colors/couture/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/perruqueSynthetique/colors/couture/silver_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ],
          imagesSynthetics_bis: [
            { url: require('../assets/perruqueSynthetique/couture2.jpg'), alt: 'volumateur synthetique' }
          ]
        },
        {
          id: 3,
          url: require('../assets/perruqueSynthetique/dream.jpg'),
          alt: 'volumateur synthetique',
          name: 'Dream',
          size: 'Size: 29 x 22 cm',
          lenth: 'Length: 9 - 10 cm',
          price: 500,
          description: 'Les perruques en fibre synthétiques ellen wille sont reconnues pour leur qualité haut de gamme. La fibre capillaire synthétique procure en effet un éclat tellement naturel qu’elle ne se différencie pas, à première vue, de la fibre naturelle. Le coiffage est très simple car le mouvement du cheveu est intégré dans sa structure. La coiffure reste ainsi parfaite et peut se remettre en place simplement avec les doigts. Cette fibre permet en outre de réaliser une large palette de couleurs exceptionnelles et chatoyantes. Afin de préserver longtemps l’éclat de la chevelure, il est conseillé d‘utiliser des produits adaptés aux cheveux synthétiques, comme par exemple le set de soins (shampoing, balsam, conditionneur) avec notamment shampoing auto-nettoyant.',
          slide: [
            { url: require('../assets/perruqueSynthetique/dream.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/dream2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/dream3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/dream4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueSynthetique/colors/dream/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/perruqueSynthetique/colors/dream/chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/perruqueSynthetique/colors/dream/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/dream/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/perruqueSynthetique/colors/dream/lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueSynthetique/colors/dream/mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/perruqueSynthetique/colors/dream/salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/perruqueSynthetique/colors/dream/sand_rooted.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/perruqueSynthetique/colors/dream/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/perruqueSynthetique/colors/dream/silver_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ],
          imagesSynthetics_bis: [
            { url: require('../assets/perruqueSynthetique/dream2.jpg'), alt: 'volumateur synthetique' }
          ]
        },
        {
          id: 4,
          url: require('../assets/perruqueSynthetique/effect_1.jpg'),
          alt: 'volumateur synthetique',
          name: 'Effect',
          size: 'Size: 18 x 17 cm',
          lenth: 'Length: 18 - 29 cm',
          price: 475,
          description: 'Légèreté, naturel et disponibilité dans de nombreux coloris. Les perruques, les compléments capillaires et les toupets en fibre synthétique résistante à la chaleur disposent d‘une mémoire de forme. Cela permet à la coiffure d’être toujours parfaite. Selon le souhait de la cliente il est possible d’apporter des modifications temporaires par exemple avec le séchoir ou un fer à lisser. Cette fibre synthétique est légère et disponible dans un grand choix de coloris – dont également des tons de gris.',
          slide: [
            { url: require('../assets/perruqueSynthetique/effect_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/effect_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueSynthetique/colors/effect/auburn rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/perruqueSynthetique/colors/effect/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/perruqueSynthetique/colors/effect/caramel mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/perruqueSynthetique/colors/effect/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/effect/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/effect/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruqueSynthetique/colors/effect/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/perruqueSynthetique/colors/effect/pearlblonde rooted.jpg'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/perruqueSynthetique/colors/effect/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ],
          imagesSynthetics_bis: [
            { url: require('../assets/perruqueSynthetique/effect_2.jpg'), alt: 'volumateur synthetique' }
          ]
        },
        {
          id: 5,
          url: require('../assets/perruqueSynthetique/fizz_1.jpg'),
          alt: 'volumateur synthetique',
          name: 'Fizz',
          size: 'Size: 29 x 22 cm',
          lenth: 'Length: 17 - 25 cm',
          price: 530,
          description: 'Les perruques en fibre synthétiques ellen wille sont reconnues pour leur qualité haut de gamme. La fibre capillaire synthétique procure en effet un éclat tellement naturel qu’elle ne se différencie pas, à première vue, de la fibre naturelle. Le coiffage est très simple car le mouvement du cheveu est intégré dans sa structure. La coiffure reste ainsi parfaite et peut se remettre en place simplement avec les doigts. Cette fibre permet en outre de réaliser une large palette de couleurs exceptionnelles et chatoyantes. Afin de préserver longtemps l’éclat de la chevelure, il est conseillé d‘utiliser des produits adaptés aux cheveux synthétiques, comme par exemple le set de soins (shampoing, balsam, conditionneur) avec notamment shampoing auto-nettoyant.',
          slide: [
            { url: require('../assets/perruqueSynthetique/fizz_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/fizz_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueSynthetique/colors/fizz/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/fizz/darkchocolate rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate rooted' },
            { url: require('../assets/perruqueSynthetique/colors/fizz/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruqueSynthetique/colors/fizz/lightchampagne rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/perruqueSynthetique/colors/fizz/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/perruqueSynthetique/colors/fizz/pastelblonde rooted.jpg'), alt: 'perruque femme blond', name: 'pastelblonde rooted' },
            { url: require('../assets/perruqueSynthetique/colors/fizz/sand rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/perruqueSynthetique/colors/fizz/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/perruqueSynthetique/colors/fizz/silver mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' },
            { url: require('../assets/perruqueSynthetique/colors/fizz/stonegrey rooted.jpg'), alt: 'perruque femme blond', name: 'stonegrey rooted' },
            { url: require('../assets/perruqueSynthetique/colors/fizz/lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' }
          ],
          imagesSynthetics_bis: [
            { url: require('../assets/perruqueSynthetique/fizz_2.jpg'), alt: 'volumateur synthetique' }
          ]
        },
        {
          id: 6,
          url: require('../assets/perruqueSynthetique/fun.jpg'),
          alt: 'volumateur synthetique',
          name: 'Fun',
          size: 'Size: 29 x 22 cm',
          lenth: 'Length: 9 - 10 cm',
          price: 350,
          description: 'Les perruques en fibre synthétiques ellen wille sont reconnues pour leur qualité haut de gamme. La fibre capillaire synthétique procure en effet un éclat tellement naturel qu’elle ne se différencie pas, à première vue, de la fibre naturelle. Le coiffage est très simple car le mouvement du cheveu est intégré dans sa structure. La coiffure reste ainsi parfaite et peut se remettre en place simplement avec les doigts. Cette fibre permet en outre de réaliser une large palette de couleurs exceptionnelles et chatoyantes. Afin de préserver longtemps l’éclat de la chevelure, il est conseillé d‘utiliser des produits adaptés aux cheveux synthétiques, comme par exemple le set de soins (shampoing, balsam, conditionneur) avec notamment shampoing auto-nettoyant.',
          slide: [
            { url: require('../assets/perruqueSynthetique/fun.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/fun2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/fun3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/fun4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueSynthetique/colors/fun/auburn_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/perruqueSynthetique/colors/fun/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/perruqueSynthetique/colors/fun/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/fun/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/perruqueSynthetique/colors/fun/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueSynthetique/colors/fun/lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/perruqueSynthetique/colors/fun/mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/perruqueSynthetique/colors/fun/pearl_rooted.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/perruqueSynthetique/colors/fun/sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/perruqueSynthetique/colors/fun/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/perruqueSynthetique/colors/fun/white_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' }
          ],
          imagesSynthetics_bis: [
            { url: require('../assets/perruqueSynthetique/fun2.jpg'), alt: 'volumateur synthetique' }
          ]
        },
        {
          id: 7,
          url: require('../assets/perruqueSynthetique/just_1.jpg'),
          alt: 'volumateur synthetique',
          name: 'Just',
          size: 'Size: 8 x 14 cm',
          lenth: 'Length: 23 - 26 cm',
          price: 350,
          description: 'Légèreté, naturel et disponibilité dans de nombreux coloris. Les perruques, les compléments capillaires et les toupets en fibre synthétique résistante à la chaleur disposent d‘une mémoire de forme. Cela permet à la coiffure d’être toujours parfaite. Selon le souhait de la cliente il est possible d’apporter des modifications temporaires par exemple avec le séchoir ou un fer à lisser. Cette fibre synthétique est légère et disponible dans un grand choix de coloris – dont également des tons de gris.',
          slide: [
            { url: require('../assets/perruqueSynthetique/just_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/just_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueSynthetique/colors/just/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/perruqueSynthetique/colors/just/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/perruqueSynthetique/colors/just/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/just/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/just/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruqueSynthetique/colors/just/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueSynthetique/colors/just/pearlblonde mix.jpg'), alt: 'perruque femme blond', name: 'pearlblonde mix' },
            { url: require('../assets/perruqueSynthetique/colors/just/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/perruqueSynthetique/colors/just/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/perruqueSynthetique/colors/just/silver mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' }
          ],
          imagesSynthetics_bis: [
            { url: require('../assets/perruqueSynthetique/just_2.jpg'), alt: 'volumateur synthetique' }
          ]
        },
        {
          id: 8,
          url: require('../assets/perruqueSynthetique/just_long.jpg'),
          alt: 'volumateur synthetique',
          name: 'Just Long',
          size: 'Size: 29 x 22 cm',
          lenth: 'Length: 9 - 10 cm',
          price: 350,
          description: 'Les perruques en fibre synthétiques ellen wille sont reconnues pour leur qualité haut de gamme. La fibre capillaire synthétique procure en effet un éclat tellement naturel qu’elle ne se différencie pas, à première vue, de la fibre naturelle. Le coiffage est très simple car le mouvement du cheveu est intégré dans sa structure. La coiffure reste ainsi parfaite et peut se remettre en place simplement avec les doigts. Cette fibre permet en outre de réaliser une large palette de couleurs exceptionnelles et chatoyantes. Afin de préserver longtemps l’éclat de la chevelure, il est conseillé d‘utiliser des produits adaptés aux cheveux synthétiques, comme par exemple le set de soins (shampoing, balsam, conditionneur) avec notamment shampoing auto-nettoyant.',
          slide: [
            { url: require('../assets/perruqueSynthetique/just_long.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/just_long2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/just_long3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/just_long4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueSynthetique/colors/just_long/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/perruqueSynthetique/colors/just_long/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/perruqueSynthetique/colors/just_long/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/just_long/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/perruqueSynthetique/colors/just_long/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueSynthetique/colors/just_long/mocca_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/perruqueSynthetique/colors/just_long/pearlblonde_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/perruqueSynthetique/colors/just_long/sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/perruqueSynthetique/colors/just_long/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'smoke mix' }
          ],
          imagesSynthetics_bis: [
            { url: require('../assets/perruqueSynthetique/just_long2.jpg'), alt: 'volumateur synthetique' }
          ]
        },
        {
          id: 9,
          url: require('../assets/perruqueSynthetique/lace_top_1.jpg'),
          alt: 'volumateur synthetique',
          name: 'Lace Top',
          size: 'Size: 17 x 16 cm',
          lenth: 'Length: 10 - 12 cm',
          price: 450,
          description: 'Les perruques en fibre synthétiques ellen wille sont reconnues pour leur qualité haut de gamme. La fibre capillaire synthétique procure en effet un éclat tellement naturel qu’elle ne se différencie pas, à première vue, de la fibre naturelle. Le coiffage est très simple car le mouvement du cheveu est intégré dans sa structure. La coiffure reste ainsi parfaite et peut se remettre en place simplement avec les doigts. Cette fibre permet en outre de réaliser une large palette de couleurs exceptionnelles et chatoyantes. Afin de préserver longtemps l’éclat de la chevelure, il est conseillé d‘utiliser des produits adaptés aux cheveux synthétiques, comme par exemple le set de soins (shampoing, balsam, conditionneur) avec notamment shampoing auto-nettoyant.',
          slide: [
            { url: require('../assets/perruqueSynthetique/lace_top_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/lace_top_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueSynthetique/colors/lace_top/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/perruqueSynthetique/colors/lace_top/caramel rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/perruqueSynthetique/colors/lace_top/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/perruqueSynthetique/colors/lace_top/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/lace_top/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruqueSynthetique/colors/lace_top/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/perruqueSynthetique/colors/lace_top/nougat rooted.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' },
            { url: require('../assets/perruqueSynthetique/colors/lace_top/silver mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' },
            { url: require('../assets/perruqueSynthetique/colors/lace_top/stonegrey mix.jpg'), alt: 'perruque femme blond', name: 'stonegrey mix' }
          ],
          imagesSynthetics_bis: [
            { url: require('../assets/perruqueSynthetique/lace_top_2.jpg'), alt: 'volumateur synthetique' }
          ]
        },
        {
          id: 10,
          url: require('../assets/perruqueSynthetique/lovely.jpg'),
          alt: 'volumateur synthetique',
          name: 'Lovely',
          size: 'Size: 29 x 22 cm',
          lenth: 'Length: 9 - 10 cm',
          price: 530,
          description: 'Les perruques en fibre synthétiques ellen wille sont reconnues pour leur qualité haut de gamme. La fibre capillaire synthétique procure en effet un éclat tellement naturel qu’elle ne se différencie pas, à première vue, de la fibre naturelle. Le coiffage est très simple car le mouvement du cheveu est intégré dans sa structure. La coiffure reste ainsi parfaite et peut se remettre en place simplement avec les doigts. Cette fibre permet en outre de réaliser une large palette de couleurs exceptionnelles et chatoyantes. Afin de préserver longtemps l’éclat de la chevelure, il est conseillé d‘utiliser des produits adaptés aux cheveux synthétiques, comme par exemple le set de soins (shampoing, balsam, conditionneur) avec notamment shampoing auto-nettoyant.',
          slide: [
            { url: require('../assets/perruqueSynthetique/lovely.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/lovely2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/lovely3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/lovely4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueSynthetique/colors/lovely/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/perruqueSynthetique/colors/lovely/chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/perruqueSynthetique/colors/lovely/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/lovely/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/perruqueSynthetique/colors/lovely/lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueSynthetique/colors/lovely/mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/perruqueSynthetique/colors/lovely/pearlblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/perruqueSynthetique/colors/lovely/sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/perruqueSynthetique/colors/lovely/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'smoke mix' }
          ],
          imagesSynthetics_bis: [
            { url: require('../assets/perruqueSynthetique/lovely2.jpg'), alt: 'volumateur synthetique' }
          ]
        },
        {
          id: 11,
          url: require('../assets/perruqueSynthetique/secret_1_1.jpg'),
          alt: 'volumateur synthetique',
          name: 'Secret',
          size: 'Size: 29 x 23 cm',
          lenth: 'Length: 28 - 32 cm',
          price: 550,
          description: 'Les perruques en fibre synthétiques ellen wille sont reconnues pour leur qualité haut de gamme. La fibre capillaire synthétique procure en effet un éclat tellement naturel qu’elle ne se différencie pas, à première vue, de la fibre naturelle. Le coiffage est très simple car le mouvement du cheveu est intégré dans sa structure. La coiffure reste ainsi parfaite et peut se remettre en place simplement avec les doigts. Cette fibre permet en outre de réaliser une large palette de couleurs exceptionnelles et chatoyantes. Afin de préserver longtemps l’éclat de la chevelure, il est conseillé d‘utiliser des produits adaptés aux cheveux synthétiques, comme par exemple le set de soins (shampoing, balsam, conditionneur) avec notamment shampoing auto-nettoyant.',
          slide: [
            { url: require('../assets/perruqueSynthetique/secret_1_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/secret_2_1.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueSynthetique/colors/secret/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/perruqueSynthetique/colors/secret/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/perruqueSynthetique/colors/secret/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/secret/darkchocolate rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate rooted' },
            { url: require('../assets/perruqueSynthetique/colors/secret/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruqueSynthetique/colors/secret/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueSynthetique/colors/secret/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/perruqueSynthetique/colors/secret/silvergrey mix.jpg'), alt: 'perruque femme blond', name: 'silvergrey mix' },
            { url: require('../assets/perruqueSynthetique/colors/secret/stonegrey rooted.jpg'), alt: 'perruque femme blond', name: 'stonegrey rooted' }
          ],
          imagesSynthetics_bis: [
            { url: require('../assets/perruqueSynthetique/secret_2_1.jpg'), alt: 'volumateur synthetique' }
          ]
        },
        {
          id: 12,
          url: require('../assets/perruqueSynthetique/top_mono_1.jpg'),
          alt: 'volumateur synthetique',
          name: 'Top Mono',
          size: 'Size: 17 x 16 cm',
          lenth: 'Length: 12 - 14 cm',
          price: 400,
          description: 'Les perruques en fibre synthétiques ellen wille sont reconnues pour leur qualité haut de gamme. La fibre capillaire synthétique procure en effet un éclat tellement naturel qu’elle ne se différencie pas, à première vue, de la fibre naturelle. Le coiffage est très simple car le mouvement du cheveu est intégré dans sa structure. La coiffure reste ainsi parfaite et peut se remettre en place simplement avec les doigts. Cette fibre permet en outre de réaliser une large palette de couleurs exceptionnelles et chatoyantes. Afin de préserver longtemps l’éclat de la chevelure, il est conseillé d‘utiliser des produits adaptés aux cheveux synthétiques, comme par exemple le set de soins (shampoing, balsam, conditionneur) avec notamment shampoing auto-nettoyant.',
          slide: [
            { url: require('../assets/perruqueSynthetique/top_mono_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueSynthetique/top_mono_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueSynthetique/colors/top_mono/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/perruqueSynthetique/colors/top_mono/caramel mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/perruqueSynthetique/colors/top_mono/chocolatemix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueSynthetique/colors/top_mono/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruqueSynthetique/colors/top_mono/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/perruqueSynthetique/colors/top_mono/silver mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' },
            { url: require('../assets/perruqueSynthetique/colors/top_mono/smoke mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/perruqueSynthetique/colors/top_mono/snow mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ],
          imagesSynthetics_bis: [
            { url: require('../assets/perruqueSynthetique/top_mono_2.jpg'), alt: 'volumateur synthetique' }
          ]
        },

        // ],
        // imagesHuman: [
        {
          id: 13,
          url: require('../assets/perruqueHuman/cometa.jpg'),
          alt: 'volumateur synthetique',
          name: 'Cometa',
          size: 'Size: 29 x 22 cm',
          lenth: 'Length: 9 - 10 cm',
          price: 1550,
          description: 'Les perruques en fibre synthétiques ellen wille sont reconnues pour leur qualité haut de gamme. La fibre capillaire synthétique procure en effet un éclat tellement naturel qu’elle ne se différencie pas, à première vue, de la fibre naturelle. Le coiffage est très simple car le mouvement du cheveu est intégré dans sa structure. La coiffure reste ainsi parfaite et peut se remettre en place simplement avec les doigts. Cette fibre permet en outre de réaliser une large palette de couleurs exceptionnelles et chatoyantes. Afin de préserver longtemps l’éclat de la chevelure, il est conseillé d‘utiliser des produits adaptés aux cheveux synthétiques, comme par exemple le set de soins (shampoing, balsam, conditionneur) avec notamment shampoing auto-nettoyant.',
          slide: [
            { url: require('../assets/perruqueHuman/cometa.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueHuman/cometa2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueHuman/cometa3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueHuman/colors/cometa/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/perruqueHuman/colors/cometa/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/perruqueHuman/colors/cometa/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueHuman/colors/cometa/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/perruqueHuman/colors/cometa/lightbernstein_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueHuman/colors/cometa/mocca_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/perruqueHuman/colors/cometa/sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' }
          ],
          imagesHuman_bis: [
            { url: require('../assets/perruqueHuman/cometa2.jpg'), alt: 'volumateur synthetique' }
          ]
        },
        {
          id: 14,
          url: require('../assets/perruqueHuman/famous_1.jpg'),
          alt: 'volumateur femme',
          name: 'Famous',
          size: 'Size: 16 x 17 cm',
          lenth: 'Length: 30 - 32 cm',
          price: 1500,
          description: `
            Du charme, du luxe, du cheveu naturel. Un modèle en cheveux naturels convient à toutes les femmes qui recherchent un naturel absolu. Le cheveu naturel offre un retombé parfait, un éclat pur et un confort inégalable pour toutes les occasions. Pour les soins particuliers requis par votre perruque ou complément en cheveux naturels ellen wille, nous vous conseillons la gamme de soins spécialement créée à cet effet. Votre chevelure gardera ainsi son éclat chatoyant et son confort sans pareil très longtemps.`,
          slide: [
            { url: require('../assets/perruqueHuman/famous_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueHuman/famous_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueHuman/colors/famous/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/perruqueHuman/colors/famous/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueHuman/colors/famous/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/perruqueHuman/colors/famous/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruqueHuman/colors/famous/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/perruqueHuman/colors/famous/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueHuman/colors/famous/nougat rooted.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' },
            { url: require('../assets/perruqueHuman/colors/famous/sandyblonde mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' }
          ],
          imagesHuman_bis: [
            { url: require('../assets/perruqueHuman/famous_2.jpg'), alt: 'volumateur femme' }
          ]
        },
        {
          id: 15,
          url: require('../assets/perruqueHuman/fill_in_1.jpg'),
          alt: 'volumateur femme',
          name: 'Fill In',
          size: 'Size: 8 x 13 cm',
          lenth: 'Length: 26 - 28 cm',
          price: 1000,
          description: 'Du charme, du luxe, du cheveu naturel. Un modèle en cheveux naturels convient à toutes les femmes qui recherchent un naturel absolu. Le cheveu naturel offre un retombé parfait, un éclat pur et un confort inégalable pour toutes les occasions. Pour les soins particuliers requis par votre perruque ou complément en cheveux naturels ellen wille, nous vous conseillons la gamme de soins spécialement créée à cet effet. Votre chevelure gardera ainsi son éclat chatoyant et son confort sans pareil très longtemps.',
          slide: [
            { url: require('../assets/perruqueHuman/fill_in_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueHuman/fill_in_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueHuman/colors/fill_in/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/perruqueHuman/colors/fill_in/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/perruqueHuman/colors/fill_in/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueHuman/colors/fill_in/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/perruqueHuman/colors/fill_in/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruqueHuman/colors/fill_in/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueHuman/colors/fill_in/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/perruqueHuman/colors/fill_in/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ],
          imagesHuman_bis: [
            { url: require('../assets/perruqueHuman/fill_in_2.jpg'), alt: 'volumateur femme' }
          ]
        },
        {
          id: 16,
          url: require('../assets/perruqueHuman/ideal_1.jpg'),
          alt: 'volumateur femme',
          name: 'Ideal',
          size: 'Size: 29 x 24 cm',
          lenth: 'Length: 9 - 14 cm',
          price: 1250,
          description: 'Du charme, du luxe, du cheveu naturel. Un modèle en cheveux naturels convient à toutes les femmes qui recherchent un naturel absolu. Le cheveu naturel offre un retombé parfait, un éclat pur et un confort inégalable pour toutes les occasions. Pour les soins particuliers requis par votre perruque ou complément en cheveux naturels ellen wille, nous vous conseillons la gamme de soins spécialement créée à cet effet. Votre chevelure gardera ainsi son éclat chatoyant et son confort sans pareil très longtemps.',
          slide: [
            { url: require('../assets/perruqueHuman/ideal_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueHuman/ideal_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueHuman/colors/ideal/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/perruqueHuman/colors/ideal/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/perruqueHuman/colors/ideal/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueHuman/colors/ideal/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/perruqueHuman/colors/ideal/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruqueHuman/colors/ideal/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueHuman/colors/ideal/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/perruqueHuman/colors/ideal/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ],
          imagesHuman_bis: [
            { url: require('../assets/perruqueHuman/ideal_2.jpg'), alt: 'volumateur femme' }
          ]
        },
        {
          id: 17,
          url: require('../assets/perruqueHuman/just_nature_1.jpg'),
          alt: 'volumateur femme',
          name: 'Just Nature',
          size: 'Size: 8 x 13 cm',
          lenth: 'Length: 26 - 28 cm',
          price: 1000,
          description: 'Du charme, du luxe, du cheveu naturel. Un modèle en cheveux naturels convient à toutes les femmes qui recherchent un naturel absolu. Le cheveu naturel offre un retombé parfait, un éclat pur et un confort inégalable pour toutes les occasions. Pour les soins particuliers requis par votre perruque ou complément en cheveux naturels ellen wille, nous vous conseillons la gamme de soins spécialement créée à cet effet. Votre chevelure gardera ainsi son éclat chatoyant et son confort sans pareil très longtemps.',
          slide: [
            { url: require('../assets/perruqueHuman/just_nature_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueHuman/just_nature_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueHuman/colors/just_nature/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/perruqueHuman/colors/just_nature/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/perruqueHuman/colors/just_nature/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueHuman/colors/just_nature/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/perruqueHuman/colors/just_nature/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruqueHuman/colors/just_nature/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueHuman/colors/just_nature/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/perruqueHuman/colors/just_nature/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ],
          imagesHuman_bis: [
            { url: require('../assets/perruqueHuman/just_nature_2.jpg'), alt: 'volumateur femme' }
          ]
        },
        {
          id: 18,
          url: require('../assets/perruqueHuman/magic.jpg'),
          alt: 'volumateur femme',
          name: 'Magic',
          size: 'Size: 16 x 17 cm',
          lenth: 'Length: 30 - 32 cm',
          price: 1500,
          description: `
            Du charme, du luxe, du cheveu naturel. Un modèle en cheveux naturels convient à toutes les femmes qui recherchent un naturel absolu. Le cheveu naturel offre un retombé parfait, un éclat pur et un confort inégalable pour toutes les occasions. Pour les soins particuliers requis par votre perruque ou complément en cheveux naturels ellen wille, nous vous conseillons la gamme de soins spécialement créée à cet effet. Votre chevelure gardera ainsi son éclat chatoyant et son confort sans pareil très longtemps.`,
          slide: [
            { url: require('../assets/perruqueHuman/magic.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueHuman/magic2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueHuman/magic3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueHuman/magic4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueHuman/colors/magic/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/perruqueHuman/colors/magic/chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueHuman/colors/magic/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/perruqueHuman/colors/magic/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruqueHuman/colors/magic/lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/perruqueHuman/colors/magic/mocca_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueHuman/colors/magic/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' }
          ],
          imagesHuman_bis: [
            { url: require('../assets/perruqueHuman/magic2.jpg'), alt: 'volumateur femme' }
          ]
        },
        {
          id: 19,
          url: require('../assets/perruqueHuman/matrix_1.jpg'),
          alt: 'volumateur femme',
          name: 'Matrix',
          size: 'Size: 16 x 17 cm',
          lenth: 'Length: 32 - 36 cm',
          price: 1990,
          description: 'Du charme, du luxe, du cheveu naturel. Un modèle en cheveux naturels convient à toutes les femmes qui recherchent un naturel absolu. Le cheveu naturel offre un retombé parfait, un éclat pur et un confort inégalable pour toutes les occasions. Pour les soins particuliers requis par votre perruque ou complément en cheveux naturels ellen wille, nous vous conseillons la gamme de soins spécialement créée à cet effet. Votre chevelure gardera ainsi son éclat chatoyant et son confort sans pareil très longtemps.',
          slide: [
            { url: require('../assets/perruqueHuman/matrix_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueHuman/matrix_2.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueHuman/colors/matrix/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/perruqueHuman/colors/matrix/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueHuman/colors/matrix/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/perruqueHuman/colors/matrix/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruqueHuman/colors/matrix/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueHuman/colors/matrix/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/perruqueHuman/colors/matrix/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ],
          imagesHuman_bis: [
            { url: require('../assets/perruqueHuman/matrix_2.jpg'), alt: 'volumateur femme' }
          ]
        },
        {
          id: 20,
          url: require('../assets/perruqueHuman/value.jpg'),
          alt: 'volumateur femme',
          name: 'Value',
          size: 'Size: 16 x 17 cm',
          lenth: 'Length: 30 - 32 cm',
          price: 1500,
          description: `
            Du charme, du luxe, du cheveu naturel. Un modèle en cheveux naturels convient à toutes les femmes qui recherchent un naturel absolu. Le cheveu naturel offre un retombé parfait, un éclat pur et un confort inégalable pour toutes les occasions. Pour les soins particuliers requis par votre perruque ou complément en cheveux naturels ellen wille, nous vous conseillons la gamme de soins spécialement créée à cet effet. Votre chevelure gardera ainsi son éclat chatoyant et son confort sans pareil très longtemps.`,
          slide: [
            { url: require('../assets/perruqueHuman/value.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueHuman/value2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueHuman/value3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruqueHuman/value4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruqueHuman/colors/value/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/perruqueHuman/colors/value/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruqueHuman/colors/value/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/perruqueHuman/colors/value/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruqueHuman/colors/value/lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/perruqueHuman/colors/value/mocca_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/perruqueHuman/colors/value/sand_mix.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' },
            { url: require('../assets/perruqueHuman/colors/value/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' }
          ],
          imagesHuman_bis: [
            { url: require('../assets/perruqueHuman/value2.jpg'), alt: 'volumateur femme' }
          ]
        },
        // {
        //   id: 13,
        //   url: require('../assets/perruqueHuman/pleasure_1.jpg'),
        //   alt: 'volumateur femme',
        //   name: 'Pleasure',
        //   price: 0,
        //   slide: [
        //     { url: require('../assets/perruqueHuman/pleasure_1.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/perruqueHuman/pleasure_2.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   imagesHuman_bis: [
        //     { url: require('../assets/perruqueHuman/pleasure_2.jpg'), alt: 'volumateur femme' }
        //   ]
        // }
        // ],

        // imagesPrimeHair: [

        {
          id: 21,
          url: require('../assets/perruquePrimeAir/real_1_1.jpg'),
          alt: 'volumateur femme',
          name: 'Real',
          size: 'Size: 13 x 16 cm',
          lenth: 'Length: 14 - 20 cm',
          price: 1050,
          description: 'Le Prime Hair d’ellen wille est la parfaite alliance d’un cheveu naturel de grande qualité avec une fibre synthétique très fine et résistante à la chaleur. Mis au point grâce à notre savoir-faire, ce Prime Hair associe les avantages des deux fibres et constitue ainsi un mélange parfait pour une clientèle exigeante. Afin que votre chevelure Prime Hair garde son éclat et son look naturel le plus longtemps possible, nous vous conseillons d’utiliser nos poduits de soins pour cheveux naturels, déjà bien rodés, et de respecter les consignes de soins joints à votre modèle.',
          slide: [
            { url: require('../assets/perruquePrimeAir/real_1_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruquePrimeAir/real_2_1.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruquePrimeAir/colors/real/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/perruquePrimeAir/colors/real/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/perruquePrimeAir/colors/real/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/perruquePrimeAir/colors/real/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/perruquePrimeAir/colors/real/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/perruquePrimeAir/colors/real/naturewhite mix.jpg'), alt: 'perruque femme blond', name: 'naturewhite mix' },
            { url: require('../assets/perruquePrimeAir/colors/real/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/perruquePrimeAir/colors/real/smoke mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' }
          ],
          imagesPrimeHair_bis: [
            { url: require('../assets/perruquePrimeAir/real_2_1.jpg'), alt: 'volumateur femme' }
          ]
        }
        // {
        //   id: 15,
        //   url: require('../assets/perruquePrimeAir/true_1.jpg'),
        //   alt: 'volumateur femme',
        //   name: 'True',
        //   price: 0,
        //   slide: [
        //     { url: require('../assets/perruquePrimeAir/true_1.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/perruquePrimeAir/true_2.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/perruquePrimeAir/colors/true'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/true'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/true'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/true'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/true'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/true'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/true'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/true'), alt: 'perruque femme blond', name: 'bernstein mix' }
        //   ],
        //   imagesPrimeHair_bis: [
        //     { url: require('../assets/perruquePrimeAir/true_2.jpg'), alt: 'volumateur femme' }
        //   ]
        // },
        // {
        //   id: 16,
        //   url: require('../assets/perruquePrimeAir/ultra_1.jpg'),
        //   alt: 'volumateur femme',
        //   name: 'Ultra',
        //   price: 0,
        //   slide: [
        //     { url: require('../assets/perruquePrimeAir/ultra_1.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/perruquePrimeAir/ultra_2.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/perruquePrimeAir/colors/ultra'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/ultra'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/ultra'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/ultra'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/ultra'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/ultra'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/ultra'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/ultra'), alt: 'perruque femme blond', name: 'bernstein mix' }
        //   ],
        //   imagesPrimeHair_bis: [
        //     { url: require('../assets/perruquePrimeAir/ultra_2.jpg'), alt: 'volumateur femme' }
        //   ]
        // },
        // {
        //   id: 17,
        //   url: require('../assets/perruquePrimeAir/vario_1.jpg'),
        //   alt: 'volumateur femme',
        //   name: 'Vario',
        //   price: 0,
        //   slide: [
        //     { url: require('../assets/perruquePrimeAir/vario_1.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/perruquePrimeAir/vario_2.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/perruquePrimeAir/colors/vario'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/vario'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/vario'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/vario'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/vario'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/vario'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/vario'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/perruquePrimeAir/colors/vario'), alt: 'perruque femme blond', name: 'bernstein mix' }
        //   ],
        //   imagesPrimeHair_bis: [
        //     { url: require('../assets/perruquePrimeAir/vario_2.jpg'), alt: 'volumateur femme' }
        //   ]
        // }
      ]
    }
  },
  components: {
    BaseModal,
    ImagesHumanBis,
    ImagesSyntheticsBis,
    ImagesPrimeHairBis
  },
  mounted () {
    const parallax = document.getElementById('parallax')
    window.addEventListener('scroll', function () {
      const offset = window.scrollY
      parallax.style.backgroundPositionY = offset * 0.2 + 'px'
    })
  },
  methods: {
    // openModal () {
    //   this.modalOpen = !this.modalOpen
    // },
    toggleModal (id) {
      if (this.isShowModal !== id) {
        this.isShowModal = true
        this.isShowModal = id
        // window.scrollTo(0, 600)
        const body = document.querySelector('body')
        // const containerBody = document.querySelector('.container_body')
        if (this.isShowModal === id | id === this.filteredNames.id) {
          body.style.overflow = 'hidden'
          // containerBody.style.visibility = 'hidden'
          // console.log('la popup est ouverte !')
        }
      } else {
        const body = document.querySelector('body')
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
        body.style.overflow = 'visible'
      }
    },
    closePopup (id) {
      const body = document.querySelector('body')
      if (this.isShowModal !== id) {
        body.style.overflow = 'visible'
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
      }
    },
    setCurrent (id) {
      this.current = id
      // console.log(id)
    }
  },
  computed: {
    filteredNames () {
      return this.imagesSynthetics.filter(item => item.name.toLowerCase().includes(this.rechercher.toLowerCase()))
    }
  }
})
</script>
<style lang="scss" scoped>
.Volumateurs {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    margin-bottom: 48px;
    float: right;
}

.fixed {
  height: 100vh;
  overflow: hidden;
}
.container_intro_pmf {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: 80%;
}
.container_full_intro {
 width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
.container_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.sub_container_synthetic, .sub_container_human, .sub_container_PrimeHair{
 display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}
.container_synthetic, .container_human, .container_PrimeHair {
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // z-index: 0;
}
// h1 {
//   font-size: 60px;
//   margin: 10px 10px;

// }
.container_img_name {
 margin: 5px;
    padding: 0;
    display: flex;
    /* width: 15%; */
    position: relative;
    cursor: pointer;
  img {
    width: 50%;
  }
 .container_name_price {
    position: absolute;
    background: white;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    color: black;
    display: flex;
    justify-content: space-around;
    padding-left: 8px;
    padding-right: 8px;
    padding: 1px 0px;
    .name, .price {
    font-size: 22px;
  }
  }
}

@media (max-width: 550px) {
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans4.jpg')
}
.container_img_name {
  width: 47%;
.name {
  font-size: 18px;
}
}
.Volumateurs {
  width:100%;
}
}
@media (max-width: 990px) {

.Volumateurs {
  width:100%;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg')
}
}
@media (min-width: 990px) {
  .container_img_name {
  max-width: 30%;
.name {
  font-size: 30px;
}
}
}

// @media only screen and (max-width: 700px) {
//   table,
//   thead,
//   tbody,
//   tr,
//   th,
//   td {
//     display: block;
//   }
//   .entry-content table:not(.variations),
//   body.et-pb-preview #main-content .container table {
//     border: 1px solid black;
//   }
//   thead {
//     display: none;
//   }
//   td {
//     padding-left: 150px !important;
//     position: relative;
//     margin-top: -1px;
//     background: #fff;
//     font-size: 10px;
//     line-height: 15px;
//     color: black;
//   }
//   td:nth-child(odd) {
//     background-color: #eee;
//   }

//   td::before {
//     padding: 10px;
//     content: attr(data-label);
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 130px;
//     bottom: 0;
//     background-color: #000;
//     color: #fff;
//     display: flex;
//     align-items: center;
//     font-weight: bold;
//   }
//   tr {
//     margin-bottom: 1rem;
//   }
// }

</style>
