<template>

    <img style="width:50%" v-for="(item, j) in imagesPrimeHair.imagesPrimeHair_bis" :key="j" :src="item.url" :alt="item.alt" @click="$emit('open')">

</template>

<script>
export default {
  props: [
    'imagesPrimeHair'
  ]
}
</script>
