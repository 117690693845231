<template>
    <div class="carousel-item-v2">
        <img :src="slide.url" alt="">
    </div>
</template>

<script>
export default {
  props: ['slide']
}
</script>
