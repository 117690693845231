<template>
  <div class="PerruquesMedicalesFemme">
     <header class='header-image__image' id="parallax">

      <div class="firstContainerWidget">
        <div class="containerCTA">
            <h1 class="titleCTA" style="margin-bottom:10px; ">Venez decouvrir notre salon</h1>
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight" style="user-select: none; pointer-events:none;">
                <h6 class="titleCTA" style=" font-weight: 800 !important;">Prendre rendez-vous</h6>
                <h5 class="titleCTA" style=" font-weight: 800 !important;">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
        </div>
      </div>

    <div class='header-image__content'>
      <div class='header-image__content__container'>
      </div>
    </div>
  </header>
  <div class="container_full_intro">
    <div class="container_intro_pmf">
      <h2 class="title_tab"> Nos Perruques </h2>
      <h3 class="title_tab">
        Modalité de remboursement pour les Perruques synthétiques et naturelles
      </h3>
      <p class="paragraph_intro">
        Notre institut capillaire est un établissement agrée par la sécurité sociale. Tous nos modèles répondent aux dernières normes médicales définies par le Ministère de la Santé. Une majorité des prothèses capillaires est remboursée à 100% par la sécurité sociale et votre mutuelle. N'hésitez pas à vous renseigner auprès ce cette dernière pour connaître vos droits.
      </p>
      <p class="paragraph_intro">
        <br>
        Le ministère de la santé à définit qu'il y avait deux classes pour ces prothèses capillaires tête entière.
        La classe I, des modèles à 350€ entièrement prit en charge par la sécurité sociale. La classe II, avec des modèles à plus de 350€, est remboursée à hauteur de 250€ par la sécurité sociale mais est souvent complétée par votre mutuelle.
      </p><br>
      <div class="containerTableau">
        <div class="tableau1">
          <h4 style="text-align:left; margin-left:30px;">PROTHESE CAPILLAIRE TOTALE Classe I <br>
            + un bonnet easy fit</h4><br>
          <ul style="padding-left:40px">
            <li class="list1">Prix de vente forfaitaire : 350 euros</li> <br>
            <li class="list1">Montant remboursé par la sécurité Sociale : 350 euros</li><br>
            <li class="list1">Aucun reste à charge / Prise en charge 100%</li><br>
          </ul>
          <div>
            <h4 style="font-weight:bold">Perruques 100% remboursées</h4>
          </div>
        </div>
        <div class="tableau2">
          <h4 style="text-align:left;">PROTHESE CAPILLAIRE TOTALE Classe I <br>
            + un bonnet easy fit</h4><br>
          <ul style="padding-left:40px">
            <li class="list1">Prix de vente forfaitaire : 350 euros</li> <br>
            <li class="list1">Montant remboursé par la sécurité Sociale : 350 euros</li><br>
            <li class="list1">Aucun reste à charge / Prise en charge 100%</li><br>
          </ul>
          <div>
            <h4 style="font-weight:bold">Perruques 100% remboursées</h4>
          </div>
        </div>
      </div>
        <!-- <div class="container_stars">
        <img :src="images[2].url" alt="">
        </div> -->
    </div>
  </div>
    <!-- <img :src="images[0].url" alt="">
    <img :src="images[1].url" alt=""> -->
     <div class="container_body">
          <div class="container_coupe_courte" >
            <!-- <h3>Notre catalogue - modeles presentés en boutique</h3> -->
             <h4>Gamme homme </h4>
            <div class="sub_container_coupe_courte">
              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in imagesAmbreBoutique" :key="item.id"  >
                <img :src="item.url" :alt="item.alt" :id="item.id" @click="toggleModal(item.id)">
                <div v-if="current !== item.id " class="container_name_price">
                  <div   class="name">{{ item.name }}</div>
                  <!-- <div   class="price">{{ item.price }}€</div> -->
                </div>
                <base-modal  v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :descriptionTitle = item.description :priceToto = item.price :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal>

              </div>
              <div class="transitionV3">
                  <div class="leftBarV3"></div>
                  <span style="width: 40%;"><h6>{{title[9]}}</h6></span>
                  <div class="rightBarV3"></div>
                </div>
                <div class="container-carousel">
                  <carousel
                    :slides="slides"
                    :loop="true"
                    @drag="onDrag"
                  >
                  </carousel>
                </div>
            </div>
          </div>
                <div class="containerCTAback">
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight">
                <h6 class="titleCTA">Prendre rendez-vous</h6>
                <h5 class="titleCTA">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
          </div>
      </div>

  </div>
</template>

<script>
import Carousel from './vuejs3-components-carousel.vue'
import BaseModal from '@/components/BaseModal.vue'
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - un large choix de Perruques pour hommes',
      description: 'Pérruques chimio pour hommes remboursées par la sécu ',
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/perruques-fibre-prime'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      title: ['Gamme ambre - 350€ - intégralement remboursé par la sécu', 'Gamme amethyste - 450€ à 470€ - partiellement remboursé par la sécu', 'Gamme Rubis - 550€ - Monofilament', 'Gamme Emeraude - 590€ - 100% fait main', 'Gamme Saphir - de 600€ à 630€ - Monofilament - 100% fait main', 'Gamme ambre - 350€ - intégralement remboursé par la sécu', 'Gamme amethyste - 450€ à 460€ - partiellement remboursé par la sécu', 'Gamme rubis - 550€ à 560€ - Monofilament', 'Gamme Emeraude - 590€ - 100% fait main', 'Nos catalogues '],
      images: [
        { url: require('../assets/perruque_homme/category_hairformance1.jpg'), alt: 'shooting homme' },
        { url: require('../assets/category_primepower_small.jpg'), alt: 'shooting femme' },
        { url: require('../assets/Sans-titre-7.png'), alt: 'shooting femme' }
      ],
      myModal: false,
      current: false,
      isShowModal: false,
      propFixed: false,
      imagest: [
        { url: require('../assets/imgTurbans1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans3.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans4.jpg'), alt: 'turbans femme' }
      ],
      slides: [
        {
          id: 1,
          url: require('../assets/images_perruques/perruque_medicale.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques medicales',
          price: 350,
          description: 'Remboursées par la sécurité sociale',
          to: '/perruques-medicales-femmes'
        },
        {
          id: 2,
          url: require('../assets/images_perruques/perruque_naturelle.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruque naturelles',
          price: 350,
          description: 'perruque en vrais cheveux 100% naturels Remy Hair pour une longevité maximum',
          to: '/perruques-cheveux-naturels'
        },
        {
          id: 3,
          url: require('../assets/images_perruques/perruque_prime.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques fibre mixte',
          price: 350,
          description: "L'avantage du chebveux naturel et de la fibre synthétique",
          to: '/perruques-fibre-prime'
        },
        {
          id: 4,
          url: require('../assets/images_perruques/perruque_relooking.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques petits prix',
          price: 350,
          description: 'Sans remboursement sécu pour des budgets plus modérés',
          to: '/perruques-relooking'
        },
        {
          id: 5,
          url: require('../assets/images_perruques/perruque_lace.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques type lace wig',
          price: 350,
          description: 'chevelure ultra réaliste et indetectable 100% personnalisable',
          to: '/perruques-type-lace'
        },
        {
          id: 6,
          url: require('../assets/perruque_homme/ew_hairformance_justin_4.webp'),
          alt: 'perruque homme cheveux poivre et sel coupe courte',
          name: 'Perruques homme',
          description: 'Ligne de perruques dediée aux hommes remboursées par la sécu. Coupe personnalisable.',
          to: '/perruques-homme'
        }
      ],
      imagesAmbreBoutique: [
        {
          id: 1,
          url: require('../assets/perruque_homme/ew_hairformance_brad_5.jpg'),
          alt: 'perruque homme',
          name: 'Brad',
          price: 350,
          slide: [
            { url: require('../assets/perruque_homme/ew_hairformance_brad_5.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_brad_1.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_brad_3.jpg'), alt: 'perruque homme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruque_homme/colors/brad/m14s.jpg'), alt: 'perruque homme blond', name: 'm14s' },
            { url: require('../assets/perruque_homme/colors/brad/m17s.jpg'), alt: 'perruque homme blond', name: 'm17s' },
            { url: require('../assets/perruque_homme/colors/brad/m2s.jpg'), alt: 'perruque homme blond', name: 'm2s' },
            { url: require('../assets/perruque_homme/colors/brad/m34s.jpg'), alt: 'perruque homme blond', name: 'm34s' },
            { url: require('../assets/perruque_homme/colors/brad/m36s.jpg'), alt: 'perruque homme blond', name: 'm36s' },
            { url: require('../assets/perruque_homme/colors/brad/m38s.jpg'), alt: 'perruque homme blond', name: 'm38s' },
            { url: require('../assets/perruque_homme/colors/brad/m39s.jpg'), alt: 'perruque homme blond', name: 'm39s' },
            { url: require('../assets/perruque_homme/colors/brad/m3s.jpg'), alt: 'perruque homme blond', name: 'm3s' },
            { url: require('../assets/perruque_homme/colors/brad/m44s.jpg'), alt: 'perruque homme blond', name: 'm44s' },
            { url: require('../assets/perruque_homme/colors/brad/m46s.jpg'), alt: 'perruque homme blond', name: 'm46s' },
            { url: require('../assets/perruque_homme/colors/brad/m51s.jpg'), alt: 'perruque homme blond', name: 'm51s' },
            { url: require('../assets/perruque_homme/colors/brad/m56s.jpg'), alt: 'perruque homme blond', name: 'm56s' },
            { url: require('../assets/perruque_homme/colors/brad/m5s.jpg'), alt: 'perruque homme blond', name: 'm5s' },
            { url: require('../assets/perruque_homme/colors/brad/m7s.jpg'), alt: 'perruque homme blond', name: 'm7s' },
            { url: require('../assets/perruque_homme/colors/brad/pepper-mix.jpg'), alt: 'perruque homme blond', name: 'pepper mix' }

          ]
        },
        {
          id: 2,
          url: require('../assets/perruque_homme/ew_hairformance_bradford_1.jpg'),
          alt: 'perruque homme',
          name: 'Bradford',
          price: 550,
          slide: [
            { url: require('../assets/perruque_homme/ew_hairformance_bradford_1.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_bradford_2.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_bradford_3.jpg'), alt: 'perruque homme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruque_homme/colors/bradford/m17s.jpg'), alt: 'perruque homme blond', name: 'm17s' },
            { url: require('../assets/perruque_homme/colors/bradford/m36s.jpg'), alt: 'perruque homme blond', name: 'm36s' },
            { url: require('../assets/perruque_homme/colors/bradford/m38s.jpg'), alt: 'perruque homme blond', name: 'm38s' },
            { url: require('../assets/perruque_homme/colors/bradford/m3s.jpg'), alt: 'perruque homme blond', name: 'm3s' },
            { url: require('../assets/perruque_homme/colors/bradford/m51s.jpg'), alt: 'perruque homme blond', name: 'm51s' },
            { url: require('../assets/perruque_homme/colors/bradford/m56s.jpg'), alt: 'perruque homme blond', name: 'm56s' },
            { url: require('../assets/perruque_homme/colors/bradford/m5s.jpg'), alt: 'perruque homme blond', name: 'm5s' },
            { url: require('../assets/perruque_homme/colors/bradford/m7s.jpg'), alt: 'perruque homme blond', name: 'm7s' },
            { url: require('../assets/perruque_homme/colors/bradford/pepper-mix.jpg'), alt: 'perruque homme blond', name: 'pepper mix' }

          ]
        },
        {
          id: 3,
          url: require('../assets/perruque_homme/ew_hairformance_brian_2.jpg'),
          alt: 'perruque homme',
          name: 'Brian',
          price: 450,
          slide: [
            { url: require('../assets/perruque_homme/ew_hairformance_brian_2.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_brian_1.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_brian_4.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_brian_6.jpg'), alt: 'perruque homme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruque_homme/colors/brian/espresso-tipped.jpg'), alt: 'perruque homme blond', name: 'espresso tipped' },
            { url: require('../assets/perruque_homme/colors/brian/m17s.jpg'), alt: 'perruque homme blond', name: 'm17s' },
            { url: require('../assets/perruque_homme/colors/brian/m36s.jpg'), alt: 'perruque homme blond', name: 'm36s' },
            { url: require('../assets/perruque_homme/colors/brian/m5s.jpg'), alt: 'perruque homme blond', name: 'm5s' },
            { url: require('../assets/perruque_homme/colors/brian/m7s.jpg'), alt: 'perruque homme blond', name: 'm7s' },
            { url: require('../assets/perruque_homme/colors/brian/sand rooted.jpg'), alt: 'perruque homme blond', name: 'sand rooted' },
            { url: require('../assets/perruque_homme/colors/brian/tabac-rooted.jpg'), alt: 'perruque homme blond', name: 'tabac rooted' }

          ]
        },
        // {
        //   id: 4,
        //   url: require('../assets/perruque_homme/ew_hairformance_dave_1.jpg'),
        //   alt: 'perruque homme',
        //   name: 'Dave',
        //   price: 350,
        //   slide: [
        //     { url: require('../assets/perruque_homme/ew_hairformance_dave_1.jpg'), alt: 'perruque homme blond' },
        //     { url: require('../assets/perruque_homme/ew_hairformance_dave_2.jpg'), alt: 'perruque homme blond' },
        //     { url: require('../assets/perruque_homme/ew_hairformance_dave_4.jpg'), alt: 'perruque homme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/perruque_homme/colors/dave/esspresso-lighted.jpg'), alt: 'perruque homme blond', name: 'esspresso lighted' },
        //     { url: require('../assets/perruque_homme/colors/dave/m17s.jpg'), alt: 'perruque homme blond', name: 'm17s' },
        //     { url: require('../assets/perruque_homme/colors/dave/m39s.jpg'), alt: 'perruque homme blond', name: 'm39s' },
        //     { url: require('../assets/perruque_homme/colors/dave/m5s.jpg'), alt: 'perruque homme blond', name: 'm5s' },
        //     { url: require('../assets/perruque_homme/colors/dave/m7s.jpg'), alt: 'perruque homme blond', name: 'm7s' },
        //     { url: require('../assets/perruque_homme/colors/dave/tabac-lighted.jpg'), alt: 'perruque homme blond', name: 'tabac lighted' },
        //     { url: require('../assets/perruque_homme/colors/dave/walnut-mix.jpg'), alt: 'perruque homme blond', name: 'walnut mix' }

        //   ]
        // },
        {
          id: 5,
          url: require('../assets/perruque_homme/ew_hairformance_gary_6.jpg'),
          alt: 'perruque homme',
          name: 'Gary',
          price: 550,
          slide: [
            { url: require('../assets/perruque_homme/ew_hairformance_gary_6.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_gary_1.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_gary_4.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_gary_7.jpg'), alt: 'perruque homme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruque_homme/colors/gary/coffee-mix.jpg'), alt: 'perruque homme blond', name: 'm17s' },
            { url: require('../assets/perruque_homme/colors/gary/m36s.jpg'), alt: 'perruque homme blond', name: 'm36s' },
            { url: require('../assets/perruque_homme/colors/gary/m38s.jpg'), alt: 'perruque homme blond', name: 'm38s' },
            { url: require('../assets/perruque_homme/colors/gary/m3s.jpg'), alt: 'perruque homme blond', name: 'm3s' },
            { url: require('../assets/perruque_homme/colors/gary/m44s.jpg'), alt: 'perruque homme blond', name: 'm51s' },
            { url: require('../assets/perruque_homme/colors/gary/m56s.jpg'), alt: 'perruque homme blond', name: 'm56s' },
            { url: require('../assets/perruque_homme/colors/gary/m5s.jpg'), alt: 'perruque homme blond', name: 'm5s' },
            { url: require('../assets/perruque_homme/colors/gary/pepper-mix.jpg'), alt: 'perruque homme blond', name: 'pepper mix' },
            { url: require('../assets/perruque_homme/colors/gary/sand-mix.jpg'), alt: 'perruque homme blond', name: 'sand mix' },
            { url: require('../assets/perruque_homme/colors/gary/teak-mix.jpg'), alt: 'perruque homme blond', name: 'teak mix' }

          ]
        },
        {
          id: 6,
          url: require('../assets/perruque_homme/ew_hairformance_georg_5stars_1.jpg'),
          alt: 'perruque homme ',
          name: 'Georges',
          price: 600,
          slide: [
            { url: require('../assets/perruque_homme/ew_hairformance_georg_5stars_1.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_georg_5stars_5.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_georg_5stars_7.jpg'), alt: 'perruque homme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruque_homme/colors/george/m14s.jpg'), alt: 'perruque homme blond', name: 'm14s' },
            { url: require('../assets/perruque_homme/colors/george/m17s.jpg'), alt: 'perruque homme blond', name: 'm17s' },
            { url: require('../assets/perruque_homme/colors/george/m34s.jpg'), alt: 'perruque homme blond', name: 'm34s' },
            { url: require('../assets/perruque_homme/colors/george/m36s.jpg'), alt: 'perruque homme blond', name: 'm36s' },
            { url: require('../assets/perruque_homme/colors/george/m38s.jpg'), alt: 'perruque homme blond', name: 'm38s' },
            { url: require('../assets/perruque_homme/colors/george/m3s.jpg'), alt: 'perruque homme blond', name: 'm3s' },
            { url: require('../assets/perruque_homme/colors/george/m44s.jpg'), alt: 'perruque homme blond', name: 'm44s' },
            { url: require('../assets/perruque_homme/colors/george/m46s.jpg'), alt: 'perruque homme blond', name: 'm46s' },
            { url: require('../assets/perruque_homme/colors/george/m51s.jpg'), alt: 'perruque homme blond', name: 'm51s' },
            { url: require('../assets/perruque_homme/colors/george/m56s.jpg'), alt: 'perruque homme blond', name: 'm56s' },
            { url: require('../assets/perruque_homme/colors/george/m5s.jpg'), alt: 'perruque homme blond', name: 'm5s' },
            { url: require('../assets/perruque_homme/colors/george/m7s.jpg'), alt: 'perruque homme blond', name: 'm7s' },
            { url: require('../assets/perruque_homme/colors/george/pepper-mix.jpg'), alt: 'perruque homme blond', name: 'pepper mix' }

          ]
        },
        {
          id: 7,
          url: require('../assets/perruque_homme/ew_hairformance_johnny_4.jpg'),
          alt: 'perruque homme',
          name: 'Johnny',
          price: 450,
          slide: [
            { url: require('../assets/perruque_homme/ew_hairformance_johnny_4.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_johnny_2.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_johnny_7.jpg'), alt: 'perruque homme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruque_homme/colors/johnny/m14s.jpg'), alt: 'perruque homme blond', name: 'm17s' },
            { url: require('../assets/perruque_homme/colors/johnny/m17s.jpg'), alt: 'perruque homme blond', name: 'm36s' },
            { url: require('../assets/perruque_homme/colors/johnny/m2s.jpg'), alt: 'perruque homme blond', name: 'm38s' },
            { url: require('../assets/perruque_homme/colors/johnny/m36s.jpg'), alt: 'perruque homme blond', name: 'm3s' },
            { url: require('../assets/perruque_homme/colors/johnny/m39s.jpg'), alt: 'perruque homme blond', name: 'm51s' },
            { url: require('../assets/perruque_homme/colors/johnny/m44s.jpg'), alt: 'perruque homme blond', name: 'm56s' },
            { url: require('../assets/perruque_homme/colors/johnny/m5s.jpg'), alt: 'perruque homme blond', name: 'm5s' },
            { url: require('../assets/perruque_homme/colors/johnny/m7s.jpg'), alt: 'perruque homme blond', name: 'm7s' }

          ]
        },
        {
          id: 8,
          url: require('../assets/perruque_homme/ew_hairformance_justin_4.jpg'),
          alt: 'perruque homme',
          name: 'Justin',
          price: 550,
          slide: [
            { url: require('../assets/perruque_homme/ew_hairformance_justin_4.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_justin_3.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_justin_2.jpg'), alt: 'perruque homme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruque_homme/colors/justin/m14s.jpg'), alt: 'perruque homme blond', name: 'm14s' },
            { url: require('../assets/perruque_homme/colors/justin/m17s.jpg'), alt: 'perruque homme blond', name: 'm17s' },
            { url: require('../assets/perruque_homme/colors/justin/m36s.jpg'), alt: 'perruque homme blond', name: 'm36s' },
            { url: require('../assets/perruque_homme/colors/justin/m38s.jpg'), alt: 'perruque homme blond', name: 'm38s' },
            { url: require('../assets/perruque_homme/colors/justin/m3s.jpg'), alt: 'perruque homme blond', name: 'm3s' },
            { url: require('../assets/perruque_homme/colors/justin/m44s.jpg'), alt: 'perruque homme blond', name: 'm44s' },
            { url: require('../assets/perruque_homme/colors/justin/m46s.jpg'), alt: 'perruque homme blond', name: 'm46s' },
            { url: require('../assets/perruque_homme/colors/justin/m51s.jpg'), alt: 'perruque homme blond', name: 'm51s' },
            { url: require('../assets/perruque_homme/colors/justin/m56s.jpg'), alt: 'perruque homme blond', name: 'm56s' },
            { url: require('../assets/perruque_homme/colors/justin/m5s.jpg'), alt: 'perruque homme blond', name: 'm5s' },
            { url: require('../assets/perruque_homme/colors/justin/m7s.jpg'), alt: 'perruque homme blond', name: 'm7s' },
            { url: require('../assets/perruque_homme/colors/justin/pepper-mix.jpg'), alt: 'perruque homme blond', name: 'pepper mix' }

          ]
        },
        {
          id: 9,
          url: require('../assets/perruque_homme/ew_hairformance_roger_5stars_2.jpg'),
          alt: 'perruque homme',
          name: 'Roger',
          price: 600,
          slide: [
            { url: require('../assets/perruque_homme/ew_hairformance_roger_5stars_2.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_roger_5stars_4.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_roger_5stars_7.jpg'), alt: 'perruque homme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruque_homme/colors/roger/m17s.jpg'), alt: 'perruque homme blond', name: 'm17s' },
            { url: require('../assets/perruque_homme/colors/roger/m34s.jpg'), alt: 'perruque homme blond', name: 'm34s' },
            { url: require('../assets/perruque_homme/colors/roger/m36s.jpg'), alt: 'perruque homme blond', name: 'm36s' },
            { url: require('../assets/perruque_homme/colors/roger/m38s.jpg'), alt: 'perruque homme blond', name: 'm38s' },
            { url: require('../assets/perruque_homme/colors/roger/m44s.jpg'), alt: 'perruque homme blond', name: 'm44s' },
            { url: require('../assets/perruque_homme/colors/roger/m46s.jpg'), alt: 'perruque homme blond', name: 'm46s' },
            { url: require('../assets/perruque_homme/colors/roger/m51s.jpg'), alt: 'perruque homme blond', name: 'm51s' },
            { url: require('../assets/perruque_homme/colors/roger/m56s.jpg'), alt: 'perruque homme blond', name: 'm56s' },
            { url: require('../assets/perruque_homme/colors/roger/m5s.jpg'), alt: 'perruque homme blond', name: 'm5s' },
            { url: require('../assets/perruque_homme/colors/roger/m7s.jpg'), alt: 'perruque homme blond', name: 'm7s' }

          ]
        },
        {
          id: 10,
          url: require('../assets/perruque_homme/ew_hairformance_steven_2.jpg'),
          alt: 'perruque homme',
          name: 'Steven',
          price: 550,
          slide: [
            { url: require('../assets/perruque_homme/ew_hairformance_steven_2.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_steven_5.jpg'), alt: 'perruque homme blond' },
            { url: require('../assets/perruque_homme/ew_hairformance_steven_6.jpg'), alt: 'perruque homme blond' }
          ],
          mozaique: [
            { url: require('../assets/perruque_homme/colors/steven/m14s.jpg'), alt: 'perruque homme blond', name: 'm17s' },
            { url: require('../assets/perruque_homme/colors/steven/m17s.jpg'), alt: 'perruque homme blond', name: 'm36s' },
            { url: require('../assets/perruque_homme/colors/steven/m34s.jpg'), alt: 'perruque homme blond', name: 'm38s' },
            { url: require('../assets/perruque_homme/colors/steven/m36s.jpg'), alt: 'perruque homme blond', name: 'm3s' },
            { url: require('../assets/perruque_homme/colors/steven/m38s.jpg'), alt: 'perruque homme blond', name: 'm51s' },
            { url: require('../assets/perruque_homme/colors/steven/m44s.jpg'), alt: 'perruque homme blond', name: 'm56s' },
            { url: require('../assets/perruque_homme/colors/steven/m46s.jpg'), alt: 'perruque homme blond', name: 'm5s' },
            { url: require('../assets/perruque_homme/colors/steven/m51s.jpg'), alt: 'perruque homme blond', name: 'm7s' },
            { url: require('../assets/perruque_homme/colors/steven/m56s.jpg'), alt: 'perruque homme blond', name: 'pepper mix' },
            { url: require('../assets/perruque_homme/colors/steven/m5s.jpg'), alt: 'perruque homme blond', name: 'pepper mix' },
            { url: require('../assets/perruque_homme/colors/steven/m7s.jpg'), alt: 'perruque homme blond', name: 'pepper mix' }

          ]
        }
      ]
    }
  },
  components: {
    BaseModal,
    Carousel
  },
  mounted () {
    const parallax = document.getElementById('parallax')
    window.addEventListener('scroll', function () {
      const offset = window.scrollY
      parallax.style.backgroundPositionY = offset * 0.2 + 'px'
    })
  },
  methods: {
    // openModal () {
    //   this.modalOpen = !this.modalOpen
    // },
    toggleModal (id) {
      if (this.isShowModal !== id) {
        this.isShowModal = true
        this.isShowModal = id
        window.scrollTo(0, 1800)
        const body = document.querySelector('body')
        // const containerBody = document.querySelector('.container_body')
        if (this.isShowModal === id) {
          body.style.overflow = 'hidden'
          // containerBody.style.visibility = 'hidden'
          // console.log('la popup est ouverte !')
        }
      } else {
        const body = document.querySelector('body')
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
        body.style.overflow = 'visible'
      }
    },
    closePopup (id) {
      const body = document.querySelector('body')
      if (this.isShowModal !== id) {
        body.style.overflow = 'visible'
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
      }
    },
    setCurrent (id) {
      this.current = id
      // console.log(id)
    }
  }
})
</script>

<style lang="scss" scoped>

// html,
// body {
//   height: 100%;
// }
// $base-header-height: 100%;
.PerruquesMedicalesFemme {
      width: 80%;
    right: 0;
    text-align: center;
     float: right;
}
.header-image__image {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/perruque_homme/category_hairformance1.webp');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
// .header-image {
//   position: relative; // 2.
//   height: 100%;
//   overflow: auto; // 1.
//   -webkit-overflow-scrolling: touch; // 5.
//   @at-root .header-image__image {
//     height: $base-header-height;
//     background-position: center center; // 3.
//     background-repeat: no-repeat; // 3.
//     background-size: cover; // 3.
//   }
// }
.container_intro_pmf {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: 80%;
}
.title_tab {
  margin: 0px 0px 40px 0px;
    padding-top: 40px;
}
.container_full_intro {
 width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
}
table {
  width: 20%;
  border-collapse: collapse;
  color: white;
}
td {
  color: black;
}
tr {
 background-color: #eee;
}
.entry-content thead th, .entry-content tr th {
 color: white;

}
th,
td {
  border: 1px solid white;
  padding: 10px;
}
th {
  background: #949393;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  font-family: sans-serif;
 line-height:1.7em;
}
table {
  width: 100%;
  border-collapse: collapse;
      margin-bottom: 60px;
}
th,
td {
  padding: 10px;
  text-align: left;
  border: solid 1px #ccc;
}
th {
  background-color: #000;
  color: #fff;
}
tr:nth-child(odd) {
  background-color: #eee;
}
.container_stars {
  margin-bottom: 80px;
  img {
    max-width:100%;
  }
}

.PerruquesRelooking {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    margin-bottom: 48px;
}

.fixed {
  height: 100vh;
  overflow: hidden;
}
.header-image__image {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/perruque_homme/category_hairformance2.webp');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
.container_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.sub_container_coupe_courte{
 display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}
.container_coupe_courte{
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // z-index: 0;
}
// h1 {
//   font-size: 40px;
//   margin: 10px 10px;

// }
// h2 {
//   font-size: 40px;
//   margin: 10px 10px;

// }
// h3 {
//   font-size: 40px;
//   margin: 10px 10px;

// }
// h4 {
//   font-size: 30px;
//   margin: 10px 10px;

// }
.container_img_name {
 margin: 5px;
    padding: 0;
    display: inline-grid;
    width: 30%;
    position: relative;
    cursor: pointer;
  img {
    width: 100%;
  }
  .container_name_price {
    position: absolute;
    background: white;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    color: black;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    .name, .price {
    font-size: 15px;
    @media(min-width: 550px ) {
      font-size:20px;
    }
  }
  }
}
.transition {
border-top: 1px solid black;
    width: 80%;
    margin: 50px;
}
@media only screen and (max-width: 700px) {
  table,
  thead,
  tbody,
  tr,
  th,
  td {
    display: block;
  }
  .entry-content table:not(.variations),
  body.et-pb-preview #main-content .container table {
    border: 1px solid black;
  }
  thead {
    display: none;
  }
  td {
    padding-left: 150px !important;
    position: relative;
    margin-top: -1px;
    background: #fff;
    font-size: 10px;
    line-height: 15px;
    color: black;
  }
  td:nth-child(odd) {
    background-color: #eee;
  }

  td::before {
    padding: 10px;
    content: attr(data-label);
    position: absolute;
    top: 0;
    left: 0;
    width: 130px;
    bottom: 0;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  tr {
    margin-bottom: 1rem;
  }
}
@media (max-width: 990px) {
.header-image__image {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/perruque_homme/category_hairformance2.webp')
}
.PerruquesMedicalesFemme {
  width:100%;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/perruque_homme/category_hairformance2.webp')
}
.container_img_name {
  width: 47%;
.name, .price {
  font-size: 15px;
}
}
.PerruquesRelooking {
  width:100%;
}
}
@media (max-width: 990px) {

.PerruquesMedicalesFemme {
  width:100%;
}
.PerruquesRelooking {
  width:100%;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/perruque_homme/category_hairformance2.webp')
}
}

@media (min-width: 990px) {
  .container_img_name {
  max-width: 15%;
}
}
</style>
