import { createApp } from 'vue'
import { createHead } from '@vueuse/head'
import App from './App.vue'
import router from './router'
import store from './store'
const head = createHead()

// import VueAnalytics from 'vue-analytics'
// import VueAnalytics from 'vue-analytics'

createApp(App).use(store).use(router).use(head).directive('scroll', {
  mounted: function (el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
}).mount('#app')
