<template>
  <div class="QuiSommesNous">
    <video-bg type="mp4" :sources="[require('../assets/salon.webm')]">
        <div class="firstContainerWidget" >
          <div class="containerLogo">
            <img class="img_logo" src="https://lhairderien-perruque.fr/lhairderien/src/assets/logo_hdr_2.webp" alt="">
          </div>
          <div class="containerCTA">
            <h1 class="titleCTA" style="margin-bottom:10px; ">Venez decouvrir notre salon</h1>
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight" style="user-select: none; pointer-events:none;">
                <h6 class="titleCTA" style=" font-weight: 800 !important;">Prendre rendez-vous</h6>
                <h5 class="titleCTA" style=" font-weight: 800 !important;">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
        </div>
          </div>
      <!-- If you want to add content here, a slot is waiting! -->
      </video-bg>
    <h1 class="TitleSalon" >Bienvenue chez l'hair de rien </h1>
    <div class="containerHistoire">
      <div class="containerText">
        <h2 class="storyTitle">Notre Histoire</h2>
        <p class="paragraphSalon">
          C'est l'histoire de deux passionnées de la coiffure qui  ont décidé d'ouvrir cet institut capillaire afin d'aider et pouvoir répondre à des attentes vues nulles part ailleurs. <br><br>

Étant d'abord collègues pendant plusieurs années et ne pouvant répondre aux attentes de tous les clients étant bridée par certaines franchises, des clients poussent Floriane et Laetitia à ouvrir leur propre entreprise. <br>
 Ces jeunes femmes ont alors prit l'initiative de créer "L'Hair de rien". Un lieu unique dédié aux personnes ayant une chute de cheveux. <br><br>

Ayant écouté les attentes de nombreux clients atteint d'alopecie, ce lieu a été pensé par eux. <br>
Lieu chaleureux avec de l'espace, des cabines individuelles mixtes pour un maximum de discrétion, une cabine individuelle conçue pour les femmes en chimiothérapie, un salon ouvert pour le suivi en coiffure lorsque la maladie  est terminée , un grand ateliers afin de pouvoir créer et personnaliser vos prothèses capillaires et surtout un parking gratuit rien que pour vous.
        </p>
      </div>
      <div class="containerImageSalon">
        <img src="https://lhairderien-perruque.fr/lhairderien/src/assets/salon01.jpg" alt="">
      </div>
    </div>
    <div style="width: 100%; justify-content: ce; display: flex ; justify-content: center; align-items: center;">
      </div>
    <!-- <div class="containerHistoire">
      <div class="containerImageSalon">
        <img :src="imageSalon[0].url">
      </div>
      <div class="containerText">
        <h2 class="storyTitle">Notre Histoire</h2>
        <p class="paragraphSalon">
          voiqskjqfkkjfnksjfncskdjncksdjncsjddnclsjdnc  kdjn kvj ddkjnidjfnvejfkn dkjfhvdkj ndjjnodfjojjfjd  jeorjvofvl dofvjodfvl dlffnvfzoifj nj dvkjvndd dfjvn
        </p>
      </div>
    </div> -->
  </div>
</template>

<style lang="scss" scoped>
.QuiSommesNous {
  background: #c4c4c4;
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    float: right;
}
.TitleSalon {
  font-family: Arial, open-sans;
    font-size: 20px;
    color:#787575;
    margin-top:40px;
    @media(min-width:1500px) {
    font-size:30px;
    }
}
.storyTitle {
  font-family: 'GFS Didot', serif;
  font-weight: normal;
  color: black;
  font-style: italic;
  margin:20px 0px;
  font-size: 30px;
}
.paragraphSalon {
  padding: 0px 20px 20px 0px;
}
.containerText {
  text-align: left;
  padding-left: 10%;
  width: 50%;
  @media(max-width: 990px) {
    width:100%;
  }
}
.containerHistoire {
  display: flex;
    flex-direction: row;
    background: white;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 3%;
    margin: top;
    margin-top: 3%;
    justify-content: space-between;
    @media(max-width: 990px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    }
}
// .containerImageSalon {
//   width: 80%;
// }
.containerImageSalon {
  max-width: 50%;
  display: flex;
  margin-right: 20px;
  overflow: hidden;
  margin-top: 20px;
    margin-bottom: 20px;
  @media(max-width: 990px) {
    width:100%;
    max-width: 80%;
    margin: 40px 10px;
  }
}
.containerImageSalon > img {
  width: 800%;
}
@media(max-width: 990px) {
  .QuiSommesNous {
    width:100%;
  }
}
</style>
<script>
import VideoBg from 'vue-videobg'
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: "lhairderien - l'histoire d'un institut crée par une equipe de passionnées",
      description: '',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/salon'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      imageSalon: [
        { url: require('../assets/salon01.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans3.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans4.jpg'), alt: 'turbans femme' }
      ]
    }
  },
  components: {
    VideoBg
    // InstagramEmbed
    // VueInstagram
  }
})
</script>
