<template>

    <img style="width:50%" v-for="(item, i) in imagesSynthetics.imagesSynthetics_bis" :key="i" :src="item.url" alt="item.alt" @click="$emit('open')">

</template>

<script>
export default {
  props: [
    'imagesSynthetics'
  ]
}
</script>
