<template>
  <div class="map">
    <h1>Plan du site</h1>

    <div class="container_links">

     <div class="container_links_bis">
     <h3>Hommes</h3>
    <router-link style=" color:black;list-style:none; text-decoration:none;" v-for="item in sublist1.slice(0,3)" :key="item" :to="item.to">
    {{ item.name }}
    </router-link>
  </div>

  <div class="container_links_bis">
     <h3>Femmes</h3>
    <router-link style=" color:black;list-style:none; text-decoration:none;" v-for="item in sublist1.slice(3,13)" :key="item" :to="item.to">
    {{ item.name }}
    </router-link>
  </div>

  <div class="container_links_bis" >
     <h3>Conseils</h3>
    <router-link style=" color:black;list-style:none; text-decoration:none;" v-for="item in sublist1.slice(13,16)" :key="item" :to="item.to">
    {{ item.name }}
    </router-link>
  </div>

  <div class="container_links_bis" >
     <h3></h3>
    <router-link style=" color:black;list-style:none; text-decoration:none;" v-for="item in sublist1.slice(16,17)" :key="item" :to="item.to">
    {{ item.name }}
    </router-link>

  </div>

  <div class="container_links_bis" >
     <h3>A propos</h3>
    <router-link style=" color:black;list-style:none; text-decoration:none;" v-for="item in sublist1.slice(17,22)" :key="item" :to="item.to">
    {{ item.name }}
    </router-link>

  </div>
  </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      current: false,
      isActive: false,
      sublist1: [
        { id: 1, name: 'Complements Capillaires homme', to: '/complements-capillaires-homme' },
        { id: 2, name: 'Conseils homme', to: '/conseils-complements-capillaires-homme' },
        { id: 3, name: 'Perruques Homme', to: '/perrques-homme' },
        { id: 4, name: 'Complements Capillaires', to: '/complements-capillaires-femme' },
        { id: 5, name: 'Perruques Médicales', to: '/perruques-medicales-femmes' },
        { id: 6, name: 'Perruques Cheveux Naturels', to: '/perruques-cheveux-naturels' },
        { id: 7, name: 'Perruques Fibre Prime', to: '/perruques-fibre-prime' },
        { id: 8, name: 'Perruques Relooking', to: '/perruques-relooking' },
        { id: 9, name: 'Perruques Type Lace', to: '/perruques-type-lace' },
        { id: 10, name: 'Bonnets Et Turbans', to: '/bonnets-turbans' },
        { id: 11, name: 'Franges Et Couronnes', to: '/franges-couronnes' },
        { id: 12, name: 'Volumateurs', to: '/volumateurs' },
        { id: 13, name: 'Postiches', to: '/postiches' },
        { id: 14, name: 'Conseils Bonnets Et Franges', to: '/conseils-bonnets-franges' },
        { id: 15, name: 'Conseils Complement Femme', to: '/conseils-complements-femme' },
        { id: 16, name: 'Conseils Perruque', to: '/conseils-perruque-femme' },
        { id: 17, name: 'Causes chute de cheveux', to: '/causes-chute-de-cheveux' },
        { id: 18, name: 'Salon', to: '/salon' },
        { id: 19, name: 'Reflexologie Massage Shiatsu', to: '/reflexologie-massage-shiatsu' },
        { id: 20, name: 'Gestion des cookies', to: '/gestion-cookies' },
        { id: 21, name: 'Politique de confidentialité', to: '/politique-de-confidentialite' },
        { id: 22, name: 'CGU', to: '/cgu' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.map {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
     float: right;
}
.container_links {
  width: 100%;
    // position: absolute;
    // right: 0;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
}
.container_links_bis {
display:flex;
justify-content:center;
align-items: center;
flex-direction: column;
text-align:center;
width:25%;
border: 1px solid black;
 border-radius: 5px;
 padding:10px;
}
@media  (max-width: 990px) {
  .container_links_bis {
    width:50%;
  }
  .map {
    width:100%;
  }
}
</style>
