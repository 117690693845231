<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="PerruquesRelooking">

    <section class="product">
<div class="wrapperCarouel" style="position:relative" v-on:mousemove="dragging" v-on:mousedown="dragStart" v-on:mouseup="dragStop">
  <button id="prev-button" >
  <svg style="width:50%" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="45px" height="45px" viewBox="0 0 45 70" enable-background="new 0 0 45 70" xml:space="preserve">
<polygon fill="#1AC6E2" points="0,35.022 34.946,0 44.873,0 9.966,34.931 45.117,70 34.946,70 "/>
</svg>
</button>
<button id="next-button" >
  <svg style="width:50%" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="45px" height="45px" viewBox="0 0 45 70" enable-background="new 0 0 45 70" xml:space="preserve">
<polygon fill="#1AC6E2" points="45.117,35.022 10.171,0 0.244,0 35.15,34.931 0,70 10.171,70 "/>
</svg>
</button>
  <ul class="carouselUl">
    <li class="card" v-for="item in slides" :key="item">
      <div class="img">
        <img :src="item.image" alt="" draggable="false">
      </div>
    </li>
  </ul>
</div>
<ul class="indicators">
  <li v-for="(indicator, index) in indicators" :key="index" :class="{ active: indicator.active }"></li>
</ul>

</section>
<!-- </section> -->
    <div style="margin-top: 20px;" class="container_full_intro">
      <div style="padding-bottom: 0px;" class="container_intro_pmf">
        <h1 style="font-family: unset; color:black;" class="title_tab">Complément Capillaire Homme </h1>
      </div>
    </div>
    <div style="padding: 40px 20px 20px 20px;" class="container_description_hdp">
      <p>
        Qu'est ce que c'est ? <br>
        Fine membrane de 0.02mm à 0.1mm ou en tulle, ces prothèses aussi épaisses qu'une feuille de cigarette, n'ont rien de camparable à la perruque homme. <br> Les compléments capillaires sont quasiment indetectables, que ce soit visuellement ou au touché. <br>
        Ces membranes capillaires sont une très bonnes alternatives aux implants. <br>
        Oubliez le manque de cheveux ou la calvitie qui vous complexe tant, grâce à cette nouvelle technologie. Fixées avec des résines hypoallergéniques, ces compléments capillaires résistent à l'eau, à la transpiration et fortes tractions. Avec celles-ci, n'ayez pas peur de faire des sports extremes comme du saut en parachute. Ces prothèses restes fixées 3 à 4 semaines avant le recollage.   <br>

      </p>

    </div>

    <!-- <div class="container_body">
          <div class="container_coupe_courte" >
            <div class="sub_container_coupe_courte">
              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in imagesFibrePrime" :key="item.id"  @click="toggleModal(item.id)">
                <img :src="item.url" :alt="item.alt" :id="item.id">
                <div class="container_name_price">
                  <div v-if="current === item.id "  class="name">{{ item.name }}</div>
                  <div v-if="current === item.id "  class="price">{{ item.price }}€</div>
                </div>
                <base-modal :descriptionTest="item.description"  v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide" />
              </div>
            </div>
          </div>
          <span class="transition"></span> -->
<!-- </div> -->
<div class="container_vids2">
    <!-- <div class="imgs" > -->
      <div class="container_video1">
  <videos-item style="" class="video" type="mp4" :sources="[require('../assets/complementCapH/video_homme_1.mp4')]">
      <!-- If you want to add content here, a slot is waiting! -->
  </videos-item>
</div>

    <!-- </div> -->
  </div>

<h2 class="title_tab" style="margin-top:70px;"> Pourquoi opter pour le complément capillaires plus que les implants ? </h2>
<div class="container_description_bis">
    <div class="container_step">
    <!-- <h4>Etape</h4> <h4 class="number">1</h4> -->
    </div>
        <p style="margin-bottom:50px; margin-top:10px;">
          A l'institut l'Hair de rien, nous avons remarqué en écoutant nos clients, que plusieurs d'entres eux avaient déja fait l'expérience des implants, avant de passer au complément capillaire. <br>
            En effet certaines de ces personnes ont eu un rejet de leurs implants et leurs cheveux greffés sont retombés après quelques mois ou quelques années laissant en plus des cicatrices plus ou moins visibles. Pour d'autres, ils sont tombés sur un mauvais chirurgiens et la chirurgie a été ratée. En plus d'avoir été douloureux et coûteux, ces personnes sont ressortis de cette experiences plus complexées qu'avant. <br>
            En règle générale, on évite de faire des implants de cheveux avant 35ans, l'âge ou enfin la calvitie commence à se stabiliser. <br>
            Certaines personnes aiment aussi avoir une certaine densité de cheveux qu'ils ne pourront avoir autrement que par le complement capillaire. <br>
            Avec les implants vous ne pouvez pas avoir le choix de votre futur densité, vos greffons se font en fonction de vos cheveux sur l'arrière du crâne. <br>
            Avec les membranes vous pourrez choisir et vous faire conseiller sur la densité de cheveux qui vous plait et vous correspond: 90%, 100%, 110% ou même 120%. <br>
            En plus d'être complétement indolore, c'est à dire aucune douleur, pas de chirurgie donc pas de remission, le complément capillaire peut être mis du jour au lendemain. De plus, aucuns regrets: si ça ne vous convient pas, vous pouvez l'enlevez, aucunes marques ou cicatrices. <br>
        </p>
  </div>
  <!-- <h3 class="title_tab"> Pourquoi les compléments capillaires plus que les implants ? </h3> -->

   <div class="container_vids2">
   <iframe width="450" height="365" src="https://www.youtube.com/embed/4h4gYQnRg1Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <!-- </div> -->
  </div>
  <p style="margin-left: 20px; margin-right: 20px;">
          Les compléments capillaires, appelés "dermofusion" sur la video, passent à la télé. De plus en plus assumés, ils deviennent virales, en partie sur les réseaux sociaux. <br>
        </p>
<div class="container_mozaic">
  <div @mouseover="setCurrent(item.id)" class="container_image_mozaic"  v-for="item in image_mozaic" :key="item.id" >
    <img :src="item.url" :alt="item.alt" :id="item.id" @click="toggleModal(item.id)">
    <base-modal-mozaic :image_mozaic="item.url"  v-if="isShowModal === item.id" @close="closePopup()"></base-modal-mozaic>
  </div>
</div>
<h3 class="title_tab" style="margin-top: 50px;" > Vous optez pour cette solution, quelles sont les étapes? </h3>
<div class="container_desc_pics">

  <div class="container_description1">
    <div class="container_step">
    <h4>Step</h4> <h4 class="number">1</h4>
    </div>
        <p>
          Lors d'un 1er rendez-vous auprès de professionnels diplomés qui saura vous écouter, nous vous expliquerons tous les avantages et limites du compléments capillaire. <b></b> <br>
          Après toutes ces explications et si vous êtes près à commencer l'aventure, nous déterminerons avec vous le type de base du complément capillaire qui vous correspond le mieux au vue de la future coupe choisie, de la densitée de cheveux existante, de votre budget et de votre train de vie: sportif ou non, votre métier, si vous transpirez beaucoup et si vous voyagez beaucoup. <b></b> <br>
Nous choisirons alors ensemble une base en fine membrane avec une épaisseur définie, plus ou moins robuste, en tulle ou avec un mixte de matière.
            <br>
          <br>
        </p>
  </div>
  <div class="container_pics">
    <div class="imgs" >
      <img class="img1" :src="pictures[0].url" alt="">
      <img class="img2" :src="pictures[1].url" alt="">
      <img class="img3" :src="pictures[2].url" alt="">

    </div>
  </div>
</div>

<div class="container_desc_picsBis">
  <div class="container_descriptionBis">
    <div class="container_step">
    <h4>Step</h4> <h4 class="number">2</h4>
    </div>
        <p>
            Ensuite, si votre longueur de cheveux est suffisement longue sur votre couronne, c'est à dire 1cm de cheveux, nous pourrons determiner la couleur des cheveux de votre complément capillaire. <br> Un large panel existe, du brun ou blond clair en passant par différents pourcentages de cheveux blancs. <br> Pour toutes demandes spécifiques, nous sommes coloristes-permanentistes. Nous déterminerons également avec vous votre type de frisure.
        </p>
  </div>
  <div class="container_pics2">
    <div class="imgs" >
      <img class="img1" :src="pictures2[0].url" alt="">
      <img class="img2" :src="pictures2[1].url" alt="">

    </div>
  </div>
</div>

<div class="container_desc_pics">
  <div class="container_description1">
    <div class="container_step">
    <h4>Step</h4> <h4 class="number">3</h4>
    </div>
        <p>
            Une fois les 2 étapes précédentes finalisées, nous choisirons ensemble le type de collage. Colle Acrylique, résine, adhésifs ou mixte de plusieurs type de collage. <br>
            Le choix sera fait en fonction de la base du complément capillaire choisie et de la fixation que vous voulez: <br>
            Temporaire: pour quelques jours maximum et vous pouvez enlever la prothèse comme bon vous semble , ou permanente: fixée fortement 3 à 4 semaines jusqu'au prochain rendez-vous d'entretien. <br>

        </p>
  </div>
  <div class="container_pics">
    <div class="imgs" >
      <img class="img1" :src="pictures3[0].url" alt="">
      <img class="img2" :src="pictures3[1].url" alt="">
      <!-- <img class="img3" :src="pictures3[2].url" alt=""> -->

    </div>
  </div>
</div>

<div class="container_desc_pics2">
  <div class="container_descriptionBis">
    <div class="container_step">
    <h4>Step</h4> <h4 class="number">4</h4>
    </div>
        <p>
            Lorsque toutes ces étapes ont été effectuées, nous commanderons votre chevelure et la préparerons. <br>
            Ensuite sera fixé un second rendez vous. Nous vous conseillons de prévoir 2h. <br> Pendant ces 2h, nous vous préparerons le cuir chevelu, la coupe sur vos propres cheveux (fondu américain, coupe classique...) puis nous fixerons la prothèse capillaire sur votre cuir chevelu. Il faudra vraiment attendre 48h, sans eau sur la tête, pour une fixation assurée. <br>
              Après avoir été fixé, toujours pendant ces 2 de rendez-vous, nous ferons la coupe de cheveux du complément capillaire en fonction de vos envies puis nous procéderons au coiffage.  <br>
              Vous aurez ainsi une nouvelle chevelure.  <br>
              Pour tout nouveau porteur, nous vous conseillerons de faire une offre d'essaie afin d'avoir un maximum de conseils et suivi sur 1 à 2 mois: retouches de colle et shampooings chaques semaines.
        </p>
  </div>
  <div class="container_pics1">
    <div class="imgs1" >
      <!-- <div class="container_vids2"> -->
    <!-- <div class="imgs" > -->
      <div class="container_video2">
  <videos-item style="" class="video" type="mp4" :sources="[require('../assets/complementCapH/video1_2.mp4')]">
      <!-- If you want to add content here, a slot is waiting! -->
  </videos-item>
</div>
<!-- </div> -->

    </div>
  </div>
</div>
<div class="container_b">
  <div id="kalendes-widget-container2" onclick="kalendes.show()">
    <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
    </iframe>
    <div id="kalendes-widget-button2" class="blue bottomRight">
      <h2 class="cta_rdv2">Prendre rendez-vous ici</h2>
    </div>
    <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
  </div>
</div>
</div>

</template>

<script>
// import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'
import BaseModalMozaic from '@/components/baseModalMozaic.vue'
import VideosItem from '../components/VideosItem.vue'
// import CarouselItem from '../components/CarouselItem.vue'
// import CarouselSlide from '../components/CarouselSlide.vue'
import { Glide, GlideSlide } from 'vue-glide-js'
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import VideoBg from 'vue-videobg'
// import BaseModal from '@/components/BaseModal.vue'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - nos complements capillaires pour hommes',
      description: '',
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/complements-capillaires-homme'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      indicators: [],
      isDragging: false,
      startX: false,
      startScrollLeft: false,
      isTransitionEnabled: false,
      currentSlideIndex: 0,
      visibleSlides: [],
      firstSlide: null,
      lastSlide: null,

      item: 'image_mozaic',
      myModal: false,
      current: false,
      isShowModal: false,
      startPos: 0,
      currentTranslate: 0,
      prevTranslate: 0,
      animationID: 0,
      currentIndex: 0,
      numbers: [1, 2, 3],
      slides: [
        { image: require('@/assets/complementCapH/slider/1_a.jpg') },
        { image: require('@/assets/complementCapH/slider/1_b.jpg') },
        { image: require('@/assets/complementCapH/slider/1_c.jpg') },
        { image: require('@/assets/complementCapH/slider/1_e.jpg') },
        { image: require('@/assets/complementCapH/slider/2_a.jpg') },
        { image: require('@/assets/complementCapH/slider/2_b.jpg') },
        { image: require('@/assets/complementCapH/slider/2_c.jpg') },
        { image: require('@/assets/complementCapH/slider/3_a.jpg') },
        { image: require('@/assets/complementCapH/slider/3_b.jpg') },
        { image: require('@/assets/complementCapH/slider/4_a.jpg') },
        { image: require('@/assets/complementCapH/slider/4_b.jpg') },
        { image: require('@/assets/complementCapH/slider/4_c.jpg') },
        { image: require('@/assets/complementCapH/slider/5_a.jpg') },
        { image: require('@/assets/complementCapH/slider/5_b.jpg') },
        { image: require('@/assets/complementCapH/slider/6_a.jpg') },
        { image: require('@/assets/complementCapH/slider/6_b.jpg') },
        { image: require('@/assets/complementCapH/slider/9_a.jpg') },
        { image: require('@/assets/complementCapH/slider/9_b.jpg') },
        { image: require('@/assets/complementCapH/slider/9_c.jpg') },
        { image: require('@/assets/complementCapH/slider/10_a.jpg') },
        { image: require('@/assets/complementCapH/slider/10_b.jpg') },
        { image: require('@/assets/complementCapH/slider/11_a.jpg') },
        { image: require('@/assets/complementCapH/slider/11_b.jpg') },
        { image: require('@/assets/complementCapH/slider/12_a.jpg') }
      ],
      parallax: 1,
      visibleSlide: 0,
      propFixed: false,
      pictures: [
        { url: require('../assets/complementCapH/base_1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementCapH/base_2.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementCapH/base_3.jpg'), alt: 'turbans femme' }
      ],
      pictures3: [
        { url: require('../assets/complementsCapF/2_1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/2_2.jpg'), alt: 'turbans femme' }
      ],
      pictures2: [
        { url: require('../assets/complementCapH/texture_1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementCapH/texture_2.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/3_3.jpg'), alt: 'turbans femme' }
      ],
      imagesSlide: [
        { url: require('../assets/complementsCapF/1_a.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/1_b.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/1_c.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/1_d.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/1_e.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/1_f.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/2_a.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/2_b.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/2_c.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/3_a.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/3_b.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/3_c.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/4_a.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/4_b.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/4_c.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/5.jpg'), alt: 'turbans femme' }
      ],
      image_mozaic: [
        {
          id: 1,
          url: ('https://lhairderien-perruque.fr/lhairderien/src/assets/mozaic_h/img1.jpg'),
          alt: 'complement capillaire homme'
        },
        {
          id: 2,
          url: ('https://lhairderien-perruque.fr/lhairderien/src/assets/mozaic_h/img2.jpg'),
          alt: 'complement capillaire homme'
        },
        {
          id: 3,
          url: ('https://lhairderien-perruque.fr/lhairderien/src/assets/mozaic_h/img3.jpg'),
          alt: 'complement capillaire homme'
        },
        {
          id: 4,
          url: ('https://lhairderien-perruque.fr/lhairderien/src/assets/mozaic_h/img4.jpg'),
          alt: 'complement capillaire homme'
        },
        {
          id: 5,
          url: ('https://lhairderien-perruque.fr/lhairderien/src/assets/mozaic_h/img5.jpg'),
          alt: 'complement capillaire homme'
        },
        {
          id: 6,
          url: ('https://lhairderien-perruque.fr/lhairderien/src/assets/mozaic_h/img6.jpg'),
          alt: 'complement capillaire homme'
        },
        {
          id: 7,
          url: ('https://lhairderien-perruque.fr/lhairderien/src/assets/mozaic_h/img7.jpg'),
          alt: 'complement capillaire homme'
        },
        {
          id: 8,
          url: ('https://lhairderien-perruque.fr/lhairderien/src/assets/mozaic_h/img8.jpg'),
          alt: 'complement capillaire homme'
        },
        {
          id: 9,
          url: ('https://lhairderien-perruque.fr/lhairderien/src/assets/mozaic_h/img9.jpg'),
          alt: 'complement capillaire homme'
        },
        {
          id: 10,
          url: ('https://lhairderien-perruque.fr/lhairderien/src/assets/mozaic_h/img10.jpg'),
          alt: 'complement capillaire homme'
        },
        {
          id: 11,
          url: ('https://lhairderien-perruque.fr/lhairderien/src/assets/mozaic_h/img11.jpg'),
          alt: 'complement capillaire homme'
        },
        {
          id: 12,
          url: ('https://lhairderien-perruque.fr/lhairderien/src/assets/mozaic_h/img12.jpg'),
          alt: 'complement capillaire homme'
        }
      ]
    }
  },
  components: {
    // VideoBg,
    VideosItem,
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
    // VueperSlides,
    // VueperSlide
    // CarouselItem,
    // CarouselSlide
    BaseModalMozaic
    // SliderItem

  },
  mounted () {
    const carousel = document.querySelector('.carouselUl')
    carousel.addEventListener('mousemove', this.dragging)
    carousel.addEventListener('mousedown', this.dragStart)
    carousel.addEventListener('mouseup', this.dragStop)
    const arrowBtns = document.querySelectorAll('button')
    const firstCardWidth = carousel.querySelector('.card').offsetWidth
    arrowBtns.forEach(icon => {
      icon.addEventListener('click', () => {
        console.log(icon.id)
        carousel.scrollLeft += icon.id === 'prev-button' ? -firstCardWidth : firstCardWidth
      })
    })
    // this.slides.forEach((item, index) => {
    //   this.indicators.push({ active: index === 0 })
    // })
  },

  methods: {
    dragStart (e) {
      const carousel = document.querySelector('.carouselUl')
      this.isDragging = true
      carousel.classList.add('dragging')
      this.startX = e.pageX
      this.startScrollLeft = carousel.scrollLeft
    },
    dragStop () {
      const carousel = document.querySelector('.carouselUl')
      this.isDragging = false
      carousel.classList.remove('dragging')

      const currentIndex = Math.round(carousel.scrollLeft / carousel.offsetWidth)
      this.indicators.forEach((indicator, index) => {
        indicator.active = index === currentIndex
        console.log(currentIndex)
      })
    },

    dragging (e) {
      const carousel = document.querySelector('.carouselUl')
      if (!this.isDragging) {
        return
      }
      console.log(e.pageX)
      carousel.scrollLeft = this.startScrollLeft - (e.pageX - this.startX)
    },
    toggleModal (id) {
      if (this.isShowModal !== id) {
        this.isShowModal = true
        this.isShowModal = id
        // window.scrollTo(0, 1800)
        const body = document.querySelector('body')
        // const containerBody = document.querySelector('.container_body')
        if (this.isShowModal === id) {
          body.style.overflow = 'hidden'
          // containerBody.style.visibility = 'hidden'
          // console.log('la popup est ouverte !')
        }
      } else {
        const body = document.querySelector('body')
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
        body.style.overflow = 'visible'
      }
    },
    closePopup (id) {
      const body = document.querySelector('body')
      if (this.isShowModal !== id) {
        body.style.overflow = 'visible'
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
      }
    },
    setCurrent (id) {
      this.current = id
      // console.log(id)
    },
    // close () {
    //   this.isShowModal = false
    //   console.log(this.isShowModal)
    //   console.log('toto')
    // }
    next () {
      const sliderMain = document.getElementById('slider-main')
      const item = sliderMain.getElementsByClassName('item')
      // sliderMain.append(item[0])
      setTimeout(sliderMain.append(item[0]), 1.0 * 1000)
    },
    prev () {
      const sliderMain = document.getElementById('slider-main')
      const item = sliderMain.getElementsByClassName('item')
      sliderMain.prepend(item[this.slides.length - 1])
    },
    slidEffect () {
      const slider = document.querySelector('.slider-main')
      const slides = Array.from(document.querySelectorAll('.item'))
      console.log(slider)
      console.log(slides)
    }
  }
})
</script>
<style lang="scss" scoped>

/* css for carousel js */

.container-fluid {
  width: 100%;
  @media(max-width:990px) {
    margin-top: 40px;
  }
}
.slider-wrap {
  position: relative;
  overflow: hidden;
}
.slider-main {
  position: relative;
  width: 1300%;
  display: flex;
  @media (min-width: 550px) and (max-width:990px) {
    width: 867%;

  }
  @media (min-width: 990px){
    width: 520%
  }
}
.item{
  // display: inline-block;
  width:100%;
  transition: 0.4s;
  .text {
    width: 100%;
    position: relative;
  }
}
.btn_area {
  z-index: 998;
    /* float: right; */
    /* right: 50%; */
    /* gap: 1600%; */
    justify-content: space-between;
    display: flex;
    position: absolute;
    top: 40%;
    width: 100%;
    min-height: 98%;
}
.slider-btn {
  right: 50%;
    border: none;
    outline: none;
    transition: 0.4s;
    margin-left: 10px;
    /* position: absolute; */
    /* margin: 15px; */
    background: #00ffdf36;
    border-radius: 40px;
    /* display: flex; */
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  &:hover {
    background:#00ffdfbd;
  }
}

/*  end of css carousel js */
.container_image_mozaic {
  // margin: 5px;
    padding: 0;
    display: inline-grid;
    width: 30%;
    position: relative;
    cursor: pointer;
    @media (max-width: 990px) {
      width: 32.5%;
      .name, .price {
        font-size: 15px;
      }
    }
    @media (min-width: 990px) {
      max-width: 24%;
    }
  img {
    width: 100%;
  }
}
.container_video2 {
    display: block;
    width: 100%;
    height: 400px;
}
.vueperslide {
  background-size: contain;
  background-repeat: no-repeat;

}
video {
  height: 100px !important;
}
.container_step {
  // position: absolute;
  top: 0;
  left: 0;
  // position: absolute;
  display: flex;
  margin-left: 20px;
  margin-top: 20px;
  align-items: center;
  // gap: 20px;
  h4 {
    font-size: 30px;
  }
}

.number {
  // border: 1px solid black;
  padding: 20px;
  // border-radius: 60px;
  font-weight: bold;
  font-size: 20px;
}
.container_description, .container_description_hdp {
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid black;
  // border-radius: 10px ;
  padding: 20px;
  margin: 0px;
  color: black;
}
.container_description1 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
  margin: 50px;
  color: black;
  width: 50%;
  // position: relative;
}
.container_description_bis{
  width:90%;
  justify-content: center;
  display: flex;
}
.container_descriptionBis {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 10px ;
  padding: 20px;
  // margin: 50px;
  color: black;
  width: 90%;
  // position: relative;
}
.container_video {
   display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  width: 100%;
  // background: black;
}
.container_video1 {
  display: block;
    // justify-content: center;
    // align-items: center;
   width: 60%;
    height: 400px;
}.container_descriptionBis {
  width:70%;
}
.container_b {
  margin-top: 50px;
  background: white;
    color: black;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
@media (max-width:990px) {
  #kalendes-widget-container2 {
  width: 40%;
}
    }
@media(max-width:990px) {
  .container_video1 {
  width: 70%;
    height: 200px;
  }
  .container_video2 {
  display: block;
  width: 150%;
  height: 400px;
}
}

.video {
//   width: 50% !important;
 max-height: 100%;

}
.container_desc_pics, .container_desc_picsBis {
  display: flex;
  width: 100%;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
}
.container_desc_pics2 {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  margin-top: 50px;
  justify-content: center;
}

.container_pics{
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 100%;
  }
  .imgs {
    width: 65%;
  }
.img1, .img3 {
  margin-left: 50px;
}
.img2 {
  margin-right: 50px;
}
}

.container_pics1{
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  .imgs1 {
    width: 100%;
  }
}
.container_desc_picsBis {
  flex-direction: row-reverse;
}

.container_pics2{
  width: 30%;
  display: flex;
  margin-left: 70px;
  // justify-content: center;
  img{
    width: 100%;
  }
  .imgs {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 5px;
  }
.img1, .img3 {
  margin-left: 50px;
}
.img2 {
  margin-right: 50px;
}
}
.container_vids2{
  width: 100%;
  display: flex;
  justify-content: center;
}
.PerruquesRelooking {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    margin-bottom: 48px;
     float: right;
}

.fixed {
  height: 100vh;
  overflow: hidden;
}
.header-image__image1 {
  // background-image: url('http://localhost:8080/img/imgTurbans3.d369218e.jpg');
  height: 50vh;
  background-size: cover;

  background-repeat: no-repeat;
}
.container_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.sub_container_coupe_courte{
 display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}
.container_coupe_courte{
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // z-index: 0;
}
// h1 {
//   font-size: 60px;
//   margin: 10px 10px;

// }
.container_img_name {
 margin: 5px;
    padding: 0;
    display: inline-grid;
    width: 30%;
    position: relative;
    cursor: pointer;
  img {
    width: 100%;
  }
  .container_name_price {
    position: absolute;
    background: white;
    bottom: -40px;
    left: 0;
    width: 100%;
    z-index: 8;
    color: black;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    .name, .price {
    font-size: 22px;
  }
  }
}
.transition {
border-top: 1px solid black;
    width: 80%;
    margin: 25px;
}
@media (max-width: 550px) {
.header-image__image1 {
  // background-image: url('http://localhost:8080/img/imgTurbans4.48fc48e8.jpg')
}
.container_img_name {
  width: 47%;
.name {
  font-size: 18px;
}
}
.PerruquesRelooking {
  width:100%;
}
}
@media (max-width: 800px) {
.vueperslides__parallax-wrapper {
  height: 200px !important;
}
.vueperslide {
  background-size: cover;
  background-position: center;
}
}
@media (max-width: 990px) {
.container_vids2{
  width: 100%;
}
.container_vids3{
  width: 100%;
}
.container_pics2 {
  margin-left:0px;
  width: 40%;
}
.container_video2 {
  display: block;
    width: 350px;
    height: 250px;
    text-align: center;
}
.container_pics1 {
  width:100%;
  margin-left: 0px;
}
.container_pics1 .imgs1 {
  width: 100%;
  display: flex;
    justify-content: center;
    margin-left: 0px;
}
.PerruquesRelooking {
  width:100%;
}
.container_desc_pics {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: black;
    padding-bottom: 20px;
    .imgs {
      width: 100%;
    display: flex;
    justify-content: center;
    // flex-direction: row;
    border:
     solid 5px white;
    }
}
.container_desc_picsBis, .container_desc_pics2 {
  flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
    .imgs {
      flex-direction: row;
      width: 100%;
    display: flex;
    justify-content: center;
    // flex-direction: row;
    border:
     solid 5px white;
    }
}
.container_pics, .container_pics2 {
  justify-content: center;
  margin-right: 5px;
  // margin-left: 20px;
}
.container_pics {
  justify-content: center;
.img1, .img3, .img2 {
  margin-left: 0px;
  margin-right: 0px;
}
}
.container_mozaic {
  margin-top: 50px;
}
.container_description, .container_description1, .container_decriptionBis {
  width: 90%;
  margin: 0;
  flex-direction: column;

}

.header-image__image1 {
  // background-image: url('http://localhost:8080/img/imgTurbans3.d369218e.jpg')
}
}
@media (max-width: 990px) {
//   .container_description2 {
//   width: 100%;
//   margin: 25px;
// }
.container_description_hdp {
 margin:0px;
}
.container_description1 {
  color: white;
  padding:10px;
  flex-direction: column;
}
.container_description_bis {
  width: 90%;
    justify-content: center;
    /* text-align: center; */
    align-items: center;
    display: flex;
}
.container_descriptionBis {
  color: rgb(0, 0, 0);
  padding:10px;
  width: 90%;
  flex-direction: column;
  border:0px;
  margin: 0px;
}
.container_desc_pics2  {
  flex-direction: column;
    padding-bottom: 20px;
    margin-top:0px;
}

.container_desc_pics .imgs {
  flex-direction: row;
  border: none;
}
.container_pics, .container_pics2 {
  // width:50%;
  .img1, .img2 {
  margin-left: 0px;
  margin-right: 0px;
}
}
  // .container_vids2 {
  //   justify-content: center;
  //   margin-left: 20px;
  // }
.container_video1 {
  width:100%;
  height: 200px;
}
.container_video2 {
  width:80%;
  height: 200px;
}
.video1 {
  width: 80% !important;
}
}
@media(min-width:300px) and (max-width:990px) {
  .container_video1 {
  height: 250px;
}
.container_video2 {
  height: 300px;
}
}
@media (min-width: 990px) {
  .container_img_name {
  max-width: 15%;
.name {
  font-size: 30px;
}
}
.container_video2 {
    display: block;
    justify-content: center;
    align-items: center;
    width: 70%;
    // height: 100%;
}
.container_vids2{
  width:100%;
}
.container_vids3{
  width: 100%;
}
.video {
  height: 828px !important;
}
}

// @media only screen and (max-width: 700px) {
//   table,
//   thead,
//   tbody,
//   tr,
//   th,
//   td {
//     display: block;
//   }
//   .entry-content table:not(.variations),
//   body.et-pb-preview #main-content .container table {
//     border: 1px solid black;
//   }
//   thead {
//     display: none;
//   }
//   td {
//     padding-left: 150px !important;
//     position: relative;
//     margin-top: -1px;
//     background: #fff;
//     font-size: 10px;
//     line-height: 15px;
//     color: black;
//   }
//   td:nth-child(odd) {
//     background-color: #eee;
//   }

//   td::before {
//     padding: 10px;
//     content: attr(data-label);
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 130px;
//     bottom: 0;
//     background-color: #000;
//     color: #fff;
//     display: flex;
//     align-items: center;
//     font-weight: bold;
//   }
//   tr {
//     margin-bottom: 1rem;
//   }
// }

.carousel-container {
  display: flex;
  overflow: hidden;
  position:relative;
}

.carousel {
  display: flex;
}

.carousel-image {
  flex: 0 0 calc(100% / 5); /* Modifier en fonction du nombre d'images souhaité */
  max-width: 100%;
}
.carousel-image img {
  max-width: 100%;
  height: auto;
}
.prev-button,
.next-button {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  background: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

#prev-button {
  left: 10px;
  z-index: 9999;
}

#next-button {
  right: 10px;
  z-index: 9999;
}
@media (max-width: 800px) {
  .carousel-image {
    flex: 0 0 calc(100% / 2);
  }
  .carousel-container {
    margin-top:45px;
  }
}

@media (min-width: 800px) and (max-width: 990px) {
  .carousel-image {
    flex: 0 0 calc(100% / 3);
  }
  .carousel-container {
    margin-top:45px;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateX(100%), translateX(-50%); /* Ajoutez ici la transformation souhaitée */
}
// cs 2ieme carousel
// carousel femmes
.product {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 35px;
  flex-direction: column;
  padding-top: 15px;
}
.wrapperCarouel {
  max-width: 1100px;
  width:100%;
}
.wrapperCarouel .carouselUl {
  display:grid;
  grid-auto-flow: column;
  grid-auto-columns: calc((100% / 3) - 12px);
  gap:16px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: 0;
}
.carouselUl.dragging {
  scroll-behavior: auto;
  scroll-snap-type: none;
}
.carouselUl::-webkit-scrollbar {
  display:none;
}
.carouselUl {
  cursor: pointer;
}
.carouselUl .card {
  list-style: none;
  height: 457px;
  background: white;
  scroll-snap-align: start;
}
.carouselUl.dragging .card {
  cursor: grab;
  user-select:none;
}
.card .img img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  //height: 300px;
}
#prev-button {
  position: absolute;
    text-align: center;
    cursor: pointer;
    line-height: 50px;
    border-radius: 50px;
    left: -22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 2px;
    top:50%;
    transform:translateY(-90%);
    box-shadow:0 3px 6px rgba(0,0,0,0.23);
    background: white;
}
#next-button {
  position:absolute;
  text-align: center;
  cursor: pointer;
  line-height: 50px;
  border-radius: 50px;
  right: -22px;
  display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 2px;
    top:50%;
    transform:translateY(-90%);
    box-shadow:0 3px 6px rgba(0,0,0,0.23);
    background: white;
}
@media screen and (max-width:900px) {
  .wrapperCarouel .carouselUl {
    grid-auto-columns: calc((100% / 2) - 9px);
  }
  .product{
    margin-top:35px;
  }
}
@media screen and (max-width:600px) {
  .wrapperCarouel .carouselUl {
    grid-auto-columns: calc(100%);
  }
  .product{
    margin-top:35px;
  }
}
.indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.indicators li {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
  list-style: none;
}

.indicators li.active {
  background-color: #1AC6E2;
}

</style>
