<template>
  <div class="QuiSommesNous">
    <h1 style="height: 100vh; display: flex; justify-content: center; align-items: center; background: rgba(0, 249, 255, 0.14);">Cette page est actuellement en construction ! Encore un peu de patience !</h1>
  </div>
</template>

<style lang="scss" scoped>
.QuiSommesNous {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    float: right;
}
@media(max-width: 990px) {
  .QuiSommesNous {
    width:100%;
  }
}
</style>
