<template>
    <ul class="link-list-item">
        <!-- <li class="sub-items" v-for="(item, i) in list.sublist" :key="i" > -->
          <router-link class="link-list a1" v-for="(item, i) in tab_info_img.sublist1" :key="i" :to="item.to">
            {{ item.name }}
          </router-link>
        <!-- </li> -->
    </ul>
</template>

<script>
export default {
  props: [
    'tab_info_img'
  ]
}
</script>

<style lang="scss" scoped>
.link-list-item {
  display: flex;
  flex-direction: column;
}
.a1 {
  font-family: Arial, Helvetica, sans-serif;
  color:white;
  text-decoration: none;
  list-style: none;
  margin-bottom: 10px;
  &:hover {
    // font-weight: bold;
    color: rgb(255, 255, 255);
    text-shadow: 1px 1px 15px rgba($color: #ffffff, $alpha: 0.9),1px 1px 15px rgba($color: #ffffff, $alpha: 0.9),1px 1px 15px rgba($color: #ffffff, $alpha: 0.9);
  }
}

</style>
