<template>
  <div class="PerruquesRelooking" @click="handleScroll" >
    <!-- <vueper-slides :arrows="false" :parallax="parallax" class="no-shadow"
      :visible-slides="5"
      slide-multiple
      :gap="1"
      :slide-ratio="1 / 4"
      :dragging-distance="200"
      :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }">
      <vueper-slide v-for="(slide) in slides" :key="slide" :image="slide.image" style="background-size: cover !important" />
    </vueper-slides> -->
    <section class="product">
<div class="wrapperCarouel" style="position:relative" v-on:mousemove="dragging" v-on:mousedown="dragStart" v-on:mouseup="dragStop">
  <button id="prev-button" >
  <svg style="width:25%" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="45px" height="45px" viewBox="0 0 45 70" enable-background="new 0 0 45 70" xml:space="preserve">
<polygon fill="#1AC6E2" points="0,35.022 34.946,0 44.873,0 9.966,34.931 45.117,70 34.946,70 "/>
</svg>
</button>
<button id="next-button" >
  <svg style="width:25%" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
width="45px" height="45px" viewBox="0 0 45 70" enable-background="new 0 0 45 70" xml:space="preserve">
<polygon fill="#1AC6E2" points="45.117,35.022 10.171,0 0.244,0 35.15,34.931 0,70 10.171,70 "/>
</svg>
</button>
  <ul class="carouselUl">
    <li class="card" v-for="item in slides" :key="item">
      <div class="img">
        <img :src="item.image" alt="" draggable="false">
      </div>
    </li>
  </ul>
</div>
</section>
    <div class="container_full_intro">
      <div class="container_intro_pmf">
        <h1 class="title_tab">Complements Capillaires Femme </h1>
        <br>
        <div class="container_description2">
          <p>
            Vrai complexe et parfois tabou, le manque de cheveux chez une femme est une réelle souffrance. <br>
            Certaines anomalies ou maladies du cuir chevelu sont permanentes: pelade, alopécie androgénetique, cicatrice et tant d'autres. <br><br>
            Pour cela il existe des <strong>solutions permanentes</strong>. <strong>Complètement personnalisables, les compléments capillaires sont l'une des solutions que nous préferons à  l'institut  l'Hair de rien</strong>. <br><br> En effet aucunes autres prothèses n'a autant un rendu aussi naturel. Le fait que celle ci fois fixée directemment sur le cuir chevelu pour une durée maximum de 1 mois et resistante à l'eau est un avantage indéniable. <br><br>
            Etant prothésistes capillaire diplomées par l'état et coiffeuses hautement qualifiées, Floriane et Laetitia se chargeront de transformer complétement votre nouvelle chevelure ainsi que la votre, grâce à differentes techniques tel que des ombrés modernes, effet racine, coloration et même permanente.
            <br>
          </p>
        </div>
      </div>
    </div>

    <!-- <div class="container_body">
          <div class="container_coupe_courte" >
            <div class="sub_container_coupe_courte">
              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in imagesFibrePrime" :key="item.id"  @click="toggleModal(item.id)">
                <img :src="item.url" :alt="item.alt" :id="item.id">
                <div class="container_name_price">
                  <div v-if="current === item.id "  class="name">{{ item.name }}</div>
                  <div v-if="current === item.id "  class="price">{{ item.price }}€</div>
                </div>
                <base-modal :descriptionTest="item.description"  v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide" />
              </div>
            </div>
          </div>
          <span class="transition"></span> -->
  <!-- </div> -->

    <!-- <div class="imgs" > -->
<div class="container_vids3">
    <!-- <div class="imgs" > -->
      <div class="container_video2">
  <videos-item style="" class="video" type="mp4" :sources="[require('../assets/complementsCapF/video_1.mp4')]">
      <!-- If you want to add content here, a slot is waiting! -->
  </videos-item>
</div>

    <!-- </div> -->
  </div>

    <!-- </div> -->

<div class="container_desc_pics">
  <div class="container_description1">
    <div class="container_step">
    <h4>Etape</h4> <h4 class="number">1</h4>
    </div>
        <p>
          Après consultation auprès de professionnels diplomés, un bilan sera établit pour déterminer le type de cheveu : remy hair, chinois, indien ou européen, ainsi que la longueur. <br>
          Aussi sera défini le <strong>type de base de la prothèse qui vous correspond le mieux</strong>, selon votre style de vie, votre de manque de cheveux et votre budget alloué. <br>
          Tulle pour un résultat des plus naturels comme au cinéma, membrane pour un confort supérieur ou mixte de polyurétane/ tulle pour un complément longue durée. <br> Tous les modèles peuvent se concevoir sur des zones partielles, prédéfinies par une empreinte capillaire faite à l'institut l'Hair de rien ou sur la totalité du cuir chevelu. <br>
          <br>
        </p>
  </div>
  <div class="container_pics">
    <div class="imgs" >
      <img class="img1" :src="pictures[0].url" alt="">
      <img class="img2" :src="pictures[1].url" alt="">
      <img class="img3" :src="pictures[2].url" alt="">

    </div>
  </div>
</div>

<div class="container_desc_picsBis">
  <div class="container_descriptionBis">
    <div class="container_step">
    <h4>Etape</h4> <h4 class="number">2</h4>
    </div>
        <p>
            Ensuite, étape primordiale, sera défini la fixation: collage par adhésif, colle acrylique et/ou silicone et dans certains peignes clips. <br> <br>
            Suivant vos désirs, la fixation pourra être <strong>temporaire</strong>: à la journée, quelques jours voir une semaine ou <strong>permanente</strong>, qui tiendra fortement le mois complet en resistant à l'eau et forte tension sur la chevelure. Pour un maximum de liberté.  <br>
Une touche d'essaie sera obligatoirement faite 48h avant la fixation. <br>
        </p>
  </div>
  <div class="container_pics2">
    <div class="imgs" >
      <img class="img1" :src="pictures2[0].url" alt="">
      <img class="img2" :src="pictures2[1].url" alt="">

    </div>
  </div>
</div>

<div class="container_desc_pics">
  <div class="container_description1">
    <div class="container_step">
    <h4>Etape</h4> <h4 class="number">3</h4>
    </div>
        <p>
            Aussi, vous pourrez demander à votre professionnnel la couleur de vos cheveux que vous désirez. Classiques, sobres, colorés, méchés et tendances actuelles tel que les ombrés ou effet racine, <strong>la technique sera faite au sein de notre boutique</strong>. De plus vous choisirez le type d'ondultion: du lisse au bouclé. <br>
            Nous travaillerons toutes ces techniques sur la prothèse capillaire mais aussi sur vos propres cheveux, si cela est possible, pour un maximum de naturel.
        </p>
  </div>
  <div class="container_pics">
    <div class="imgs" >
      <img class="img1" :src="pictures3[0].url" alt="">
      <img class="img2" :src="pictures3[1].url" alt="">
      <img class="img3" :src="pictures3[2].url" alt="">

    </div>
  </div>
</div>

<div class="container_desc_pics2">
  <div class="container_descriptionBis">
    <div class="container_step">
    <h4>Etape</h4> <h4 class="number">4</h4>
    </div>
        <p>
            Une fois toutes ces étapes effectuées; la chevelure d'appoint prête; une rendez-vous de 2 à 3h vous sera donné. Ce jour, <strong>la prothèse sera fixée ainsi la coupe sera faite suivant vos choix</strong> et nous vous conseillerons suivant la forme de votre visage. <br>
            Tous les professionels de l'Hair de rien sont coiffeurs. Aussi nous procéderons au coiffage de votre chevelure et nous vous accompagnerons au mieux pour son entretien. <br>
            <strong>Suivant la fixation, un rendez-vous mensuel sera necessaire à la refixation du complément capillaire ainsi qu'à la coloration de vos cheveux et la coupe. Vous retrouverez ainsi votre service coiffeur.</strong></p>
  </div>
  <div class="container_vids2">
    <!-- <div class="imgs" > -->
      <div class="container_video1">
  <videos-item style="" class="video1" type="mp4" :sources="[require('../assets/complementsCapF/video_2.mp4')]">
      <!-- If you want to add content here, a slot is waiting! -->
  </videos-item>
</div>

    <!-- </div> -->
  </div>
</div>
  <div class="container_a">
    <h2 class="title_tab">Modalités de remboursement lors d'une alopécie déclarée par un médecin <br>
      pour un complément capillaire <br></h2>
      <p style="margin-left:20px;">Une partie de la prothèse capillaire peut être prit en charge par la sécurité sociale. Un remboursement de 125€ pourra être effectué sur un complément capillaire, hors abonnement, après obtention d'une ordonnnance. <br> Celle-ci pourra être délivrée auprès de votre médecin traitant ou dermatologue. <br>
      Vous pouvez vous renseigner auprès de votre mutuelle pour un rembousement complémentaire. <br> </p>
    <div class="container_b">
      <div class="containerTableau">
        <div class="tableau3">
          <h4 style="text-align:left; font-family:roboto;">Prothèse capillaire partielle<br>
            + un accessoire textile</h4><br>
          <ul style="padding-left:40px">
            <li class="list1">Prix de vente forfaitaire : 125 euros</li> <br>
            <li class="list1">Montant remboursé par la Sécurité Sociale : 125 euros</li><br>
          </ul>
                        <!-- <div>
                          <h4 style="font-weight:bold">Perruques 100% remboursées</h4>
                        </div> -->
        </div>
      </div>
      <div id="kalendes-widget-container3" onclick="kalendes.show()">
            <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
            </iframe>
            <div id="kalendes-widget-button2" class="blue bottomRight">
              <h2 class="cta_rdv">Prendre rendez-vous ici</h2>
            </div>
            <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
          </div>
    </div>
  </div>
</div>

</template>

<script>
// import { VueperSlides, VueperSlide } from 'vueperslides'
// import 'vueperslides/dist/vueperslides.css'
import VideosItem from '../components/VideosItem.vue'
// import SliderTest from '@/components/SliderTest.vue'
// import VideoBg from 'vue-videobg'
// import BaseModal from '@/components/BaseModal.vue'
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - nos complements capillaires pour femmes',
      description: '',
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/complements-capillaires-femme'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      isDragging: false,
      startX: false,
      startScrollLeft: false,
      current: false,
      isShowModal: false,
      propFixed: false,
      pictures: [
        { url: require('../assets/complementsCapF/1_1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/1_2.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/1_3.jpg'), alt: 'turbans femme' }
      ],
      pictures2: [
        { url: require('../assets/complementsCapF/2_1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/2_2.jpg'), alt: 'turbans femme' }
      ],
      pictures3: [
        { url: require('../assets/complementsCapF/3_1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/3_2.jpg'), alt: 'turbans femme' },
        { url: require('../assets/complementsCapF/3_3.jpg'), alt: 'turbans femme' }
      ],
      slides: [
        { image: require('@/assets/complementsCapF/1_a.jpg') },
        { image: require('@/assets/complementsCapF/1_b.jpg') },
        { image: require('@/assets/complementsCapF/1_c.jpg') },
        { image: require('@/assets/complementsCapF/1_d.jpg') },
        { image: require('@/assets/complementsCapF/1_e.jpg') },
        { image: require('@/assets/complementsCapF/1_f.jpg') },
        { image: require('@/assets/complementsCapF/2_a.jpg') },
        { image: require('@/assets/complementsCapF/2_b.jpg') },
        { image: require('@/assets/complementsCapF/2_c.jpg') },
        { image: require('@/assets/complementsCapF/3_a.jpg') },
        { image: require('@/assets/complementsCapF/3_b.jpg') },
        { image: require('@/assets/complementsCapF/3_c.jpg') },
        { image: require('@/assets/complementsCapF/4_a.jpg') },
        { image: require('@/assets/complementsCapF/4_b.jpg') },
        { image: require('@/assets/complementsCapF/4_c.jpg') },
        { image: require('@/assets/complementsCapF/5.jpg') }

      ]
    }
  },
  components: {
    // VideoBg,
    VideosItem
    // BaseModal
    // SliderItem
    // VueperSlides,
    // VueperSlide
  },
  mounted () {
    const carousel = document.querySelector('.carouselUl')
    carousel.addEventListener('mousemove', this.dragging)
    carousel.addEventListener('mousedown', this.dragStart)
    carousel.addEventListener('mouseup', this.dragStop)
    const arrowBtns = document.querySelectorAll('button')
    const firstCardWidth = carousel.querySelector('.card').offsetWidth
    arrowBtns.forEach(icon => {
      icon.addEventListener('click', () => {
        console.log(icon.id)
        carousel.scrollLeft += icon.id === 'prev-button' ? -firstCardWidth : firstCardWidth
      })
    })
  },
  methods: {
    dragStart (e) {
      const carousel = document.querySelector('.carouselUl')
      this.isDragging = true
      carousel.classList.add('dragging')
      this.startX = e.pageX
      this.startScrollLeft = carousel.scrollLeft
    },
    dragStop () {
      const carousel = document.querySelector('.carouselUl')
      this.isDragging = false
      carousel.classList.remove('dragging')
    },
    dragging (e) {
      const carousel = document.querySelector('.carouselUl')
      if (!this.isDragging) {
        return
      }
      console.log(e.pageX)
      carousel.scrollLeft = this.startScrollLeft - (e.pageX - this.startX)
    },
    // openModal () {
    //   this.modalOpen = !this.modalOpen
    // },
    toggleModal (id) {
      if (this.isShowModal !== id) {
        this.isShowModal = true
        this.isShowModal = id
        window.scrollTo(0, 1800)
        const body = document.querySelector('body')
        // const containerBody = document.querySelector('.container_body')
        if (this.isShowModal === id | id === this.imagesFibrePrime.id) {
          body.style.overflow = 'hidden'
          // containerBody.style.visibility = 'hidden'
          console.log('la popup est ouverte !')
        }
      } else {
        const body = document.querySelector('body')
        console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
        body.style.overflow = 'visible'
      }
    },
    // close (id) {
    //   if (this.isShowModal === id) {
    //     this.isShowModal = false
    //     this.isShowModal = !this.isShowModal
    //     console.log('oué !')
    //   }
    // },
    setCurrent (id) {
      this.current = id
      // console.log(id)
    },
    next () {
      const sliderMain = document.getElementById('slider-main')
      const item = sliderMain.getElementsByClassName('item')
      // sliderMain.append(item[0])
      setTimeout(sliderMain.append(item[0]), 1.0 * 1000)
    },
    prev () {
      const sliderMain = document.getElementById('slider-main')
      const item = sliderMain.getElementsByClassName('item')
      sliderMain.prepend(item[this.slides.length - 1])
    },
    slidEffect () {
      const slider = document.querySelector('.slider-main')
      const slides = Array.from(document.querySelectorAll('.item'))
      console.log(slider)
      console.log(slides)
    },
    handleScroll (e) {
      const scrollPos = e.target.scrollTop
      console.log(scrollPos)
    },
    scrollGallery () {
      document.querySelector('.slider-main').scrollTo({
        left: 500,
        behavior: 'smooth'
      })
    }
    // close () {
    //   this.isShowModal = false
    //   console.log(this.isShowModal)
    //   console.log('toto')
    // }
  }
})
</script>
<style lang="scss" scoped>
  /* css for carousel js */

.container-fluid {
  width: 100%;
  @media(max-width:990px) {
    margin-top: 40px;
  }
}
.slider-wrap {
  position: relative;
  overflow: hidden;
}
.slider-main {
  position: relative;
  width: 800%;
  display: flex;
  @media (min-width: 550px) and (max-width:990px) {
    width: 534%;

  }
  @media (min-width: 990px){
    width: 320%;
  }
}
.item{
  // display: inline-block;
  width:100%;
  transition: 0.4s;
  .text {
    width: 100%;
    position: relative;
  }
}
.btn_area {
  z-index: 998;
    /* float: right; */
    /* right: 50%; */
    /* gap: 1600%; */
    justify-content: space-between;
    display: flex;
    position: absolute;
    top: 40%;
    width: 100%;
    min-height: 98%;
}
.slider-btn {
  right: 50%;
    border: none;
    outline: none;
    transition: 0.4s;
    margin-left: 10px;
    /* position: absolute; */
    /* margin: 15px; */
    background: #00ffdf36;
    border-radius: 40px;
    /* display: flex; */
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  &:hover {
    background:#00ffdfbd;
  }
}

/*  end of css carousel js */
.vueperslide {
  background-size: contain;
  background-repeat: no-repeat;

}
video {
  height: 100px !important;
}
.container_step {
  // position: absolute;
  top: 0;
  left: 0;
  // position: absolute;
  display: flex;
  margin-left: 20px;
  margin-top: 20px;
  align-items: center;
  // gap: 20px;
}
.container_intro_pmf {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: 90%;
}
.container_full_intro {
 width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
}
.container_a {
 width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
    margin-top: 20px;
    margin-bottom:20px;
}
.container_b {
  background: white;
    color: black;
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.number {
  // border: 1px solid black;
  padding: 20px;
  // border-radius: 60px;
  font-weight: bold;
  font-size: 20px;
}
.container_description {
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid black;
  // border-radius: 10px ;
  padding: 20px;
  margin: 50px;
  color: black;
}
.container_description2 {
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid black;
  // border-radius: 10px ;
  // padding: 20px;
  // margin: 50px;
  color: black;
}

.container_description1 {
  display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px;
    margin: 25px;
    color: black;
    width: 60%;
}
.container_descriptionBis {
  display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid black;
    border-radius: 10px;
    padding: 20px;
    margin: 25px;
    color: rgb(0, 0, 0);
    width: 60%;
}
.container_video {
   display: flex;
  justify-content: center;
  align-items: center ;
  margin-bottom: 100px;
  width: 100%;
  // background: black;
}
.container_video1 {
   display: flex;
  justify-content: center;
  align-items: center ;
  // margin-bottom: 100px;
  width: 100%;
  // background: black;
  height:400px;
}
.container_video2 {
  display: flex;
  justify-content: center;
  align-items: center ;
  // margin-bottom: 100px;
  width: 100%;
  // background: black;
  height:600px;
}
.video {
  // width: 50% !important;
 max-height: 80%;
 height: 600px;

}
.video1 {
  width: 100% !important;
 max-height: 100%;

}
.container_desc_pics {
  display: flex;
  width: 100%;
  margin-top: 50px;
  justify-content: center;
  @media(max-width:990px) {

  }
}
.container_desc_picsBis {
  display: flex;
  width: 100%;
  margin-top: 50px;
  justify-content: center;
  flex-direction: row-reverse;
  align-items: center;
}
.container_desc_pics2 {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  margin-top: 50px;
  justify-content: center;
}

.container_pics{
  width: 30%;
  display: flex;
  justify-content: center;
  img{
    width: 100%;
  }
  .imgs {
    width: 50%;
  }
.img1, .img3 {
  margin-left: 50px;
}
.img2 {
  margin-right: 50px;
}
}
.container_pics2{
  width: 30%;
  display: flex;
  // justify-content: center;
  img{
    width: 100%;
  }
  .imgs {
    width: 80%;
    display: flex;
    flex-direction: column;
    // align-items: flex-end;
    gap: 5px;
  }
.img1, .img3 {
  margin-left: 50px;
}
.img2 {
  margin-right: 50px;
}
}
.container_vids2{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container_vids3 {
  width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.PerruquesRelooking {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    margin-bottom: 48px;
    float: right;
}

.fixed {
  height: 100vh;
  overflow: hidden;
}
.header-image__image1 {
  // background-image: url('http://localhost:8080/img/imgTurbans3.d369218e.jpg');
  height: 50vh;
  background-size: cover;

  background-repeat: no-repeat;
}
.container_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.sub_container_coupe_courte{
 display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}
.container_coupe_courte{
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // z-index: 0;
}
// h1 {
//   font-size: 60px;
//   margin: 10px 10px;

// }
.container_img_name {
 margin: 5px;
    padding: 0;
    display: inline-grid;
    width: 30%;
    position: relative;
    cursor: pointer;
  img {
    width: 100%;
  }
  .container_name_price {
    position: absolute;
    background: white;
    bottom: -40px;
    left: 0;
    width: 100%;
    z-index: 8;
    color: black;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    .name, .price {
    font-size: 22px;
  }
  }
}
.transition {
border-top: 1px solid black;
    width: 80%;
    margin: 25px;
}
@media (max-width: 550px) {
.header-image__image1 {
  // background-image: url('http://localhost:8080/img/imgTurbans4.48fc48e8.jpg')
}
.container_img_name {
  width: 47%;
.name {
  font-size: 18px;
}
}
.PerruquesRelooking {
  width:100%;
}
}
@media (max-width: 800px) {
.vueperslides__parallax-wrapper {
  height: 200px !important;
}
}
@media (max-width: 990px) {
.container_vids2{
  width: 100%;
}
.container_vids3{
  width: 100%;
}
.container_description2 {
  margin:0;
  padding:0;
}
.container_intro_pmf {
  width:90%;
}
.video {
    width: 70% !important;
    height: 30%;
}
.PerruquesRelooking {
  width:100%;
}
.container_desc_pics {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: black;
    padding-bottom: 20px;
    .imgs {
      width: 100%;
    display: flex;
    justify-content: center;
    // flex-direction: row;
    border:
     solid 5px white;
    }
}
.container_desc_picsBis, .container_desc_pics2 {
  flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
    .imgs {
      width: 100%;
    display: flex;
    justify-content: center;
    // flex-direction: row;
    border:
     solid 5px white;
    }
}
.container_pics, .container_pics2 {
  justify-content: center;
  margin-right: 5px;
  // margin-left: 20px;
}
.container_pics {
  justify-content: center;
.img1, .img3, .img2 {
  margin-left: 0px;
  margin-right: 0px;
}
}
.container_description, .container_description1, .container_decriptionBis {
  width: 90%;
  margin: 0;
}

.header-image__image1 {
  // background-image: url('http://localhost:8080/img/imgTurbans3.d369218e.jpg')
}
}
@media (max-width: 990px) {
//   .container_description2 {
//   width: 100%;
//   margin: 25px;
// }
.container_description1 {
  color: white;
  padding:10px;
  flex-direction: column;
}
.container_descriptionBis {
  color: rgb(0, 0, 0);
  padding:10px;
  width: 90%;
  flex-direction: column;
  border:0px;
  margin: 0px;
}
// .container_desc_pics {
//   flex-direction: row;
// }
.container_desc_pics2  {
  flex-direction: column;
    padding-bottom: 20px;
    margin-top:0px;
}
.container_desc_picsBis {
  flex-direction: column;
  margin-top:0px;
  .imgs {
    flex-direction: row;
  }
}
.container_desc_pics .imgs {
  flex-direction: column;
  @media(max-width:990px) {
    flex-direction: row;
    border: none;
  }
}
.container_pics2 {
  width:50%;
  .img1, .img2 {
  margin-left: 0px;
  margin-right: 0px;
}
}
  // .container_vids2 {
  //   justify-content: center;
  //   margin-left: 20px;
  // }
.container_video1 {
  width:100%;
  height: 200px;
}
.container_video2 {
  width:100%;
  height: 200px;
}
.video1 {
  width: 80% !important;
}
}
@media(min-width:300px) and (max-width:990px) {
  .container_video1 {
  height: 250px;
}
.container_video2 {
  height: 400px;
}
}
@media (min-width: 990px) {
  .container_img_name {
  max-width: 15%;
.name {
  font-size: 30px;
}
}
.container_video2 {
    display: block;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 100%;
}
.container_vids2{
  width:30%;
}
.container_vids3{
  width: 100%;
}
.video {
  height: 828px !important;
}
}

// @media only screen and (max-width: 700px) {
//   table,
//   thead,
//   tbody,
//   tr,
//   th,
//   td {
//     display: block;
//   }
//   .entry-content table:not(.variations),
//   body.et-pb-preview #main-content .container table {
//     border: 1px solid black;
//   }
//   thead {
//     display: none;
//   }
//   td {
//     padding-left: 150px !important;
//     position: relative;
//     margin-top: -1px;
//     background: #fff;
//     font-size: 10px;
//     line-height: 15px;
//     color: black;
//   }
//   td:nth-child(odd) {
//     background-color: #eee;
//   }

//   td::before {
//     padding: 10px;
//     content: attr(data-label);
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 130px;
//     bottom: 0;
//     background-color: #000;
//     color: #fff;
//     display: flex;
//     align-items: center;
//     font-weight: bold;
//   }
//   tr {
//     margin-bottom: 1rem;
//   }
// }
// carousel femmes
.product {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 35px;
  padding-top: 15px;
}
.wrapperCarouel {
  max-width: 1100px;
  width:100%;
}
.wrapperCarouel .carouselUl {
  display:grid;
  grid-auto-flow: column;
  grid-auto-columns: calc((100% / 3) - 12px);
  gap:16px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scrollbar-width: 0;
}
.carouselUl.dragging {
  scroll-behavior: auto;
  scroll-snap-type: none;
}
.carouselUl::-webkit-scrollbar {
  display:none;
}
.carouselUl {
  cursor: pointer;
}
.carouselUl .card {
  list-style: none;
  height: 457px;
  background: white;
  scroll-snap-align: start;
}
.carouselUl.dragging .card {
  cursor: grab;
  user-select:none;
}
.card .img img {
  width: 100%;
  height: 450px;
  object-fit: cover;
  //height: 300px;
}
#prev-button {
  position: absolute;
    text-align: center;
    cursor: pointer;
    line-height: 50px;
    border-radius: 50px;
    left: -22px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 2px;
    top:50%;
    transform:translateY(-90%);
    box-shadow:0 3px 6px rgba(0,0,0,0.23);
    background: white;
    opacity: 0.6;
}
#next-button {
  position:absolute;
  text-align: center;
  cursor: pointer;
  line-height: 50px;
  border-radius: 50px;
  right: -22px;
  display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 2px;
    top:50%;
    transform:translateY(-90%);
    box-shadow:0 3px 6px rgba(0,0,0,0.23);
    background: white;
    opacity: 0.6;
}
@media screen and (max-width:900px) {
  .wrapperCarouel .carouselUl {
    grid-auto-columns: calc((100% / 2) - 9px);
  }
  .product{
    margin-top:35px;
  }
}
@media screen and (max-width:600px) {
  .wrapperCarouel .carouselUl {
    grid-auto-columns: calc(100%);
  }
  .product{
    margin-top:35px;
  }
}

// end carousel femme
</style>
