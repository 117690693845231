<template>
  <head>
    <title>lhairderien - Un large choix de perruques medicales pour femmes</title>
    <meta name="description" content="Perruques médicales naturelles de hautes qualitées. Cheveux naturels, fibres haute température et fibres exclusives à prix avantageux. Personnalisation possible.">
    <link rel="canonical" href="https://lhairderien-perruque.fr/perruques-medicales-femmes">
  </head>
  <div class="PerruquesMedicalesFemme">
     <header class='header-image__image' id="parallax">

      <div class="firstContainerWidget">
        <div class="containerLogo">
            <img class="img_logo" src="https://lhairderien-perruque.fr/lhairderien/src/assets/logo_hdr_2.webp" alt="">
          </div>
          <div class="containerCTA">
            <h1 class="titleCTA" style="margin-bottom:10px; ">Venez decouvrir notre salon</h1>
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight" style="user-select: none; pointer-events:none;">
                <h6 class="titleCTA" style=" font-weight: 800 !important;">Prendre rendez-vous</h6>
                <h5 class="titleCTA" style=" font-weight: 800 !important;">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
        </div>
      </div>
      <CarouselV2 :slides="slides">
      </CarouselV2>
    <div class='header-image__content'>
      <div class='header-image__content__container'>
      </div>
    </div>
  </header>
  <div class="container_full_intro">
    <div class="container_intro_pmf">
      <h3 class="title_tab">
        Modalité de remboursement pour les Perruques synthétiques et naturelles
      </h3>
      <p class="paragraph_intro">
        Notre institut capillaire est un établissement agrée par la sécurité sociale. Tous nos modèles répondent aux dernières normes médicales définies par le Ministère de la Santé. Une majorité des prothèses capillaires est remboursée à 100% par la sécurité sociale et votre mutuelle. N'hésitez pas à vous renseigner auprès ce cette dernière pour connaître vos droits.
      </p>
      <p class="paragraph_intro">
       <br>

        Le ministère de la santé à définit qu'il y avait deux classes pour ces prothèses capillaires tête entière.
        La classe I, des modèles à 350€ entièrement prit en charge par la sécurité sociale. La classe II, avec des modèles à plus de 350€, est remboursée à hauteur de 250€ par la sécurité sociale mais est souvent complétée par votre mutuelle.

      </p><br>
<div class="container_stars">
</div>
<div class="containerTableau">
  <div class="tableau1">
  <h4 style="text-align:left; margin-left:30px; font-family:roboto;">PROTHESE CAPILLAIRE TOTALE Classe I <br>
  + un bonnet easy fit</h4><br>
  <ul style="padding-left:40px">
    <li class="list1">Prix de vente forfaitaire : 350 euros</li> <br>
    <li class="list1">Montant remboursé par la sécurité Sociale : 350 euros</li><br>
    <li class="list1">Aucun reste à charge / Prise en charge 100%</li><br>
  </ul>
  <div>
    <h4 style="font-weight:bold">Perruques 100% remboursées</h4>
  </div>
  </div>
  <div class="tableau2">
  <h4 style="text-align:left; margin-left:30px; font-family:roboto;">PROTHESE CAPILLAIRE TOTALE Classe II<br>
  + un bonnet easy fit</h4><br>
  <ul style="padding-left:40px">
    <li class="list1">Prix de vente forfaitaire : entre 350 et 700 euros</li> <br>
    <li class="list1">Montant remboursé par la sécurité Sociale : 250 euros</li><br>
    <li class="list1">Votre mutuelle peut prendre tout ou en partie le reste à charge</li><br>
  </ul>
  <div>
    <h4 style="font-weight:bold">Perruques remboursées à 250 euros</h4>
  </div>
  </div>
</div>
<h3 class="titlePowerFashion">
  The Power of Women
</h3>
<p class="gammes">
  Nous savons que la chevelure de la femme est sa parure, c'est pourquoi nous vous proposons en magasin + de 500 modèles aux choix qui s'adaptent aussi bien à votre budget qu'à votre esthétisme.  </p>
  <p class="gammes">
  Nous avons selectionné dans ces nombreux modèles, 33 perruques que nous avons toujours en stock en boutiques prêtes à être essayée. Toutes nos prothèses capillaires sont complètement personnalisables grâce à notre savoir-faire. <br><br>
</p>
  <p class="gammes">
    Les prothèses capillaires choisies par L'HAIR DE RIEN existent toutes de differentes couleurs, avec une multitude longueurs et textures et avec surtout un confort optimal pour un résultat des plus naturel.  Notre institut propose également des perruques en cheveux naturels remy hair, full lace, front lace et volumateurs. <br><br> </p>
  <p class="gammes">
   Le style ne se démode jamais. La mode, c’est le pinceau qui nous
permet de mettre des touches de couleur et d’apporter des nuances
qui complètent notre image personnelle. Un bel accessoire illumine
votre look. Comme dirait une certaine Coco "Pour être irremplaçable, il faut être differente"
</p>

<div class="container_bonnets_shemas">

    <div class="container_shema1">
    <div style="width:100%; background:gray; display:flex ; align-items:center; justify-content: space-around">
      <div style="width:50%; display:flex; justify-content:center;">
        <h4 style="font-family: Amatic sc; font-size: 15px;color:white;">Gamme Ambre - Classe I</h4>
      </div>
      <div style="padding-bottom:2px; width:50%; display:flex; justify-content: flex-start;">
        <p>&#9734; &#9734;</p>
      </div>
    </div>
    <div class="container_img_desc_shema">
      <div>
      </div>
      <div class="containerStringImg">
        <div class="img_container_shema">
          <img style="width:100%" :src="shema[1].url" alt="bonnet monofilament">
        </div>
        <div class="desc_shema">
          <div class="containerList">
            <div class="containerList1">1</div>
            <p class="point">Rond point et/ou bordure frontale en monofilament</p>
          </div>
          <div class="containerList">
            <div style="border-radius:20px; border:solid 1px gray; padding-left: 4px; padding-right: 4px; color:gray;">2</div>
            <p class="point">Bonnet entierement fait à la machine. <br>Cheveux implantés sur tresses.</p>
          </div>
        </div>
      </div>
      <div class="desc_shema_bis">
          <p class="point">Bonnet integralement remboursé par la sécurité sociale.</p>
        </div>
    </div>
  </div>

    <div class="container_shema1">
    <div style="width:100%; background:gray; display:flex ; align-items:center; justify-content: space-around">
      <div style="width:50%; display:flex; justify-content:center;">
        <h4 style="font-family: Amatic sc; font-size: 15px;color:white;">Gamme Amethyste - Classe II</h4>
      </div>
      <div style="padding-bottom:2px; width:50%; display:flex; justify-content: flex-start;">
        <p>&#9734; &#9734; &#9734;</p>
      </div>
    </div>
    <div class="container_img_desc_shema">
      <div>
      </div>
      <div class="containerStringImg">
        <div class="img_container_shema">
          <img style="width:100%" :src="shema[1].url" alt="bordure frontale implantée à la main">
        </div>
        <div class="desc_shema">
          <div class="containerList">
            <div  class="containerList1">1</div>
            <p class="point">Bordure frontale et raie implantées à la main sur 20cm ou plus.</p>
          </div>
          <div class="containerList">
            <div style="border-radius:20px; border:solid 1px gray; padding-left: 4px; padding-right: 4px; color:gray;">2</div>
            <p class="point">Bonnet fait à la machine <br>Cheveux implantés sur tresses.</p>
          </div>
        </div>
      </div>
      <div class="desc_shema_bis">
          <p class="point">250 euros remboursés par la sécurité sociale. Peut être prit en charge par la mutuelle.</p>
        </div>
    </div>
  </div>

    <div class="container_shema1">
    <div style="width:100%; background:gray; display:flex ; align-items:center; justify-content: space-around">
      <div style="width:50%; display:flex; justify-content:center;">
        <h4 style="font-family: Amatic sc; font-size: 15px;color:white;">Gamme Rubis - Classe II</h4>
      </div>
      <div style="padding-bottom:2px; width:50%; display:flex; justify-content: flex-start;">
        <p>&#9734; &#9734; &#9734; &#9734;</p>
      </div>
    </div>
    <div class="container_img_desc_shema">
      <div>
      </div>
      <div class="containerStringImg">
        <div class="img_container_shema">
          <img style="width:100%" :src="shema[2].url" alt="bonnet monofilamant effet cuir chevelu">
        </div>
        <div class="desc_shema">
          <div class="containerList">
            <div class="containerList1">1</div>
            <p class="point">Partie du dessus en monofilament.<br>Effet cuir chevelu.<br>Possibilité de changer la raie de côté.</p>
          </div>
          <div class="containerList">
            <div style="border-radius:20px; border:solid 1px gray; padding-left: 4px; padding-right: 4px; color:gray;">2</div>
            <p class="point">Partie faite à la machine. <br>Cheveux implantés sur tresses.</p>
          </div>
          <div class="containerList">
            <div style="border-radius:20px; border:solid 1px gray; padding-left: 4px; padding-right: 4px; color:gray;">3</div>
            <p class="point">Bordure frontale cinema</p>
          </div>
        </div>
      </div>
      <div class="desc_shema_bis">
          <p class="point">250 euros remboursés par la sécurité sociale.</p>
        </div>
    </div>
  </div>

    <div class="container_shema1">
    <div style="width:100%; background:gray; display:flex ; align-items:center; justify-content: space-around">
      <div style="width:50%; display:flex; justify-content:center;">
        <h4 style="font-family: Amatic sc; font-size: 15px;color:white;">Gamme Emeraude - Classe II</h4>
      </div>
      <div style="padding-bottom:2px; width:50%; display:flex; justify-content: flex-start;">
        <p>&#9734; &#9734; &#9734; &#9734; &#9734;</p>
      </div>
    </div>
    <div class="container_img_desc_shema">
      <div>
      </div>
      <div class="containerStringImg">
        <div class="img_container_shema">
          <img style="width:100%" :src="shema[4].url" alt="bonnet coton">
        </div>
        <div class="desc_shema">
          <div class="containerList">
            <div class="containerList1">1</div>
            <p class="point">Bonnet coton avec cheveux integralement implantés à la main. <br>Spéciale cuir chevelu très sensible.</p>
          </div>
        </div>
      </div>
      <div class="desc_shema_bis">
          <p class="point">Remboursé à hauteur de 250 Euros par la sécurité sociale. Prise en charge possible par la mutuelle.</p>
        </div>
    </div>
  </div>

   <div class="container_shema1">
    <div style="width:100%; background:gray; display:flex ; align-items:center; justify-content: space-around">
      <div style="width:50%; display:flex; justify-content:center;">
        <h4 style="font-family: Amatic sc; font-size: 15px;color:white;">Gamme Saphir - Classe II</h4>
      </div>
      <div style="padding-bottom:2px; width:50%; display:flex; justify-content: flex-start;">
        <p>&#9734; &#9734; &#9734; &#9734; &#9734; &#9734;</p>
      </div>
    </div>
    <div class="container_img_desc_shema">
      <div>
      </div>
      <div class="containerStringImg">
        <div class="img_container_shema">
          <img style="width:100%" :src="shema[3].url" alt="bonnet monofilamant effet cuir chevelu">
        </div>
        <div class="desc_shema">
         <div class="containerList">
            <div class="containerList1">1</div>
            <p class="point">Partie du dessus en monofilament pour un effet cuir chevelu.</p>
          </div>
          <div style="display:flex;justify-content:flex-start;font-size: 8px;gap: 10px;align-items:center; margin-bottom:5px;">
            <div style="border-radius:20px; border:solid 1px gray; padding-left: 4px; padding-right: 4px; color:gray;">2</div>
            <p class="point">Partie arriere integralement implanté à la main pour un maximum de confort.</p>
          </div>
          <div style="display:flex;justify-content:flex-start;font-size: 8px;gap: 10px;align-items:center; margin-bottom:5px;">
            <div style="border-radius:20px; border:solid 1px gray; padding-left: 4px; padding-right: 4px; color:gray;">3</div>
            <p class="point">Bordure cinema. Bonnet aux finitions maximale et très léger.</p>
          </div>
        </div>
      </div>
      <div class="desc_shema_bis">
          <p class="point">Remboursé à hauteur de 250 euros par la sécurité sociale. Prise en charge possible par la mutuelle.</p>
        </div>
    </div>
  </div>

</div>
  </div>
</div>
     <div class="container_body">
          <div class="container_coupe_courte" >
            <div class="tansitionDropDownV2"></div>
            <div style="width:100%; display:flex; justify-content:center; align-items:center;">
              <h3 class="title_conseils" style=" color:black">Notre catalogue</h3>
            </div>
            <!-- <h6 style="font-family:Arial, Helvetica, sans-serif; ">Tous ces modèles sont présentés en boutique avec possibilité de repartir avec le jour même &#128522;</h6> -->
              <div class="container_title_btn">
              </div>
              <!-- <div class="tansitionDropDown"></div> -->
              <div class="container_dropSearch">
              <div class="container_flex_dropdown">
                <input class="inputSearch" style="border: 1px solid gray;padding: 5px;" placeholder="rechercher une perruque" type="text" v-model="rechercher">
                <div  class="dropdownContainer justify-center items-center   bg-green-400  relative flex  w-full">
                  <div class=" arrowDownRight  w-6 rounded-lg text-white flex items-center absolute right-0">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-down" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M6 9l6 6l6 -6"></path>
                    </svg>
                  </div>
                  <div class=" selected text-xl text-white"><span style="color:gray;">Trier par : &nbsp;</span>{{ selected }}</div>
                  <div id="dropDownList" class="dropDownList absolute hidden w-full shadow-gray-500 shadow-md rounded-r-md top-8  ">
                    <div v-for="option in options" :key="option" @click="selectOption(option)" class=" listOption border border-gray-200 hover:border-green-500 hover:bg-green-500 hover:text-white  bg-white rounded-xs flex justify-center items-center">{{ option }}</div>
                  </div>
                </div>
              </div>
            </div>
<!-- <div class="tansitionDropDown"></div> -->
          <div style="display:flex; width:100%; justify-content:center; align-items:center;">
            <!-- <div class="container_filters" style="width:30%;"></div> -->
            <div class="sub_container_coupe_courte">
              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in filteredNames" :key="item.id" v-show="selected == 'Toutes les gammes' || item.gamme == selected" >
                  <img :src="item.url" :alt="item.alt" :id="item.id" @click="toggleModal(item.id)">
                  <div v-if="current !== item.id" class="container_name_price">
                    <div   class="name">{{ item.name }}</div>
                    <!-- <div   class="price">{{ item.price }}€</div> -->
                  </div>
                  <base-modal  v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :priceToto = item.price :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal>
              </div>
              <div class="transitionV3">
                  <div class="leftBarV3"></div>
                  <span style="width: 40%;"><h6>{{title[9]}}</h6></span>
                  <div class="rightBarV3"></div>
                </div>
                <div class="container-carousel">
                  <carousel
                    :slides="slides"
                    :loop="true"
                    @drag="onDrag"
                  >
                  </carousel>
                </div>
                <div class="containerCTAback">
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight">
                <h6 class="titleCTA">Prendre rendez-vous</h6>
                <h5 class="titleCTA">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
          </div>
            </div>
          </div>
          </div>
      </div>
  </div>
</template>

<script>
import CarouselV2 from '@/components/carousel-v2.vue'
import Carousel from './vuejs3-components-carousel.vue'
import BaseModal from '@/components/BaseModal.vue'
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - Perruques medicales près de Marseille',
      description: 'A quelques pas de Marseille un large choix de Perruques médicales naturelles de hautes qualitées. Cheveux naturels, fibres haute température et fibres exclusives à prix avantageux.',
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/perruques-medicales-femmes'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      rel: 'canonical',
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      selected: 'Ambre - classe I - 350€',
      titles: [],
      title: ['Gamme ambre - intégralement remboursé par la sécu', 'Gamme amethyste - partiellement remboursé par la sécu', 'Gamme Rubis - Monofilament', 'Gamme Emeraude - 100% fait main', 'Gamme Saphir - Monofilament - 100% fait main', 'Gamme ambre - intégralement remboursé par la sécu', 'Gamme amethyste - partiellement remboursé par la sécu', 'Gamme rubis - Monofilament', 'Gamme Emeraude - 590€ - 100% fait main', 'Nos catalogues '],
      rechercher: '',
      shema: [
        { url: require('../assets/shema_1.webp'), alt: 'shema bonnet' },
        { url: require('../assets/shema_2.webp'), alt: 'shema bonnet' },
        { url: require('../assets/shema_3.webp'), alt: 'shema bonnet' },
        { url: require('../assets/shema_4.webp'), alt: 'shema bonnet' },
        { url: require('../assets/shema_5.webp'), alt: 'shema bonnet' }
      ],
      slides: [
        {
          id: 1,
          url: require('../assets/images_perruques/perruque_medicale.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques medicales',
          price: 350,
          description: 'Remboursées par la sécurité sociale',
          to: '/perruques-medicales-femmes'
        },
        {
          id: 2,
          url: require('../assets/images_perruques/perruque_naturelle.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruque naturelles',
          price: 350,
          description: 'perruque en vrais cheveux 100% naturels Remy Hair pour une longevité maximum',
          to: '/perruques-cheveux-naturels'
        },
        {
          id: 3,
          url: require('../assets/images_perruques/perruque_prime.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques fibre mixte',
          price: 350,
          description: "L'avantage du chebveux naturel et de la fibre synthétique",
          to: '/perruques-fibre-prime'
        },
        {
          id: 4,
          url: require('../assets/images_perruques/perruque_relooking.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques petits prix',
          price: 350,
          description: 'Sans remboursement sécu pour des budgets plus modérés',
          to: '/perruques-relooking'
        },
        {
          id: 5,
          url: require('../assets/images_perruques/perruque_lace.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques type lace wig',
          price: 350,
          description: 'chevelure ultra réaliste et indetectable 100% personnalisable',
          to: '/perruques-type-lace'
        },
        {
          id: 6,
          url: require('../assets/perruque_homme/ew_hairformance_justin_4.webp'),
          alt: 'perruque homme cheveux poivre et sel coupe courte',
          name: 'Perruques homme',
          description: 'Ligne de perruques dediée aux hommes remboursées par la sécu. Coupe personnalisable.',
          to: '/perruques-homme'
        }
      ],
      images: [
        { url: require('../assets/category_primepower.webp'), alt: 'shooting femme' },
        { url: require('../assets/category_primepower_small.jpg'), alt: 'shooting femme' },
        { url: require('../assets/Sans-titre-7.png'), alt: 'shooting femme' },
        { url: require('../assets/nouvlhair-Femme.jpg'), alt: 'shooting femme' }
      ],
      item: 'imagesAmbreBoutique',
      myModal: false,
      current: false,
      isShowModal: false,
      propFixed: false,
      imagest: [
        { url: require('../assets/imgTurbans1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans3.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans4.jpg'), alt: 'turbans femme' }
      ],
      options: [
        'Toutes les gammes',
        'Ambre - classe I - 350€',
        'Amethyste - classe II - 450 à 470€',
        'Rubis - classe II - 550 à 570€',
        'Emeraude - classe II - 620€',
        'Saphir - classe II - 630 à 700€'
      ],
      imagesAmbreBoutique: [
        {
          id: 1,
          sex: 'femme',
          url: require('../assets/ambre350boutique/tab_1.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Tab',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/ambre350boutique/tab_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/tab_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/tab_3_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/tab_4_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/tab/tab_bernstein_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tab/tab_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne_rooted' },
            { url: require('../assets/colors/tab/tab_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/tab/tab_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/tab/tab_hotaubergine_mix.jpg'), alt: 'perruque femme blond', name: 'hotaubergine mix' },
            { url: require('../assets/colors/tab/tab_hotchilli_mix.jpg'), alt: 'perruque femme blond', name: 'hotchilli mix' },
            { url: require('../assets/colors/tab/tab_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/tab/tab_safranred_rooted.jpg'), alt: 'perruque femme blond', name: 'safranred rooted' },
            { url: require('../assets/colors/tab/tab_salt_pepper_rooted.jpg'), alt: 'perruque femme blond', name: 'pepper rooted' },
            { url: require('../assets/colors/tab/tab_sand_rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/colors/tab/tab_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/tab/tab_silver_mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' }

          ]
        },
        {
          id: 2,
          sex: 'femme',
          url: require('../assets/perruques medicales/350/carrie.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Carrie',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/perruques medicales/350/carrie.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruques medicales/350/carrie2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruques medicales/350/carrie3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruques medicales/350/carrie4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/carrie/Carrie_AuburnMix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/carrie/Carrie_ChampagneRooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/carrie/Carrie_ChocolateMix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/carrie/Carrie_EbonyBlack.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/carrie/Carrie_EspressoMix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/carrie/Carrie_LightBernsteinRooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/carrie/Carrie_MoccaRooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/carrie/Carrie_SafranredRooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/carrie/darkchocolate.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' }
          ]
        },
        {
          id: 3,
          sex: 'femme',
          url: require('../assets/350/change.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Change',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/change.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/change2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/change3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/change4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/change/change_aubergine_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/change/change_auburn_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/change/change_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/change/change_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/change/change_ebony_black.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/change/change_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/change/change_hotchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/change/change_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/change/change_safranred_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/change/change_sand_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' }
          ]
        },
        {
          id: 4,
          sex: 'femme',
          url: require('../assets/350/charlotte.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Charlotte',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/charlotte.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/charlotte2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/charlotte3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/charlotte4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/charlotte/charlotte_auburn_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/charlotte/charlotte_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/charlotte/charlotte_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/charlotte/charlotte_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/charlotte/charlotte_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/charlotte/charlotte_sand_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' }
          ]
        },
        {
          id: 5,
          sex: 'femme',
          url: require('../assets/350/cloud.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Cloud',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/cloud.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/cloud2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/cloud3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/cloud4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/cloud5.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/cloud/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/cloud/cherry_red_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/cloud/chocolate_tipped.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/cloud/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/cloud/espresso_tipped.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/cloud/pastel_lilac_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/cloud/pastel_mint_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/cloud/pastel_rose_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/cloud/rosewood_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/cloud/sand_tipped.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/cloud/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' }
          ]
        },
        {
          id: 6,
          sex: 'femme',
          url: require('../assets/350/debbie.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Debbie',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/debbie.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/debbie2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/debbie3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/debbi/debbie_auburn_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/debbi/debbie_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/debbi/debbie_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/debbi/debbie_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/debbi/debbie_ginger_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/debbi/debbie_hotchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/debbi/debbie_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/debbi/debbie_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/debbi/debbie_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/debbi/debbie_sand_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/debbi/debbie_snow_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' }
          ]
        },
        {
          id: 7,
          sex: 'femme',
          url: require('../assets/350/disco.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Disco',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/disco.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/disco2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/disco3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/disco4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/disco5.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/disco6.jpg'), alt: 'perruque femme blond' }

          ],
          mozaique: [
            { url: require('../assets/colors/disco/aubergine.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/disco/champagne.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/disco/chocolate.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/disco/darkchocolate.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/disco/espresso.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/disco/hotmocca.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/disco/lightbernstein.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/disco/pearlblonde.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' }
          ]
        },
        {
          id: 8,
          sex: 'femme',
          url: require('../assets/350/echo.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Echo',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/echo.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/echo2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/echo3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/echo4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/echo/echo_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/echo/echo_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/echo/echo_hotchilli_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/echo/echo_hotmocca_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/echo/echo_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/echo/echo_lightchampagne_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/echo/echo_safranred_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/echo/echo_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' }
          ]
        },
        {
          id: 9,
          sex: 'femme',
          url: require('../assets/350/open.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Open',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/open.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/open2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/open3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/open/open_bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/open/open_darkauburn_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/open/open_darkchocolate_lighted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/open/open_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/open/open_hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/open/open_hotaubergine_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/open/open_hotchilli_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/open/open_hotchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/open/open_lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/open/open_safranred_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/open/open_sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' }
          ]
        },
        {
          id: 10,
          sex: 'femme',
          url: require('../assets/350/point.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Point',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/point.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/point2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/point3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/point4.jpg'), alt: 'perruque femme blond' }

          ],
          mozaique: [
            { url: require('../assets/colors/point/point_bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/point/point_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/point/point_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/point/point_ebony_black.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/point/point_hotaubergine_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/point/point_hotchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/point/point_hotflame_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/point/point_lightmango_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/point/point_mocca_lighted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/point/point_platinblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' }
          ]
        },
        {
          id: 11,
          sex: 'femme',
          url: require('../assets/350/stay.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Stay',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/stay.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/stay2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/stay3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/stay4.jpg'), alt: 'perruque femme blond' }

          ],
          mozaique: [
            { url: require('../assets/colors/stay/hotchilli.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/stay/stay_caramel_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/stay/stay_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/stay/stay_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/stay/stay_darkchocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/stay/stay_espresso_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/stay/stay_hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/stay/stay_lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/stay/stay_mocca_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/stay/stay_salt_pepper_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/stay/stay_sand_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/stay/stay_sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/stay/stay_snow_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' }
          ]
        },
        {
          id: 12,
          sex: 'femme',
          url: require('../assets/350/tabu.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Tabu',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/tabu.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/tabu2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/tabu3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/tabu4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/tabu5.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/tabu6.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/tabu/champagne.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tabu/chocolate.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tabu/ebonyblack.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tabu/espresso.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tabu/iceblue.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tabu/lavender.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tabu/roseblonde.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tabu/rosewood.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' }
          ]
        },
        {
          id: 13,
          sex: 'femme',
          url: require('../assets/350/tool.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Tool',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/tool.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/tool2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/tool3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/tool4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/tool/ginger_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tool/pastelblonde_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tool/tool_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tool/tool_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tool/tool_darksand_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tool/tool_espresso_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tool/tool_hotaubergine_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tool/tool_hotchilli_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tool/tool_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tool/tool_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/tool/tool_platinblonde_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' }
          ]
        },
        {
          id: 14,
          sex: 'femme',
          url: require('../assets/350/vista.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Vista',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/vista.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/vista2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/vista3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/vista4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/vista5.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/vista/bernstein.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/vista/chocolate.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/vista/darkchocolate.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/vista/espresso.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/vista/ginger.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/vista/hazelnut.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/vista/lightchampagne.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/vista/lighthoney.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/vista/lightmocca.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/vista/safranred.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/vista/sandyblonde.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' }
          ]
        },
        {
          id: 15,
          sex: 'femmme',
          url: require('../assets/ambre350boutique/disc_1.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Disc',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          // description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          // toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/ambre350boutique/disc_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/disc_2_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/disc/disc_aubergine_mix.jpg'), alt: 'perruque femme blond', name: 'aubergine mix' },
            { url: require('../assets/colors/disc/disc_auburn_rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/colors/disc/disc_bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/disc/disc_black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/colors/disc/disc_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/disc/disc_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/disc/disc_coffeebrown_lighted.jpg'), alt: 'perruque femme blond', name: 'coffeebrown lighted' },
            { url: require('../assets/colors/disc/disc_darksand_rooted.jpg'), alt: 'perruque femme blond', name: 'darksand_rooted' },
            { url: require('../assets/colors/disc/disc_flame_mix.jpg'), alt: 'perruque femme blond', name: 'flame_mix' },
            { url: require('../assets/colors/disc/disc_hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/colors/disc/disc_lightcaramel_mix.jpg'), alt: 'perruque femme blond', name: 'lightcaramel mix' },
            { url: require('../assets/colors/disc/disc_lighthoney_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney mix' },
            { url: require('../assets/colors/disc/disc_lightmango_mix.jpg'), alt: 'perruque femme blond', name: 'lightmango mix' },
            { url: require('../assets/colors/disc/disc_pepper_lighted.jpg'), alt: 'perruque femme blond', name: 'pepper lighted' },
            { url: require('../assets/colors/disc/disc_platin_mix.jpg'), alt: 'perruque femme blond', name: 'platin mix' },
            { url: require('../assets/colors/disc/disc_tobacco_mix.jpg'), alt: 'perruque femme blond', name: 'tobacco mix' }
          ]
        },
        {
          id: 16,
          sex: 'homme',
          url: require('../assets/ambre350boutique/java_1.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Java',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/ambre350boutique/java_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/java_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/java_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/java/java_auburn_rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/colors/java/java_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/java/java_chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/java/java_espresso_rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/colors/java/java_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/java/java_sand_rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/colors/java/java_tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 17,
          sex: 'homme',
          url: require('../assets/ambre350boutique/link_1.jpg'),
          alt: 'perruque femme gris ambre cheveux courte',
          name: 'Link',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          // description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          // toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/ambre350boutique/link_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/link_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/link_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/link/chocolate.jpg'), alt: 'perruque femme blond', name: 'chocolate' },
            { url: require('../assets/colors/link/darkchocolate.jpg'), alt: 'perruque femme blond', name: 'darkchocolate' },
            { url: require('../assets/colors/link/espresso.jpg'), alt: 'perruque femme blond', name: 'espresso' },
            { url: require('../assets/colors/link/lavender.jpg'), alt: 'perruque femme blond', name: 'lavender' },
            { url: require('../assets/colors/link/lightbernstein.jpg'), alt: 'perruque femme blond', name: 'lightbernstein' },
            { url: require('../assets/colors/link/pastel_mint_rooted.jpg'), alt: 'perruque femme blond', name: 'pastel mint rooted' },
            { url: require('../assets/colors/link/pastel_rose.jpg'), alt: 'perruque femme blond', name: 'pastel rose' },
            { url: require('../assets/colors/link/pearlblonde.jpg'), alt: 'perruque femme blond', name: 'pearlblonde' },
            { url: require('../assets/colors/link/rosewood.jpg'), alt: 'perruque femme blond', name: 'rosewood' },
            { url: require('../assets/colors/link/sandyblonde.jpg'), alt: 'perruque femme blond', name: 'sandyblonde' }
          ]
        },
        {
          id: 18,
          sex: 'homme',
          url: require('../assets/ambre350boutique/moviestar_1.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Movie Star',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/ambre350boutique/moviestar_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/moviestar_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/moviestar_3_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/moviestar_4_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/movie_star/champagne.jpg'), alt: 'perruque femme blond', name: 'champagne' },
            { url: require('../assets/colors/movie_star/chocolate.jpg'), alt: 'perruque femme blond', name: 'chocolate' },
            { url: require('../assets/colors/movie_star/darkchocolate.jpg'), alt: 'perruque femme blond', name: 'darkchocolate' },
            { url: require('../assets/colors/movie_star/darksand.jpg'), alt: 'perruque femme blond', name: 'darksand' },
            { url: require('../assets/colors/movie_star/espresso.jpg'), alt: 'perruque femme blond', name: 'espresso' },
            { url: require('../assets/colors/movie_star/hotchilli.jpg'), alt: 'perruque femme blond', name: 'hotchilli' },
            { url: require('../assets/colors/movie_star/lightbernstein.jpg'), alt: 'perruque femme blond', name: 'lightbernstein' },
            { url: require('../assets/colors/movie_star/safranred.jpg'), alt: 'perruque femme blond', name: 'safranred' },
            { url: require('../assets/colors/movie_star/sandmulti.jpg'), alt: 'perruque femme blond', name: 'sandmulti' },
            { url: require('../assets/colors/movie_star/sandyblonde.jpg'), alt: 'perruque femme blond', name: 'sandyblonde' }
          ]
        },
        {
          id: 19,
          sex: 'femme',
          url: require('../assets/ambre350boutique/daily_1.jpg'),
          alt: 'perruque femme blond ambre coupe mi long ',
          name: 'Daily',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/ambre350boutique/daily_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/daily_3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/daily/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/daily/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/daily/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/daily/darkauburn_mix.jpg'), alt: 'perruque femme blond', name: 'darkauburn mix' },
            { url: require('../assets/colors/daily/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/daily/hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/colors/daily/mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/daily/salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/daily/sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/daily/sandmulti_mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' },
            { url: require('../assets/colors/daily/smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/colors/daily/snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 20,
          sex: 'homme',
          url: require('../assets/ambre350boutique/touch_1.jpg'),
          alt: 'perruque femme blond ambre cheveux long',
          name: 'Touch',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/ambre350boutique/touch_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/touch_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/touch_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/touch/candyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'candyblonde rooted' },
            { url: require('../assets/colors/touch/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/touch/cherry_mix.jpg'), alt: 'perruque femme blond', name: 'cherry mix' },
            { url: require('../assets/colors/touch/chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/touch/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/touch/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/touch/lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/touch/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/touch/tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 21,
          sex: 'femme',
          url: require('../assets/ambre350boutique/alive_1.jpg'),
          alt: 'perruque femme ambre coupe courte',
          name: 'Alive',
          price: '350',
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          // toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/ambre350boutique/alive_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/alive_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/alive_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/alive/auburn_rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/colors/alive/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/alive/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/alive/chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/alive/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/alive/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/alive/hotmocca_rooted.jpg'), alt: 'perruque femme blond', name: 'hotmocca rooted' },
            { url: require('../assets/colors/alive/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 22,
          sex: 'femme',
          url: require('../assets/ambre350boutique/code_mono_1.jpg'),
          alt: 'perruque femme ambre coupe courte gris',
          name: 'Code Mono',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/ambre350boutique/code_mono_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/code_mono_1_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/ambre350boutique/code_mono_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/code/code_mono_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/code/code_mono_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/code/code_mono_hotchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'hotchocolate mix' },
            { url: require('../assets/colors/code/code_mono_hotflame_mix.jpg'), alt: 'perruque femme blond', name: 'hotflame mix' },
            { url: require('../assets/colors/code/code_mono_hotmocca_rooted.jpg'), alt: 'perruque femme blond', name: 'hotmocca rooted' },
            { url: require('../assets/colors/code/code_mono_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/code/code_mono_lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/colors/code/code_mono_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/code/code_mono_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 23,
          url: require('../assets/amethyste450Boutique/sunset_1.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Sunset',
          price: 450,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/amethyste450Boutique/sunset_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/amethyste450Boutique/sunset_2_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/amethyste450Boutique/sunset_3_450.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/sunset/champagne.jpg'), alt: 'perruque femme blond', name: 'champagne' },
            { url: require('../assets/colors/sunset/chocolate.jpg'), alt: 'perruque femme blond', name: 'chocolate' },
            { url: require('../assets/colors/sunset/darkchocolate.jpg'), alt: 'perruque femme blond', name: 'darkchocolate' },
            { url: require('../assets/colors/sunset/espresso.jpg'), alt: 'perruque femme blond', name: 'espresso' },
            { url: require('../assets/colors/sunset/hotchilli.jpg'), alt: 'perruque femme blond', name: 'hotchilli' },
            { url: require('../assets/colors/sunset/lightbernstein.jpg'), alt: 'perruque femme blond', name: 'lightbernstein' },
            { url: require('../assets/colors/sunset/platinblonde.jpg'), alt: 'perruque femme blond', name: 'platinblonde' },
            { url: require('../assets/colors/sunset/safran.jpg'), alt: 'perruque femme blond', name: 'safran' },
            { url: require('../assets/colors/sunset/sandmulti.jpg'), alt: 'perruque femme blond', name: 'sandmulti' },
            { url: require('../assets/colors/sunset/sandyblonde.jpg'), alt: 'perruque femme blond', name: 'sandyblonde' },
            { url: require('../assets/colors/sunset/tobacco.jpg'), alt: 'perruque femme blond', name: 'tobacco' }
          ]
        },
        {
          id: 24,
          url: require('../assets/amethyste450Boutique/dance_1.jpg'),
          alt: 'perruque femme gris ambre cheveux courte',
          name: 'Dance',
          price: 450,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/amethyste450Boutique/dance_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/amethyste450Boutique/dance_2_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/amethyste450Boutique/dance_3_450.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/dance/candyblonde.jpg'), alt: 'perruque femme blond', name: 'candyblonde' },
            { url: require('../assets/colors/dance/chocolate.jpg'), alt: 'perruque femme blond', name: 'chocolate' },
            { url: require('../assets/colors/dance/espresso.jpg'), alt: 'perruque femme blond', name: 'espresso' },
            { url: require('../assets/colors/dance/hotchilli.jpg'), alt: 'perruque femme blond', name: 'hotchilli' },
            { url: require('../assets/colors/dance/lightbernstein.jpg'), alt: 'perruque femme blond', name: 'lightbernstein' },
            { url: require('../assets/colors/dance/lightchampagne.jpg'), alt: 'perruque femme blond', name: 'lightchampagne' },
            { url: require('../assets/colors/dance/mocca.jpg'), alt: 'perruque femme blond', name: 'mocca' },
            { url: require('../assets/colors/dance/sand.jpg'), alt: 'perruque femme blond', name: 'sand' },
            { url: require('../assets/colors/dance/sandyblonde.jpg'), alt: 'perruque femme blond', name: 'sandyblonde' }
          ]
        },
        {
          id: 25,
          url: require('../assets/amethyste450Boutique/united_1.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'United',
          price: 450,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/amethyste450Boutique/united_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/amethyste450Boutique/united_4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/amethyste450Boutique/united_6.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/united/hazelnut.jpg'), alt: 'perruque femme blond', name: 'hazelnut' },
            { url: require('../assets/colors/united/hotchilli.jpg'), alt: 'perruque femme blond', name: 'hotchilli' },
            { url: require('../assets/colors/united/safran.jpg'), alt: 'perruque femme blond', name: 'safran' },
            { url: require('../assets/colors/united/united_caramel_rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/colors/united/united_chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/united/united_espresso_rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/colors/united/united_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/united/united_pastelblonde_mix.jpg'), alt: 'perruque femme blond', name: 'pastelblonde mix' },
            { url: require('../assets/colors/united/united_sand_rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/colors/united/united_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/united/united_tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 26,
          url: require('../assets/amethyste450Boutique/arrow_1.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Arrow',
          price: 467,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/amethyste450Boutique/arrow_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/amethyste450Boutique/arrow_2_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/amethyste450Boutique/arrow_3_450.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/arrow/Arrow_CaramelLighted-6711.jpg'), alt: 'perruque femme blond', name: 'CaramelLighted' },
            { url: require('../assets/colors/arrow/Arrow_ChampagneRooted-6701.jpg'), alt: 'perruque femme blond', name: 'ChampagneRooted' },
            { url: require('../assets/colors/arrow/Arrow_ChocolateRooted-6738.jpg'), alt: 'perruque femme blond', name: 'ChocolateRooted' },
            { url: require('../assets/colors/arrow/Arrow_Espresso-6746.jpg'), alt: 'perruque femme blond', name: 'Espresso' },
            { url: require('../assets/colors/arrow/Arrow_LightbernsteinRooted-6722.jpg'), alt: 'perruque femme blond', name: 'LightbernsteinRooted' },
            { url: require('../assets/colors/arrow/Arrow_MoccaRooted-6730.jpg'), alt: 'perruque femme blond', name: 'MoccaRooted' },
            { url: require('../assets/colors/arrow/Arrow_SandmultiRooted-6716.jpg'), alt: 'perruque femme blond', name: 'SandmultiRooted' },
            { url: require('../assets/colors/arrow/copperbrown.jpg'), alt: 'perruque femme blond', name: 'copperbrown' },
            { url: require('../assets/colors/arrow/platinblonde.jpg'), alt: 'perruque femme blond', name: 'platinblonde' }
          ]
        },
        {
          id: 27,
          url: require('../assets/amethyste450Boutique/drive_1.jpg'),
          alt: 'perruque fibre haute temperature femme blond ambre cheveux long',
          name: 'Drive',
          price: 470,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/amethyste450Boutique/drive_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/amethyste450Boutique/drive_2_470.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/amethyste450Boutique/drive_3_470.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/amethyste450Boutique/drive_4_470.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/drive/drive-bernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/drive/drive-candyblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'candyblonde rooted' },
            { url: require('../assets/colors/drive/drive-cherryred-mix.jpg'), alt: 'perruque femme blond', name: 'cherryred mix' },
            { url: require('../assets/colors/drive/drive-chocolate-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/drive/drive-darkchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/drive/drive-espresso-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/drive/drive-gingerblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'gingerblonde rooted' },
            { url: require('../assets/colors/drive/drive-lightbernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/drive/drive-pastelblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'pastelblonde rooted' },
            { url: require('../assets/colors/drive/drive-pearlblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/colors/drive/drive-sandyblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 28,
          url: require('../assets/rubis550Boutique/risk_1.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Risk',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/rubis550Boutique/risk_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/rubis550Boutique/risk_2_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/risk/risk_aubergine_mix.jpg'), alt: 'perruque femme blond', name: 'risk aubergine mix' },
            { url: require('../assets/colors/risk/risk_caramel_mix.jpg'), alt: 'perruque femme blond', name: 'risk caramel mix' },
            { url: require('../assets/colors/risk/risk_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'risk champagne mix' },
            { url: require('../assets/colors/risk/risk_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'risk chocolate mix' },
            { url: require('../assets/colors/risk/risk_darkauburn_mix.jpg'), alt: 'perruque femme blond', name: 'risk darkauburn mix' },
            { url: require('../assets/colors/risk/risk_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'risk darkchocolate mix' },
            { url: require('../assets/colors/risk/risk_darksand_mix.jpg'), alt: 'perruque femme blond', name: 'risk darksand mix' },
            { url: require('../assets/colors/risk/risk_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'risk espresso mix' },
            { url: require('../assets/colors/risk/risk_ginger_rooted.jpg'), alt: 'perruque femme blond', name: 'risk ginger rooted' },
            { url: require('../assets/colors/risk/risk_hotchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'risk hotchocolate mix' },
            { url: require('../assets/colors/risk/risk_lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'risk lightchampagne rooted' },
            { url: require('../assets/colors/risk/risk_mocca_lighted.jpg'), alt: 'perruque femme blond', name: 'risk mocca lighted' },
            { url: require('../assets/colors/risk/risk_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'risk pepper mix' },
            { url: require('../assets/colors/risk/risk_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'risk salt pepper mix' },
            { url: require('../assets/colors/risk/risk_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'risk sandyblonde rooted' },
            { url: require('../assets/colors/risk/risk_silver_mix.jpg'), alt: 'perruque femme blond', name: 'risk silver mix' },
            { url: require('../assets/colors/risk/risk_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'risk smoke mix' },
            { url: require('../assets/colors/risk/risk_snow_mix.jpg'), alt: 'perruque femme blond', name: 'risk snow mix' },
            { url: require('../assets/colors/risk/risk_tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'risk tobacco rooted' }
          ]
        },
        {
          id: 29,
          url: require('../assets/rubis550Boutique/ginger_small_1.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Ginger Small',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/rubis550Boutique/ginger_small_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/rubis550Boutique/ginger_small_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/rubis550Boutique/ginger_small_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/ginger_small/ginger_bernstein_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/ginger_small/ginger_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/ginger_small/ginger_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/ginger_small/ginger_coffeebrown_mix.jpg'), alt: 'perruque femme blond', name: 'coffeebrown mix' },
            { url: require('../assets/colors/ginger_small/ginger_pearl_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/colors/ginger_small/ginger_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/ginger_small/ginger_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/colors/ginger_small/ginger_white_mix.jpg'), alt: 'perruque femme blond', name: 'white mix' },
            { url: require('../assets/colors/ginger_small/stonegrey_mix.jpg'), alt: 'perruque femme blond', name: 'stonegrey mix' }
          ]
        },
        {
          id: 30,
          url: require('../assets/rubis550Boutique/flip_mono_550.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Flip Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/rubis550Boutique/flip_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/rubis550Boutique/flip_mono_2_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/flip_mono/flip_mono_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/flip_mono/flip_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/flip_mono/flip_mono_darkauburn_rooted.jpg'), alt: 'perruque femme blond', name: 'darkauburn rooted' },
            { url: require('../assets/colors/flip_mono/flip_mono_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/flip_mono/flip_mono_hazelnut_rooted.jpg'), alt: 'perruque femme blond', name: 'hazelnut_rooted' },
            { url: require('../assets/colors/flip_mono/flip_mono_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/flip_mono/flip_mono_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/flip_mono/flip_mono_nougat_rooted.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' },
            { url: require('../assets/colors/flip_mono/flip_mono_pearlblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/colors/flip_mono/flip_mono_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/flip_mono/flip_mono_sandmulti_mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' },
            { url: require('../assets/colors/flip_mono/flip_mono_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 31,
          url: require('../assets/rubis550Boutique/rich_mono_2_550.jpg'),
          alt: 'perruque femme gris ambre cheveux courte',
          name: 'Rich',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/rubis550Boutique/rich_mono_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/rubis550Boutique/rich_mono_3_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/rubis550Boutique/rich_mono_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/rich/rich_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/rich/rich_mono_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/rich/rich_mono_darkcnow_mix.jpg'), alt: 'perruque femme blond', name: 'darkcnow mix' },
            { url: require('../assets/colors/rich/rich_mono_darksand_rooted.jpg'), alt: 'perruque femme blond', name: 'darksand rooted' },
            { url: require('../assets/colors/rich/rich_mono_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/rich/rich_mono_hothazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'hothazelnut mix' },
            { url: require('../assets/colors/rich/rich_mono_lightcaramel_rooted.jpg'), alt: 'perruque femme blond', name: 'lightcaramel rooted' },
            { url: require('../assets/colors/rich/rich_mono_lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/colors/rich/rich_mono_pearlblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/colors/rich/rich_mono_platinblonde_mix (1).jpg'), alt: 'perruque femme blond', name: 'platinblonde mix' },
            { url: require('../assets/colors/rich/rich_mono_tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 32,
          url: require('../assets/rubis550Boutique/talent_mono_550.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Talent',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/rubis550Boutique/talent_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/rubis550Boutique/talent_mono_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/rubis550Boutique/talent_mono_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/talent_mono/talent_mono_caramel_rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/colors/talent_mono/talent_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/talent_mono/talent_mono_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/talent_mono/talent_mono_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/talent_mono/talent_mono_hotchilli_rooted.jpg'), alt: 'perruque femme blond', name: 'hotchilli rooted' },
            { url: require('../assets/colors/talent_mono/talent_mono_lightchampagne_mix.jpg'), alt: 'perruque femme blond', name: 'lightchampagne mix' },
            { url: require('../assets/colors/talent_mono/talent_mono_safranred_mix.jpg'), alt: 'perruque femme blond', name: 'safranred mix' },
            { url: require('../assets/colors/talent_mono/talent_mono_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/talent_mono/talent_mono_tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 33,
          url: require('../assets/rubis550Boutique/mega_mono_550.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Mega Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/rubis550Boutique/mega_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/rubis550Boutique/mega_mono_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/rubis550Boutique/mega_mono_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/mega_mono/mega_mono_auburn_mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/colors/mega_mono/mega_mono_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/mega_mono/mega_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/mega_mono/mega_mono_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/mega_mono/mega_mono_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/mega_mono/mega_mono_ginger_mix.jpg'), alt: 'perruque femme blond', name: 'ginger mix' },
            { url: require('../assets/colors/mega_mono/mega_mono_hotmocca_mix.jpg'), alt: 'perruque femme blond', name: 'hotmocca mix' },
            { url: require('../assets/colors/mega_mono/mega_mono_safranred_rooted.jpg'), alt: 'perruque femme blond', name: 'safranred rooted' },
            { url: require('../assets/colors/mega_mono/mega_mono_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/mega_mono/mega_mono_sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' },
            { url: require('../assets/colors/mega_mono/mega_mono_tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        // ],
        // imagesEmeraudeBoutique: [
        {
          id: 34,
          url: require('../assets/emeraude590Boutique/alba_comfort_590.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Alba Comfort',
          price: 620,
          gamme: 'Emeraude - classe II - 620€',
          description: '100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/emeraude590Boutique/alba_comfort_590.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/emeraude590Boutique/alba_comfort_2_590.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/emeraude590Boutique/alba_comfort_3_590.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/alba_comfort/alba_comfort_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/alba_comfort/alba_comfort_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/alba_comfort/alba_comfort_darksand_rooted.jpg'), alt: 'perruque femme blond', name: 'darksand rooted' },
            { url: require('../assets/colors/alba_comfort/alba_comfort_mocca_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/colors/alba_comfort/alba_comfort_nougat_mix.jpg'), alt: 'perruque femme blond', name: 'nougat mix' },
            { url: require('../assets/colors/alba_comfort/alba_comfort_pearl_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/colors/alba_comfort/alba_comfort_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/alba_comfort/alba_comfort_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/colors/alba_comfort/boston_mono_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' }
          ]
        },
        {
          id: 35,
          url: require('../assets/emeraude590Boutique/ew_hairsociety_desire_1.webp'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Desire',
          price: 620,
          gamme: 'Emeraude - classe II - 620€',
          description: '100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/emeraude590Boutique/ew_hairsociety_desire_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/emeraude590Boutique/ew_hairsociety_desire_2_590.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/emeraude590Boutique/ew_hairsociety_desire_3_590.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/desire/desire_caramel_mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/colors/desire/desire_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/desire/desire_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/desire/desire_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/desire/desire_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/desire/desire_nougat_rooted.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' },
            { url: require('../assets/colors/desire/desire_pearl_rooted.jpg'), alt: 'perruque femme blond', name: 'pearl rooted' },
            { url: require('../assets/colors/desire/desire_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/desire/desire_sand_rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/colors/desire/desire_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/desire/desire_silver_mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' },
            { url: require('../assets/colors/desire/desire_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/colors/desire/desire_stonegrey_mix.jpg'), alt: 'perruque femme blond', name: 'stonegrey mix' }
          ]
        },
        {
          id: 36,
          url: require('../assets/emeraude590Boutique/ew_hairsociety_satin_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Satin',
          price: 620,
          gamme: 'Emeraude - classe II - 620€',
          description: '100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/emeraude590Boutique/ew_hairsociety_satin_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/emeraude590Boutique/ew_hairsociety_satin_2_590.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/emeraude590Boutique/ew_hairsociety_satin_3_590.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/satin/bernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/satin/champagne-rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/satin/chocolate-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/satin/darkchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'darksnow rooted' },
            { url: require('../assets/colors/satin/darksnow-rooted.jpg'), alt: 'perruque femme blond', name: 'darksnow rooted' },
            { url: require('../assets/colors/satin/espresso-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/colors/satin/nougat-rooted.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' },
            { url: require('../assets/colors/satin/pearlblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/colors/satin/salt-pepper-mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/satin/sand-rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/colors/satin/silverblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'silverblonde rooted' },
            { url: require('../assets/colors/satin/tobacco-rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 37,
          url: require('../assets/emeraude590Boutique/ew_hairsociety_esprit_1.webp'),
          alt: 'perruque femme gris ambre cheveux courte',
          name: 'Esprit',
          price: 620,
          gamme: 'Emeraude - classe II - 620€ - 620€',
          description: '100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/emeraude590Boutique/ew_hairsociety_esprit_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/emeraude590Boutique/ew_hairsociety_esprit_2_590.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/emeraude590Boutique/ew_hairsociety_esprit_3_590.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/esprit/auburn-lighted.jpg'), alt: 'perruque femme blond', name: 'auburn lighted' },
            { url: require('../assets/colors/esprit/bernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/esprit/caramel-rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/colors/esprit/champagne-rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/esprit/chocolate-lighted.jpg'), alt: 'perruque femme blond', name: 'chocolate lighted' },
            { url: require('../assets/colors/esprit/darkchocolate-rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate rooted' },
            { url: require('../assets/colors/esprit/espresso-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/colors/esprit/hazelnut-rooted.jpg'), alt: 'perruque femme blond', name: 'hazelnut rooted' },
            { url: require('../assets/colors/esprit/mocca-lighted.jpg'), alt: 'perruque femme blond', name: 'mocca lighted' },
            { url: require('../assets/colors/esprit/silverblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'silverblonde rooted' }
          ]
        },
        // ],
        // imagesSaphirBoutique: [
        {
          id: 38,
          url: require('../assets/saphir600_630Boutique/cara_100deluxe_1_600.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Cara small Deluxe',
          price: 630,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: '100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/saphir600_630Boutique/cara_100deluxe_1_600.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/saphir600_630Boutique/cara_100deluxe_2_600.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/saphir600_630Boutique/cara_100deluxe_3_600.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/cara_100_deluxe/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/cara_100_deluxe/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/cara_100_deluxe/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/cara_100_deluxe/darksand_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/colors/cara_100_deluxe/mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/cara_100_deluxe/pearl_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/colors/cara_100_deluxe/sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' },
            { url: require('../assets/colors/cara_100_deluxe/silver_mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' },
            { url: require('../assets/colors/cara_100_deluxe/smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/colors/cara_100_deluxe/white_mix.jpg'), alt: 'perruque femme blond', name: 'white mix' }
          ]
        },
        {
          id: 39,
          url: require('../assets/saphir600_630Boutique/ew_hairsociety_first_1_600.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'First',
          price: 630,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: '100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/saphir600_630Boutique/ew_hairsociety_first_1_600.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/saphir600_630Boutique/ew_hairsociety_first_2_600.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/saphir600_630Boutique/ew_hairsociety_first_3_600.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/first/chocolate-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/first/darkchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/first/espresso-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/first/hotchilli-rooted.jpg'), alt: 'perruque femme blond', name: 'hotchilli rooted' },
            { url: require('../assets/colors/first/lightbernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/first/lightchampagne-rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/colors/first/lighthoney-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney mix' },
            { url: require('../assets/colors/first/mocca-lighted.jpg'), alt: 'perruque femme blond', name: 'mocca lighted' },
            { url: require('../assets/colors/first/salt-pepper-mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/first/sand-rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/colors/first/sandyblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/first/snow-mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 40,
          url: require('../assets/saphir600_630Boutique/amy_small_1_600.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Amy Small Deluxe',
          price: 630,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/saphir600_630Boutique/amy_small_1_600.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/saphir600_630Boutique/amy_small_2_600.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/saphir600_630Boutique/amy_small_3_600.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/amy_small_deluxe/caramel_mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/colors/amy_small_deluxe/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/amy_small_deluxe/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/amy_small_deluxe/darknougat_mix.jpg'), alt: 'perruque femme blond', name: 'darknougat mix' },
            { url: require('../assets/colors/amy_small_deluxe/lightpearl_mix.jpg'), alt: 'perruque femme blond', name: 'lightpearl mix' },
            { url: require('../assets/colors/amy_small_deluxe/mocca_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/colors/amy_small_deluxe/salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/amy_small_deluxe/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 41,
          url: require('../assets/saphir600_630Boutique/ew_hairsociety_eclat_1_630.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Eclat',
          price: 630,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/saphir600_630Boutique/ew_hairsociety_eclat_1_630.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/saphir600_630Boutique/ew_hairsociety_eclat_2_630.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/saphir600_630Boutique/ew_hairsociety_eclat_3_630.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/eclat/bernstein-mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/eclat/chocolate-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/eclat/darkchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/eclat/espresso-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/eclat/nougat-mix.jpg'), alt: 'perruque femme blond', name: 'nougat mix' },
            { url: require('../assets/colors/eclat/pearlblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/colors/eclat/sandyblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 42,
          url: require('../assets/saphir600_630Boutique/ew_hairsociety_mirage_1_630.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Mirage',
          price: 630,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT + 100 % FAIT MAIN',
          toto: 'Du luxe grâce à des finitions parfaites. Ce sont les perruques 100 % faites main qui procurent l‘effet le plus naturel. Les cheveux sont noués un par un sur le monofilament du dessus de tête - micro-peau invisible sur le cuir chevelu. Le reste de la monture est nouée sur un tulle très fin. La perruque ne se différencie pratiquement pas de sa propre chevelure, elle est ultralégère et procure un confort parfait.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/saphir600_630Boutique/ew_hairsociety_mirage_1_630.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/saphir600_630Boutique/ew_hairsociety_mirage_2_630.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/saphir600_630Boutique/ew_hairsociety_mirage_3_630.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/mirage/champagne-rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/mirage/chocolate-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/mirage/darkchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/mirage/espresso-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/mirage/lightbernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/mirage/mocca-rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/mirage/sandyblonde-mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' }
          ]
        },
        // ],
        // imagesAmbreCommande: [
        {
          id: 43,
          url: require('../assets/350/carol_350.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Carol',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/carol_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/carol_2_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/carol/carol_auburn_mix.jpg'), alt: 'perruque femme blond', name: 'carol auburn mix' },
            { url: require('../assets/colors/carol/carol_caramel_mix.jpg'), alt: 'perruque femme blond', name: 'carol caramel mix' },
            { url: require('../assets/colors/carol/carol_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'carol champagne mix' },
            { url: require('../assets/colors/carol/carol_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'carol chocolate mix' },
            { url: require('../assets/colors/carol/carol_darksand_mix.jpg'), alt: 'perruque femme blond', name: 'carol darksand mix' },
            { url: require('../assets/colors/carol/carol_darksnow_mix.jpg'), alt: 'perruque femme blond', name: 'carol darksnow mix' },
            { url: require('../assets/colors/carol/carol_lightespresso_mix.jpg'), alt: 'perruque femme blond', name: 'carol lightespresso mix' },
            { url: require('../assets/colors/carol/carol_sand_mix.jpg'), alt: 'perruque femme blond', name: 'carol sand mix' },
            { url: require('../assets/colors/carol/carol_sandmulti_mix.jpg'), alt: 'perruque femme blond', name: 'carol sandmulti mix' },
            { url: require('../assets/colors/carol/carol_sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'carol sandyblonde mix' },
            { url: require('../assets/colors/carol/carol_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'carol smoke mix' },
            { url: require('../assets/colors/carol/carol_snow_mix.jpg'), alt: 'perruque femme blond', name: 'carol snow mix' }
          ]
        },
        {
          id: 44,
          url: require('../assets/350/casino_more_350.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Casino More',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/casino_more_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/casino_more_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/casino_more_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/casino_more/casino_more_bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/casino_more/casino_more_black.jpg'), alt: 'perruque femme blond', name: 'more black' },
            { url: require('../assets/colors/casino_more/casino_more_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/casino_more/casino_more_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/casino_more/casino_more_cinnamonbrown_rooted.jpg'), alt: 'perruque femme blond', name: 'cinnamonbrown rooted' },
            { url: require('../assets/colors/casino_more/casino_more_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/casino_more/casino_more_flame_rooted.jpg'), alt: 'perruque femme blond', name: 'rooted' },
            { url: require('../assets/colors/casino_more/casino_more_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/casino_more/casino_more_tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 45,
          url: require('../assets/350/cat_350.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Cat',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/cat_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/cat_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/cat_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/cat/cat_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'cat champagne rooted' },
            { url: require('../assets/colors/cat/cat_cognac_rooted.jpg'), alt: 'perruque femme blond', name: 'cat cognac rooted' },
            { url: require('../assets/colors/cat/cat_darkauburn_mix.jpg'), alt: 'perruque femme blond', name: 'cat darkauburn mix' },
            { url: require('../assets/colors/cat/cat_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'cat darkchocolate mix' },
            { url: require('../assets/colors/cat/cat_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'cat espresso mix' },
            { url: require('../assets/colors/cat/cat_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'cat lightbernstein rooted' },
            { url: require('../assets/colors/cat/cat_lighthoney_mix.jpg'), alt: 'perruque femme blond', name: 'cat lighthoney mix' },
            { url: require('../assets/colors/cat/cat_sand_mix.jpg'), alt: 'perruque femme blond', name: 'cat sand mix' },
            { url: require('../assets/colors/cat/cat_tobacco_mix.jpg'), alt: 'perruque femme blond', name: 'cat tobacco mix' }
          ]
        },
        {
          id: 46,
          url: require('../assets/350/coco_350.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Coco',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/coco_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/coco_2_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/coco/coco_auburn_mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/colors/coco/coco_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/coco/coco_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/coco/coco_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/coco/coco_hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/colors/coco/coco_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/coco/coco_lighthoney_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney mix' },
            { url: require('../assets/colors/coco/coco_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/coco/coco_pearl_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/colors/coco/coco_platinblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'platinblonde rooted' },
            { url: require('../assets/colors/coco/coco_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/coco/coco_sand_rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/colors/coco/coco_silver_mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' },
            { url: require('../assets/colors/coco/coco_snow_mix.jpg'), alt: 'perruque femme blond', name: 'coco_snow mix' },
            { url: require('../assets/colors/coco/coco_stonegrey_mix.jpg'), alt: 'perruque femme blond', name: 'stonegrey mix' }
          ]
        },
        {
          id: 47,
          url: require('../assets/350/cool_350.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Cool',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/cool_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/cool_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/cool_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/cool/aubergine_mix.jpg'), alt: 'perruque femme blond', name: 'aubergine mix' },
            { url: require('../assets/colors/cool/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/cool/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/cool/cinnamon_mix.jpg'), alt: 'perruque femme blond', name: 'cinnamon mix' },
            { url: require('../assets/colors/cool/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/cool/hotflame_rooted.jpg'), alt: 'perruque femme blond', name: 'hotflame rooted' },
            { url: require('../assets/colors/cool/lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/cool/mocca_lighted.jpg'), alt: 'perruque femme blond', name: 'mocca lighted' },
            { url: require('../assets/colors/cool/platinblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'platinblonde rooted' },
            { url: require('../assets/colors/cool/salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/cool/snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 48,
          url: require('../assets/350/elite_350.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Elite',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/elite_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/elite_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/elite_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/elite/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/elite/chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/elite/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/elite/darksand_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/colors/elite/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/elite/ginger_rooted.jpg'), alt: 'perruque femme blond', name: 'ginger rooted' },
            { url: require('../assets/colors/elite/hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/colors/elite/hotchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'hotchocolate mix' },
            { url: require('../assets/colors/elite/lightchampagne_mix.jpg'), alt: 'perruque femme blond', name: 'lightchampagne mix' },
            { url: require('../assets/colors/elite/mocca_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/colors/elite/pearl_rooted.jpg'), alt: 'perruque femme blond', name: 'pearl rooted' },
            { url: require('../assets/colors/elite/sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/elite/silver_mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' },
            { url: require('../assets/colors/elite/smoke_rooted.jpg'), alt: 'perruque femme blond', name: 'smoke rooted' }
          ]
        },
        {
          id: 49,
          url: require('../assets/350/fenja_350.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Fenja',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          // description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          // toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/fenja_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/fenja_2_350_small_normal.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/fenja/fenja_auburn_mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/colors/fenja/fenja_blackcherry_mix.jpg'), alt: 'perruque femme blond', name: 'blackcherry mix' },
            { url: require('../assets/colors/fenja/fenja_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/fenja/fenja_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/fenja/fenja_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/fenja/fenja_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/fenja/fenja_sand_rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/colors/fenja/fenja_sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' },
            { url: require('../assets/colors/fenja/fenja_tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 50,
          url: require('../assets/350/fresh_350.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Fresh',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          slide: [
            { url: require('../assets/350/fresh_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/fresh_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/fresh_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/fresh/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/fresh/chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/fresh/darkauburn_rooted.jpg'), alt: 'perruque femme blond', name: 'darkauburn rooted' },
            { url: require('../assets/colors/fresh/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/fresh/hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/colors/fresh/ginger_mix.jpg'), alt: 'perruque femme blond', name: 'ginger mix' },
            { url: require('../assets/colors/fresh/lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/fresh/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 51,
          url: require('../assets/350/ginger_350.jpg'),
          alt: 'perruque femme gris ambre cheveux courte',
          name: 'Ginger',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/ginger_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/ginger_2_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/ginger/ginger_auburn_mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/colors/ginger/ginger_bernstein_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/ginger/ginger_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/ginger/ginger_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/ginger/ginger_coffeebrown_mix.jpg'), alt: 'perruque femme blond', name: 'coffeebrown mix' },
            { url: require('../assets/colors/ginger/ginger_darksnow_mix.jpg'), alt: 'perruque femme blond', name: 'darksnow mix' },
            { url: require('../assets/colors/ginger/ginger_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/ginger/ginger_pearl_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/colors/ginger/ginger_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/ginger/ginger_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/ginger/ginger_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/colors/ginger/ginger_white_mix.jpg'), alt: 'perruque femme blond', name: 'white mix' }
          ]
        },
        {
          id: 52,
          url: require('../assets/350/girl_mono_350.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Girl',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/girl_mono_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/girl_mono_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/girl_mono_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/girl/auburn_rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/colors/girl/caramel_rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/colors/girl/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/girl/chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/girl/darkchocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate rooted' },
            { url: require('../assets/colors/girl/espresso_rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/colors/girl/hothazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'hothazelnut mix' },
            { url: require('../assets/colors/girl/lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/girl/safranred_rooted.jpg'), alt: 'perruque femme blond', name: 'safranred rooted' },
            { url: require('../assets/colors/girl/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 53,
          url: require('../assets/350/ivy_350.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Ivy',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/ivy_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/ivy_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/ivy_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/ivy/ivy_caramel_rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/colors/ivy/ivy_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/ivy/ivy_chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/ivy/ivy_cognac_mix.jpg'), alt: 'perruque femme blond', name: 'cognac mix' },
            { url: require('../assets/colors/ivy/ivy_darkchocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate rooted' },
            { url: require('../assets/colors/ivy/ivy_espresso_rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/colors/ivy/ivy_flame_mix.jpg'), alt: 'perruque femme blond', name: 'flame mix' },
            { url: require('../assets/colors/ivy/ivy_hotcinnamon_mix.jpg'), alt: 'perruque femme blond', name: 'hotcinnamon mix' },
            { url: require('../assets/colors/ivy/ivy_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/ivy/ivy_lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/colors/ivy/ivy_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/ivy/ivy_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/ivy/ivy_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/ivy/ivy_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 54,
          url: require('../assets/350/limit_350.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Limit',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          slide: [
            { url: require('../assets/350/limit_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/limit_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/limit_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/limit/auburn_rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/colors/limit/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/limit/caramel_rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/colors/limit/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/limit/hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/colors/limit/hotchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'hotchocolate mix' },
            { url: require('../assets/colors/limit/hotmocca_rooted.jpg'), alt: 'perruque femme blond', name: 'hotmocca rooted' },
            { url: require('../assets/colors/limit/lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/limit/lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/colors/limit/nougat_rooted.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' },
            { url: require('../assets/colors/limit/sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' }
          ]
        },
        {
          id: 55,
          url: require('../assets/350/ocean_350.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Ocean',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          slide: [
            { url: require('../assets/350/ocean_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/ocean_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/ocean_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/ocean/auburn_rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/colors/ocean/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/ocean/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/ocean/chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/ocean/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/ocean/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/ocean/hotmocca_rooted.jpg'), alt: 'perruque femme blond', name: 'hotmocca rooted' },
            { url: require('../assets/colors/ocean/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 56,
          url: require('../assets/350/pam_hi_tec_350.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Pam Hi Tec',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          slide: [
            { url: require('../assets/350/pam_hi_tec_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/pam_hi_tec_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/pam_hi_tec_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/pam_hi_tec/pam_hi_tec_bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/pam_hi_tec/pam_hi_tec_black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/colors/pam_hi_tec/pam_hi_tec_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/pam_hi_tec/pam_hi_tec_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/pam_hi_tec/pam_hi_tec_darkauburn_mix.jpg'), alt: 'perruque femme blond', name: 'darkauburn mix' },
            { url: require('../assets/colors/pam_hi_tec/pam_hi_tec_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/pam_hi_tec/pam_hi_tec_hotchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'hotchocolate mix' },
            { url: require('../assets/colors/pam_hi_tec/pam_hi_tec_hotmocca_mix.jpg'), alt: 'perruque femme blond', name: 'hotmocca mix' },
            { url: require('../assets/colors/pam_hi_tec/pam_hi_tec_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 57,
          url: require('../assets/350/risk_350.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Risk',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/risk_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/risk_2_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/risk/risk_aubergine_mix.jpg'), alt: 'perruque femme blond', name: 'aubergine mix' },
            { url: require('../assets/colors/risk/risk_caramel_mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/colors/risk/risk_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/risk/risk_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/risk/risk_darkauburn_mix.jpg'), alt: 'perruque femme blond', name: 'darkauburn mix' },
            { url: require('../assets/colors/risk/risk_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/risk/risk_darksand_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/colors/risk/risk_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/risk/risk_ginger_rooted.jpg'), alt: 'perruque femme blond', name: 'ginger rooted' },
            { url: require('../assets/colors/risk/risk_hotchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'hotchocolate mix' },
            { url: require('../assets/colors/risk/risk_lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/colors/risk/risk_mocca_lighted.jpg'), alt: 'perruque femme blond', name: 'mocca lighted' },
            { url: require('../assets/colors/risk/risk_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'pepper mix' },
            { url: require('../assets/colors/risk/risk_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/risk/risk_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/risk/risk_silver_mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' },
            { url: require('../assets/colors/risk/risk_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/colors/risk/risk_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/colors/risk/risk_tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 58,
          url: require('../assets/350/seven_super_350.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Seven Super',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          slide: [
            { url: require('../assets/350/seven_super_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/seven_super_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/seven_super_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/seven_super/seven_super_bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/seven_super/seven_super_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/seven_super/seven_super_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/seven_super/seven_super_darkauburn_mix.jpg'), alt: 'perruque femme blond', name: 'darkauburn mix' },
            { url: require('../assets/colors/seven_super/seven_super_espreso_mix.jpg'), alt: 'perruque femme blond', name: 'espreso mix' },
            { url: require('../assets/colors/seven_super/seven_super_lightchampagne_mix.jpg'), alt: 'perruque femme blond', name: 'lightchampagne mix' },
            { url: require('../assets/colors/seven_super/seven_super_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/seven_super/seven_super_sandmulti_mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' },
            { url: require('../assets/colors/seven_super/seven_super_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/colors/seven_super/seven_super_tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 59,
          url: require('../assets/350/spring_hi_350.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Spring Hi',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/spring_hi_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/spring_hi_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/spring_hi_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/spring_hi/spring_hi_auburn_rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/colors/spring_hi/spring_hi_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/spring_hi/spring_hi_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/spring_hi/spring_hi_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/spring_hi/spring_hi_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/spring_hi/spring_hi_hotchilli_rooted.jpg'), alt: 'perruque femme blond', name: 'hotchilli rooted' },
            { url: require('../assets/colors/spring_hi/spring_hi_hotchololate_mix.jpg'), alt: 'perruque femme blond', name: 'hotchololate mix' },
            { url: require('../assets/colors/spring_hi/spring_hi_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/spring_hi/spring_hi_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/spring_hi/spring_hi_nougat_rooted.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' },
            { url: require('../assets/colors/spring_hi/spring_hi_platinblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'platinblonde rooted' },
            { url: require('../assets/colors/spring_hi/spring_hi_safranred_rooted.jpg'), alt: 'perruque femme blond', name: 'safranred rooted' },
            { url: require('../assets/colors/spring_hi/spring_hi_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/spring_hi/spring_hi_sand_rooted.jpg'), alt: 'perruque femme blond', name: 'sand_rooted' },
            { url: require('../assets/colors/spring_hi/spring_hi_sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' },
            { url: require('../assets/colors/spring_hi/spring_hi_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 60,
          url: require('../assets/350/sue_mono_350.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Sue Mono',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          // description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          // toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/350/sue_mono_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/sue_mono_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/sue_mono_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/sue_mono/auburn_mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/colors/sue_mono/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/sue_mono/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/sue_mono/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/sue_mono/darksand_rooted.jpg'), alt: 'perruque femme blond', name: 'darksand rooted' },
            { url: require('../assets/colors/sue_mono/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/sue_mono/lightchampagne_mix.jpg'), alt: 'perruque femme blond', name: 'lightchampagne mix' },
            { url: require('../assets/colors/sue_mono/sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/sue_mono/smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/colors/sue_mono/snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 61,
          url: require('../assets/350/talia_mono_350.jpg'),
          alt: 'perruque femme gris ambre cheveux courte',
          name: 'Talia Mono',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/talia_mono_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/talia_mono_2_350.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/talia_mono_3_350.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/talent_mono/talent_mono_caramel_rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/colors/talent_mono/talent_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/talent_mono/talent_mono_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/talent_mono/talent_mono_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/talent_mono/talent_mono_hotchilli_rooted.jpg'), alt: 'perruque femme blond', name: 'hotchilli rooted' },
            { url: require('../assets/colors/talent_mono/talent_mono_lightchampagne_mix.jpg'), alt: 'perruque femme blond', name: 'lightchampagne mix' },
            { url: require('../assets/colors/talent_mono/talent_mono_safranred_mix.jpg'), alt: 'perruque femme blond', name: 'safranred mix' },
            { url: require('../assets/colors/talent_mono/talent_mono_tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' },
            { url: require('../assets/colors/talent_mono/talent_mono_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        // ],
        // imagesAmethysteCommande: [
        {
          id: 62,
          url: require('../assets/450/beach_mono_450.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Beach Mono',
          price: 450,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/450/beach_mono_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/beach_mono_2_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/beach_mono_3_450.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/beach_mono/beach_mono_chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/beach_mono/beach_mono_cinnamonbrown_mix.jpg'), alt: 'perruque femme blond', name: 'cinnamonbrown mix' },
            { url: require('../assets/colors/beach_mono/beach_mono_espresso_rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/colors/beach_mono/beach_mono_hotmocca_rooted.jpg'), alt: 'perruque femme blond', name: 'hotmocca rooted' },
            { url: require('../assets/colors/beach_mono/beach_mono_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/beach_mono/beach_mono_pastelblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'pastelblonde rooted' },
            { url: require('../assets/colors/beach_mono/beach_mono_pearlblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'pearlblonde rooted' }
          ]
        },
        {
          id: 63,
          url: require('../assets/450/flirt_450.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Flirt',
          price: 450,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/450/flirt_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/flirt_2_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/flirt_3_450.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/flirt/caramel_rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/colors/flirt/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/flirt/chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/flirt/cinnamonbrown_mix.jpg'), alt: 'perruque femme blond', name: 'cinnamonbrown mix' },
            { url: require('../assets/colors/flirt/darkchocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate rooted' },
            { url: require('../assets/colors/flirt/espresso_rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/colors/flirt/ginger_mix.jpg'), alt: 'perruque femme blond', name: 'ginger mix' },
            { url: require('../assets/colors/flirt/lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/flirt/pearl_rooted.jpg'), alt: 'perruque femme blond', name: 'pearl rooted' },
            { url: require('../assets/colors/flirt/safranbrown_rooted.jpg'), alt: 'perruque femme blond', name: 'safranbrown rooted' },
            { url: require('../assets/colors/flirt/salt_pepper_rooted.jpg'), alt: 'perruque femme blond', name: 'salt_pepper rooted' }
          ]
        },
        {
          id: 64,
          url: require('../assets/450/french_1_450.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'French',
          price: 450,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/450/french_1_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/french_2_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/french_3_450.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/french/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/french/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/french/chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/french/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/french/ginger_rooted.jpg'), alt: 'perruque femme blond', name: 'ginger rooted' },
            { url: require('../assets/colors/french/hotmocca_mix.jpg'), alt: 'perruque femme blond', name: 'hotmocca mix' },
            { url: require('../assets/colors/french/lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/colors/french/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/french/silverblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'silverblonde rooted' }
          ]
        },
        {
          id: 65,
          url: require('../assets/450/glamour_mono_450.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Glamour Mono',
          price: 450,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/450/glamour_mono_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/glamour_mono_2_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/glamour_mono_3_450.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/glamour_mono/glamour_mono_black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/colors/glamour_mono/glamour_mono_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/glamour_mono/glamour_mono_chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/glamour_mono/glamour_mono_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/glamour_mono/glamour_mono_darksand_rooted.jpg'), alt: 'perruque femme blond', name: 'darksand rooted' },
            { url: require('../assets/colors/glamour_mono/glamour_mono_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/glamour_mono/glamour_mono_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/glamour_mono/glamour_mono_lighthoney_rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' }
          ]
        },
        {
          id: 66,
          url: require('../assets/450/smoke_hi_mono_450.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Smoke Hi Mono',
          price: 450,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/450/smoke_hi_mono_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/smoke_hi_mono_2_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/smoke_hi_mono_3_450.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/smoke_hi_mono/smoke_hi_mono_bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/smoke_hi_mono/smoke_hi_mono_black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/colors/smoke_hi_mono/smoke_hi_mono_caramel_rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/colors/smoke_hi_mono/smoke_hi_mono_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/smoke_hi_mono/smoke_hi_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/smoke_hi_mono/smoke_hi_mono_darkauburn_mix.jpg'), alt: 'perruque femme blond', name: 'darkauburn mix' },
            { url: require('../assets/colors/smoke_hi_mono/smoke_hi_mono_darkauburn_mix.jpg'), alt: 'perruque femme blond', name: 'darkauburn mix' },
            { url: require('../assets/colors/smoke_hi_mono/smoke_hi_mono_hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/colors/smoke_hi_mono/smoke_hi_mono_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/smoke_hi_mono/smoke_hi_mono_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 67,
          url: require('../assets/450/turn_450.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Turn',
          price: 450,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/450/turn_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/turn_2_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/turn_3_450.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/turn/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/turn/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/colors/turn/caramel_rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/colors/turn/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/turn/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/turn/darkcherry_mix.jpg'), alt: 'perruque femme blond', name: 'darkcherry mix' },
            { url: require('../assets/colors/turn/darkchocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate rooted' },
            { url: require('../assets/colors/turn/hotmocca_rooted.jpg'), alt: 'perruque femme blond', name: 'hotmocca rooted' },
            { url: require('../assets/colors/turn/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 68,
          url: require('../assets/450/young_mono_450.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Young Mono',
          price: 450,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/450/young_mono_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/young_mono_2_450.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/450/young_mono_3_450.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/young_mono/young_mono_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/young_mono/young_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/young_mono/young_mono_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/young_mono/young_mono_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/young_mono/young_mono_hotchilli_mix.jpg'), alt: 'perruque femme blond', name: 'hotchilli mix' },
            { url: require('../assets/colors/young_mono/young_mono_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/young_mono/young_mono_lighthoney_rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/young_mono/young_mono_mocca_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/colors/young_mono/young_mono_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' }
          ]
        },
        {
          id: 69,
          url: require('../assets/460/miley_small_mono_460.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Miley Small Mono',
          price: 460,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'PARTIE EN MONOFILAMENT - RAIE',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/460/miley_small_mono_460.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/460/miley_small_mono_2_460.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/460/miley_small_mono_3_460.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/miley_small_mono/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/miley_small_mono/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/miley_small_mono/lightcaramel_mix.jpg'), alt: 'perruque femme blond', name: 'lightcaramel mix' },
            { url: require('../assets/colors/miley_small_mono/lightespresso_mix.jpg'), alt: 'perruque femme blond', name: 'lightespresso mix' },
            { url: require('../assets/colors/miley_small_mono/nougat_mix.jpg'), alt: 'perruque femme blond', name: 'nougat mix' },
            { url: require('../assets/colors/miley_small_mono/sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' }
          ]
        },
        // ],
        // imagesRubisCommande: [
        {
          id: 70,
          url: require('../assets/550/apart_mono_550.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Apart Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/550/apart_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/apart_mono_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/apart_mono_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/apart_mono/apart_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/apart_mono/apart_mono_darksand_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/colors/apart_mono/apart_mono_darksnow_mix.jpg'), alt: 'perruque femme blond', name: 'darksnow mix' },
            { url: require('../assets/colors/apart_mono/apart_mono_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/apart_mono/apart_mono_pearl_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/colors/apart_mono/apart_mono_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt_pepper mix' },
            { url: require('../assets/colors/apart_mono/apart_mono_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/apart_mono/apart_mono_sandmulti_mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' },
            { url: require('../assets/colors/apart_mono/apart_mono_sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' },
            { url: require('../assets/colors/apart_mono/apart_mono_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/colors/apart_mono/apart_mono_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/colors/apart_mono/apart_mono_white_mix.jpg'), alt: 'perruque femme blond', name: 'white mix' }
          ]
        },
        {
          id: 71,
          url: require('../assets/550/citta_mono_550.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Citta Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/550/citta_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/citta_mono_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/citta_mono_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/citta_mono/citta_mono_auburn_rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/colors/citta_mono/citta_mono_bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/citta_mono/citta_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/citta_mono/citta_mono_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'spresso mix' },
            { url: require('../assets/colors/citta_mono/citta_mono_hazelnut_rooted.jpg'), alt: 'perruque femme blond', name: 'hazelnut rooted' },
            { url: require('../assets/colors/citta_mono/citta_mono_lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/colors/citta_mono/citta_mono_nougat_rooted.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' },
            { url: require('../assets/colors/citta_mono/citta_mono_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/citta_mono/citta_mono_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand_mix' },
            { url: require('../assets/colors/citta_mono/citta_mono_sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' },
            { url: require('../assets/colors/citta_mono/citta_mono_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/citta_mono/citta_mono_silver_rooted.jpg'), alt: 'perruque femme blond', name: 'silver rooted' }
          ]
        },
        {
          id: 72,
          url: require('../assets/550/date_mono_550.jpg'),
          alt: 'perruque femme gris ambre cheveux courte',
          name: 'Date Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          slide: [
            { url: require('../assets/550/date_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/date_240_large_245.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/date_2_240.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/date_mono/date_mono_auburnmix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/colors/date_mono/date_mono_caramel_mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/colors/date_mono/date_mono_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/date_mono/date_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/date_mono/date_mono_darksand_rooted.jpg'), alt: 'perruque femme blond', name: 'darksand rooted' },
            { url: require('../assets/colors/date_mono/date_mono_hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/colors/date_mono/date_mono_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/date_mono/date_mono_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/date_mono/date_mono_sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' },
            { url: require('../assets/colors/date_mono/date_mono_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/date_mono/date_mono_tobacco_mix.jpg'), alt: 'perruque femme blond', name: 'tobacco mix' }
          ]
        },
        {
          id: 73,
          url: require('../assets/550/ever_mono_550.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Ever Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/550/ever_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/ever_mono_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/ever_mono_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/ever_mono/ever_mono_bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/ever_mono/ever_mono_caramel_mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/colors/ever_mono/ever_mono_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/ever_mono/ever_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/ever_mono/ever_mono_cinnamon_mix.jpg'), alt: 'perruque femme blond', name: 'cinnamon mix' },
            { url: require('../assets/colors/ever_mono/ever_mono_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/ever_mono/ever_mono_hazelnut_rooted.jpg'), alt: 'perruque femme blond', name: 'hazelnut rooted' },
            { url: require('../assets/colors/ever_mono/ever_mono_pearlblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/colors/ever_mono/ever_mono_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/ever_mono/ever_mono_sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' },
            { url: require('../assets/colors/ever_mono/ever_mono_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 74,
          url: require('../assets/550/flair_mono_550.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Flair Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/550/flair_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/flair_mono_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/flair_mono_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/flair_mono/auburn_rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/colors/flair_mono/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/flair_mono/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/flair_mono/chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/flair_mono/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/flair_mono/ginger_rooted.jpg'), alt: 'perruque femme blond', name: 'ginger rooted' },
            { url: require('../assets/colors/flair_mono/hazelnut_rooted.jpg'), alt: 'perruque femme blond', name: 'hazelnut rooted' },
            { url: require('../assets/colors/flair_mono/salt_pepper_rooted.jpg'), alt: 'perruque femme blond', name: 'salt_pepper rooted' },
            { url: require('../assets/colors/flair_mono/sand_rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/colors/flair_mono/sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' },
            { url: require('../assets/colors/flair_mono/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/flair_mono/snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/colors/flair_mono/tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 75,
          url: require('../assets/550/flash_mono_550.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Flash Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/550/flash_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/flash_mono_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/flash_mono_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/flash_mono/hotchilli_rooted.jpg'), alt: 'perruque femme blond', name: 'hotchilli rooted' },
            { url: require('../assets/colors/flash_mono/lightcaramel_mix.jpg'), alt: 'perruque femme blond', name: 'lightcaramel mix' }
          ]
        },
        {
          id: 76,
          url: require('../assets/550/ginger_mono_550.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Ginger Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/550/ginger_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/ginger_large_mono_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/ginger/ginger_auburn_mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/colors/ginger/ginger_bernstein_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/ginger/ginger_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/ginger/ginger_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/ginger/ginger_coffeebrown_mix.jpg'), alt: 'perruque femme blond', name: 'coffeebrown mix' },
            { url: require('../assets/colors/ginger/ginger_darksnow_mix.jpg'), alt: 'perruque femme blond', name: 'arksnow mix' },
            { url: require('../assets/colors/ginger/ginger_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/ginger/ginger_pearl_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/colors/ginger/ginger_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'pepper mix' },
            { url: require('../assets/colors/ginger/ginger_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/ginger/ginger_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/colors/ginger/ginger_white_mix.jpg'), alt: 'perruque femme blond', name: 'white mix' }
          ]
        },
        {
          id: 77,
          url: require('../assets/550/light_mono_550.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Light Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          slide: [
            { url: require('../assets/550/light_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/light_mono_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/light_mono_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/light_mono/caramel_mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/colors/light_mono/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/light_mono/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/light_mono/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/light_mono/darksand_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/colors/light_mono/darksmoke_mix.jpg'), alt: 'perruque femme blond', name: 'darksmoke mix' },
            { url: require('../assets/colors/light_mono/darksnow_mix.jpg'), alt: 'perruque femme blond', name: 'darksnow mix' },
            { url: require('../assets/colors/light_mono/nougat_mix.jpg'), alt: 'perruque femme blond', name: 'nougat mix' },
            { url: require('../assets/colors/light_mono/pearl_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/colors/light_mono/sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/light_mono/sandmulti_mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' },
            { url: require('../assets/colors/light_mono/smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/colors/light_mono/snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/colors/light_mono/stonegrey_mix.jpg'), alt: 'perruque femme blond', name: 'stonegrey mix' }
          ]
        },
        {
          id: 78,
          url: require('../assets/550/love_comfort_1_550.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Love Comfort',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/550/love_comfort_1_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/love_comfort_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/love_comfort_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/love_comfort/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/love_comfort/darksand_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/colors/love_comfort/espresso_rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/colors/love_comfort/hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/colors/love_comfort/hotchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'hotchocolate mix' },
            { url: require('../assets/colors/love_comfort/lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/colors/love_comfort/lighthoney_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney mix' },
            { url: require('../assets/colors/love_comfort/nougat_mix.jpg'), alt: 'perruque femme blond', name: 'nougat mix' },
            { url: require('../assets/colors/love_comfort/salt_pepper_rooted.jpg'), alt: 'perruque femme blond', name: 'salt pepper rooted' },
            { url: require('../assets/colors/love_comfort/sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand_mix' },
            { url: require('../assets/colors/love_comfort/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/love_comfort/smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/colors/love_comfort/snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 79,
          url: require('../assets/550/mia_mono_550.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Mia Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          // description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          // toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/550/mia_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/mia_mono_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/mia_mono_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/mia_mono/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/mia_mono/mia_mono-sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' },
            { url: require('../assets/colors/mia_mono/mia_mono_auburn_mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/colors/mia_mono/mia_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/mia_mono/mia_mono_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/mia_mono/mia_mono_hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/colors/mia_mono/mia_mono_hotmocca_mix.jpg'), alt: 'perruque femme blond', name: 'hotmocca mix' },
            { url: require('../assets/colors/mia_mono/mia_mono_lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/mia_mono/mia_mono_platinblonde_mix.jpg'), alt: 'perruque femme blond', name: 'platinblonde mix' },
            { url: require('../assets/colors/mia_mono/mia_mono_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/mia_mono/mia_mono_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/mia_mono/mia_mono_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 80,
          url: require('../assets/550/risk_comfort_550.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Risk Comfort',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/550/risk_comfort_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/risk_comfort_2_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/risk_comfort/auburn_rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/colors/risk_comfort/bernstein_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/risk_comfort/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/risk_comfort/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/risk_comfort/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/risk_comfort/mocca_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/colors/risk_comfort/platinblonde_mix.jpg'), alt: 'perruque femme blond', name: 'platinblonde mix' },
            { url: require('../assets/colors/risk_comfort/salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/risk_comfort/sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/risk_comfort/silver_rooted.jpg'), alt: 'perruque femme blond', name: 'silver rooted' },
            { url: require('../assets/colors/risk_comfort/smoke_rooted.jpg'), alt: 'perruque femme blond', name: 'smoke rooted' },
            { url: require('../assets/colors/risk_comfort/snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 81,
          url: require('../assets/550/solit_r_hi_mono_550.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Solit R Hi Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/550/solit_r_hi_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/solit_r_hi_mono_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/solit_r_hi_mono_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/solit_r_hi_mono/solitaer_hi_mono_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/solit_r_hi_mono/solitaer_hi_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/solit_r_hi_mono/solitaer_hi_mono_darksnow_mix.jpg'), alt: 'perruque femme blond', name: 'darksnow mix' },
            { url: require('../assets/colors/solit_r_hi_mono/solitaer_hi_mono_mocca_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/colors/solit_r_hi_mono/solitaer_hi_mono_pearl_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/colors/solit_r_hi_mono/solitaer_hi_mono_sand_rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/colors/solit_r_hi_mono/solitaer_hi_mono_sandmulti_mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' },
            { url: require('../assets/colors/solit_r_hi_mono/solitaer_hi_mono_sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' },
            { url: require('../assets/colors/solit_r_hi_mono/solitaer_hi_mono_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/colors/solit_r_hi_mono/solitaer_hi_mono_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/colors/solit_r_hi_mono/solitaer_hi_mono_white_mix.jpg'), alt: 'perruque femme blond', name: 'white mix' }
          ]
        },
        {
          id: 82,
          url: require('../assets/550/spring_mono_550.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Spring Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/550/spring_mono_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/spring_mono_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/spring_mono_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/spring_mono/spring_mono_bernstein_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/colors/spring_mono/spring_mono_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/spring_mono/spring_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/spring_mono/spring_mono_darkauburn_mix.jpg'), alt: 'perruque femme blond', name: 'darkauburn mix' },
            { url: require('../assets/colors/spring_mono/spring_mono_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/spring_mono/spring_mono_hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/colors/spring_mono/spring_mono_lightcaramel_rooted.jpg'), alt: 'perruque femme blond', name: 'lightcaramel rooted' },
            { url: require('../assets/colors/spring_mono/spring_mono_pearl_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/colors/spring_mono/spring_mono_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/spring_mono/spring_mono_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/spring_mono/spring_mono_sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' },
            { url: require('../assets/colors/spring_mono/spring_mono_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke_mix' },
            { url: require('../assets/colors/spring_mono/spring_mono_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/colors/spring_mono/spring_mono_white_mix.jpg'), alt: 'perruque femme blond', name: 'white mix' }
          ]
        },
        {
          id: 83,
          url: require('../assets/550/zizi_550.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Zizi',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          slide: [
            { url: require('../assets/550/zizi_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/zizi_2_550.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/zizi_3_550.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/zizi/auburn_mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/colors/zizi/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/colors/zizi/caramel_mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/colors/zizi/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/zizi/coffeebrown_mix.jpg'), alt: 'perruque femme blond', name: 'coffeebrown mix' },
            { url: require('../assets/colors/zizi/darkauburn_mix.jpg'), alt: 'perruque femme blond', name: 'darkauburn mix' },
            { url: require('../assets/colors/zizi/darksand_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/colors/zizi/darksnow_mix.jpg'), alt: 'perruque femme blond', name: 'darksnow mix' },
            { url: require('../assets/colors/zizi/lightchampagne_mix.jpg'), alt: 'perruque femme blond', name: 'lightchampagne mix' },
            { url: require('../assets/colors/zizi/lightespresso_mix.jpg'), alt: 'perruque femme blond', name: 'lightespresso mix' },
            { url: require('../assets/colors/zizi/salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt_pepper mix' },
            { url: require('../assets/colors/zizi/sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/zizi/sandmulti_mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' },
            { url: require('../assets/colors/zizi/sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' },
            { url: require('../assets/colors/zizi/silver_mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' },
            { url: require('../assets/colors/zizi/smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' }
          ]
        },
        {
          id: 84,
          url: require('../assets/560/bo_mono_560.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Bo Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/560/bo_mono_560.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/560/bo_mono_2_560.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/bo_mono/bo_mono_auburn_mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/colors/bo_mono/bo_mono_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/bo_mono/bo_mono_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/bo_mono/bo_mono_darksand_rooted.jpg'), alt: 'perruque femme blond', name: 'darksand rooted' },
            { url: require('../assets/colors/bo_mono/bo_mono_hotespresso_mix.jpg'), alt: 'perruque femme blond', name: 'hotespresso mix' },
            { url: require('../assets/colors/bo_mono/bo_mono_mocca_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/colors/bo_mono/bo_mono_pearlblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/colors/bo_mono/bo_mono_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/bo_mono/bo_mono_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 85,
          url: require('../assets/560/fair_mono_560.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Fair Mono',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT + TRESSES',
          toto: 'Le monofilament se situe sur tout le haut de tête. Il s’agit d‘une micro-peau invisible sur le cuir chevelu, sur laquelle les cheveux sont minutieusement noués un par un. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée. Sur le dessus de la tête, les cheveux noués à la main sur le monofilament créent un effet très naturel.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/560/fair_mono_560.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/560/fair_2_270.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/560/fair_3_270.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/fair_mono/flair_mono_bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'name' },
            { url: require('../assets/colors/fair_mono/flair_mono_caramel_rooted.jpg'), alt: 'perruque femme blond', name: 'name' },
            { url: require('../assets/colors/fair_mono/flair_mono_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'name' },
            { url: require('../assets/colors/fair_mono/flair_mono_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'name' },
            { url: require('../assets/colors/fair_mono/flair_mono_darksand_mix.jpg'), alt: 'perruque femme blond', name: 'name' },
            { url: require('../assets/colors/fair_mono/flair_mono_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'name' },
            { url: require('../assets/colors/fair_mono/flair_mono_hotmocca_rooted.jpg'), alt: 'perruque femme blond', name: 'name' },
            { url: require('../assets/colors/fair_mono/flair_mono_lightchampagne_mix.jpg'), alt: 'perruque femme blond', name: 'name' },
            { url: require('../assets/colors/fair_mono/flair_mono_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'name' },
            { url: require('../assets/colors/fair_mono/flair_mono_silver_mix.jpg'), alt: 'perruque femme blond', name: 'name' },
            { url: require('../assets/colors/fair_mono/flair_mono_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'name' },
            { url: require('../assets/colors/fair_mono/flair_mono_snow_mix.jpg'), alt: 'perruque femme blond', name: 'name' }
          ]
        },
        {
          id: 86,
          url: require('../assets/600Plus/aurora_comfort_600.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Aurora Comfort',
          price: 630,
          gamme: 'Saphir - classe II - 600 à 700€',
          description: '100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          slide: [
            { url: require('../assets/600Plus/aurora_comfort_600.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/aurora_comfort_2_600.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/aurora_comfort_3_600.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/aurora_comfort/aurora_comfort_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/aurora_comfort/aurora_comfort_coffeebrown_mix.jpg'), alt: 'perruque femme blond', name: 'coffeebrown mix' },
            { url: require('../assets/colors/aurora_comfort/aurora_comfort_darksand_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/colors/aurora_comfort/aurora_comfort_lighthoney_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney mix' },
            { url: require('../assets/colors/aurora_comfort/aurora_comfort_mocca_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/colors/aurora_comfort/aurora_comfort_sandmulti_mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' },
            { url: require('../assets/colors/aurora_comfort/aurora_comfort_sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' },
            { url: require('../assets/colors/aurora_comfort/aurora_comfort_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/colors/aurora_comfort/aurora_comfort_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/colors/aurora_comfort/aurora_comfort_white_mix.jpg'), alt: 'perruque femme blond', name: 'white mix' }
          ]
        },
        {
          id: 87,
          url: require('../assets/600Plus/alexis_deluxe_630.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Alexis Deluxe',
          price: 700,
          gamme: 'Saphir - classe II - 630 à 700 euros',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/alexis_deluxe_630.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/alexis_deluxe_2_630.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/alexis_deluxe_3_630.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/alexis_deluxe/alexis_deluxe_bernstein_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein_mix' },
            { url: require('../assets/colors/alexis_deluxe/alexis_deluxe_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne_mix' },
            { url: require('../assets/colors/alexis_deluxe/alexis_deluxe_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate_mix' },
            { url: require('../assets/colors/alexis_deluxe/alexis_deluxe_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate_mix' },
            { url: require('../assets/colors/alexis_deluxe/alexis_deluxe_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso_mix' },
            { url: require('../assets/colors/alexis_deluxe/alexis_deluxe_sand_mix.jpg'), alt: 'perruque femme blond', name: 'deluxe_sand_mix' },
            { url: require('../assets/colors/alexis_deluxe/alexis_deluxe_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke_mix' },
            { url: require('../assets/colors/alexis_deluxe/alexis_deluxe_snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow_mix' }
          ]
        },
        {
          id: 88,
          url: require('../assets/600Plus/amy_deluxe_630.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Amy Deluxe',
          price: 700,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/amy_deluxe_630.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/amy_deluxe_2_630.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/amy_deluxe_3_630.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/amy_deluxe/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/amy_deluxe/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso_mix' },
            { url: require('../assets/colors/amy_deluxe/lightbernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/amy_deluxe/lightcaramel_rooted.jpg'), alt: 'perruque femme blond', name: 'lightcaramel rooted' },
            { url: require('../assets/colors/amy_deluxe/lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/colors/amy_deluxe/mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/amy_deluxe/salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt_pepper mix' },
            { url: require('../assets/colors/amy_deluxe/sand_rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/colors/amy_deluxe/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/amy_deluxe/silver_rooted.jpg'), alt: 'perruque femme blond', name: 'silver rooted' },
            { url: require('../assets/colors/amy_deluxe/snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/colors/amy_deluxe/tobacco_mix.jpg'), alt: 'perruque femme blond', name: 'tobacco mix' }
          ]
        },
        {
          id: 89,
          url: require('../assets/600Plus/amy_small_630.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Amy Small Deluxe',
          price: 700,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/amy_small_630.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/amy_small_2_630.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/amy_small_3_630.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/amy_small_deluxe/caramel_mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/colors/amy_small_deluxe/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/amy_small_deluxe/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/amy_small_deluxe/darknougat_mix.jpg'), alt: 'perruque femme blond', name: 'darknougat mix' },
            { url: require('../assets/colors/amy_small_deluxe/lightpearl_mix.jpg'), alt: 'perruque femme blond', name: 'lightpearl mix' },
            { url: require('../assets/colors/amy_small_deluxe/mocca_mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/colors/amy_small_deluxe/salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/amy_small_deluxe/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        },
        {
          id: 90,
          url: require('../assets/600Plus/cara_100deluxe_630.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Cara 100 Deluxe',
          price: 630,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/cara_100deluxe_630.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/cara_100deluxe_2_630.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/cara_100deluxe_3_630.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/cara_100_deluxe/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/cara_100_deluxe/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/cara_100_deluxe/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/cara_100_deluxe/darksand_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/colors/cara_100_deluxe/mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/cara_100_deluxe/pearl_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/colors/cara_100_deluxe/sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' },
            { url: require('../assets/colors/cara_100_deluxe/silver_mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' },
            { url: require('../assets/colors/cara_100_deluxe/white_mix.jpg'), alt: 'perruque femme blond', name: 'white mix' }
          ]
        },
        {
          id: 91,
          url: require('../assets/600Plus/liza_small_deluxe_630.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Liza Small Deluxe',
          price: 630,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/liza_small_deluxe_630.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/liza_small_deluxe_2_630.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/liza_small_deluxe/caramel_mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/colors/liza_small_deluxe/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/liza_small_deluxe/chocolate_mix (1).jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/liza_small_deluxe/darksand_mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/colors/liza_small_deluxe/lightsand_mix.jpg'), alt: 'perruque femme blond', name: 'lightsand mix' },
            { url: require('../assets/colors/liza_small_deluxe/mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/liza_small_deluxe/pearl_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/colors/liza_small_deluxe/smoke_mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/colors/liza_small_deluxe/snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 92,
          url: require('../assets/600Plus/tempo_100deluxe_640.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Tempo 100 Deluxe',
          price: 640,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/tempo_100deluxe_640.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/tempo_100deluxe_2_640.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/tempo_100deluxe_3_640.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/tempo_100_deluxe/snow_mix.jpg'), alt: 'perruque femme blond', name: 'snow_mix' },
            { url: require('../assets/colors/tempo_100_deluxe/tempo100_deluxe_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/tempo_100_deluxe/tempo100_deluxe_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/tempo_100_deluxe/tempo100_deluxe_coffeebrown_mix.jpg'), alt: 'perruque femme blond', name: 'coffeebrown mix' },
            { url: require('../assets/colors/tempo_100_deluxe/tempo100_deluxe_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/tempo_100_deluxe/tempo100_deluxe_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/tempo_100_deluxe/tempo100_deluxe_hotmocca_mix.jpg'), alt: 'perruque femme blond', name: 'hotmocca mix' },
            { url: require('../assets/colors/tempo_100_deluxe/tempo100_deluxe_pearl_mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
            { url: require('../assets/colors/tempo_100_deluxe/tempo100_deluxe_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/tempo_100_deluxe/tempo100_deluxe_sand_mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/colors/tempo_100_deluxe/tempo100_deluxe_sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' },
            { url: require('../assets/colors/tempo_100_deluxe/tempo100_deluxe_white_mix.jpg'), alt: 'perruque femme blond', name: 'white mix' }
          ]
        },
        {
          id: 93,
          url: require('../assets/350/alexis.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Alexis',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/alexis.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/alexis2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/alexis3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/alexis/alexis_auburn_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/alexis/alexis_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/alexis/alexis_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/alexis/alexis_lightespresso_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/alexis/alexis_nougat_rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/alexis/alexis_sand_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/alexis/alexis_sandmulti_mix.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' }
          ]
        },
        {
          id: 94,
          url: require('../assets/350/apart_hi.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Apart Hi',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/apart_hi.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/apart_hi2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/apart_hi3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/apartHi/apart_hi_caramel_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/apartHi/apart_hi_coffeebrown_mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/apartHi/apart_hi_darksand_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/apartHi/apart_hi_darksmoke_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/apartHi/apart_hi_darksnow_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/apartHi/apart_hi_sand_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/apartHi/apart_hi_sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/apartHi/apart_hi_silver_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/apartHi/apart_hi_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/apartHi/apart_hi_snow_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/apartHi/apart_hi_white_mix.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/apartHi/light espresso mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' }
          ]
        },
        {
          id: 95,
          url: require('../assets/350/city.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'City',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/city.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/city2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/city3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/city/city_auburn_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/city/city_bernstein_mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/city/city_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/city/city_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/city/city_darksand_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/city/city_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/city/city_hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/city/city_lightcaramel_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/city/city_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/city/city_sand_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/city/city_sandmulti_mix.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/city/city_snow_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' }
          ]
        },
        {
          id: 96,
          url: require('../assets/350/dailyLarge.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Daily Large',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/dailyLarge.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/dailyLarge2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/dailyLarge3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/dailyLarge/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/dailyLarge/champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/dailyLarge/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/dailyLarge/darkauburn_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/dailyLarge/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/dailyLarge/hazelnut_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/dailyLarge/mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/dailyLarge/salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/dailyLarge/sand_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/dailyLarge/smoke_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/dailyLarge/snow_mix.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' }
          ]
        },
        {
          id: 97,
          url: require('../assets/350/eliteSmall.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Elite Small',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/eliteSmall.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/elitesmall2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/eliteSmall3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/eliteSmall/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/eliteSmall/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/eliteSmall/darksand_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/eliteSmall/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/eliteSmall/mocca_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/eliteSmall/sand_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' }
          ]
        },
        {
          id: 98,
          url: require('../assets/350/envogue.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'En Vogue',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/envogue.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/envogue2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/envogue3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/envogue4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/envogue5.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/envogue6.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/envogue/en-vogue-auburn-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/envogue/en-vogue-black.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/envogue/en-vogue-chocolate-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/envogue/en-vogue-darkchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/envogue/en-vogue-espresso-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/envogue/en-vogue-lightbernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/envogue/en-vogue-lightchampagne-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/envogue/en-vogue-metallicblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/envogue/en-vogue-pearlblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/envogue/en-vogue-sandyblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' }
          ]
        },
        {
          id: 99,
          url: require('../assets/350/fenjaSmall.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Fenja Small',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/fenjaSmall.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/fenjaSmall2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/fenjaSmall3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/fenjaSmall/caramel_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/fenjaSmall/darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/fenjaSmall/fenja_auburn_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/fenjaSmall/fenja_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/fenjaSmall/fenja_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/fenjaSmall/fenja_mocca_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/fenjaSmall/fenja_sand_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' }
          ]
        },
        {
          id: 100,
          url: require('../assets/350/foxy.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Foxy',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/foxy.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/foxy2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/foxy3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/foxy/auburn_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/foxy/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/foxy/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/foxy/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/foxy/darkchocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/foxy/darksand_rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/foxy/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/foxy/salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/foxy/sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/foxy/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/foxy/snow_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' }
          ]
        },
        {
          id: 101,
          url: require('../assets/350/foxySmall.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Foxy Small',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/foxySmall.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/foxySmall2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/foxySmall3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/foxySmall/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/foxySmall/champagne_mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/foxySmall/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/foxySmall/darksand_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/foxySmall/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/foxySmall/salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/foxySmall/sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/foxySmall/snow_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' }
          ]
        },
        {
          id: 102,
          url: require('../assets/350/lucky_hi.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Lucky Hi',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/lucky_hi.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/lucky_hi2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/lucky_hi3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/lucky_hi/bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/lucky_hi/black.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/lucky_hi/chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/lucky_hi/cinnamonbrown_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/lucky_hi/espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/lucky_hi/lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/lucky_hi/lighthoney_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/lucky_hi/mocca_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/lucky_hi/salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/lucky_hi/sand_rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/lucky_hi/sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/lucky_hi/silverblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' }
          ]
        },
        {
          id: 103,
          url: require('../assets/350/naomi.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Naomi',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/naomi.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/naomi2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/naomi3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/naomi/naomi-black.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/naomi/naomi-champagne-mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/naomi/naomi-espresso-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/naomi/naomi-hotflame-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/naomi/naomi-iceblonde-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/naomi/naomi-mangored-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/naomi/naomi-nougat-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' }
          ]
        },
        {
          id: 104,
          url: require('../assets/350/risk_sensitive.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Risk Sensitive',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/risk_sensitive.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/risk_sensitive2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/350/risk_sensitive3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_aubergine_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_caramel_mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_champagne_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_darkauburn_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_darksand_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_ginger_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_hotchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_mocca_lighted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_salt_pepper_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_silver_mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_smoke_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/risque_sensitive/risk_sensitive_tobacco_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' }
          ]
        },
        {
          id: 105,
          url: require('../assets/600Plus/tempolargedeluxe.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Tempo Large Deluxe',
          price: 700,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/tempolargedeluxe.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/tempolargedeluxe2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/tempolargedeluxe3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/tempolargedeluxe/snow_mix.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/tempolargedeluxe/tempo100_deluxe_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/tempolargedeluxe/tempo100_deluxe_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/tempolargedeluxe/tempo100_deluxe_mocca_mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/tempolargedeluxe/tempo100_deluxe_sand_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/tempolargedeluxe/tempo100_deluxe_sandyblonde_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' }
          ]
        },
        {
          id: 106,
          url: require('../assets/600Plus/affairHi.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Affair Hi',
          price: 700,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/affairHi.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/affairHi.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/affairHi.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/affairHi/Affair_ChampagneMix-0866.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/affairHi/Affair_ChocolateMix-0822.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/affairHi/Affair_EspressoMix-0814.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/affairHi/Affair_LightbernsteinMix-0843.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/affairHi/Affair_MoccaMix-0838.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/affairHi/Affair_SandMix-0850.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/affairHi/Affair_SandyblondeRooted-0860.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' }
          ]
        },
        {
          id: 107,
          url: require('../assets/600Plus/air.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Air',
          price: 700,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/air.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/air2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/air3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/air4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/air5.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/air/champagne-mix.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/air/chocolate-mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/air/darkchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/air/darksand-rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/air/espresso-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/air/lightbernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/air/mocca-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/air/salt-pepper-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/air/sand-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/air/sandyblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/air/silver-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/air/white-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' }
          ]
        },
        {
          id: 108,
          url: require('../assets/600Plus/aura.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Aura',
          price: 700,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/aura.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/aura2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/aura3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/aura4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/aura5.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/aura/caramel-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/aura/champagne-rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/aura/chocolate-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/aura/darksand-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/aura/espresso-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/aura/hazelnut-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/aura/lighthoney-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/aura/mocca-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/aura/pearl-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/aura/salt-pepper-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/aura/sand-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/aura/smoke-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/aura/snow-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/aura/tobacco-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney' }
          ]
        },
        {
          id: 109,
          url: require('../assets/600Plus/bloom.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Bloom',
          price: 700,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/bloom.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/bloom2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/bloom3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/bloom4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/bloom/bloom_bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/bloom/bloom_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/bloom/bloom_chocolate_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/bloom/bloom_darkpearl_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/bloom/bloom_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/bloom/bloom_lightchampagne_rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/bloom/bloom_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/bloom/bloom_sand_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/bloom/bloom_sandyblonde_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/bloom/bloom_snow_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' }
          ]
        },
        {
          id: 110,
          url: require('../assets/600Plus/charme.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Charme',
          price: 620,
          gamme: 'Emeraude - classe II - 620€ - 620€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/charme.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/charme2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/charme3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/charme4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/charme/champagne-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/charme/chocolate-mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/charme/darkchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/charme/darksand-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/charme/darksnow-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/charme/hazelnut-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/charme/mocca-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/charme/sand-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/charme/sandyblonde-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/charme/snow-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/charme/tobacco-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' }
          ]
        },
        {
          id: 111,
          url: require('../assets/600Plus/devine.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Devine',
          price: 620,
          gamme: 'Emeraude - classe II - 620€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/devine.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/devine2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/devine3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/devine4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/devine5.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/devine6.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/devine7.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/devine/aubergine-mix.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/devine/black.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/devine/champagne-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/devine/chocolate-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/devine/darkchocolate-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/devine/darksnow-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/devine/lightbernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/devine/mocca-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/devine/pearlblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/devine/red-pepper-mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/devine/sand-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/devine/silverblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' }
          ]
        },
        {
          id: 112,
          url: require('../assets/600Plus/fame.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Fame',
          price: 620,
          gamme: 'Emeraude - classe II - 620€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/fame.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/fame2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/fame3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/fame4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/fame/auburn-mix.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/fame/caramel-mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/fame/champagne-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/fame/chocolate-rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/fame/darkchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/fame/hotchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/fame/mocca-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/fame/nougat-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/fame/pastelblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/fame/sand-rooted.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/fame/sandmulti-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' }
          ]
        },
        {
          id: 113,
          url: require('../assets/600Plus/gala.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Gala',
          price: 620,
          gamme: 'Emeraude - classe II - 620€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/gala.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/gala2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/gala3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/gala/bernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/gala/champagne-rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/gala/chocolate-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/gala/darksnow-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/gala/nougat-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/gala/pearl-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/gala/salt-pepper-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/gala/sand-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/gala/sandmulti-rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/gala/sandyblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/colors/gala/silver-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/gala/snow-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' }
          ]
        },
        {
          id: 114,
          url: require('../assets/600Plus/icone.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Icone',
          price: 700,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/icone.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/icone2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/icone3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/icone4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/icone5.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/icone6.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/icone/champagne-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/icone/chocolate-mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/icone/darksand-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/icone/espresso-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/icone/hotchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/icone/lightbernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/icone/lighthoney-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/icone/mocca-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/icone/sand-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' }
          ]
        },
        {
          id: 115,
          url: require('../assets/600Plus/joy.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Joy',
          price: 700,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/joy.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/joy2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/joy4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/joy5.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/joy/auburn-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/joy/caramel-rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/joy/chocolate-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/joy/espresso-rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/joy/lightbernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/joy/mocca-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/joy/pastelblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/joy/sand-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/joy/sandyblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/joy/tobacco-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' }
          ]
        },
        {
          id: 116,
          url: require('../assets/600Plus/posh.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Posh',
          price: 700,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/posh.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/posh2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/posh3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/posh4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/posh5.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/posh6.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/posh7.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/posh8.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/posh/bernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/posh/champagne-mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/posh/chocolate-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/posh/mocca-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/posh/pearl-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/posh/salt-pepper-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/posh/sand-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/posh/silver-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/posh/smoke-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/posh/snow-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' }
          ]
        },
        {
          id: 117,
          url: require('../assets/600Plus/star.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Star',
          price: 700,
          gamme: 'Saphir - classe II - 630 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/star.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/star2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/star3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/star4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/star5.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/star6.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/600Plus/star7.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/star/caramel-mix.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/star/champagne-rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/star/chocolate-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/star/darkchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/star/espresso-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/star/hotchocolate-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/star/lightbernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/star/mocca-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/star/salt-pepper-mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/star/sand-mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/star/sandyblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/star/snow-mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' }
          ]
        },
        {
          id: 118,
          url: require('../assets/550/vanity.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Vanity',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/550/vanity.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/vanity2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/vanity3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/550/vanity4.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/vanity/bernstein-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/vanity/champagne-rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/vanity/chocolate-mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/vanity/darkchocolate-rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/vanity/darksand-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/vanity/mocca-rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/vanity/pearl-mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/colors/vanity/salt-pepper-rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/vanity/sandmulti-rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/vanity/sandyblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/vanity/silverblonde-rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' }
          ]
        },
        {
          id: 119,
          url: require('../assets/570/MusicComfort/MusicComfort_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Music Comfort',
          price: 570,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/570/MusicComfort/MusicComfort_1.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/570/MusicComfort/MusicComfort_2.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/570/MusicComfort/MusicComfort_3.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/570/MusicComfort/MusicComfort_4.webp'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/musicComfort/champagne-rooted.avif'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/musicComfort/chocolate-rooted.avif'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/musicComfort/cinnamonbrown-rooted.avif'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/musicComfort/darkchocolate-mix.avif'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/musicComfort/espresso-mix.webp'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/musicComfort/lightbernstein-rooted.avif'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/musicComfort/metallicblonde-rooted.avif'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/colors/musicComfort/sandyblonde-rooted.avif'), alt: 'perruque femme blond', name: 'bernstein rooted' }
          ]
        },
        {
          id: 120,
          url: require('../assets/350/relax/Relax_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Relax',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/relax/Relax_1.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/350/relax/Relax_2.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/350/relax/Relax_3.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/350/relax/Relax_4.webp'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/relax/auburn-rooted.webp'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/colors/relax/blackcherry.webp'), alt: 'perruque femme blond', name: 'black cherry' },
            { url: require('../assets/colors/relax/champagne-rooted.webp'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/relax/chocolate-rooted.webp'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/relax/darkchocolate-mix.avif'), alt: 'perruque femme blond', name: 'dark chocolate mix' },
            { url: require('../assets/colors/relax/relaxespressomix.webp'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/relax/metallicblonde-rooted.webp'), alt: 'perruque femme blond', name: 'metallic blonde rooted' },
            { url: require('../assets/colors/relax/pepper-mix.webp'), alt: 'perruque femme blond', name: 'pepper mix' }
          ]
        },
        {
          id: 121,
          url: require('../assets/470/scalaMonoPart/ScalaMonoPart_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Scala Mono Part',
          price: 470,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/470/scalaMonoPart/ScalaMonoPart_1.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/470/scalaMonoPart/ScalaMonoPart_2.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/470/scalaMonoPart/ScalaMonoPart_3.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/470/scalaMonoPart/ScalaMonoPart_4.webp'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/scalamonopart/champagne-rooted.avif'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/scalamonopart/chocolate-rooted.avif'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/scalamonopart/darkchocolate-mix.avif'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/scalamonopart/espresso-mix.webp'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/scalamonopart/lightbernstein-rooted.webp'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/scalamonopart/pearl-rooted.webp'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/scalamonopart/sandyblonde-rooted.webp'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/scalamonopart/snow-mix.webp'), alt: 'perruque femme blond', name: 'champagne rooted' }
          ]
        },
        {
          id: 122,
          url: require('../assets/570/MelodyMonoLARGE_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Melody Mono Large',
          price: 570,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/570/MelodyMonoLARGE_1.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/570/MelodyMonoLARGE_2.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/570/MelodyMonoLARGE_3.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/570/MelodyMonoLARGE_4.webp'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/melody_mono_large/champagne-rooted.avif'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/melody_mono_large/chocolate-rooted.avif'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/melody_mono_large/darkauburn-rooted.avif'), alt: 'perruque femme blond', name: 'darkauburn rooted' },
            { url: require('../assets/colors/melody_mono_large/espresso-mix.avif'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/melody_mono_large/lightbernstein-rooted.avif'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/melody_mono_large/sahara-beige-rooted.avif'), alt: 'perruque femme blond', name: 'sahara beige rooted' },
            { url: require('../assets/colors/melody_mono_large/stonegrey-rooted.avif'), alt: 'perruque femme blond', name: 'stonegrey rooted' }
          ]
        },
        {
          id: 123,
          url: require('../assets/560/melodymono/MelodyMono_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Melody Mono',
          price: 560,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/560/melodymono/MelodyMono_1.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/560/melodymono/MelodyMono_2.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/560/melodymono/MelodyMono_3.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/560/melodymono/MelodyMono_4.webp'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/melodymono/champagne-rooted.avif'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/melodymono/chocolate-rooted.avif'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/melodymono/darkauburn-rooted.avif'), alt: 'perruque femme blond', name: 'darkauburn rooted' },
            { url: require('../assets/colors/melodymono/darkchocolate-mix.avif'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/melodymono/espresso-mix.avif'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/melodymono/lightbernstein-rooted.avif'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/melodymono/mocca-rooted.avif'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/melodymono/sahara-beige-rooted.avif'), alt: 'perruque femme blond', name: 'sahara beige rooted' },
            { url: require('../assets/colors/melodymono/snow-mix.avif'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/colors/melodymono/stonegrey-rooted.avif'), alt: 'perruque femme blond', name: 'stonegrey rooted' }
          ]
        },
        {
          id: 124,
          url: require('../assets/350/soundmonopart/SoundMonoPart_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Sound Mono Part',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/soundmonopart/SoundMonoPart_1.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/350/soundmonopart/SoundMonoPart_2.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/350/soundmonopart/SoundMonoPart_3.webp'), alt: 'perruque femme blond' },
            { url: require('../assets/350/soundmonopart/SoundMonoPart_4.webp'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/colors/soundmonopart/auburn-rooted.avif'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/colors/soundmonopart/champagne-rooted.avif'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/soundmonopart/chocolate-rooted.avif'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/soundmonopart/espresso-mix.webp'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/soundmonopart/lightchampagne-rooted.webp'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/colors/soundmonopart/metallicblonde-rooted.webp'), alt: 'perruque femme blond', name: 'metallicblonde rooted' },
            { url: require('../assets/colors/soundmonopart/plumbrown-rooted.webp'), alt: 'perruque femme blond', name: 'plumbrown rooted' },
            { url: require('../assets/colors/soundmonopart/sahara-beige-rooted.avif'), alt: 'perruque femme blond', name: 'sahara beige rooted' },
            { url: require('../assets/colors/soundmonopart/tobacco-rooted.webp'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 125,
          url: require('../assets/350/look/Look_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Look',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/look/Look_1.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/350/look/Look_2.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/350/look/Look_3.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/350/look/Look_4.webp'), alt: 'perruque femme cheveux brun' }
          ],
          mozaique: [
            { url: require('../assets/colors/look/aubergine-mix.webp'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/colors/look/black.webp'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/look/champagne-rooted.webp'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/look/chocolate-rooted.webp'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/look/espresso-mix.webp'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/colors/look/lightbernstein-rooted.webp'), alt: 'perruque femme blond', name: 'metallicblonde rooted' },
            { url: require('../assets/colors/look/nougat-lighted.webp'), alt: 'perruque femme blond', name: 'plumbrown rooted' },
            { url: require('../assets/colors/look/sandyblonde-rooted.webp'), alt: 'perruque femme blond', name: 'sahara beige rooted' }
          ]
        },
        {
          id: 126,
          url: require('../assets/350/yoko/Yoko_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Yoko',
          price: 350,
          gamme: 'Ambre - classe I - 350€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/350/yoko/Yoko_1.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/350/yoko/Yoko_2.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/350/yoko/Yoko_3.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/350/yoko/Yoko_4.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/350/yoko/Yoko_5.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/350/yoko/Yoko_6.webp'), alt: 'perruque femme cheveux brun' }
          ],
          mozaique: [
            { url: require('../assets/colors/yoko/aubergine-mix.webp'), alt: 'perruque femme blond', name: 'aubergine mix' },
            { url: require('../assets/colors/yoko/champagne-rooted.webp'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/colors/yoko/cherrryred-rooted.avif'), alt: 'perruque femme blond', name: 'cherrryred rooted' },
            { url: require('../assets/colors/yoko/chocolate-rooted.webp'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/yoko/darkchocolate-mix.webp'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/yoko/darlksand-rooted.webp'), alt: 'perruque femme blond', name: 'darlksand rooted' },
            { url: require('../assets/colors/yoko/espresso-mix.webp'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/yoko/mocca-rooted.webp'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/yoko/platinblonde-rooted.webp'), alt: 'perruque femme blond', name: 'platinblonde rooted' },
            { url: require('../assets/colors/yoko/salt-pepper-rooted.webp'), alt: 'perruque femme blond', name: 'salt-pepper rooted' },
            { url: require('../assets/colors/yoko/sandyblonde-rooted.webp'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/yoko/snow-mix.webp'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/colors/yoko/white-mix.webp'), alt: 'perruque femme blond', name: 'white mix' }
          ]
        },
        {
          id: 127,
          url: require('../assets/470/heavenmonopart/HeavenMonoPart_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Heaven Mono Part',
          price: 470,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/470/heavenmonopart/HeavenMonoPart_1.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/470/heavenmonopart/HeavenMonoPart_2.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/470/heavenmonopart/HeavenMonoPart_3.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/470/heavenmonopart/HeavenMonoPart_4.webp'), alt: 'perruque femme cheveux brun' }
          ],
          mozaique: [
            { url: require('../assets/colors/heavermonopart/chocolate-rooted.webp'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/heavermonopart/darkchocolate-rooted.avif'), alt: 'perruque femme blond', name: 'darkchocolate rooted' },
            { url: require('../assets/colors/heavermonopart/espresso-rooted-bs.webp'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/colors/heavermonopart/hothazelnut-rooted-bs.webp'), alt: 'perruque femme blond', name: 'hothazelnut rooted' },
            { url: require('../assets/colors/heavermonopart/pastelblonde-rooted.avif'), alt: 'perruque femme blond', name: 'pastelblonde rooted' },
            { url: require('../assets/colors/heavermonopart/pearlblonde-rooted.webp'), alt: 'perruque femme blond', name: 'pearlblonde-rooted' },
            { url: require('../assets/colors/heavermonopart/salt-pepper-mix.webp'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/heavermonopart/sandyblonde-rooted.webp'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/heavermonopart/mocca-rooted.webp'), alt: 'perruque femme blond', name: 'mocca rooted' }
          ]
        },
        {
          id: 128,
          url: require('../assets/600Plus/timecomfort/TimeComfort_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Time Comfort',
          price: 600,
          gamme: 'Saphir - classe II - 600 à 700€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/600Plus/timecomfort/TimeComfort_1.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/600Plus/timecomfort/TimeComfort_2.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/600Plus/timecomfort/TimeComfort_3.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/600Plus/timecomfort/TimeComfort_4.webp'), alt: 'perruque femme cheveux brun' }
          ],
          mozaique: [
            { url: require('../assets/colors/timecomfort/chocolate-mix.webp'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/colors/timecomfort/coffeebrown-rooted.webp'), alt: 'perruque femme blond', name: 'coffeebrown rooted' },
            { url: require('../assets/colors/timecomfort/darksand-rooted.webp'), alt: 'perruque femme blond', name: 'darksand rooted' },
            { url: require('../assets/colors/timecomfort/mocca-rooted.avif'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/colors/timecomfort/pearlblonde-rooted.webp'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/colors/timecomfort/salt-pepper-mix.webp'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/colors/timecomfort/sandyblonde-rooted.avif'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/colors/timecomfort/silver-mix.webp'), alt: 'perruque femme blond', name: 'silver-mix' },
            { url: require('../assets/colors/timecomfort/stonegrey-mix.webp'), alt: 'perruque femme blond', name: 'stonegrey mix' }
          ]
        },
        {
          id: 129,
          url: require('../assets/470/vita/VitaMonoPart_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Vita Mono Part',
          price: 470,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/470/vita/VitaMonoPart_1.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/470/vita/VitaMonoPart_2.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/470/vita/VitaMonoPart_3.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/470/vita/VitaMonoPart_4.webp'), alt: 'perruque femme cheveux brun' }
          ],
          mozaique: [
            { url: require('../assets/colors/vita/chocolate-rooted.avif'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/vita/coffeebrown-rooted.avif'), alt: 'perruque femme blond', name: 'coffeebrown rooted' },
            { url: require('../assets/colors/vita/darkchocolate-mix.avif'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/vita/espresso-mix.webp'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/vita/lightbernstein-rooted.avif'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/vita/pastelblonde-rooted.avif'), alt: 'perruque femme blond', name: 'pastelblonde rooted' },
            { url: require('../assets/colors/vita/pearlblonde-rooted.avif'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/colors/vita/sahara-beige-rooted.avif'), alt: 'perruque femme blond', name: 'sahara beige rooted' }
          ]
        },
        {
          id: 129,
          url: require('../assets/470/vita/VitaMonoPart_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Vita Mono Part',
          price: 470,
          gamme: 'Amethyste - classe II - 450 à 470€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/470/vita/VitaMonoPart_1.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/470/vita/VitaMonoPart_2.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/470/vita/VitaMonoPart_3.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/470/vita/VitaMonoPart_4.webp'), alt: 'perruque femme cheveux brun' }
          ],
          mozaique: [
            { url: require('../assets/colors/vita/chocolate-rooted.avif'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/vita/coffeebrown-rooted.avif'), alt: 'perruque femme blond', name: 'coffeebrown rooted' },
            { url: require('../assets/colors/vita/darkchocolate-mix.avif'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/vita/espresso-mix.webp'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/vita/lightbernstein-rooted.avif'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/vita/pastelblonde-rooted.avif'), alt: 'perruque femme blond', name: 'pastelblonde rooted' },
            { url: require('../assets/colors/vita/pearlblonde-rooted.avif'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/colors/vita/sahara-beige-rooted.avif'), alt: 'perruque femme blond', name: 'sahara beige rooted' }
          ]
        },
        {
          id: 130,
          url: require('../assets/550/voicemonolarge/VoiceMonoLARGE_1.webp'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Voice Mono Large',
          price: 550,
          gamme: 'Rubis - classe II - 550 à 570€',
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/550/voicemonolarge/VoiceMonoLARGE_1.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/550/voicemonolarge/VoiceMonoLARGE_2.webp'), alt: 'perruque femme cheveux brun' },
            { url: require('../assets/550/voicemonolarge/VoiceMonoLARGE_3.webp'), alt: 'perruque femme cheveux brun' }
          ],
          mozaique: [
            { url: require('../assets/colors/voicemonolarge/chocolate-rooted.avif'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/colors/voicemonolarge/darkchocolate-mix.avif'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/colors/voicemonolarge/espresso-mix.avif'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/colors/voicemonolarge/lightbernstein-rooted.avif'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/colors/voicemonolarge/pearlblonde-rooted.avif'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/colors/voicemonolarge/sahara-beige-rooted.avif'), alt: 'perruque femme blond', name: 'sahara-beige rooted' },
            { url: require('../assets/colors/voicemonolarge/sandyblonde-rooted.avif'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
          ]
        }
      ]

    }
  },
  components: {
    BaseModal,
    Carousel,
    CarouselV2
  },
  mounted () {
    function toggleDropDown (el) {
      el.classList.toggle('hidden')
    }
    document.addEventListener('click', function (e) {
      const dropdown = document.querySelector('.dropDownList')
      const contDropDown = document.querySelector('.dropdownContainer')
      // la fonction toggle est utilisée que si on clique sur contDropDown
      if (e.target === contDropDown) {
        console.log(e.target)
        toggleDropDown(dropdown)
        // sinon on ajoute la classe 'hidden à dropdown
      } else {
        dropdown.classList.add('hidden')
        // console.log('toto')
      }
    })
    const parallax = document.getElementById('parallax')
    window.addEventListener('scroll', function () {
      const offset = window.scrollY
      parallax.style.backgroundPositionY = offset * 0.2 + 'px'
    })
  },
  methods: {
    handleChange (value) {
      this.selectedSex = value
    },
    selectOption (option) {
      this.selected = option
    },
    // openModal () {
    //   this.modalOpen = !this.modalOpen
    // },
    toggleModal (id) {
      if (this.isShowModal !== id) {
        this.isShowModal = true
        this.isShowModal = id
        // window.scrollTo(0, 1800)
        const body = document.querySelector('body')
        // const containerBody = document.querySelector('.container_body')
        if (this.isShowModal === id | id === this.filteredNames.id) {
          body.style.overflow = 'hidden'
          // containerBody.style.visibility = 'hidden'
          // console.log('la popup est ouverte !')
        }
      } else {
        const body = document.querySelector('body')
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
        body.style.overflow = 'visible'
      }
    },
    closePopup (id) {
      const body = document.querySelector('body')
      if (this.isShowModal !== id) {
        body.style.overflow = 'visible'
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
      }
    },
    setCurrent (id) {
      this.current = id
      // console.log(id)
    }
  },
  computed: {
    filteredNames () {
      return this.imagesAmbreBoutique.filter(item => item.name.toLowerCase().includes(this.rechercher.toLowerCase()))
    }
  }
})
</script>

<style lang="scss" scoped>
.PerruquesMedicalesFemme {
      width: 80%;
    right: 0;
    text-align: center;
    float: right;
}
.container_title_btn {
  display: flex;
  gap:20px;
}
.container_bonnets_shemas {
  width:100%;
  display: flex;
  flex-wrap:wrap;
  justify-content: center;
  align-items:center;
  gap:25px;
  margin-top:30px;
}
.desc_shema_bis {
  // background: orange;
  // border: 1px solid gray;
  position:absolute;
  bottom:20px;
}
.img_container_shema {
  width:40%;
  margin-top:10px;
}
.container_shema1 {
  color:white;
  text-align: left;
  width:46%;
  background: rgb(255, 255, 255);
  // height: 300px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  // margin: 26px auto 0;
  max-width: 550px;
  min-height: 420px;
  padding: 24px 24px 0px 24px;
  position: relative;
  @media(max-width:990px ) {
    min-height: 550px;
  }
}
.container_shema1:before, .container_shema1:after {
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}
.container_shema1:before {
  background: #ffffff;
  box-shadow: 0 0 8px rgba(0,0,0,0.2);
  left: -5px;
  top: 4px;
  transform: rotate(-2.5deg);
}
.container_shema1:after {
  background: #f6f6f6;
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  right: -3px;
  top: 1px;
  transform: rotate(1.4deg);
}
.container_shema2 {
  color:white;
  text-align: left;
  width:50%;
  background: #949393;

}
.container_img_desc_shema {
  display:flex;
  flex-direction:column;
  align-items: left;
  text-align: center;
}
#container_autre_model {
  background: rgb(228, 224, 224);
    display: flex;
    flex-wrap: wrap;
    margin-top:50px;
    justify-content: center;
}
.transition {
  span {
    h4 {
      margin: 20px;
    }
  }
}
.container_title_btn {
  h4{
    margin:20px
  }
}
.title_other_models {
  display:inline-grid;
  justify-content:center;
  align-items:center;
  list-style:none;
  text-decoration: none;
  font-family: Gloria Hallelujah;
  font-size: 12px;
  color:#fff;
  background:rgb(0 0 0 / 37%);
  cursor: pointer;
  padding: 5px;
  border-radius:2px;
  position:absolute;
  width: 100%;
  min-height: 100%;
  font-weight: 700;
  justify-content: center;
}
.containerStringImg {
  display:flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-evenly;
  @media(max-width:990px) {
    flex-direction:column;
  }
}
.desc_shema {
  width:60%;
  @media(max-width:990px) {
    width:100%;
  }
}
.containerList {
  display:flex;
  justify-content:flex-start;
  font-size: 8px;
  gap: 10px;
  align-items:center;
  margin-bottom:5px;
  @media(max-width:990px) {
    justify-content:center;
  }
}
.containerList1 {
  border-radius:20px;
  border:solid 1px gray;
  padding-left: 4px;
  padding-right: 4px;
  color:gray;
  @media(max-width:990px) {
  }
}
.title_other_models:hover {
  text-shadow:
      0 0 7px #fff,
      0 0 10px #fff,
      0 0 21px #fff,
      0 0 42px #0fa,
      0 0 82px #0fa,
      0 0 92px #0fa,
      0 0 102px #0fa,
      0 0 151px #0fa;
}
.header-image__image {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/category_primepower.webp');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
.container_intro_pmf {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: 80%;
}
.title_tab {
  margin: 0px 0px 20px 0px;
    padding-top: 10px;
}
.container_full_intro {
 width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
}
table {
  width: 20%;
  border-collapse: collapse;
  color: white;
}
td {
  color: black;
}
tr {
 background-color: #eee;
}
.entry-content thead th, .entry-content tr th {
 color: white;

}
th,
td {
  border: 1px solid white;
  padding: 10px;
}
th {
  background: #949393;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  font-family: sans-serif;
 line-height:1.7em;
}
table {
  width: 100%;
  border-collapse: collapse;
      margin-bottom: 60px;
}
th,
td {
  padding: 10px;
  text-align: left;
  border: solid 1px #ccc;
}
th {
  background-color: #000;
  color: #fff;
}
tr:nth-child(odd) {
  background-color: #eee;
}
.container_stars {
  img {
    max-width:100%;
  }
}

.PerruquesRelooking {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    margin-bottom: 48px;
}

.fixed {
  height: 100vh;
  overflow: hidden;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
.container_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.list1 {
  font-family: Montserrat;
  font-size: 13px;
}
.container_img_name, .container_img_link  {
 margin: 5px;
    padding: 0;
    display: inline-grid;
    width: 30%;
    position: relative;
    cursor: pointer;
  img {
    width: 100%;
  }
  .container_name_price {
    position: absolute;
    background: white;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    color: black;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    .name, .price {
    font-size: 15px;
    @media(min-width: 550px ) {
      font-size:17px;
    }
  }
  }
}
.transition {
  border-top: 1px solid black;
  width: 100%;
  margin: 0px;
}
.transition1 {
  border-top: 1px solid black;
  width: 100%;
  margin-bottom: 10px;
}
@media only screen and (max-width: 700px) {
  table,
  thead,
  tbody,
  tr,
  th,
  td {
    display: block;
  }
  .entry-content table:not(.variations),
  body.et-pb-preview #main-content .container table {
    border: 1px solid black;
  }
  thead {
    display: none;
  }
  td {
    padding-left: 150px !important;
    position: relative;
    margin-top: -1px;
    background: #fff;
    font-size: 10px;
    line-height: 15px;
    color: black;
  }
  td:nth-child(odd) {
    background-color: #eee;
  }

  td::before {
    padding: 10px;
    content: attr(data-label);
    position: absolute;
    top: 0;
    left: 0;
    width: 130px;
    bottom: 0;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  tr {
    margin-bottom: 1rem;
  }
}
@media (max-width: 550px) {
.header-image__image {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/category_primepower_small.jpg')
}
.PerruquesMedicalesFemme {
  width:100%;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans4.jpg')
}
.container_img_name, .container_img_link {
  width: 47%;
.name, .price {
  font-size: 15px;
}
}
.PerruquesRelooking {
  width:100%;
}
.title_other_models {
 font-size: 12px;
}
}
@media (min-width: 550px) and (max-width: 990px) {
  .title_other_models {
 font-size: 15px;
}
}
@media (max-width: 990px) {

.PerruquesMedicalesFemme {
  width:100%;
}
.PerruquesRelooking {
  width:100%;
}
.container_shema1 {
  width:100%;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg')
}
}

@media (min-width: 990px) {
  .container_img_name, .container_img_link {
  max-width: 15%;
}
.title_other_models {
 font-size: 14px;
}
}
</style>
