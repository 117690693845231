/* eslint-disable vue/no-parsing-error */
<template>
<div class="PerruquesRelooking">
  <h1>Perruque invisible personnalisable</h1>
  <div class="container_body">
          <div class="container_coupe_courte" >
            <h3>En stock</h3>
            <div class="sub_container_coupe_courte">
              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in laces" :key="item.id">
                <img :src="item.url" :alt="item.alt" :id="item.id" @click="toggleModal(item.id)">
                <div class="container_name_price">
                  <div v-if="current === item.id "  class="name">{{ item.name }}</div>
                  <!-- <div v-if="current === item.id "  class="price">{{ item.price }}€</div> -->
                </div>
                <!-- if isShowModal est associé à id de l'image clickée alors la modale et le contenu associée à cette image s'affiche -->
                <!-- @close="closeModal(item.id)" -->
                <base-modal-bis v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide"  :li="item.li" :descriptionTitle = "item.description" :totoTest = "item.toto" :totoTest2 = "item.toto2" :descriptionTitle2 = "item.description2" :nameCell = "item.name" :imageLace="item.imageLace" :imagesAmbreBoutique2="item.mozaique" @close="closePopup()" ></base-modal-bis>
              </div>
            </div>
          </div>
          <span class="transition"></span>
          <h2>Commander des full lace, front lace ou 4x4 lace 100% personnalisées</h2>
          <p>En cheveux indiens ou chinois, ces modeles sont les dernieres innovations en terme de perruques personnalisables. Très réputées dans les communautés afro pour leur aspect naturel, nous vous en proposons avec du tulle dit "Lace" transparent qui s'adapte à toute les carnations même aux plus blanches.</p>
          <h3>
            Possibilité de personnalisation
          </h3>
          <div class="container_desc_pics">
            <div class="container_description1">
              <div class="container_step">
                <h4>Etape</h4> <h4>1</h4>
              </div>
              <div style="display:flex; flex-direction: column;">
                <h4>
                  Choix du bonnet
                </h4>
                <p>
                  Suivant votre souhait ou problématique, vous aurez le choix entre 3 types de bonnets: <br><br>
                  <!-- <li> -->
                    - Full lace:<br> Tout en tulle, modèle très léger qui se colle entièrement ou sur des zones définies comme la bordure frontale ou tout le pourtour de la tête afin que vous puissiez vous attacher les cheveux en ayant un résultat plus que naturel. <br><br>
                    - Front lace:<br> Toute la bordure frontale en tulle ainsi qu'une partie du dessus de la tête afin de pouvoir coller cette bordure pour un effet très naturel les cheveux détachés. <br><br>
                    - 4x4 Lace:<br> Tulle seulement au niveau du haut de la bordure frontale ce qui permet d'avoir un maintient sans colle.<br>
                  <!-- </li> -->
                </p>
              </div>
            </div>
            <div class="container_pics">
              <div class="imgs" >
                <img class="img1" :src="pictures[0].url" alt="">
                <img class="img2" :src="pictures[1].url" alt="">
                <!-- <img class="img3" :src="pictures[2].url" alt=""> -->

              </div>
            </div>
        </div>

        <div class="container_desc_pics">
            <div class="container_description1">
              <div class="container_step">
                <h4>Etape</h4> <h4>2</h4>
              </div>
              <div style="display:flex; flex-direction: column;">
                <h4>
                  Choix de l'epaisseur des cheveux
                </h4>
                <p>
                  Vous aurez le choix entre 3 types d'épaisseur du plus fin au plus fourni, 130%, 150% ou 180%.': <br><br>
                  <!-- <li> -->
                  <!-- </li> -->
                </p>
              </div>
            </div>
            <div class="container_pics">
              <div class="imgs" >
                <img class="img1" :src="pictures2[0].url" alt="">
                <img class="img2" :src="pictures2[1].url" alt="">
                <!-- <img class="img3" :src="pictures[2].url" alt=""> -->

              </div>
            </div>
        </div>

        <div class="container_desc_pics">
            <div class="container_description1">
              <div class="container_step">
                <h4>Etape</h4> <h4>3</h4>
              </div>
              <div style="display:flex; flex-direction: column;">
                <h4>
                  Choix du type d'ondulation
                </h4>
                <p>
                  Nous vous proposerons différentes ondulations du raide, du lisse naturel, du wavy, de la boucle soutenue au très frisé.  <br><br>
                  <!-- <li> -->
                  <!-- </li> -->
                </p>
              </div>
            </div>
            <div class="container_pics">
              <div class="imgs" >
                <img class="img1" :src="pictures3[0].url" alt="">
                <img class="img2" :src="pictures3[1].url" alt="">
                <!-- <img class="img3" :src="pictures[2].url" alt=""> -->

              </div>
            </div>
        </div>

        <div class="container_desc_pics">
            <div class="container_description1">
              <div class="container_step">
                <h4>Etape</h4> <h4 >4</h4>
              </div>
              <div style="display:flex; flex-direction: column;">
                <h4>
                  Choix de la longueur des cheveux
                </h4>
                <p>
                  Le choix pourra se faire entre differentes longueurs de cheveux de 25 à 80cm  <br><br>
                  <!-- <li> -->
                  <!-- </li> -->
                </p>
              </div>
            </div>
            <div class="container_pics">
              <div class="imgs" >
                <img class="img1" :src="pictures4[0].url" alt="">
                <!-- <img class="img3" :src="pictures[2].url" alt=""> -->

              </div>
            </div>
        </div>

        <div class="container_desc_pics">
            <div class="container_description1">
              <div class="container_step">
                <h4>Etape</h4> <h4>5</h4>
              </div>
              <div style="display:flex; flex-direction: column;">
                <h4>
                  Choix de la couleur
                </h4>
                <p>
                  Nous vous proposerons un nuancier de couleurs. Sur certaines demandes spécifiques comme des ombrées ou méchées nous faisons les techniques à l'institut avec un supplément.  <br><br>
                  <!-- <li> -->
                  <!-- </li> -->
                </p>
              </div>
            </div>
            <div class="container_pics">
              <div class="imgs" >
                <img class="img1" :src="pictures[0].url" alt="">
                <img class="img2" :src="pictures[1].url" alt="">
                <!-- <img class="img3" :src="pictures[2].url" alt=""> -->

              </div>
            </div>
        </div>

        <div class="container_desc_pics">
            <div class="container_description1">
              <div class="container_step">
                <h4>Etape</h4> <h4>6</h4>
              </div>
              <div style="display:flex; flex-direction: column;">
                <h4>
                  Blanchiement des noeuds
                </h4>
                <p>
                  Afin d'obtenir un résultat très naturel nous vous proposerons en option de blanchir ou non les noeuds de la perruque. <br> En effet le resultat donne un effet naturel comme si le cheveux était implanté dans le crâne mais cette technique diminue la durée de vie de la perruque.  <br><br>
                  <!-- <li> -->
                  <!-- </li> -->
                </p>
              </div>
            </div>
            <div class="container_pics">
              <div class="imgs" >
                <img class="img1" :src="pictures[0].url" alt="">
                <img class="img2" :src="pictures[1].url" alt="">
                <!-- <img class="img3" :src="pictures[2].url" alt=""> -->

              </div>
            </div>
        </div>

        <div class="container_desc_pics">
            <div class="container_description1">
              <div class="container_step">
                <h4>Etape</h4> <h4>7</h4>
              </div>
              <div style="display:flex; flex-direction: column;">
                <h4>
                  Personnalisation de la bordure avant
                </h4>
                <p>
                  En option nous vous proposons comme prestation une personnalisation de la bordure avant. <br> A l'aide d'une pince à épiler nous reproduisons une bordure progressive très naturelle. Celle-ci diminue également la durée de vie si vous souhaitez que la wig soit collée intégralement.   <br><br>
                  <!-- <li> -->
                  <!-- </li> -->
                </p>
              </div>
            </div>
            <div class="container_pics">
              <div class="imgs" >
                <img class="img1" :src="pictures[0].url" alt="">
                <img class="img2" :src="pictures[1].url" alt="">
                <!-- <img class="img3" :src="pictures[2].url" alt=""> -->

              </div>
            </div>
        </div>
        <div class="transitionV3">
                  <div class="leftBarV3"></div>
                  <span style="width: 40%;"><h6>{{title[9]}}</h6></span>
                  <div class="rightBarV3"></div>
                </div>
                <div class="container-carousel">
                  <carousel
                    :slides="slides"
                    :loop="true"
                    @drag="onDrag"
                  >
                  </carousel>
                </div>
                <div class="containerCTAback">
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight">
                <h6 class="titleCTA">Prendre rendez-vous</h6>
                <h5 class="titleCTA">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
          </div>
  </div>
</div>
</template>

<script>
import Carousel from './vuejs3-components-carousel.vue'
// import BaseModal from '@/components/BaseModal.vue'
import BaseModalBis from '@/components/BaseModalBis.vue'
// import SliderTest from '../components/SliderTest.vue'
// import VideosItem from '../components/VideosItem.vue'
// import SliderTest from '@/components/SliderTest.vue'
// import VideoBg from 'vue-videobg'
// import BaseModal from '@/components/BaseModal.vue'
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - un vaste choix de perruques type lace',
      description: 'Perruques ultra personnalisables en cheveux naturels',
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/perruques-type-lace'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      title: ['Gamme ambre - 350€ - intégralement remboursé par la sécu', 'Gamme amethyste - 450€ à 470€ - partiellement remboursé par la sécu', 'Gamme Rubis - 550€ - Monofilament', 'Gamme Emeraude - 590€ - 100% fait main', 'Gamme Saphir - de 600€ à 630€ - Monofilament - 100% fait main', 'Gamme ambre - 350€ - intégralement remboursé par la sécu', 'Gamme amethyste - 450€ à 460€ - partiellement remboursé par la sécu', 'Gamme rubis - 550€ à 560€ - Monofilament', 'Gamme Emeraude - 590€ - 100% fait main', 'Nos catalogues '],
      test: false,
      current: false,
      isShowModal: false,
      propFixed: false,
      screen: window.screen.width,
      pictures: [
        { url: require('../assets/perruque type lace/17.jpg'), alt: 'turbans femme' },
        { url: require('../assets/perruque type lace/16.jpg'), alt: 'turbans femme' },
        { url: require('../assets/perruque type lace/16.jpg'), alt: 'turbans femme' }
      ],
      pictures2: [
        { url: require('../assets/perruque type lace/20.jpg'), alt: 'turbans femme' },
        { url: require('../assets/perruque type lace/21.jpg'), alt: 'turbans femme' }
      ],
      pictures3: [
        { url: require('../assets/perruque type lace/22.jpg'), alt: 'turbans femme' },
        { url: require('../assets/perruque type lace/23.jpg'), alt: 'turbans femme' }
      ],
      pictures4: [
        { url: require('../assets/perruque type lace/24.jpg'), alt: 'turbans femme' }
      ],
      slides: [
        {
          id: 1,
          url: require('../assets/images_perruques/perruque_medicale.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques medicales',
          price: 350,
          description: 'Remboursées par la sécurité sociale',
          to: '/perruques-medicales-femmes'
        },
        {
          id: 2,
          url: require('../assets/images_perruques/perruque_naturelle.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruque naturelles',
          price: 350,
          description: 'perruque en vrais cheveux 100% naturels Remy Hair pour une longevité maximum',
          to: '/perruques-cheveux-naturels'
        },
        {
          id: 3,
          url: require('../assets/images_perruques/perruque_prime.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques fibre mixte',
          price: 350,
          description: "L'avantage du chebveux naturel et de la fibre synthétique",
          to: '/perruques-fibre-prime'
        },
        {
          id: 4,
          url: require('../assets/images_perruques/perruque_relooking.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques petits prix',
          price: 350,
          description: 'Sans remboursement sécu pour des budgets plus modérés',
          to: '/perruques-relooking'
        },
        {
          id: 5,
          url: require('../assets/images_perruques/perruque_lace.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques type lace wig',
          price: 350,
          description: 'chevelure ultra réaliste et indetectable 100% personnalisable',
          to: '/perruques-type-lace'
        },
        {
          id: 6,
          url: require('../assets/perruque_homme/ew_hairformance_justin_4.webp'),
          alt: 'perruque homme cheveux poivre et sel coupe courte',
          name: 'Perruques homme',
          description: 'Ligne de perruques dediée aux hommes remboursées par la sécu. Coupe personnalisable.',
          to: '/perruques-homme'
        }
      ],
      laces: [
        {
          id: 1,
          url: require('../assets/perruque type lace/1.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: '4 x 4 Lace wig',
          lenth: '40 - 47 cm',
          price: 700,
          // eslint-disable-next-line quotes
          description: `Perruque sans collage, couleur retouchée sur place avec effet racine naturelle.`,
          li: [
            { el: 'Noeuds blanchis' },
            { el: 'Longueur de cheveux: 35 cm' },
            { el: 'Cheveux naturels chinois' },
            { el: 'Densité: 180%' }
          ],
          slide: [
            { url: require('../assets/perruque type lace/1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruque type lace/2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruque type lace/3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruque type lace/5.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruque type lace/7.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruque type lace/8.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruque type lace/17a.jpg'), alt: 'perruque femme blond' }
          ],
          imageLace: [
            { url: require('../assets/perruque type lace/17.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/aqua/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/postiches/colors/aqua/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/postiches/colors/aqua/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/aqua/ginger blonde.jpg'), alt: 'perruque femme blond', name: 'ginger blonde' },
            { url: require('../assets/postiches/colors/aqua/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/aqua/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/aqua/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/postiches/colors/aqua/mahagony brown.jpg'), alt: 'perruque femme blond', name: 'mahagony brown' },
            { url: require('../assets/postiches/colors/aqua/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/postiches/colors/aqua/natural blonde.jpg'), alt: 'perruque femme blond', name: 'natural blonde' },
            { url: require('../assets/postiches/colors/aqua/nut brown.jpg'), alt: 'perruque femme blond', name: 'nut brown' },
            { url: require('../assets/postiches/colors/aqua/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' }
          ]
        },
        {
          id: 2,
          url: require('../assets/perruque type lace/10.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Full Lace',
          lenth: '50 - 55 cm',
          price: 700,
          description: 'Perruque destinée à être collée intégralement ou en bordure. Couleur non retouchée. Coloris 4#.',
          li: [
            { el: 'Noeuds non blanchis' },
            { el: 'Longueur de cheveux: 35 cm' },
            { el: 'Cheveux naturels chinois' },
            { el: 'Densité: 150%' }
          ],
          slide: [
            { url: require('../assets/perruque type lace/10.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruque type lace/9.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruque type lace/11.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruque type lace/12.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruque type lace/13.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruque type lace/14.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruque type lace/15.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/perruque type lace/16a.jpg'), alt: 'perruque femme blond' }
          ],
          imageLace: [
            { url: require('../assets/perruque type lace/16.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/postiches/colors/champagne/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/postiches/colors/champagne/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/postiches/colors/champagne/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/postiches/colors/champagne/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/postiches/colors/champagne/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/postiches/colors/champagne/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/postiches/colors/champagne/mahagony brown.jpg'), alt: 'perruque femme blond', name: 'mahagony brown' },
            { url: require('../assets/postiches/colors/champagne/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/postiches/colors/champagne/natural blonde.jpg'), alt: 'perruque femme blond', name: 'natural blonde' },
            { url: require('../assets/postiches/colors/champagne/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' }
          ]
        }
      ]
    }
  },
  components: {
    // VideoBg,
    // VideosItem,
    // BaseModal,
    BaseModalBis,
    Carousel
    // SliderItem
    // SliderTest
  },
  mounted () {
  },
  methods: {
    // openModal () {
    //   this.modalOpen = !this.modalOpen
    // },
    toggleModal (id) {
      if (this.isShowModal !== id) {
        this.isShowModal = true
        this.isShowModal = id
        // window.scrollTo(0, 600)
        const body = document.querySelector('body')
        // const containerBody = document.querySelector('.container_body')
        if (this.isShowModal === id | id === this.laces.id) {
          body.style.overflow = 'hidden'
          // containerBody.style.visibility = 'hidden'
          // console.log('la popup est ouverte !')
        }
      } else {
        const body = document.querySelector('body')
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
        body.style.overflow = 'visible'
      }
    },
    closePopup (id) {
      const body = document.querySelector('body')
      if (this.isShowModal !== id) {
        body.style.overflow = 'visible'
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
      }
    },
    //   if (this.isShowModal !== id) {
    //     console.log('cono')
    //     // console.log(id)
    //     console.log(this.isShowModal)
    //   }
    // },
    setCurrent (id) {
      this.current = id
      // console.log(id)
    }
    // close () {
    //   this.isShowModal = false
    //   console.log(this.isShowModal)
    //   console.log('toto')
    // }
  }
})
</script>

<style lang="scss" scoped>
.container_step {
  // position: absolute;
  top: 0;
  left: 0;
  // position: absolute;
  display: flex;
  margin-left: 20px;
  margin-top: 20px;
  align-items: center;
  // gap: 20px;
}

.number {
  // border: 1px solid black;
  padding: 20px;
  // border-radius: 60px;
  font-weight: bold;
  font-size: 20px;
}
.container_description {
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid black;
  // border-radius: 10px ;
  padding: 20px;
  margin: 50px;
  color: black;
}
.container_description1 {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid black;
  border-radius: 10px ;
  padding: 20px;
  margin: 50px;
  color: black;
  width: 50%;
  // position: relative;
}
.container_desc_pics {
  display: flex;
  width: 100%;
  margin-top: 50px;
  justify-content: center;
}
.container_pics{
  width: 30%;
  display: flex;
  justify-content: center;
  img{
    width: 100%;
  }
  .imgs {
    width: 50%;
  }
.img1, .img3 {
  margin-left: 50px;
}
.img2 {
  margin-right: 50px;
}
}
.PerruquesRelooking {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
     float: right;
}
.sub_container_coupe_courte{
 display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    z-index: 0;
    gap: 50px;
    justify-content: center;
}
.fixed {
  height: 100vh;
  overflow: hidden;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.d369218e.jpg');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
.container_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.container_coupe_courte{
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // z-index: 0;
}
// h1 {
//   font-size: 40px;
//   margin: 10px 10px;

// }
// h2 {
//   font-size: 40px;
//   margin: 10px 10px;

// }
// h3 {
//   font-size: 40px;
//   margin: 10px 10px;

// }
// h4 {
//   font-size: 30px;
//   margin: 10px 10px;

// }
.container_img_name {
 margin: 5px;
    padding: 0;
    display: inline-grid;
    width: 30%;
    position: relative;
    cursor: pointer;
  img {
    width: 100%;
  }
  .container_name_price {
    position: absolute;
    background: white;
    bottom: -30px;
    left: 0;
    width: 100%;
    z-index: 8;
    color: black;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    .name, .price {
    font-size: 22px;
  }
  }
}
.transition {
border-top: 1px solid black;
    width: 80%;
    margin: 50px;
}
@media only screen and (max-width: 700px) {
  table,
  thead,
  tbody,
  tr,
  th,
  td {
    display: block;
  }
  .entry-content table:not(.variations),
  body.et-pb-preview #main-content .container table {
    border: 1px solid black;
  }
  thead {
    display: none;
  }
  td {
    padding-left: 150px !important;
    position: relative;
    margin-top: -1px;
    background: #fff;
    font-size: 10px;
    line-height: 15px;
    color: black;
  }
  td:nth-child(odd) {
    background-color: #eee;
  }

  td::before {
    padding: 10px;
    content: attr(data-label);
    position: absolute;
    top: 0;
    left: 0;
    width: 130px;
    bottom: 0;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  tr {
    margin-bottom: 1rem;
  }
}
@media (max-width: 550px) {
.header-image__image {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/category_primepower_small.b5af6660.jpg')
}
.PerruquesMedicalesFemme {
  width:100%;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans4.48fc48e8.jpg')
}
.container_img_name {
  width: 60%;
.name, .price {
  font-size: 15px;
}
}
.PerruquesRelooking {
  width:100%;
}
}
@media (max-width: 990px) {
.container_desc_pics {
  flex-direction: column;
}
.container_description1 {
  width:100%;
  margin:0;
}
.container_pics {
  width:100%;
}
.container_pics .imgs {
  display: flex;
  width: 30%;
  justify-content: center;
  .img1, .img2, .img3 {
    margin-left:0;
    margin-right: 0;
  }
}
.PerruquesMedicalesFemme {
  width:100%;
}
.PerruquesRelooking {
  width:100%;
}
.header-image__image1 {
  background-image: url('http://localhost:8080/img/imgTurbans3.d369218e.jpg')
}
}

@media (min-width: 990px) {
  .container_img_name {
  max-width: 15%;
}
}
</style>
