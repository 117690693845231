<template>
  <div class="PerruquesRelooking">
    <header class='header-image__image1' id="parallax">

      <div class="firstContainerWidget">
        <div class="containerLogo">
            <img class="img_logo" src="https://lhairderien-perruque.fr/lhairderien/src/assets/logo_hdr_2.webp" alt="">
          </div>
          <div class="containerCTA">
            <h1 class="titleCTA" style="margin-bottom:10px; ">Venez decouvrir notre salon</h1>
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight" style="user-select: none; pointer-events:none;">
                <h6 class="titleCTA" style=" font-weight: 800 !important;">Prendre rendez-vous</h6>
                <h5 class="titleCTA" style=" font-weight: 800 !important;">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
        </div>
      </div>

    <div class='header-image__content'>
      <div class='header-image__content__container'>
      </div>
    </div>
    </header>
    <div class="container_full_intro" style="display: flex;
      justify-content: center;
      width: 100%;">
      <div class="container_intro_pmf" style="width:80%;">
        <h1 class="title_tab"> Nos Perruques Relooking </h1>
        <p class="paragraph_intro">
      Modèles parfaits pour les plus petits budgets. Ces perruques en fibres synthétiques au bonnet intégralement conçu à la machine. C'est pourquoi elles ne sont plus remboursés depuis 2019 par la sécurité sociale, décretant qu'il faut un minimum de tulle pour entrer dans ces normes. <br> Conçues pour avoir un rendu naturel, ces chevelures d'appoints sont parfaites lors d'un relooking, d'un manque de volume, ou perte de densité et de cheveux.             </p>
      </div>
    </div>

    <div class="container_body">
      <input style="margin-top:30px; border:2px solid black; padding:5px; margin-bottom:30px;" placeholder="rechercher une perruque" type="text" v-model="rechercher">
          <div class="container_coupe_courte" >
            <div style="width:100%; background:#93dbd8 ;">
            <h2  style="font-family: Amatic sc; color: white;">Perruques non remboursées coupe courte</h2>
          </div>
            <div class="sub_container_coupe_courte">
              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in filteredNames" :key="item.id"  >
                <img :src="item.url" :alt="item.alt" :id="item.id" @click="toggleModal(item.id)">
                <div v-if="current !== item.id " class="container_name_price">
                  <div  class="name">{{ item.name }}</div>
                  <div   class="price">{{ item.price }}€</div>
                </div>
                <base-modal  v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide" :priceToto = item.price :imagesAmbreBoutique2="item.mozaique" :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal>
              </div>
              <div class="transitionV3">
                  <div class="leftBarV3"></div>
                  <span style="width: 40%;"><h6>{{title[9]}}</h6></span>
                  <div class="rightBarV3"></div>
                </div>
                <div class="container-carousel">
                  <carousel
                    :slides="slides"
                    :loop="true"
                    @drag="onDrag"
                  >
                  </carousel>
                </div>
            </div>
          </div>
          <div class="containerCTAback">
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight">
                <h6 class="titleCTA">Prendre rendez-vous</h6>
                <h5 class="titleCTA">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
          </div>
</div>
</div>

</template>
<script>
import Carousel from './vuejs3-components-carousel.vue'
import BaseModal from '@/components/BaseModal.vue'
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - notre gamme de perruques type relooking',
      titles: ['Gamme ambre - 350€ - intégralement remboursé par la sécu', 'Gamme amethyste - 450€ à 470€ - partiellement remboursé par la sécu', 'Gamme Rubis - 550€ - Monofilament', 'Gamme Emeraude - 590€ - 100% fait main', 'Gamme Saphir - de 600€ à 630€ - Monofilament - 100% fait main', 'Gamme ambre - 350€ - intégralement remboursé par la sécu', 'Gamme amethyste - 450€ à 460€ - partiellement remboursé par la sécu', 'Gamme rubis - 550€ à 560€ - Monofilament', 'Gamme Emeraude - 590€ - 100% fait main', 'Nos catalogues '],
      description: 'pérruques pas cher avec rendu naturel et de très bonne qualité',
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/perruques-relooking'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      rechercher: '',
      current: false,
      isShowModal: false,
      propFixed: false,
      title: ['Gamme ambre - 350€ - intégralement remboursé par la sécu', 'Gamme amethyste - 450€ à 470€ - partiellement remboursé par la sécu', 'Gamme Rubis - 550€ - Monofilament', 'Gamme Emeraude - 590€ - 100% fait main', 'Gamme Saphir - de 600€ à 630€ - Monofilament - 100% fait main', 'Gamme ambre - 350€ - intégralement remboursé par la sécu', 'Gamme amethyste - 450€ à 460€ - partiellement remboursé par la sécu', 'Gamme rubis - 550€ à 560€ - Monofilament', 'Gamme Emeraude - 590€ - 100% fait main', 'Nos catalogues '],
      imagest: [
        { url: require('../assets/imgTurbans1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans3.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans4.jpg'), alt: 'turbans femme' }
      ],
      slides: [
        {
          id: 1,
          url: require('../assets/images_perruques/perruque_medicale.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques medicales',
          price: 350,
          description: 'Remboursées par la sécurité sociale',
          to: '/perruques-medicales-femmes'
        },
        {
          id: 2,
          url: require('../assets/images_perruques/perruque_naturelle.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruque naturelles',
          price: 350,
          description: 'perruque en vrais cheveux 100% naturels Remy Hair pour une longevité maximum',
          to: '/perruques-cheveux-naturels'
        },
        {
          id: 3,
          url: require('../assets/images_perruques/perruque_prime.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques fibre mixte',
          price: 350,
          description: "L'avantage du chebveux naturel et de la fibre synthétique",
          to: '/perruques-fibre-prime'
        },
        {
          id: 4,
          url: require('../assets/images_perruques/perruque_relooking.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques petits prix',
          price: 350,
          description: 'Sans remboursement sécu pour des budgets plus modérés',
          to: '/perruques-relooking'
        },
        {
          id: 5,
          url: require('../assets/images_perruques/perruque_lace.webp'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Perruques type lace wig',
          price: 350,
          description: 'chevelure ultra réaliste et indetectable 100% personnalisable',
          to: '/perruques-type-lace'
        },
        {
          id: 6,
          url: require('../assets/perruque_homme/ew_hairformance_justin_4.webp'),
          alt: 'perruque homme cheveux poivre et sel coupe courte',
          name: 'Perruques homme',
          description: 'Ligne de perruques dediée aux hommes remboursées par la sécu. Coupe personnalisable.',
          to: '/perruques-homme'
        }
      ],
      imagesFibrePrime: [
        {
          id: 1,
          url: require('../assets/court/alexis_230.jpg'),
          alt: 'perruque fibre femme',
          name: 'Alexis',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 230,
          slide: [
            { url: require('../assets/court/alexis_230.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/alexis_2_230.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/alexis_3_230.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/alexis/auburn mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/court/colors/alexis/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/court/colors/alexis/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/alexis/lightespresso mix.jpg'), alt: 'perruque femme blond', name: 'lightespresso mix' },
            { url: require('../assets/court/colors/alexis/nougat rooted.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' },
            { url: require('../assets/court/colors/alexis/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/court/colors/alexis/sandmulti mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' }
          ]
        },
        {
          id: 2,
          url: require('../assets/court/apart_hi_190.jpg'),
          alt: 'perruque fibre femme',
          name: 'Apart Hi',
          description: 'MONTAGE MACHINE',
          toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 190,
          slide: [
            { url: require('../assets/court/apart_hi_190.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/apart_hi_2_190.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/apart_hi_3_190.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/apart_hi/caramel mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
            { url: require('../assets/court/colors/apart_hi/coffeebrown mix.jpg'), alt: 'perruque femme blond', name: 'coffeebrown mix' },
            { url: require('../assets/court/colors/apart_hi/darksand mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/court/colors/apart_hi/darksmoke mix.jpg'), alt: 'perruque femme blond', name: 'darksmoke mix' },
            { url: require('../assets/court/colors/apart_hi/darksnow mix.jpg'), alt: 'perruque femme blond', name: 'darksnow mix' },
            { url: require('../assets/court/colors/apart_hi/lightespresso mix.jpg'), alt: 'perruque femme blond', name: 'lightespresso mix' },
            { url: require('../assets/court/colors/apart_hi/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/court/colors/apart_hi/sandyblonde mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' },
            { url: require('../assets/court/colors/apart_hi/silver mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' },
            { url: require('../assets/court/colors/apart_hi/smoke mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
            { url: require('../assets/court/colors/apart_hi/snow mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
            { url: require('../assets/court/colors/apart_hi/white mix.jpg'), alt: 'perruque femme blond', name: 'white mix' }
          ]
        },
        // {
        //   id: 3,
        //   url: require('../assets/court/avanti_190.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Avanti',
        //   description: 'MONTAGE MACHINE',
        //   toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
        //   price: 190,
        //   slide: [
        //     { url: require('../assets/court/avanti_190.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/avanti_2_190.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/avanti_3_190.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/avanti/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
        //     { url: require('../assets/court/colors/avanti/darksand mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
        //     { url: require('../assets/court/colors/avanti/lightcaramel mix.jpg'), alt: 'perruque femme blond', name: 'lightcaramel mix' },
        //     { url: require('../assets/court/colors/avanti/lighthoney mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney mix' },
        //     { url: require('../assets/court/colors/avanti/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
        //     { url: require('../assets/court/colors/avanti/sandmulti mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' },
        //     { url: require('../assets/court/colors/avanti/sandyblonde mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' }
        //   ]
        // },
        {
          id: 4,
          url: require('../assets/court/beam_260.jpg'),
          alt: 'perruque fibre femme',
          name: 'Beam',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 260,
          slide: [
            { url: require('../assets/court/beam_260.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/beam_2_260.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/beam_3_260.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/beam/caramel rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/court/colors/beam/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/court/colors/beam/chocolate rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/court/colors/beam/espresso rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/court/colors/beam/flame mix.jpg'), alt: 'perruque femme blond', name: 'flame mix' },
            { url: require('../assets/court/colors/beam/hotchocolate rooted.jpg'), alt: 'perruque femme blond', name: 'hotchocolate rooted' },
            { url: require('../assets/court/colors/beam/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/court/colors/beam/lightchampagne rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/court/colors/beam/mocca lighted.jpg'), alt: 'perruque femme blond', name: 'mocca lighted' }
          ]
        },
        {
          id: 5,
          url: require('../assets/court/city_200.jpg'),
          alt: 'perruque fibre femme',
          name: 'City',
          description: 'MONTAGE MACHINE',
          toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 200,
          slide: [
            { url: require('../assets/court/city_200.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/city_2_200.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/city_3_200.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/city/auburn mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/court/colors/city/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/court/colors/city/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/court/colors/city/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/city/darksand mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
            { url: require('../assets/court/colors/city/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/court/colors/city/hazelnut mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/court/colors/city/lightcaramel mix.jpg'), alt: 'perruque femme blond', name: 'lightcaramel mix' },
            { url: require('../assets/court/colors/city/salt pepper mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/court/colors/city/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/court/colors/city/sandmulti mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' },
            { url: require('../assets/court/colors/city/snow mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 6,
          url: require('../assets/court/click_185.jpg'),
          alt: 'perruque fibre femme',
          name: 'Click',
          description: 'MONTAGE MACHINE',
          toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
          price: 185,
          slide: [
            { url: require('../assets/court/click_185.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/click_2_185.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/click/aubergine mix.jpg'), alt: 'perruque femme blond', name: 'aubergine mix' },
            { url: require('../assets/court/colors/click/black violet.jpg'), alt: 'perruque femme blond', name: 'black violet' },
            { url: require('../assets/court/colors/click/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/court/colors/click/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/court/colors/click/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/court/colors/click/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/click/cinnamon mix.jpg'), alt: 'perruque femme blond', name: 'cinnamon mix' },
            { url: require('../assets/court/colors/click/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/court/colors/click/darksand rooted.jpg'), alt: 'perruque femme blond', name: 'darksand rooted' },
            { url: require('../assets/court/colors/click/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/court/colors/click/fire mix.jpg'), alt: 'perruque femme blond', name: 'fire mix' },
            { url: require('../assets/court/colors/click/hotchilli mix.jpg'), alt: 'perruque femme blond', name: 'hotchilli mix' },
            { url: require('../assets/court/colors/click/hotmocca mix.jpg'), alt: 'perruque femme blond', name: 'hotmocca mix' },
            { url: require('../assets/court/colors/click/sand rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/court/colors/click/tobacco mix.jpg'), alt: 'perruque femme blond', name: 'tobacco mix' }
          ]
        },
        {
          id: 7,
          url: require('../assets/court/club_10_240.jpg'),
          alt: 'perruque fibre femme',
          name: 'Club 10',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 240,
          slide: [
            { url: require('../assets/court/club_10_240.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/club_2_10_240.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/club/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/court/colors/club/caramel rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/court/colors/club/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/court/colors/club/chocolate rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/court/colors/club/darkauburn mix.jpg'), alt: 'perruque femme blond', name: 'darkauburn mix' },
            { url: require('../assets/court/colors/club/darksand rooted.jpg'), alt: 'perruque femme blond', name: 'darksand rooted' },
            { url: require('../assets/court/colors/club/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/court/colors/club/hazelnut rooted.jpg'), alt: 'perruque femme blond', name: 'hazelnut rooted' },
            { url: require('../assets/court/colors/club/hotmocca mix.jpg'), alt: 'perruque femme blond', name: 'hotmocca mix' },
            { url: require('../assets/court/colors/club/sandmulti rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' },
            { url: require('../assets/court/colors/club/tobacco rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 8,
          url: require('../assets/court/date_240_large_245.jpg'),
          alt: 'perruque fibre femme',
          name: 'Date',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 245,
          slide: [
            { url: require('../assets/court/date_240_large_245.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/date_2_240.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/date/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
            { url: require('../assets/court/colors/date/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/court/colors/date/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/court/colors/date/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/date/darkauburn mix.jpg'), alt: 'perruque femme blond', name: 'darkauburn mix' },
            { url: require('../assets/court/colors/date/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/court/colors/date/ginger rooted.jpg'), alt: 'perruque femme blond', name: 'ginger rooted' },
            { url: require('../assets/court/colors/date/hazelnut mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/court/colors/date/hazelnut rooted.jpg'), alt: 'perruque femme blond', name: 'hazelnut rooted' },
            { url: require('../assets/court/colors/date/hotflame mix.jpg'), alt: 'perruque femme blond', name: 'hotflame mix' },
            { url: require('../assets/court/colors/date/lightbernstein mix.jpg'), alt: 'perruque femme blond', name: 'lightbernstein mix' },
            { url: require('../assets/court/colors/date/lighthoney mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney mix' },
            { url: require('../assets/court/colors/date/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' }
          ]
        },
        {
          id: 9,
          url: require('../assets/court/fair_270.jpg'),
          alt: 'perruque fibre femme',
          name: 'Fair',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 270,
          slide: [
            { url: require('../assets/court/fair_270.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/fair_2_270.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/fair_3_270.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/fair/aubergine mix.jpg'), alt: 'perruque femme blond', name: 'aubergine mix' },
            { url: require('../assets/court/colors/fair/auburn rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/court/colors/fair/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/court/colors/fair/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/fair/fair espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/court/colors/fair/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/court/colors/fair/lightchampagne rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/court/colors/fair/safranred rooted.jpg'), alt: 'perruque femme blond', name: 'safranred rooted' },
            { url: require('../assets/court/colors/fair/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/court/colors/fair/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/court/colors/fair/tobacco rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        // {
        //   id: 10,
        //   url: require('../assets/court/flex_230.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Flex',
        //   price: 230,
        //   description: 'MONTAGE MACHINE',
        //   toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
        //   description2: 'BORDURE INVISIBLE',
        //   toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
        //   slide: [
        //     { url: require('../assets/court/flex_230.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/flex_2_230.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/flex_3_230.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/flex/caramel rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
        //     { url: require('../assets/court/colors/flex/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
        //     { url: require('../assets/court/colors/flex/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
        //     { url: require('../assets/court/colors/flex/flame rooted.jpg'), alt: 'perruque femme blond', name: 'flame rooted' },
        //     { url: require('../assets/court/colors/flex/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
        //     { url: require('../assets/court/colors/flex/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
        //   ]
        // },
        {
          id: 11,
          url: require('../assets/court/foxy_280.jpg'),
          alt: 'perruque fibre femme',
          name: 'Foxy',
          description: 'MONTAGE MACHINE',
          toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 280,
          slide: [
            { url: require('../assets/court/foxy_280.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/foxy_2_280.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/foxy_3_280.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/foxy/auburn mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/court/colors/foxy/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/court/colors/foxy/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/court/colors/foxy/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/foxy/darkchocolate rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate rooted' },
            { url: require('../assets/court/colors/foxy/darksand rooted.jpg'), alt: 'perruque femme blond', name: 'darksand rooted' },
            { url: require('../assets/court/colors/foxy/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/court/colors/foxy/salt pepper mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/court/colors/foxy/sandmulti rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' },
            { url: require('../assets/court/colors/foxy/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/court/colors/foxy/snow mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
          ]
        },
        {
          id: 12,
          url: require('../assets/court/gold_250.jpg'),
          alt: 'perruque fibre femme',
          name: 'Gold',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 250,
          slide: [
            { url: require('../assets/court/gold_250.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/gold_2_250.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/gold_3_250.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/gold/auburn mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/court/colors/gold/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/court/colors/gold/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/court/colors/gold/cinnamon mix.jpg'), alt: 'perruque femme blond', name: 'cinnamon mix' },
            { url: require('../assets/court/colors/gold/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/court/colors/gold/darksnow mix.jpg'), alt: 'perruque femme blond', name: 'darksnow mix' },
            { url: require('../assets/court/colors/gold/espresso rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/court/colors/gold/nougat mix.jpg'), alt: 'perruque femme blond', name: 'nougat mix' },
            { url: require('../assets/court/colors/gold/sandmulti rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' },
            { url: require('../assets/court/colors/gold/sand rooted.jpg'), alt: 'perruque femme blond', name: 'sand rooted' },
            { url: require('../assets/court/colors/gold/smoke mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' }
          ]
        },
        // {
        //   id: 13,
        //   url: require('../assets/court/golf_180.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Golf',
        //   description: 'MONTAGE MACHINE',
        //   toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
        //   price: 180,
        //   slide: [
        //     { url: require('../assets/court/golf_180.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/golf_2_180.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/golf/aubergine mix.jpg'), alt: 'perruque femme blond', name: 'aubergine mix' },
        //     { url: require('../assets/court/colors/golf/auburn mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
        //     { url: require('../assets/court/colors/golf/black.jpg'), alt: 'perruque femme blond', name: 'black' },
        //     { url: require('../assets/court/colors/golf/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
        //     { url: require('../assets/court/colors/golf/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
        //     { url: require('../assets/court/colors/golf/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
        //     { url: require('../assets/court/colors/golf/hazelnut mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
        //     { url: require('../assets/court/colors/golf/hotchilli mix.jpg'), alt: 'perruque femme blond', name: 'hotchilli mix' },
        //     { url: require('../assets/court/colors/golf/lightbernstein mix.jpg'), alt: 'perruque femme blond', name: 'lightbernstein mix' },
        //     { url: require('../assets/court/colors/golf/pearl rooted.jpg'), alt: 'perruque femme blond', name: 'pearl rooted' },
        //     { url: require('../assets/court/colors/golf/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
        //   ]
        // },
        // {
        //   id: 14,
        //   url: require('../assets/court/jazz_280.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Jazz',
        //   description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
        //   toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
        //   price: 280,
        //   slide: [
        //     { url: require('../assets/court/jazz_280.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/jazz_2_280.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/jazz/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
        //     { url: require('../assets/court/colors/jazz/chocolate rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
        //     { url: require('../assets/court/colors/jazz/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
        //     { url: require('../assets/court/colors/jazz/hotflame mix.jpg'), alt: 'perruque femme blond', name: 'hotflame mix' },
        //     { url: require('../assets/court/colors/jazz/lightchampagne rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
        //     { url: require('../assets/court/colors/jazz/mangored rooted.jpg'), alt: 'perruque femme blond', name: 'mangored rooted' },
        //     { url: require('../assets/court/colors/jazz/pearlblonde rooted.jpg'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
        //     { url: require('../assets/court/colors/jazz/sandmulti rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' },
        //     { url: require('../assets/court/colors/jazz/silverblonde rooted.jpg'), alt: 'perruque femme blond', name: 'silverblonde rooted' }
        //   ]
        // },
        // {
        //   id: 15,
        //   url: require('../assets/court/jolly_240.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Jolly',
        //   description: 'MONTAGE MACHINE',
        //   toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
        //   description2: 'BORDURE INVISIBLE',
        //   toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
        //   price: 240,
        //   slide: [
        //     { url: require('../assets/court/jolly_240.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/jolly_2_240.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/jolly/aubergine mix.jpg'), alt: 'perruque femme blond', name: 'aubergine mix' },
        //     { url: require('../assets/court/colors/jolly/chocolate rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
        //     { url: require('../assets/court/colors/jolly/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
        //     { url: require('../assets/court/colors/jolly/darksand rooted.jpg'), alt: 'perruque femme blond', name: 'darksand rooted' },
        //     { url: require('../assets/court/colors/jolly/darksnow rooted.jpg'), alt: 'perruque femme blond', name: 'darksnow rooted' },
        //     { url: require('../assets/court/colors/jolly/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
        //     { url: require('../assets/court/colors/jolly/hotchilli mix.jpg'), alt: 'perruque femme blond', name: 'hotchilli mix' },
        //     { url: require('../assets/court/colors/jolly/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
        //     { url: require('../assets/court/colors/jolly/lightchampagne rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
        //     { url: require('../assets/court/colors/jolly/lighthoney rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
        //     { url: require('../assets/court/colors/jolly/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
        //     { url: require('../assets/court/colors/jolly/silver rooted.jpg'), alt: 'perruque femme blond', name: 'silver rooted' }
        //   ]
        // },
        {
          id: 16,
          url: require('../assets/court/keira_220.jpg'),
          alt: 'perruque fibre femme',
          name: 'Keira',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 270,
          slide: [
            { url: require('../assets/court/keira_220.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/keira_2_220.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/keira/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/court/colors/keira/black aubergine mix.jpg'), alt: 'perruque femme blond', name: 'black aubergine mix' },
            { url: require('../assets/court/colors/keira/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/court/colors/keira/chocolate rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/court/colors/keira/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/court/colors/keira/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/court/colors/keira/ginger rooted.jpg'), alt: 'perruque femme blond', name: 'ginger rooted' },
            { url: require('../assets/court/colors/keira/hotchilli rooted.jpg'), alt: 'perruque femme blond', name: 'hotchilli rooted' },
            { url: require('../assets/court/colors/keira/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/court/colors/keira/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/court/colors/keira/sandmulti rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' }
          ]
        },
        // {
        //   id: 17,
        //   url: require('../assets/court/kiss_270.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Kiss',
        //   description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
        //   toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
        //   price: 270,
        //   slide: [
        //     { url: require('../assets/court/kiss_270.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/kiss_2_270.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/kiss/chocolate rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
        //     { url: require('../assets/court/colors/kiss/hazelnut rooted.jpg'), alt: 'perruque femme blond', name: 'hazelnut rooted' },
        //     { url: require('../assets/court/colors/kiss/hotflame mix.jpg'), alt: 'perruque femme blond', name: 'hotflame mix' },
        //     { url: require('../assets/court/colors/kiss/mango mix.jpg'), alt: 'perruque femme blond', name: 'mango mix' },
        //     { url: require('../assets/court/colors/kiss/mocca lighted.jpg'), alt: 'perruque femme blond', name: 'mocca lighted' },
        //     { url: require('../assets/court/colors/kiss/pearlblonde rooted.jpg'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
        //     { url: require('../assets/court/colors/kiss/salt pepper rooted.jpg'), alt: 'perruque femme blond', name: 'salt pepper rooted' },
        //     { url: require('../assets/court/colors/kiss/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' }
        //   ]
        // },
        // {
        //   id: 18,
        //   url: require('../assets/court/lucia_210.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Lucia',
        //   description: 'MONTAGE MACHINE',
        //   toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
        //   description2: 'BORDURE INVISIBLE',
        //   toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
        //   price: 210,
        //   slide: [
        //     { url: require('../assets/court/lucia_210.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/lucia_2_210.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/lucia_3_210.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/lucia/caramel mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
        //     { url: require('../assets/court/colors/lucia/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
        //     { url: require('../assets/court/colors/lucia/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
        //     { url: require('../assets/court/colors/lucia/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
        //     { url: require('../assets/court/colors/lucia/pearl rooted.jpg'), alt: 'perruque femme blond', name: 'pearl rooted' },
        //     { url: require('../assets/court/colors/lucia/sandmulti rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' },
        //     { url: require('../assets/court/colors/lucia/smoke mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
        //     { url: require('../assets/court/colors/lucia/snow mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
        //     { url: require('../assets/court/colors/lucia/white mix.jpg'), alt: 'perruque femme blond', name: 'white mix' }
        //   ]
        // },
        {
          id: 19,
          url: require('../assets/court/nancy_190.jpg'),
          alt: 'perruque fibre femme',
          name: 'Nancy',
          description: 'MONTAGE MACHINE',
          toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
          price: 190,
          slide: [
            { url: require('../assets/court/nancy_190.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/nancy_2_190.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/nancy_3_190.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/nancy/auburn mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/court/colors/nancy/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/court/colors/nancy/lightespresso mix.jpg'), alt: 'perruque femme blond', name: 'lightespresso mix' },
            { url: require('../assets/court/colors/nancy/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
            { url: require('../assets/court/colors/nancy/nougat mix.jpg'), alt: 'perruque femme blond', name: 'nougat mix' },
            { url: require('../assets/court/colors/nancy/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/court/colors/nancy/sandyblonde mix.jpg'), alt: 'perruque femme blond', name: 'sandyblonde mix' },
            { url: require('../assets/court/colors/nancy/silver mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' },
            { url: require('../assets/court/colors/nancy/smoke mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' }
          ]
        },
        // {
        //   id: 20,
        //   url: require('../assets/court/play_240.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Play',
        //   description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
        //   toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
        //   price: 240,
        //   slide: [
        //     { url: require('../assets/court/play_240.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/play_2_240.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/play/auburn rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
        //     { url: require('../assets/court/colors/play/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
        //     { url: require('../assets/court/colors/play/cappucino softed.jpg'), alt: 'perruque femme blond', name: 'cappucino softed' },
        //     { url: require('../assets/court/colors/play/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
        //     { url: require('../assets/court/colors/play/flame mix.jpg'), alt: 'perruque femme blond', name: 'flame mix' },
        //     { url: require('../assets/court/colors/play/nougat rooted.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' },
        //     { url: require('../assets/court/colors/play/safranred rooted.jpg'), alt: 'perruque femme blond', name: 'safranred rooted' },
        //     { url: require('../assets/court/colors/play/tobacco mix.jpg'), alt: 'perruque femme blond', name: 'tobacco mix' }
        //   ]
        // },
        {
          id: 21,
          url: require('../assets/court/push_up_220.jpg'),
          alt: 'perruque fibre femme',
          name: 'Push',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 200,
          slide: [
            { url: require('../assets/court/push_up_220.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/push_up_2_220.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/push/aubergine mix.jpg'), alt: 'perruque femme blond', name: 'aubergine mix' },
            { url: require('../assets/court/colors/push/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/court/colors/push/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/court/colors/push/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/push/cognac rooted.jpg'), alt: 'perruque femme blond', name: 'cognac rooted' },
            { url: require('../assets/court/colors/push/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/court/colors/push/ginger rooted.jpg'), alt: 'perruque femme blond', name: 'ginger rooted' },
            { url: require('../assets/court/colors/push/hazelnut rooted.jpg'), alt: 'perruque femme blond', name: 'hazelnut rooted' },
            { url: require('../assets/court/colors/push/hotchilli mix.jpg'), alt: 'perruque femme blond', name: 'hotchilli mix' },
            { url: require('../assets/court/colors/push/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/court/colors/push/tobacco rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        {
          id: 22,
          url: require('../assets/court/sky_245.jpg'),
          alt: 'perruque fibre femme',
          name: 'Sky',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 245,
          slide: [
            { url: require('../assets/court/sky_245.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/sky_2_245.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/sky/auburn rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/court/colors/sky/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/court/colors/sky/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/sky/darksand rooted.jpg'), alt: 'perruque femme blond', name: 'darksand rooted' },
            { url: require('../assets/court/colors/sky/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/court/colors/sky/hotmocca rooted.jpg'), alt: 'perruque femme blond', name: 'hotmocca rooted' },
            { url: require('../assets/court/colors/sky/lighthoney rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/court/colors/sky/safranred rooted.jpg'), alt: 'perruque femme blond', name: 'safranred rooted' },
            { url: require('../assets/court/colors/sky/tobacco rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        // {
        //   id: 23,
        //   url: require('../assets/court/start_240.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Start',
        //   description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
        //   toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
        //   price: 240,
        //   slide: [
        //     { url: require('../assets/court/start_240.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/start_2_240.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/start_3_240.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/start/darkchocolate rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate rooted' },
        //     { url: require('../assets/court/colors/start/espresso rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
        //     { url: require('../assets/court/colors/start/hotflame lighted.jpg'), alt: 'perruque femme blond', name: 'hotflame lighted' }
        //   ]
        // },
        {
          id: 24,
          url: require('../assets/court/stop_hi_tec_260.jpg'),
          alt: 'perruque fibre femme',
          name: 'Stop Hi Tec',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 230,
          slide: [
            { url: require('../assets/court/stop_hi_tec_260.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/stop_hi_tec_2_260.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/stop_hi/aubergine mix.jpg'), alt: 'perruque femme blond', name: 'aubergine mix' },
            { url: require('../assets/court/colors/stop_hi/auburn rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/court/colors/stop_hi/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/court/colors/stop_hi/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/court/colors/stop_hi/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/court/colors/stop_hi/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/court/colors/stop_hi/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/stop_hi/darkauburn mix.jpg'), alt: 'perruque femme blond', name: 'darkauburn mix' },
            { url: require('../assets/court/colors/stop_hi/darksand rooted.jpg'), alt: 'perruque femme blond', name: 'darksand rooted' },
            { url: require('../assets/court/colors/stop_hi/flame mix.jpg'), alt: 'perruque femme blond', name: 'flame mix' },
            { url: require('../assets/court/colors/stop_hi/ginger rooted.jpg'), alt: 'perruque femme blond', name: 'ginger rooted' },
            { url: require('../assets/court/colors/stop_hi/hazelnut mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
            { url: require('../assets/court/colors/stop_hi/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' }
          ]
        },
        {
          id: 25,
          url: require('../assets/court/swing_280.jpg'),
          alt: 'perruque fibre femme',
          name: 'Swing',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 280,
          slide: [
            { url: require('../assets/court/swing_280.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/swing_2_280.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/swing/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/court/colors/swing/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/swing/cinnamon mix.jpg'), alt: 'perruque femme blond', name: 'cinnamon mix' },
            { url: require('../assets/court/colors/swing/darkespresso mix.jpg'), alt: 'perruque femme blond', name: 'darkespresso mix' },
            { url: require('../assets/court/colors/swing/flame mix.jpg'), alt: 'perruque femme blond', name: 'flame mix' },
            { url: require('../assets/court/colors/swing/hotmocca mix.jpg'), alt: 'perruque femme blond', name: 'hotmocca mix' },
            { url: require('../assets/court/colors/swing/lightchampagne rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/court/colors/swing/lighthoney rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/court/colors/swing/mangored rooted.jpg'), alt: 'perruque femme blond', name: 'mangored rooted' },
            { url: require('../assets/court/colors/swing/silverblonde rooted.jpg'), alt: 'perruque femme blond', name: 'silverblonde rooted' }
          ]
        },
        // {
        //   id: 26,
        //   url: require('../assets/court/take_240.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Take',
        //   description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
        //   toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
        //   price: 240,
        //   slide: [
        //     { url: require('../assets/court/take_240.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/take_2_240.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/take_3_240.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/take/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
        //     { url: require('../assets/court/colors/take/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
        //     { url: require('../assets/court/colors/take/darksand mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
        //     { url: require('../assets/court/colors/take/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
        //     { url: require('../assets/court/colors/take/sandmulti mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' },
        //     { url: require('../assets/court/colors/take/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
        //     { url: require('../assets/court/colors/take/smoke mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
        //     { url: require('../assets/court/colors/take/snow mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
        //     { url: require('../assets/court/colors/take/tobacco rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' },
        //     { url: require('../assets/court/colors/take/white mix.jpg'), alt: 'perruque femme blond', name: 'white mix' }
        //   ]
        // },
        // {
        //   id: 27,
        //   url: require('../assets/court/veronica_180.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Veronica',
        //   description: 'MONTAGE MACHINE',
        //   toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
        //   description2: 'BORDURE INVISIBLE',
        //   toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
        //   price: 180,
        //   slide: [
        //     { url: require('../assets/court/veronica_180.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/veronica_2_180.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/veronica_3_180.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/veronica/auburn mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
        //     { url: require('../assets/court/colors/veronica/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
        //     { url: require('../assets/court/colors/veronica/coffeebrown mix.jpg'), alt: 'perruque femme blond', name: 'coffeebrown mix' },
        //     { url: require('../assets/court/colors/veronica/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
        //     { url: require('../assets/court/colors/veronica/lightcaramel mix.jpg'), alt: 'perruque femme blond', name: 'lightcaramel mix' },
        //     { url: require('../assets/court/colors/veronica/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
        //     { url: require('../assets/court/colors/veronica/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
        //     { url: require('../assets/court/colors/veronica/sandmulti mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' }
        //   ]
        // },
        // {
        //   id: 28,
        //   url: require('../assets/court/viva_plus_215.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Viva Plus',
        //   description: 'MONTAGE MACHINE',
        //   toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
        //   description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
        //   toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
        //   price: 215,
        //   slide: [
        //     { url: require('../assets/court/viva_plus_215.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/viva_plus_2_215.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/viva_plus_3_215.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/viva_plus/auburn mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
        //     { url: require('../assets/court/colors/viva_plus/bernstein mix.jpg'), alt: 'perruque femme blond', name: 'bernstein mix' },
        //     { url: require('../assets/court/colors/viva_plus/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
        //     { url: require('../assets/court/colors/viva_plus/coffeebrown mix.jpg'), alt: 'perruque femme blond', name: 'coffeebrown mix' },
        //     { url: require('../assets/court/colors/viva_plus/darksand mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
        //     { url: require('../assets/court/colors/viva_plus/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
        //     { url: require('../assets/court/colors/viva_plus/nougat mix.jpg'), alt: 'perruque femme blond', name: 'nougat mix' },
        //     { url: require('../assets/court/colors/viva_plus/sandmulti mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' },
        //     { url: require('../assets/court/colors/viva_plus/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
        //     { url: require('../assets/court/colors/viva_plus/smoke mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
        //     { url: require('../assets/court/colors/viva_plus/snow mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' },
        //     { url: require('../assets/court/colors/viva_plus/stonegrey mix.jpg'), alt: 'perruque femme blond', name: 'stonegrey mix' }
        //   ]
        // },
        // {
        //   id: 29,
        //   url: require('../assets/court/you_200.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'You',
        //   description: 'MONTAGE MACHINE',
        //   toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
        //   price: 200,
        //   slide: [
        //     { url: require('../assets/court/you_200.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/you_2_200.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/you/bernstein lighted.jpg'), alt: 'perruque femme blond', name: 'bernstein lighted' },
        //     { url: require('../assets/court/colors/you/caramel lighted.jpg'), alt: 'perruque femme blond', name: 'caramel lighted' },
        //     { url: require('../assets/court/colors/you/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
        //     { url: require('../assets/court/colors/you/cognac rooted.jpg'), alt: 'perruque femme blond', name: 'cognac rooted' },
        //     { url: require('../assets/court/colors/you/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
        //     { url: require('../assets/court/colors/you/platinblonde mix.jpg'), alt: 'perruque femme blond', name: 'platinblonde mix' },
        //     { url: require('../assets/court/colors/you/sandmulti rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' }
        //   ]
        // },
        // {
        //   id: 30,
        //   url: require('../assets/court/zizi_290.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Zizi',
        //   description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
        //   toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
        //   price: 290,
        //   slide: [
        //     { url: require('../assets/court/zizi_290.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/zizi_2_290.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/zizi_3_290.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/court/colors/zizi_mono/caramel mix.jpg'), alt: 'perruque femme blond', name: 'caramel mix' },
        //     { url: require('../assets/court/colors/zizi_mono/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
        //     { url: require('../assets/court/colors/zizi_mono/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
        //     { url: require('../assets/court/colors/zizi_mono/darksand mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
        //     { url: require('../assets/court/colors/zizi_mono/darksmoke mix.jpg'), alt: 'perruque femme blond', name: 'darksmoke mix' },
        //     { url: require('../assets/court/colors/zizi_mono/lightespresso mix.jpg'), alt: 'perruque femme blond', name: 'lightespresso mix' },
        //     { url: require('../assets/court/colors/zizi_mono/mocca mix.jpg'), alt: 'perruque femme blond', name: 'mocca mix' },
        //     { url: require('../assets/court/colors/zizi_mono/pearl mix.jpg'), alt: 'perruque femme blond', name: 'pearl mix' },
        //     { url: require('../assets/court/colors/zizi_mono/pepper mix.jpg'), alt: 'perruque femme blond', name: 'pepper mix' },
        //     { url: require('../assets/court/colors/zizi_mono/sandmulti mix.jpg'), alt: 'perruque femme blond', name: 'sandmulti mix' },
        //     { url: require('../assets/court/colors/zizi_mono/silver mix.jpg'), alt: 'perruque femme blond', name: 'silver mix' },
        //     { url: require('../assets/court/colors/zizi_mono/smoke mix.jpg'), alt: 'perruque femme blond', name: 'smoke mix' },
        //     { url: require('../assets/court/colors/zizi_mono/snow mix.jpg'), alt: 'perruque femme blond', name: 'snow mix' }
        //   ]
        // },
        // {
        //   id: 31,
        //   url: require('../assets/court/hot_1_220.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Hot',
        //   price: 220,
        //   slide: [
        //     { url: require('../assets/court/hot_1_220.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/hot_2_220.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/hot_3_220.jpg'), alt: 'perruque femme blond' }
        //   ]
        // mozaique: [
        //   { url: require('../assets/court/colors/'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors/'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors/'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors/'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors/'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' }
        // ]
        // },
        // {
        //   id: 32,
        //   url: require('../assets/court/snap_210.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Snap',
        //   price: 210,
        //   slide: [
        //     { url: require('../assets/court/snap_210.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/snap_2_210.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/snap_3_210.jpg'), alt: 'perruque femme blond' }
        //   ]
        // mozaique: [
        //   { url: require('../assets/court/colors/sn'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' }
        // ]
        // },
        {
          id: 33,
          url: require('../assets/court/night_230.jpg'),
          alt: 'perruque fibre femme',
          name: 'Night',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 230,
          slide: [
            { url: require('../assets/court/night_230.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/night_2_230.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/night_3_230.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/night/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/court/colors/night/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/night/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/court/colors/night/hazelnut rooted.jpg'), alt: 'perruque femme blond', name: 'hazelnut rooted' },
            { url: require('../assets/court/colors/night/lightcaramel rooted.jpg'), alt: 'perruque femme blond', name: 'lightcaramel rooted' },
            { url: require('../assets/court/colors/night/lightchampagne rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/court/colors/night/sand mix.jpg'), alt: 'perruque femme blond', name: 'sand mix' },
            { url: require('../assets/court/colors/night/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/court/colors/night/silverblonde rooted.jpg'), alt: 'perruque femme blond', name: 'silverblonde rooted' },
            { url: require('../assets/court/colors/night/stonegrey mix.jpg'), alt: 'perruque femme blond', name: 'stonegrey mix' },
            { url: require('../assets/court/colors/night/tobacco lighted.jpg'), alt: 'perruque femme blond', name: 'tobacco lighted' }
          ]
        },
        {
          id: 34,
          url: require('../assets/court/pixie_200.jpg'),
          alt: 'perruque fibre femme',
          name: 'Pixie',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 200,
          slide: [
            { url: require('../assets/court/pixie_200.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/pixie_2_200.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/pixie_3_200.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/pixie/aubergine mix.jpg'), alt: 'perruque femme blond', name: 'aubergine mix' },
            { url: require('../assets/court/colors/pixie/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/court/colors/pixie/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/pixie/espresso rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/court/colors/pixie/hotflame rooted.jpg'), alt: 'perruque femme blond', name: 'hotflame rooted' },
            { url: require('../assets/court/colors/pixie/lightcaramel rooted.jpg'), alt: 'perruque femme blond', name: 'lightcaramel rooted' },
            { url: require('../assets/court/colors/pixie/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/court/colors/pixie/pastelblonde mix.jpg'), alt: 'perruque femme blond', name: 'pastelblonde mix' },
            { url: require('../assets/court/colors/pixie/platinblonde mix.jpg'), alt: 'perruque femme blond', name: 'platinblonde mix' },
            { url: require('../assets/court/colors/pixie/sandmulti rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' }
          ]
        },
        // {
        //   id: 35,
        //   url: require('../assets/court/spark_240.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Spark',
        //   price: 240,
        //   slide: [
        //     { url: require('../assets/court/spark_240.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/spark_2_240.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/spark_3_240.jpg'), alt: 'perruque femme blond' }
        //   ]
        // mozaique: [
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' }
        // ]
        // },
        {
          id: 36,
          url: require('../assets/court/raise_220.jpg'),
          alt: 'perruque fibre femme',
          name: 'Raise',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 220,
          slide: [
            { url: require('../assets/court/raise_220.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/raise_2_220.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/raise_3_220.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/raise/caramel rooted.jpg'), alt: 'perruque femme blond', name: 'caramel rooted' },
            { url: require('../assets/court/colors/raise/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/court/colors/raise/chocolate rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/court/colors/raise/darkcherry mix.jpg'), alt: 'perruque femme blond', name: 'darkcherry mix' },
            { url: require('../assets/court/colors/raise/darkchocolate rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate rooted' },
            { url: require('../assets/court/colors/raise/espresso rooted.jpg'), alt: 'perruque femme blond', name: 'espresso rooted' },
            { url: require('../assets/court/colors/raise/pearlblonde rooted.jpg'), alt: 'perruque femme blond', name: 'pearlblonde rooted' },
            { url: require('../assets/court/colors/raise/salt pepper mix.jpg'), alt: 'perruque femme blond', name: 'salt pepper mix' },
            { url: require('../assets/court/colors/raise/sandmulti rooted.jpg'), alt: 'perruque femme blond', name: 'sandmulti rooted' },
            { url: require('../assets/court/colors/raise/stonegrey rooted.jpg'), alt: 'perruque femme blond', name: 'stonegrey rooted' },
            { url: require('../assets/court/colors/raise/tobacco rooted.jpg'), alt: 'perruque femme blond', name: 'tobacco rooted' }
          ]
        },
        // {
        //   id: 37,
        //   url: require('../assets/court/next_230.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Next',
        //   price: 230,
        //   slide: [
        //     { url: require('../assets/court/next_230.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/next_2_230.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/court/next_3_230.jpg'), alt: 'perruque femme blond' }
        //   ]
        // mozaique: [
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'silver mix' },
        //   { url: require('../assets/court/colors'), alt: 'perruque femme blond', name: 'white mix' }
        // ]
        // },
        {
          id: 38,
          url: require('../assets/mi-long/blues_2_345.jpg'),
          alt: 'perruque fibre femme',
          name: 'Blues',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 345,
          slide: [
            { url: require('../assets/mi-long/blues_2_345.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/mi-long/blues_345.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/mi-long/colors/blues/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/mi-long/colors/blues/cosmoblack mix.jpg'), alt: 'perruque femme blond', name: 'cosmoblack mix' },
            { url: require('../assets/mi-long/colors/blues/darkcherry mix.jpg'), alt: 'perruque femme blond', name: 'darkcherry mix' },
            { url: require('../assets/mi-long/colors/blues/hotespresso mix.jpg'), alt: 'perruque femme blond', name: 'hotespresso mix' },
            { url: require('../assets/mi-long/colors/blues/hotmocca mix.jpg'), alt: 'perruque femme blond', name: 'hotmocca mix' },
            { url: require('../assets/mi-long/colors/blues/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/mi-long/colors/blues/lightchampagne rooted.jpg'), alt: 'perruque femme blond', name: 'lightchampagne rooted' },
            { url: require('../assets/mi-long/colors/blues/lighthoney rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/mi-long/colors/blues/sandyblonde rooted.jpg'), alt: 'perruque femme blond', name: 'sandyblonde rooted' },
            { url: require('../assets/mi-long/colors/blues/silverblonde rooted.jpg'), alt: 'perruque femme blond', name: 'silverblonde rooted' }
          ]
        },
        {
          id: 39,
          url: require('../assets/mi-long/jamila_plus_250.jpg'),
          alt: 'perruque fibre femme',
          name: 'Jamila Plus',
          price: 250,
          description: 'MONTAGE MACHINE',
          toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/mi-long/jamila_plus_250.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/mi-long/jamila_plus_2_250.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/mi-long/jamila_plus_3_250.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/mi-long/colors/jamila_plus/auburn rooted.jpg'), alt: 'perruque femme blond', name: 'auburn rooted' },
            { url: require('../assets/mi-long/colors/jamila_plus/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/mi-long/colors/jamila_plus/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/mi-long/colors/jamila_plus/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/mi-long/colors/jamila_plus/chocolate rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/mi-long/colors/jamila_plus/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/mi-long/colors/jamila_plus/ginger rooted.jpg'), alt: 'perruque femme blond', name: 'ginger rooted' },
            { url: require('../assets/mi-long/colors/jamila_plus/hotmocca rooted.jpg'), alt: 'perruque femme blond', name: 'hotmocca rooted' },
            { url: require('../assets/mi-long/colors/jamila_plus/lighthoney rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' }
          ]
        },
        // {
        //   id: 40,
        //   url: require('../assets/mi-long/planet_hi_230.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Planet Hi',
        //   description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
        //   toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
        //   price: 230,
        //   slide: [
        //     { url: require('../assets/mi-long/planet_hi_230.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/mi-long/planet_hi_2_230.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/mi-long/planet_hi_3_230.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/mi-long/colors/planet_hi/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
        //     { url: require('../assets/mi-long/colors/planet_hi/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
        //     { url: require('../assets/mi-long/colors/planet_hi/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
        //     { url: require('../assets/mi-long/colors/planet_hi/darkauburn mix.jpg'), alt: 'perruque femme blond', name: 'darkauburn mix' },
        //     { url: require('../assets/mi-long/colors/planet_hi/darksand mix.jpg'), alt: 'perruque femme blond', name: 'darksand mix' },
        //     { url: require('../assets/mi-long/colors/planet_hi/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
        //     { url: require('../assets/mi-long/colors/planet_hi/hazelnut mix.jpg'), alt: 'perruque femme blond', name: 'hazelnut mix' },
        //     { url: require('../assets/mi-long/colors/planet_hi/rooted.jpg'), alt: 'perruque femme blond', name: 'rooted' }
        //   ]
        // },
        {
          id: 41,
          url: require('../assets/mi-long/storyville_2_340.jpg'),
          alt: 'perruque fibre femme',
          name: 'Storyville',
          description: 'MONTAGE MACHINE',
          toto: 'Forme seyante. La chevelure est nouée à la machine sur des tresses, qui sont cousues en forme de ruban sur des bandes de coton fin extensible. La tresse s‘adapte particulièrement bien à la forme de la tête, donne de la tenue et du volume à la racine, est facile à coiffer et permet une bonne aération du cuir chevelu.',
          description2: 'BORDURE INVISIBLE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          price: 340,
          slide: [
            { url: require('../assets/mi-long/storyville_2_340.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/mi-long/storyville_340.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/mi-long/storyville_3_340.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/mi-long/colors/storyville/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/mi-long/colors/storyville/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/mi-long/colors/storyville/chocolate rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/mi-long/colors/storyville/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/mi-long/colors/storyville/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/mi-long/colors/storyville/gingerblonde rooted.jpg'), alt: 'perruque femme blond', name: 'gingerblonde rooted' },
            { url: require('../assets/mi-long/colors/storyville/hotmocca rooted.jpg'), alt: 'perruque femme blond', name: 'hotmocca rooted' }
          ]
        },
        // {
        //   id: 42,
        //   url: require('../assets/long/glow_2_340.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Glow',
        //   description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
        //   toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
        //   price: 340,
        //   slide: [
        //     { url: require('../assets/long/glow_2_340.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/long/glow_340.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/long/glow_3_340.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/long/colors/glow/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
        //     { url: require('../assets/long/colors/glow/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
        //     { url: require('../assets/long/colors/glow/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
        //     { url: require('../assets/long/colors/glow/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
        //     { url: require('../assets/long/colors/glow/hotchilli mix.jpg'), alt: 'perruque femme blond', name: 'hotchilli mix' },
        //     { url: require('../assets/long/colors/glow/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
        //     { url: require('../assets/long/colors/glow/lighthoney rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
        //     { url: require('../assets/long/colors/glow/lightmocca mix.jpg'), alt: 'perruque femme blond', name: 'lightmocca mix' },
        //     { url: require('../assets/long/colors/glow/mangored rooted.jpg'), alt: 'perruque femme blond', name: 'mangored rooted' }
        //   ]
        // },
        {
          id: 43,
          url: require('../assets/long/impress_230.jpg'),
          alt: 'perruque fibre femme',
          name: 'Impress',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 230,
          slide: [
            { url: require('../assets/long/impress_230.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/long/impress_2_230.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/long/impress_3_230.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/long/colors/impress/auburn mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
            { url: require('../assets/long/colors/impress/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/long/colors/impress/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/long/colors/impress/cinnamon rooted.jpg'), alt: 'perruque femme blond', name: 'cinnamon rooted' },
            { url: require('../assets/long/colors/impress/darkchocolate rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate rooted' },
            { url: require('../assets/long/colors/impress/ebony black.jpg'), alt: 'perruque femme blond', name: 'ebony black' },
            { url: require('../assets/long/colors/impress/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/long/colors/impress/lightbernstein rooted.jpg'), alt: 'perruque femme blond', name: 'lightbernstein rooted' },
            { url: require('../assets/long/colors/impress/mocca rooted.jpg'), alt: 'perruque femme blond', name: 'mocca rooted' },
            { url: require('../assets/long/colors/impress/pastelblonde rooted.jpg'), alt: 'perruque femme blond', name: 'pastelblonde rooted' }
          ]
        },
        {
          id: 44,
          url: require('../assets/long/naomi_280.jpg'),
          alt: 'perruque fibre femme',
          name: 'Naomi',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 280,
          slide: [
            { url: require('../assets/long/naomi_280.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/long/naomi_2_280.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/long/naomi_3_280.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/long/colors/naomi/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/long/colors/naomi/champagne mix.jpg'), alt: 'perruque femme blond', name: 'champagne mix' },
            { url: require('../assets/long/colors/naomi/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/long/colors/naomi/hotflame mix.jpg'), alt: 'perruque femme blond', name: 'hotflame mix' },
            { url: require('../assets/long/colors/naomi/iceblonde mix.jpg'), alt: 'perruque femme blond', name: 'iceblonde mix' },
            { url: require('../assets/long/colors/naomi/mangored rooted.jpg'), alt: 'perruque femme blond', name: 'mangored rooted' },
            { url: require('../assets/long/colors/naomi/nougat rooted.jpg'), alt: 'perruque femme blond', name: 'nougat rooted' }
          ]
        },
        {
          id: 45,
          url: require('../assets/long/pretty_280.jpg'),
          alt: 'perruque fibre femme',
          name: 'Pretty',
          description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
          toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
          price: 280,
          slide: [
            { url: require('../assets/long/pretty_280.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/long/pretty_2_280.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/long/pretty_3_280.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/long/colors/pretty/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/long/colors/pretty/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/long/colors/pretty/chocolate rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate rooted' },
            { url: require('../assets/long/colors/pretty/darkchocolate mix.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/long/colors/pretty/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/long/colors/pretty/hotmocca rooted.jpg'), alt: 'perruque femme blond', name: 'hotmocca rooted' },
            { url: require('../assets/long/colors/pretty/lighthoney rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' }
          ]
        },
        {
          id: 46,
          url: require('../assets/court/west.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'West',
          price: 250,
          description: 'MONOFILAMENT 100% FAIT MAIN',
          toto: 'Pour les cuirs chevelus sensibles. Les cheveux sont noués à la main sur un tulle Ultra-fin et élastique. La monture procure un confort très agréable et est ultra-légère.',
          description2: 'BORDURE INVISIBLE EXTRA-LONGUE',
          toto2: 'Des cheveux sont noués très minutieusement un par un à la main sur une micro-peau invisible au niveau du front. La frange parait ainsi très naturelle et peut-être coiffée de multiples façons (par exemple en arrière).',
          slide: [
            { url: require('../assets/court/west.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/west2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/court/west3.jpg'), alt: 'perruque femme blond' }
          ],
          mozaique: [
            { url: require('../assets/court/colors/west/west_bernstein_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/court/colors/west/west_black_aubergine_mix.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/court/colors/west/west_champagne_rooted.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/west/west_chocolate_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' },
            { url: require('../assets/court/colors/west/west_darkchocolate_mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
            { url: require('../assets/court/colors/west/west_darksand_rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/court/colors/west/west_espresso_mix.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
            { url: require('../assets/court/colors/west/west_hotchilli_rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
            { url: require('../assets/court/colors/west/west_mocca_rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
            { url: require('../assets/court/colors/west/west_sand_mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
            { url: require('../assets/court/colors/west/west_sandmulti_rooted.jpg'), alt: 'perruque femme blond', name: 'darkchocolate mix' }
          ]
        }
        // {
        //   id: 46,
        //   url: require('../assets/long/vogue_2_280.jpg'),
        //   alt: 'perruque fibre femme',
        //   name: 'Vogue',
        //   description: 'PARTIE EN MONOFILAMENT - ROND-POINT',
        //   toto: 'Ultra naturel au niveau de la raie ou du rond-point. Avec ce type de monture, la raie ou le rond-point apparaissent naturellement grâce à la transparence de la micro-peau. Chaque cheveu y est noué minutieusement un par un. Cette partie, de forme ronde ou en longueur comme la raie, varie d’une coupe à l’autre. Ainsi la chevelure apparait particulièrement naturelle à ce niveau ; Les cheveux implantés sur le monofilament donnent l’impression de prendre directement racine sur le cuir chevelu. Le reste de la monture est en grande partie composée de tresses. Les cheveux y sont cousus à la machine sur des rubans en coton extensible très fin. Ces tresses s’adaptent très bien à la forme de la tête et sont disposées de façon très aérée.',
        //   price: 280,
        //   slide: [
        //     { url: require('../assets/long/vogue_2_280.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/long/pretty_2_280.jpg'), alt: 'perruque femme blond' },
        //     { url: require('../assets/long/pretty_3_280.jpg'), alt: 'perruque femme blond' }
        //   ],
        //   mozaique: [
        //     { url: require('../assets/long/colors/vogue/auburn mix.jpg'), alt: 'perruque femme blond', name: 'auburn mix' },
        //     { url: require('../assets/long/colors/vogue/bernstein rooted.jpg'), alt: 'perruque femme blond', name: 'bernstein rooted' },
        //     { url: require('../assets/long/colors/vogue/black.jpg'), alt: 'perruque femme blond', name: 'black' },
        //     { url: require('../assets/long/colors/vogue/champagne rooted.jpg'), alt: 'perruque femme blond', name: 'champagne rooted' },
        //     { url: require('../assets/long/colors/vogue/chocolate mix.jpg'), alt: 'perruque femme blond', name: 'chocolate mix' },
        //     { url: require('../assets/long/colors/vogue/espresso mix.jpg'), alt: 'perruque femme blond', name: 'espresso mix' },
        //     { url: require('../assets/long/colors/vogue/lighthoney rooted.jpg'), alt: 'perruque femme blond', name: 'lighthoney rooted' },
        //     { url: require('../assets/long/colors/vogue/sand2tone.jpg'), alt: 'perruque femme blond', name: 'sand2tone' }
        //   ]
        // }
      ]
    }
  },
  components: {
    BaseModal,
    Carousel
  },
  mounted () {
    const parallax = document.getElementById('parallax')
    window.addEventListener('scroll', function () {
      const offset = window.scrollY
      parallax.style.backgroundPositionY = offset * 0.2 + 'px'
    })
  },
  methods: {
    // openModal () {
    //   this.modalOpen = !this.modalOpen
    // },
    toggleModal (id) {
      if (this.isShowModal !== id) {
        this.isShowModal = true
        this.isShowModal = id
        // window.scrollTo(0, 1800)
        const body = document.querySelector('body')
        // const containerBody = document.querySelector('.container_body')
        if (this.isShowModal === id | id === this.filteredNames.id) {
          body.style.overflow = 'hidden'
          // containerBody.style.visibility = 'hidden'
          // console.log('la popup est ouverte !')
        }
      } else {
        const body = document.querySelector('body')
        console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
        body.style.overflow = 'visible'
      }
    },
    closePopup (id) {
      const body = document.querySelector('body')
      if (this.isShowModal !== id) {
        body.style.overflow = 'visible'
        // console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
      }
    },
    // close (id) {
    //   if (this.isShowModal === id) {
    //     this.isShowModal = false
    //     this.isShowModal = !this.isShowModal
    //     console.log('oué !')
    //   }
    // },
    setCurrent (id) {
      this.current = id
      // console.log(id)
    }
    // close () {
    //   this.isShowModal = false
    //   console.log(this.isShowModal)
    //   console.log('toto')
    // }
  },
  computed: {
    filteredNames () {
      return this.imagesFibrePrime.filter(item => item.name.toLowerCase().includes(this.rechercher.toLowerCase()))
    }
  }
})
</script>
<style lang="scss" scoped>
.PerruquesRelooking {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    margin-bottom: 48px;
    float: right;
}
.paragraph_intro {
  margin-top: 30px;;
}

.fixed {
  height: 100vh;
  overflow: hidden;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
.container_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.sub_container_coupe_courte{
 display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}
.container_coupe_courte{
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // z-index: 0;
}
.container_img_name {
 margin: 5px;
    padding: 0;
    display: inline-grid;
    width: 30%;
    position: relative;
    cursor: pointer;
  img {
    width: 100%;
  }
  .container_name_price {
    position: absolute;
    background: white;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 8;
    color: black;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    .name, .price {
    font-size: 15px;
    @media(min-width: 550px ) {
      font-size:20px;
    }
  }
  }
}
.transition {
border-top: 1px solid black;
    width: 80%;
    margin: 25px;
}
@media (max-width: 550px) {
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans4.jpg')
}
.container_img_name {
  width: 47%;
.name {
  font-size: 18px;
}
}
.PerruquesRelooking {
  width:100%;
}
}
@media (max-width: 990px) {

.PerruquesRelooking {
  width:100%;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg')
}
}
@media (min-width: 990px) {
  .container_img_name {
  max-width: 15%;
.name {
  font-size: 30px;
}
}
}

// @media only screen and (max-width: 700px) {
//   table,
//   thead,
//   tbody,
//   tr,
//   th,
//   td {
//     display: block;
//   }
//   .entry-content table:not(.variations),
//   body.et-pb-preview #main-content .container table {
//     border: 1px solid black;
//   }
//   thead {
//     display: none;
//   }
//   td {
//     padding-left: 150px !important;
//     position: relative;
//     margin-top: -1px;
//     background: #fff;
//     font-size: 10px;
//     line-height: 15px;
//     color: black;
//   }
//   td:nth-child(odd) {
//     background-color: #eee;
//   }

//   td::before {
//     padding: 10px;
//     content: attr(data-label);
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 130px;
//     bottom: 0;
//     background-color: #000;
//     color: #fff;
//     display: flex;
//     align-items: center;
//     font-weight: bold;
//   }
//   tr {
//     margin-bottom: 1rem;
//   }
// }

</style>
