<template>
  <!-- <div class="container_popup"> -->
    <div class="div1">
      <div class="div2">
        <div class="div3" @click="$emit('close')"></div>
      </div>
      <div class="div4">
        <div class="container_button">
              <button type="button" class="button"   @click="$emit('close')">
                X
              </button>
            </div>
        <div class="div5">
          <div class="div6">
            <div class="div7">
              <div class="div8">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                </h3>
              </div>
              <div class="container_slider">
                <div class="div9">
                  <img :src="activeImage.url" alt="" >
                </div>
                <div class="container_img_slide">
                  <div class="div10" v-for="(item, index) in images" :key="index" @click="changeActiveImage(index)">
                    <!-- <img :src="item.url" alt=""> -->
                    <img :src="item.url" :alt="item.alt">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div  class="container_right">
            <div class="infos">
              <h1>{{ name }}</h1>
              <!-- <p>Prix: {{ price }}€</p> -->
              <p style="margin-top: 20px">{{ taille1 }}</p>
              <p style="margin-bottom: 20px">{{ taille2 }}</p>
              <p>{{ desc }}</p>
              <div class="container_list">
                <li v-for="item in lii" :key="item">{{ item.el }}</li>
              </div>
              <p>{{ toto1 }}</p>
              <p>{{ desc1 }}</p>
              <p>{{ toto2 }}</p>
              <div class="container_colors">
                <div class="container_image">
                  <!-- <img :src="imgLace.url" alt=""> -->
                </div>
                <!-- <div class="divColors">
                  <p>{{ activeColor.name }}</p>
                  <img :src="activeColor.url" alt="" >
                </div> -->
                <!-- <div class="container_color_slide">
                  <div class="divColor" v-for="(item, index) in colors" :key="index" @click="changeActiveColor(index)"> -->
                    <!-- <img :src="item.url" alt=""> -->
                    <!-- <img :src="item.url" :alt="item.alt">
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </div> -->
</template>

<script>
export default {
  props: [
    'li',
    'imagesAmbreBoutique1',
    'imagesAmbreBoutique2',
    'isShowModal',
    'lenth',
    'size',
    // 'current'
    'descriptionTitle',
    'descriptionTitle2',
    'priceToto',
    'totoTest',
    'totoTest2',
    'nameCell',
    'imagesBabyDoll',
    'imageLace'
  ],
  data () {
    return {
      // showModale: this.isShowModal,
      // theCurrent: this.current,
      images: this.imagesAmbreBoutique1,
      colors: this.imagesAmbreBoutique2,
      activeImage: this.imagesAmbreBoutique1[0],
      //   activeColor: this.imagesAmbreBoutique2[0],
      desc: this.descriptionTitle,
      taille1: this.size,
      taille2: this.lenth,
      desc1: this.descriptionTitle2,
      toto1: this.totoTest,
      toto2: this.totoTest2,
      name: this.nameCell,
      imgBabyDoll: this.imagesBabyDoll,
      lii: this.li,
      price: this.priceToto
      //   imgLace: this.imageLace[0]

    }
  },
  methods: {
    changeActiveImage (index) {
      this.activeImage = this.images[index]
      // console.log(this.images)
      // console.log(this.activeImage)
    },
    changeActiveColor (index) {
      if (this.images !== this.imgBabyDoll) {
        this.activeColor = this.colors[index]
        // console.log(this.colors)
        // console.log(this.activeColor)
      }
    }
    // setCurrent (id) {
    //   this.theCurrent = id
    //   console.log(id)
    // }
  }
  // methods: {
  //   closeModal () {
  //     this.isShowModal = !this.isShowModal
  //     const body = document.querySelector('body')
  //     if (!this.isShowModal) {
  //       body.style.overflow = 'inherit'
  //     }
  //   }
  // },

}
</script>

<style lang="scss" scoped>
.container_list {
  margin-top:10px;
}
.container_popup {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.div1 {
position: fixed;
    bottom: 0px;
    /* left: 0; */
    right: 10%;
    /* padding-left: 16px; */
    /* padding-right: 16px; */
    /* padding-bottom: 16px; */
    width: 60%;
    z-index: 9999;
     display: flex;
    justify-content: center;
    // align-items: center;
    // padding-top: 40px;
}

.div2 {
position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    /* left: 0px; */
    transition-property: opacity;
    width: 100%;
}

.div3 {
position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background: #252525;
}
.div4 {
z-index: 10;
background: white;
border-radius: 8px;
overflow-y: scroll;
// overflow: hidden;
// display: flex;
// flex-direction: column;
// align-items: center;
// justify-content: center;
height: 100vh;
// position: relative;
width: 100%;
}

.div5 {
 background: white;
padding-top: 16px;
padding-bottom: 16px;
padding-left:16px;
padding-right: 16px;
width: 100%;
display: flex;
// justify-content: center;
// align-items: center;
}
.div6 {
  width: 60%;
  display: flex;
}
.container_right {
  width: 40%;
  display: flex;
  height: 100vh;
  margin: 0px 20px 0px 20px;
  justify-content: flex-end;
}
.infos {
  width: 100%;
}
.container_button {
  // min-width: 5%;
  // position: absolute;
  // right: 15px;
  // margin: 20px;
  // top: 10px;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.div7 {
margin-top: 12px;
text-align: center;
width: 100%;
display: flex;
justify-content: center;

}

.div8 {
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 16px;
}
.div9 {
margin-top: 8px;
// max-width: 30%;
// width: 50%;
img {
  width:100%;
}
}
.divColors {
    width: 50%;
    display: flex;
    margin-bottom: 50px;
    flex-direction: column;
img {
  width:100%;
}
}
.divColor {
  max-width: 26%;
  display: inline-grid;
  img {
    width:100%;
  }
}
.container_colors {
  display: flex;
  width: 100%;
  margin-top: 150px;
  // margin-bottom: 50px;
  justify-content: center;
  align-items: center;

}
.div10 {
// background: gray;
// padding-left: 16px;
// padding-right: 16px;
// padding-bottom: 22px;
// padding-top: 22px;
// max-width: 10%;

img {
  width:100%;
}
}
.container_img_slide {
  width: 100%;
  display: flex;
  justify-content:flex-start;
}
.container_color_slide {
  width: 50%;
  display: flex;
  // justify-content: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-bottom: 50px;
}
.span {
margin-top: 12px;
 display:flex;
width: 100%;
border-radius: 5px;
}
.button {
justify-content: center;
    /* width: 20px; */
    /* height: 20px; */
    border-radius: 10px;
    border: 3px solid gray;
    /* padding: 16px; */
    background: white;
    text-align: base;
    color: black;
    transition: ease-in-out 0.2s;
    cursor: pointer;
    font-size: 20px;
    margin: 10px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    /* padding: 10px; */
    padding-top: 10px;
}
.button:hover {
  background: black;
  color: white;
  // border: 3px solid ;
}
@media(max-width: 990px) {
  .div1{
    left: 11%;
    width: 80%;
  }
  .container_right {
    width:95%;
  }
  .div5 {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.div6 {
  width: 95%;
}
.div10 {
  padding-right:0 !important;
  padding-left: 0 !important;
}
}
@media(max-width: 550px) {
    .div4 {
        max-width: 512px;
        width: 100%;
    }
    .div5 {
        padding: 20px;
        padding-bottom: 20px;
    }
    .div6 {
        display: flex;
        align-items: flex-start;
    }
    .div7 {
        margin-top: 0;
        margin-left: 16px;
        text-align: left;
    }
    .div10 {
        padding-right:22px;
        padding-left: 22px;
        display: flex;
        flex-direction: row-reverse;
    }
    span {
        margin-top: 0;
        width: auto;
    }
}

</style>
