<template>

  <!-- <div @drag="onDrag" style=" display:flex; width: 150%; position:absolute; justify-content:center"> -->
    <div class="container-slider" id="slider-main">
      <!-- <div class="btn_area"> -->

        <!-- </div> -->
        <div @click="click1" @mouseover="setCurrent(item.id)" class="item"  v-for="(item, index) in slides" :key="index">
          <transition name="voile-up">
          <router-link v-if="current === item.id" :to="item.to" class="voile_carousel_img"><router-link style="color: white; position: absolute; bottom:50%; text-decoration: none;"  class="title_link_catalogue" :to="item.to">{{ item.name }}</router-link>
            <div class="container-link-up">
            </div>
          </router-link>
          </transition >
          <transition name="link-up">
          <div class="title-catalogue" v-if="current !== item.id"><span class="title-span-catalogue">{{ item.name }} <div class="paragraph-desc-catalogue">{{ item.description }}</div></span></div>
        </transition>
        <router-link style="width: 100%; height: 100%;" :to="item.to">
          <img class="carousel_img" style=" transition: translate 0.2s ease; width:100%" :src="item.url">
        </router-link>
        </div>
        <div @click="click1" @mouseover="setCurrent(item.id)" class="item"  v-for="(item, index) in slides" :key="index">
          <transition name="voile-up">
          <router-link v-if="current === item.id" :to="item.to" class="voile_carousel_img"><router-link style="color: white; position: absolute; bottom:50%; text-decoration: none;"  class="title_link_catalogue" :to="item.to">{{ item.name }}</router-link>
            <div class="container-link-up">
            </div>
          </router-link>
          </transition >
          <transition name="link-up">
          <div class="title-catalogue" v-if="current !== item.id"><span class="title-span-catalogue">{{ item.name }} <div class="paragraph-desc-catalogue">{{ item.description }}</div></span></div>
        </transition>
        <router-link style="width: 100%; height: 100%;" :to="item.to">
          <img class="carousel_img" style=" transition: translate 0.2s ease; width:100%" :src="item.url">
        </router-link>
        </div>
        <div class="slider-btn" @click="prev">
          <div style="display: inline-flex;">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-badge-left-filled" width="54" height="54" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M17 6h-6a1 1 0 0 0 -.78 .375l-4 5a1 1 0 0 0 0 1.25l4 5a1 1 0 0 0 .78 .375h6l.112 -.006a1 1 0 0 0 .669 -1.619l-3.501 -4.375l3.5 -4.375a1 1 0 0 0 -.78 -1.625z" stroke-width="0" fill="currentColor"></path>
            </svg>
          </div>
        </div>
        <div class="slider-btn2" @click="next">
          <div style="display: inline-flex;">
            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-badge-right-filled" width="54" height="54" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M7 6l-.112 .006a1 1 0 0 0 -.669 1.619l3.501 4.375l-3.5 4.375a1 1 0 0 0 .78 1.625h6a1 1 0 0 0 .78 -.375l4 -5a1 1 0 0 0 0 -1.25l-4 -5a1 1 0 0 0 -.78 -.375h-6z" stroke-width="0" fill="currentColor"></path>
            </svg>
          </div>
        </div>
    </div>
    <div style="display:flex; width: 100%; margin-top:10px; margin-bottom:20px; align-items: center; justify-content: center; gap: 12px;">
      <div v-for="(item, index) in slides" :key="index">
        <div style="background-color: #d0eef5; width: 15px; height: 15px; border-radius: 8px;" ></div>
      </div>
    </div>
  <!-- </div> -->

</template>

<script>

export default {
  props: [
    'slides'

  ],
  data () {
    return {
      current: false,
      slidesCatalogue: this.slides,
      loop: true,
      currentIndex: 0
    }
  },
  methods: {
    click1 (e) {
      console.log(e.target)
    },
    setCurrent (id) {
      this.current = id
      // console.log(id)
    },
    modifySlideWidth () {
      const index = this.slides.indexOf(this.slides[this.currentIndex])
      const slide = this.slides[index]
      slide.width += 10
    },
    // onDrag (data) {
    //   if (data.direction === 'left') {
    //     console.log('left')
    //     this.slides.push(this.slides.shift())
    //   } else if (data.direction === 'right') {
    //     console.log('right')
    //     this.slides.unshift(this.slides.pop())
    //   }
    // },
    next (e) {
      const sliderMain = document.getElementById('slider-main')
      const item = sliderMain.getElementsByClassName('item')
      // sliderMain.append(item[0])
      setTimeout(sliderMain.append(item[0]), 1.0 * 1000)
      const currentIndex = this.currentIndex + 1
      if (currentIndex < this.slides.length) {
        this.currentIndex = currentIndex
      }
    },
    prev () {
      const sliderMain = document.getElementById('slider-main')
      const item = sliderMain.getElementsByClassName('item')
      sliderMain.prepend(item[this.slides.length - 1])
      let currentIndex = this.currentIndex - 1
      if (currentIndex >= 0) {
        this.currentIndex = currentIndex
        // console.log(currentIndex)
      } else if (currentIndex === 0) {
        currentIndex = this.currentIndex + 1
        // console.log(currentIndex)
      }
    },
    click (e) {
      // console.log(e.target)
    }
  },
  // mounted () {
  //   this.setCurrent()
  //   this.modifySlideWidth()
  // },
  watch: {
    currentIndex () {
      if (this.currentIndex === 4) {
        this.modifySlideWidth()
      }
    }
  }

}
</script>

<style lang="scss" scoped>

.item {
  animation: container-slider 18s linear infinite ;
  display: flex;
  justify-content: center;
  transition: translate 0.2s ease;
  height: 30%;
  position: relative;
  flex-shrink: 0;
  width: 30%;
  @media(max-width:990px) {
    width:90%;
  }
}
// .carousel_img {

// }
// .carousel_img {
//   object-fit: cover;
// }
@keyframes container-slider {
  0% {transform: translateX(0);}
  100% {transform: translateX(-625%);}
}
.btn_area {
  z-index: 998;
    /* float: right; */
    /* right: 50%; */
    /* gap: 1600%; */
    display: flex;
    position: absolute;
    min-height: 40px;
    width: 40px;
}
// carousel catalogue
.container-slider {
  width: 100%;
  // position: relative;
}
.voile-up-enter-from {
  height: 0%
}
.voile-up-enter-to {
  height: 98.5%
}
.voile-up-enter-active {
  transition: all 0.2s ease
}
.voile_carousel_img {
  width:100%;
  background-color: hsla(8,36%,68%,.55);
  position: absolute;
  bottom: 0px;
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.voile_carousel_img:hover {
  height: 100%;
}

    .container-link-up {
      z-index: 999;
      left:0;
      width: 100%;
      bottom:6px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      height: 60px;
      // background: #587880;
      transition: all 0.2s ease;
    }
    .container-link-up:hover {
  height: 100%;
  // background: none;
}
.title-catalogue {
  // background: #087d71;
  padding: 20px;
  width:100%;
  position: absolute;
  bottom:0px;
  height: 100%;
  // transition: all 0.2s ease;
  pointer-events: none;
  display: flex;
  justify-content: center;
}
.title-catalogue:hover {
  opacity:0;
  bottom:50%;
}
.title-span-catalogue {
  display: flex;
    justify-content: center;
    align-items: left;
    position: absolute;
    bottom: 5%;
    @media(max-width:800px) {
      bottom: 40px;
      bottom: 15%;
  }
  @media(max-width:550px) {
    bottom: 16px;
    bottom: 15%;
  }
    background: #ffffff;
    width: 80%;
    color: #686868;
    height: 150px;
    text-overflow: ellipsis;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    @media(max-width:550px) {
      gap:10px;
    }
    text-align: left;
    transition: all 0.2s ease;
}
.paragraph-desc-catalogue {
  font-family: Arial, Helvetica, sans-serif;
  font-size:20px !important;
  @media(max-width:990px) {
    font-size:12px !important;
    // margin-bottom:10px;
  }
}
#slider-main {
  display:flex;
  gap:20px;
  padding: 0 20px;
  transition: transform 0.2s ease;
  overflow-x: scroll;
  overflow-y: hidden;
}
.slider-btn {
  display:flex;
  align-items: center;
  background: #d0eef5;
  border-radius:50px;
  position:absolute;
  padding:10px;
  top:40%;
  left:20px;
  font-size:40px;
  font-weight: bold;
  color:white;
  z-index: 1;
  .icon-tabler-arrow-badge-left-filled {
  width: 54px;
  height: 54px;
  @media(max-width:800px) {
    width: 30px;
    height: 30px;
  }
  @media(max-width:550px) {
    width: 20px;
    height: 20px;
  }

}
}
.icon-tabler-arrow-badge-right-filled {
  width: 54px;
  height: 54px;
  @media(max-width:800px) {
    width: 30px;
    height: 30px;
  }
  @media(max-width:550px) {
    width: 20px;
    height: 20px;
  }

}
.slider-btn2 {
  display:flex;
  align-items: center;
  background: #d0eef5;
  border-radius:50px;
  position:absolute;
  padding:10px;
  top:40%;
  right:20px;
  font-size:40px;
  font-weight: bold;
  color:white;
  z-index:1;
}
::-webkit-scrollbar {
  background: none;
  width: 100px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: none;
  background: none;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: none;
  border-radius: none;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: none;
}

</style>
