<template>
  <div class="QuiSommesNous">
    <div class="page_conseils">
      <h1>Conseils pour votre perruque</h1>
      <p class="title_conseils">Comment bien positionner sa cheveulure</p>
      <div class="iframex">
      <iframe style="width:100%;" width="700" height="315" src="https://www.youtube.com/embed/uy8S1cHT5PI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <span class="transition"></span>
      <p class="title_conseils">Comment entretenir sa perruque en cheveux naturels ?</p>
      <p class="paragraphConseils">
      Les cheveux naturels peuvent être travaillés à la chaleur ce qui permet de nombreuses variations de coiffage. N‘utilisez pour l’entretien que des produits parfaitement adaptés, comme par exemple le shampoing Human Hair de la gamme Expert-Care d‘ellen-wille. Les produits habituels qui sont utilisés pour les vrais cheveux naturels ne sont pas adaptés pour les perruques.

Avec quelle fréquence doit-on laver les cheveux naturels ?

La fréquence avec laquelle on doit laver une perruque ou un complément capillaire en cheveux naturels dépend surtout de l’utilisation qui en est faite. Plus vous lavez régulièrement votre <router-link :to="link[1].to" >modèle en cheveux naturels</router-link>, plus vous augmentez sa durée de vie et son brillant soyeux naturel. Notre conseil pour un port quotidien normal est de laver votre perruque une à deux fois par semaine. Adaptez la fréquence à vos activités quotidiennes.
</p>
<div class="iframex">
  <iframe style="width:100%;" width="700" height="315" src="https://www.youtube.com/embed/byoYg8fRIpA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
<p style="margin-top:20px;" class="title_conseils">Les différentes étapes de l‘entretien des cheveux naturels.</p>
<ul class="listeConseils">
<li> Installez la perruque ou le complément en cheveux naturels sur une tête polystyrène. Vous pouvez fixer la perruque avec des épingles. Cependant il est important d‘éviter de piquer dans le monofilament ou le tulle front cinéma.</li>

<li>- Avant le lavage, brossez la chevelure avec une brosse à picots et dêmélez doucement les noeux. S’il reste un peu de produit de styling sur les cheveux, il sera automatiquement dissout par le shampoing lors du processus de nettoyage suivant.</li>
<li>- Mouillez ensuite les cheveux de la perruque ou du complément en faisant couler de l’eau tiède des racines vers les pointes. Ne mettez pas le modèle dans une bassine.</li>
<li>- Prenez une petite dose de shampoing ellen-wille pour cheveux naturels et massez doucement l’ensemble de la chevelure des racines vers les pointes. N’exercez pas de pression.</li>
<li>- Rincez abondamment le shampoing ellen-wille sous l‘eau claire, jusqu’à disparition de toute trace de shampoing.</li>
<li>- Recommencez la même opération avec le baume ellen-wille cheveux naturels. Prenez une petite dose de baume et massez à nouveau doucement la chevelure des racines vers les pointes.</li>
<li>- Rincez à nouveau abondamment la chevelure sous l’eau claire. Éponger délicatement les cheveux avec une serviette éponge. Ne frottez et n’essorez surtout pas trop fort.</li>
<li>- Tous les 4 lavages, remplacez le baume par le Intensive Recovery Mask pour cheveux naturels de ellen-wille. Ce dernier régénère et hydrate les cheveux. Laissez agir le Intensive Recorery Mask pendant 10 minutes. Rincer ensuite la chevelure avec de l’eau tiède et épongez-la délicatement.</li>
<li>- Laissez la perruque sécher à l’air de préférence sur un porte perruque ou sur une tête de soutien. Vous pouvez enfin coiffer la perruque ou le complément à votre guise.</li>
</ul>
<p style="margin-top:20px;" class="title_conseils"> Encore un conseil d‘expert</p>

<p class="paragraphConseils"> Si vous n’avez pas beaucoup de temps : vaporisez simplement le Care’n Repair d‘ellen wille sur l’ensemble de la chevelure encore humide sans rincer. Le condionneur avec Repair-Effekt hydrate les cheveux et facilite leur démêlage, sans rinçage.</p>
<span class="transition"></span>
<p class="title_conseils">Comment entretenir sa perruque en fibre synthétique ?</p>
<p class="paragraphConseils">
  Les perruques synthétiques existent aussi bien dans les <router-link :to="link[3].to">modèles de perruques relooking</router-link> que dans les <router-link :to="link[0].to">modèles de pérruques médicales</router-link>.
  Les perruques et les compléments en fibres synthétiques ont beaucoup de succès et sont très largement diffusés. La qualité actuelle de la fibre synthétique est si impressionnante qu’il est souvent difficile de la différencier au regard ou au touché du cheveu naturel. La fibre synthétique se décline dans un grand choix de coloris naturels et chatoyants. La mémoire de forme de la fibre synthétique permet à la chevelure de retrouver automatiquement sa forme initiale après le lavage. Cependant la fibre synthétique, malgré la ressemblance n’a pas les même propriétés que le cheveu naturel. Par exemple, elle ne supporte pas la chaleur et ne peut donc pas être travaillée avec un séchoir, un lisseur ou des bigoudis. La fibre synthétique nécessite des soins différents du cheveu naturel. C’est pourquoi la gamme ellen wille Expert Care propose des produits spécifiques. Les produits habituels qui sont utilisés pour les vrais cheveux naturels ne sont pas adaptés pour les perruques en fibres synthétiques.
</p>
<p class="title_conseils">Avec quelle fréquence doit-on laver les modèles en fibre synthétique ?</p>
<p class="paragraphConseils">
  La fréquence avec laquelle on doit laver une perruque ou un complément capillaire en fibre synthétique dépend surtout de l’utilisation qui en est faite. Plus vous lavez régulièrement votre modèle en fibre synthétique, plus vous augmentez sa durée de vie et son brillant soyeux naturel. Notre conseil pour un port quotidien normal est de laver votre perruque une à deux fois par semaine.
</p>
<div style="margin-bottom:20px;" class="iframex">
  <iframe style="width:100%;" width="700" height="315" src="https://www.youtube.com/embed/2mS39YIkIwo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
<p class="title_conseils">Les différentes étapes de l‘entretien de la fibre synthétique</p>
<ul class="listeConseils">
  <li>- Versez une petite quantité de shampoing ellen wille pour cheveux synthétiques dans environ un litre d’eau tiède.</li>
  <li>- Secouez préalablement le modèle avant de le poser dans le bain avec le shampoing pour cheveux synthétiques.</li>
  <li>- Laissez tremper la perruque ou le complément environ 10 minutes dans le bain avec le shampoing. La shampoing est autonettoyant. Ne frottez pas et ne tordez pas le modèle !</li>
  <li>- Après 10 minutes, rincez le modèle à l‘eau claire et tiède. Là aussi : ne frottez pas et ne tordez pas !</li>
  <li>- Afin de maintenir la structure de la fibre et de la garder souple et soyeuse, nous vous conseillons de recommencer le processus avec le baume spécial fibre synthétique d’ellen wille.</li>
  <li>- Versez une petite quantité de baume pour fibre synthétique d’ellen wille dans une bassine remplie d’eau claire et tiède et faire tremper 10 minutes afin de laisser le produit agir. Rincez ensuite la perruque ou le complément abondamment et laissez sécher à l’air libre.</li>
  <li>- Important : N’essorez jamais une perruque ou un complément dans une serviette éponge. Par contre vous pouvez la brosser délicatement avec une brosse souple et à picots. En séchant à l’air la perruque ou le complément retrouvera automatiquement sa forme initiale.</li>
</ul>
<p class="title_conseils">Un autre conseil d’expert</p>
<p class="paragraphConseils">
  Le conditionneur pour fibres synthétiques d’ellen wille offre une protection optimale et un effet soyeux durable. Après le lavage, vaporisez un peu de conditionneur sur la chevelure sèche à une distance d’environ 10cm. N‘hésitez pas à utiliser le conditionneur quotidiennement.
</p>
<span class="transition"></span>
<p class="title_conseils">Comment entretenir sa perruque en fibre prime ?</p>
<p class="paragraphConseils">
  Le Prime Hair est un mélange innovant de cheveux naturels et de fibres synthétiques résistantes à la chaleur. La combinaison permet de profiter au mieux des avantages des deux types de cheveux. Les modèles Prime Power sont légers et proposent de magnifiques coloris, peuvent être facilement stylés et recoiffés en utilisant la chaleur. La fibre Prime est utilisée pour les perruques et les compléments capillaires de la collection Prime Power. La fibre Prime a une durée de vie plus longue, coûte moins cher à l’achat qu’un modèle 100% cheveux naturels et les modèles restent parfaitement coiffés grâce à leur mémoire de forme. Il faut utiliser tous les produits pour cheveux naturels de la gamme ellen wille Expert Care pour le perruques et les compléments en fibre Prime – comme par exemple le shampoing, le baume et le soin Care’n Repair. Les produits pour véritables cheveux naturels ne sont pas adaptés.
</p>
<p class="title_conseils">Avec quelle fréquence doit-on laver les modèles en fibre Prime ?</p>
<p class="paragraphConseils">
  La fréquence avec laquelle on doit laver une perruque ou un complément capillaire en fibre Prime dépend surtout de l’utilisation qui en est faite. Plus vous lavez régulièrement votre <router-link :to="link[2].to">modèle en fibre Prime</router-link>, plus vous augmentez sa durée de vie et son brillant soyeux naturel. Pour le choix des produits à utiliser, il faut privilégier le soin à apporter à la partie en cheveux naturels contenue dans la fibre Prime. Il faut donc utiliser les produits de la gamme ellen wille Expert Care pour cheveux naturels pour la fibre Prime. Notre conseil de fréquence d’entretien est donc la même que pour les cheveux naturels : pour un port quotidien normal lavez votre perruque une à deux fois par semaine.
</p>
<div style="margin-bottom:50px;" class="iframex">
<iframe style="width:100%;" width="700" height="315" src="https://www.youtube.com/embed/byoYg8fRIpA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>
<p class="title_conseils">Les différentes étapes de l‘entretien de la fibre Prime</p>
<ul class="listeConseils">
  <li>- Installez la perruque ou le complément en fibre Prime sur une tête polystyrène. Vous pouvez fixer la perruque avec des épingles. Cependant il est important d‘éviter de piquer dans le monofilament ou le tulle front cinéma.</li>
  <li>- Avant le lavage, brossez la chevelure avec une brosse à picots et dêmélez doucement les noeux. S’il reste un peu de produit de styling sur les cheveux, il sera automatiquement dissout par le shampoing lors du processus de nettoyage suivant.</li>
  <li>- Mouillez ensuite les cheveux de la perruque ou du complément en faisant couler de l’eau tiède des racines vers les pointes. Ne mettez pas le modèle dans une bassine.</li>
  <li>- Prenez une petite dose de shampoing ellen-wille pour cheveux naturels et massez doucement l’ensemble de la chevelure des racines vers les pointes. N’exercez pas de pression.</li>
  <li>- Rincez abondamment le shampoing ellen-wille sous l‘eau claire, jusqu’à disparition de toute trace de shampoing.</li>
  <li>- Recommencez la même opération avec le baume après shampoing ellen-wille cheveux naturels. Prenez une petite dose de baume et massez à nouveau doucement la chevelure des racines vers les pointes.</li>
  <li>- Rincez à nouveau abondamment la chevelure sous l’eau claire. Épongez délicatement les cheveux avec une serviette éponge. Ne frottez et n’essorez surtout pas trop fort.</li>
  <li>- Tous les 4 lavages, remplacez le baume par le Intensive Recovery Mask pour cheveux naturels de ellen-wille. Ce dernier régénère et hydrate les cheveux. Laissez agir le Intensive Recovery Mask pendant 10 minutes. Rincer ensuite la chevelure avec de l’eau tiède et épongez-la délicatement.</li>
</ul>
<p class="title_conseils">Encore un conseil d’expert</p>
<p class="paragraphConseils">
  Le produit Hair-typ-liquid est un soin pour les pointes, qui forme un gaine légère et les protège contre les frottements, les fourches et les agressions. Versez une petite quantité de produit sur vos mains et massez délicatement les pointes avec. Le Hair-Typ-Liquid d‘ellen wille est par ailleurs adapté pour tous les types de chevelures.
</p>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - Conseils perruque de tout les types confondus',
      description: 'Les cheveux naturels peuvent être travaillés à la chaleur ce qui permet de nombreuses variations de coiffage',
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/conseils-perruque-femme'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      link: [
        {
          to: '/perruques-medicales-femmes'
        },
        {
          to: '/perruques-cheveux-naturels'
        },
        {
          to: '/perruques-fibre-prime'
        },
        {
          to: '/perruques-relooking'
        },
        {
          to: '/perruques-type-lace'
        }
      ]
    }
  }
})
</script>

<style lang="scss" scoped>
.QuiSommesNous {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    float: right;
    background: rgb(212, 212, 212);
    display: flex;
    justify-content: center;
}
.page_conseils {
  background: white;
  width: 80%;
  box-shadow: 0 0px 5em #6e6d6d;
  flex-direction: column;
  display: flex;
  align-items: center;
  @media(max-width:990px) {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.iframex {
  width:80%;
}
@media(max-width:550px) {
.iframex{
  width:98%;
}
}
@media(max-width: 990px) {
  .QuiSommesNous {
    width:100%;
  }
}
</style>
