<template>
<transition name='expand'
@enter="enter"
@after-enter="afterEnter"
@leave="leave">

    <ul v-show="list.open" class="list-item" >
        <!-- <li class="sub-items" v-for="(item, i) in list.sublist" :key="i" > -->
          <router-link class="sub-items a" v-for="(item, i) in list.sublist" :key="i" :to="item.to" >
            {{ item.name }}
          </router-link>
        <!-- </li> -->
    </ul>
</transition>
</template>

<script>
export default {
  props: [
    'list'
  ],
  data: () => {
    return {
      mobileView: true,
      hideNav: false
    }
  },
  methods: {
    showMenu1 () {
      // const btn1 = document.getElementsByClassName('.bar')
      // if (btn1 === false) {
      this.hideNav = !this.hideNav
      // btn1.addEventListener('click', () => {
      //   console.log('hello')
      // })
      // if (btn1) {
      //   console.log("c'est bon tu peux te fermer")
      // } else console.log('loupé !')

      // }
      // if (this.hideNav) {
      //   this.showSidebar = !this.showSideBar
      //   const backgroundNav = document.createElement('div')
      //   backgroundNav.setAttribute('id', 'backgroundNav')
      //   backgroundNav.style.cssText = ' right: 0; width: 10%; position: fixed; height: 100vh; background: #0000009e; z-index: 9999;'
      //   backgroundNav.onclick = function () {
      //     if (!this.hideNav) {
      //       this.hideNav = false
      //       console.log(this.hideNav)
      //     }
      //   }
      //   const currentDiv = document.getElementById('burger')
      //   const quelpn = currentDiv.parentNode
      //   quelpn.insertBefore(backgroundNav, currentDiv)
      //   // console.log(quelpn)
      // } else if (!this.hideNav) {
      //   const elToRemove = document.getElementById('backgroundNav')
      //   elToRemove.remove()
      // }
    },
    enter (e) {
      e.style.height = 'auto'
      const height = getComputedStyle(e).height
      e.style.height = 0
      getComputedStyle(e)
      setTimeout(() => {
        e.style.height = height
      })
    },
    afterEnter (e) {
      e.style.height = 'auto'
    },
    leave (e) {
      e.style.height = getComputedStyle(e).height
      getComputedStyle(e)
      setTimeout(() => {
        e.style.height = 0
      })
    }
  }

}
</script>

<style lang="scss" scoped>
  /*
    CSS definitions for the sublist
  */
  .list-item {
    list-style: none;
    .sub-items {
      padding: 10px;
      text-indent: 20px;
      color: #ffffff;
      background-color: #333;
      border-top: 1px solid #222;
      &:hover {
        color: #333;
        background-color: #d0eef5;
      }
    }
  }
  .sub-items {
    display: flex;
    text-decoration: none;
    color:white;
    text-transform: uppercase;
    font-size: 8px;
    @media(min-width:2300px) {
          font-size: 13px;
         }
    font-family: Montserrat;
  }
  @media (max-width: 990px) {
    .sub-items {
      font-size: 12px;
    }
  }
  .sub-items:hover {
    color:rgb(0, 0, 0);
    font-weight: bold;
    .a {
      color:#000000;
    }
  }
  /*
    The CSS classes for the opening and closing animation.
  */
//   .expand-enter-active, .expand-leave-active {
//     transition: height .5s ease-in-out;
//     overflow: hidden;
//   }

.expand-enter-active, .expand-leave-active {
    transition: height .5s ease-in-out;
    overflow:hidden;
}
</style>
