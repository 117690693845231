import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ComplementsCapillairesH from '../views/ComplementsCapillairesH.vue'
import ConseilsH from '../views/ConseilsH.vue'
import PerruquesH from '../views/PerruquesH.vue'
import ComplementsCapillairesFemme from '../views/ComplementsCapillairesFemme.vue'
import ConseilsComplementFemme from '../views/ConseilsComplementFemme.vue'
import PerruquesMedicalesFemmes from '../views/PerruquesMedicalesFemmes.vue'
import PerruquesCheveuxNaturels from '../views/PerruquesCheveuxNaturels.vue'
import PerruquesFibrePrime from '../views/PerruquesFibrePrime.vue'
import PerruquesRelooking from '../views/PerruquesRelooking.vue'
import PerruquesTypeLace from '../views/PerruquesTypeLace.vue'
import ConseilsPerruque from '../views/ConseilsPerruque.vue'
import BonnetsEtTurbans from '../views/BonnetsEtTurbans.vue'
import FrangesEtCouronnes from '../views/FrangesEtCouronnes.vue'
import ConseilsBonnetsEtFranges from '../views/ConseilsBonnetsEtFranges.vue'
import VolumaTeurs from '../views/VolumaTeurs.vue'
import PosTiches from '../views/PosTiches.vue'
import CausesChuteDeCheveux from '../views/CausesChuteDeCheveux.vue'
import SalOn from '../views/SalOn.vue'
import ReflexologieMassageShiatsu from '../views/ReflexologieMassageShiatsu.vue'
import QuiSommesNous from '../views/QuiSommesNous.vue'
import SiteMap from '../views/SiteMap.vue'
import GestionCookies from '../views/GestionDesCookies.vue'
import PolitiqueDeConfidentialite from '../views/PolitiqueDeConfidentialite.vue'
import PerruquesItem from '../views/PerruquesItem.vue'
import ComplementCapillaireItem from '../views/ComplementCapillaireItem.vue'
import NotFound from '../views/NotFound.vue'
import VueMeta from 'vue-meta'
import Cgu from '../views/CguItem.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    title: 'lhairderien - Institut capillaire specialisé dans la lutte contre la perte de cheveux',
    component: HomeView
  },
  // {
  //   path: '/perruques',
  //   name: 'perruques',
  //   title: 'lhairderien - Institut capillaire specialisé dans la lutte contre la perte de cheveux',
  //   component: HomeView
  // },
  {
    path: '/cgu',
    name: 'Cgu',
    title: 'lhairderien - cgu',
    component: Cgu
  },
  {
    path: '/complements-capillaires-homme',
    name: 'Compléments Capillaires Homme',
    title: 'lhairderien - nos complements capillaires pour hommes',
    component: ComplementsCapillairesH
  },
  {
    path: '/conseils-complements-capillaires-homme',
    name: 'Conseils',
    title: 'lhairderien - conseils specifiques pour les hommes',
    component: ConseilsH
  },
  {
    path: '/perruques-homme',
    name: 'Perruques Homme',
    title: 'lhairderien - un large choix de Perruques pour hommes',
    component: PerruquesH
  },
  {
    path: '/complements-capillaires-femme',
    name: 'Complements Capillaires',
    title: 'lhairderien - nos complements capillaires pour femmes',
    component: ComplementsCapillairesFemme
  },
  {
    path: '/conseils-complements-femme',
    name: 'Conseils Complement Femme',
    title: 'lhairderien - nos conseils complements capillaires pour femmes',
    component: ConseilsComplementFemme
  },
  {
    path: '/perruques-medicales-femmes',
    name: 'Perruques Médicales',
    title: 'lhairderien - Un large choix d perruques medicales pour femmes',
    component: PerruquesMedicalesFemmes
  },
  {
    path: '/perruques-cheveux-naturels',
    name: 'Perruques Cheveux Naturels',
    title: 'lhairderien - une varieté de perruques à base de cheveux naturels',
    component: PerruquesCheveuxNaturels
  },
  {
    path: '/perruques-fibre-prime',
    name: 'Perruques Fibre Prime',
    title: 'lhairderien - un large choix de perruque type fibre prime',
    component: PerruquesFibrePrime
  },
  {
    path: '/perruques-relooking',
    name: 'Perruques Relooking',
    title: 'lhairderien - notre gamme de perruques type relooking',
    component: PerruquesRelooking
  },
  {
    path: '/perruques-type-lace',
    name: 'Perruques Type Lace',
    title: 'lhairderien - un vaste choix de perruques type lace',
    component: PerruquesTypeLace
  },
  {
    path: '/conseils-perruque-femme',
    name: 'Conseils Perruque',
    title: 'lhairderien - Conseils perruque de tout les types confondus',
    component: ConseilsPerruque
  },
  {
    path: '/bonnets-turbans',
    name: 'Bonnets Et Turbans',
    title: 'lhairderien - bonnets et turbans avec un large choix de coloris',
    component: BonnetsEtTurbans
  },
  {
    path: '/franges-couronnes',
    name: 'Franges Et Couronnes',
    title: 'lhairderien - un large choix de franges et de couronnes',
    component: FrangesEtCouronnes
  },
  {
    path: '/conseils-bonnets-franges',
    name: 'Conseils Bonnets Et Franges',
    title: 'lhairderien - Conseils bonnets et franges',
    component: ConseilsBonnetsEtFranges
  },
  {
    path: '/volumateurs',
    name: 'Volumateurs',
    title: 'lhairderien - un large choix de volumateurs adaptés à chaque situation',
    component: VolumaTeurs
  },
  {
    path: '/postiches',
    name: 'Postiches',
    title: 'lhairderien - un vaste choix de postiches',
    component: PosTiches
  },
  {
    path: '/causes-chute-de-cheveux',
    name: 'Causes chute de cheveux',
    title: 'lhairderien - les principales causes des chutes de cheveux',
    component: CausesChuteDeCheveux
  },
  {
    path: '/salon',
    name: 'Salon',
    title: "lhairderien - l'histoire d'un institut crée par une equipe de passionnées",
    component: SalOn
  },
  {
    path: '/site-map',
    name: 'Map',
    title: '',
    component: SiteMap
  },
  {
    path: '/reflexologie-massage-shiatsu',
    name: 'Reflexologie Massage Shiatsu',
    title: '',
    component: ReflexologieMassageShiatsu
  },
  {
    path: '/qui-sommes-nous',
    name: 'Qui Sommes Nous',
    title: 'lhairderien - notre philosophie et nos valeurs',
    component: QuiSommesNous
  },
  {
    path: '/gestion-cookies',
    name: 'Gestion des cookies',
    title: '',
    component: GestionCookies
  },
  {
    path: '/politique-de-confidentialite',
    name: 'Politique de confidentialité',
    title: '',
    component: PolitiqueDeConfidentialite
  },
  {
    path: '/perruques',
    name: 'PerruquesItem',
    title: 'Perruques',
    component: PerruquesItem
  },
  {
    path: '/complements-capillaires',
    name: 'ComplementCapillaireItem',
    title: 'Complements capillaires',
    component: ComplementCapillaireItem
  },
  {
    path: '/cgu',
    name: 'CGU',
    title: '',
    // route level code-splitting
    // this generates a separate chunk (cgu.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Cgu" */ '../views/CguItem.vue')
  },
  {
    path: '/:pathMatch(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: '404 Not Found'
    }
  }
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  VueMeta,
  linkActiveClass: 'lhairderien-active-link',
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return window.scrollTo({
        top: document.querySelector(to.hash).offsetTop,
        behavior: 'smooth'
      })
    } else {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ left: 0, top: 0 })
        }, 500)
      })
    }
  }
//  window.scroll({
//   top: 0
//  })
})
export default router
