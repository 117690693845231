<template>
  <div class="GestionCookies">
    <h1>POLITIQUE DE CONFIDENTIALITÉ</h1>
    <div class="containerPageCookies">
    <div class="containerCookies">
    <p>

La politique de protection de confidentialité est à jour au 2022-08-07. <br><br>

La politique de confidentialité a pour but de détailler le traitement des données des utilisateurs qui utilisent les services de L'hair de rien. Elle permet de vous décrire comment L'hair de rien utilise et protège vos données personnelles.<br><br>

L’utilisateur qui utilise le site accepte la présente politique de confidentialité. <br><br>

Quel genre de données collectons-nous ? <br><br>

Pour les besoins du site et de la société, nous récoltons les informations suivantes : <br>
Coordonnées (Nom, Prénom, adresse). <br><br>

Est-ce que nous utilisons des cookies ? <br><br>

Notre société utilise des cookies qui enregistrent certaines informations, stockées dans la mémoire de leur disque dur. Les cookies permettent d’améliorer l’accès à notre site et d’identifier les visiteurs réguliers. <br><br>

Nous diffusons un message d’alerte (bandeau), demandant à chaque personne visitant le site s’il souhaite accepter les cookies. Ils ne contiennent aucune information confidentielle concernant les utilisateurs du site. <br><br>

Le bandeau informera l’utilisateur : <br>
Sur les finalités précises des cookies utilisés ; <br>
Sur la possibilité de s’opposer à ces cookies et de changer les paramètres en cliquant directement sur un lien ; <br>
Sur le fait que la poursuite de la navigation vaut accord au dépôt de cookies sur son terminal. <br><br>

Le dépôt et la lecture des cookies ne seront pas effectués si l’utilisateur refuse le dépôt des cookies. <br><br>

Qui collecte vos données ? <br><br>

Le site internet L'hair de rien collecte vos données personnelles. <br>
Mais aussi : <br>
Autres (uniquement avec notre consentement) <br><br>

Nous recueillons des informations au moment de votre inscription sur notre site, lorsque vous vous connectez à votre compte, faites un achat, participez à un concours, ainsi que lorsque vous vous déconnectez. Les informations recueillies sont celles que vous avez entrées. Elles incluent votre nom, votre adresse e-mail, votre numéro de téléphone et votre carte de crédit. <br><br>

Nous informons l’utilisateur que nous recevons et enregistrons automatiquement des informations à partir de son ordinateur et navigateur. <br><br>

À quelle fin sont collectées mes données ? <br><br>

Le site, la société et toutes les autres personnes concernées collectent vos données dans le but de : <br>
Respecter la loi etpour les données statistiques. <br><br>

Ainsi, toutes les informations que nous recueillons auprès de vous peuvent être utilisées pour : <br>
Personnaliser votre expérience et améliorer le service client en répondant mieux à vos besoins ; <br>
Afficher un contenu publicitaire ciblé ; <br>
Pouvoir vous contacter ; <br>
Pouvoir répondre aux sollicitations des autorités judiciaires ; <br><br>

Combien de temps vos données sont-elles conservées ? <br><br>

Le site et la société conservent vos données pour deux années. <br><br>

Les données récoltées sur moi sont-elles vendues ou divulguées à des tiers ? <br><br>

La société est seule propriétaire des informations collectées sur ce site. Les informations ainsi recueillies ne seront pas vendues à une autre société sans votre consentement, en dehors de ce qui est nécessaire pour répondre à une demande ou une transaction. <br><br>

Les informations personnelles identifiables ne seront pas transférées à des tiers, à l’exception des tiers parties qui permettent à la société d’exploiter ce site, ou mener à bien ses affaires. Les tiers parties s’engagent à garder ces informations confidentielles. <br><br>

En outre, nous partagerons les informations nécessaires à une enquête, ou pour prévenir des activités illégales ou toutes situations impliquant des menaces potentielles pour la sécurité physique des personnes. Plus largement, nous partagerons les informations lorsque la loi nous y contraint. <br><br>

Les informations non-privées peuvent être fournies à d’autres parties pour toute utilisation commerciale. <br><br>

Sur quels fondements sont collectées mes données ? <br><br>

Les données personnelles seront récoltées par ce site et la société uniquement dans les cas où la réglementation applicable le permet et notamment dans les conditions suivantes : <br><br>

Vous avez manifesté un consentement libre, spécifique, éclairé et univoque concernant le traitement de vos données personnelles ; <br>
Les informations fournies sont nécessaires à l’exécution du contrat ou de mesures précontractuelles prises à votre demande ; <br><br>

Pour assurer le respect des obligations légales ou réglementaires de la société ou du site. <br><br>

Lorsque les intérêts de la société ou du site sont de nature à justifier un traitement par le site ou la société de vos données personnelles accompagné des garanties de protection adéquates. <br><br>

Pour les mineurs de moins de 16 ans, le consentement ne peut être donné ou autorisé que par le titulaire de l’autorité parentale. <br><br>

Comment la sécurité de mes données personnelles est-elle préservée ? <br><br>

Le site utilise des mesures de sécurité afin de protéger les informations personnelles que vous nous transmettez. <br><br>

Les informations sensibles transmises en ligne sont protégées par un cryptage à la pointe de la technologie. <br><br>

Par ailleurs, la société met en place des protections pour les informations hors ligne. Les serveurs qui hébergent ces données sont stockés dans un environnement sécurisé. <br><br>

À l’exception des employés ayant besoin d’accéder à ces données (facturation, prospect, service à la clientèle), personne n’a accès à ces informations personnelles identifiables. <br><br>

Quels sont mes droits ? <br><br>

Nous vous informons que vous disposez de plusieurs droits, listés ci-dessous : <br><br>

Le droit d’accès : si l’utilisateur en fait la demande, la société lui fournira une copie des données personnelles qui le concernent ; <br>
Le droit d’opposition : l’utilisateur a le droit de s’opposer à ce que ses données personnelles soient traitées par la société. <br>
Le droit à la limitation du traitement : l’utilisateur a le droit de demander à la société que celle-ci limite le traitement de ses données personnelles, notamment lorsque celui-ci s’oppose à ce traitement, conteste l’exactitude des données ou lorsqu’il pense que l’utilisation en est illicite. <br>
Le droit à la portabilité des données : l’utilisateur peut, sous certaines conditions, demander à recevoir l’intégralité de ses données personnelles pour les transférer vers un autre responsable de traitement, sans que la société ne puisse s’y opposer. <br>
Le droit de rectification : l’utilisateur peut demander à la société de rectifier les données personnelles qui lui ont été communiquées par lui.
Le droit à l’oubli : sous certaines conditions, l’utilisateur a le droit d’obtenir de la société qu’elle efface dans les meilleurs délais certaines données personnelles le concernant. Le droit à l’oubli ne peut être obtenu que dans certains cas et notamment pour des motifs d’intérêt public, à des fins d’archives ou pour respecter des obligations légales conditionnant le traitement des données personnelles par la société. <br><br>

À condition de justifier de leur identité, les utilisateurs peuvent exercer leur droit auprès de la société comme suit : Par mail ç l'adresse suivante: lhairderienmarseille@gmail.com. <br><br>

La société s’engage à respecter les directives données par tout utilisateur relatives à la conservation, à l’effacement et à la communication de ses données à caractère personnel après son décès. En l’absence de directive de la part de l’utilisateur, la société fera droit aux demandes des héritiers telles qu’énoncées par les dispositions applicables de la Loi Informatique et Libertés. <br><br>

À défaut, les réclamations relatives au traitement de vos données personnelles peuvent être adressées à la Commission Nationale de l’Informatique et des Libertés à l’adresse suivante : 3 Place de Fontenoy - TSA 80715 - 75334 Paris Cedex. <br><br>

    </p>
</div>
</div>
  </div>
</template>
<script>
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - Politique-de-confidentialite',
      description: '',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/politique-de-confidentialite'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  }
})
</script>
<style lang="scss" scoped>
.GestionCookies {
    width: 80%;
    float: right;
    background: gray;
    // text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.containerCookies {
    background: white;
    width: 90%;
    padding: 20px;
}
.containerPageCookies {
    width: 100%;
    display: flex;
    justify-content: center;
}
@media (max-width:990px) {
  .GestionCookies {
    width: 100%;
  }
}
</style>
