<template>
  <div class="QuiSommesNous">
    <div class="page_conseils">
      <h1>Conseils</h1>
      <p class="title_conseils">Comment bien positionner son bonnet ?</p>
      <div class="iframex">
        <iframe style="margin-bottom:20px; width:100%;" min-width="580" height="315" src="https://www.youtube.com/embed/8AmCqLGCEGM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <p class="paragraphConseils">Modèle facile a enfiler comme un bonnet en plaçant le « bun » bien au dessus du crâne, ou légèrement sur le côté ou complètement sur l'arrière de la nuque façon chignon.

Rentrez la petite pointe de tissus à l’arrière du bonnet vers l’intérieur (cf tutoriel vidéo) et gérez le volume du bun en l'étirant une fois placé sur votre tête.

Ce modèle est parfait pour toutes celles qui ne souhaitent pas s'embêter à élaborer un nouage et pour toutes celles qui ont mal à leur petit bras.

Les Franjynes souhaitent combler tout le monde en leur apportant toujours plus de style et une identité propre à chacune !</p>
<p class="title_conseils">Entretien :</p>
<ul class="listeConseils">
  <li>
    - Lavage à la main préconisé.
  </li>
  <li style="margin-bottom:20px;">
    - Séchage à l'air libre à l'ombre, obligatoire.
  </li>
</ul>
      <p class="title_conseils">Comment appliquer sa frange:</p>
      <div class="iframex">
        <iframe style="margin-bottom:20px; width:100%;" min-width="580" height="315" src="https://www.youtube.com/embed/PuXJgl3-8ks" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
      <p class="paragraphConseils">
Enfilez votre frange de façon à ce que celle ci soit disposée au dessus de votre front légèrement sur l’avant au niveau de la fontanelle. Ajustez la frange de façon à ce que la bordure du front corresponde à l’emplacement de votre carnation de cheveux.

Une fois la frange sur votre tête placez vous devant votre miroir et disposez par dessus un turban à nouer (laisser parler votre créativité ou suivez les tutos vidéos nouages du site) ou un turban déjà noué (un tuto est à votre disposition pour vous montrer comment le disposer au mieux sur votre tête) ou un autre type de couvre chef (chapeau, casquette, béret, bonnet de bain, etc…)

Une fois le couvre chef posé, n’oubliez pas de fixer les deux grandes mèches de la frange au turban grâce aux deux barrettes invisibles fournis avec votre frange.

* Vous pouvez vous servir occasionnellement d'un gel ou de laque sur votre frange.</p>

<p class="title_conseils">Entretien : Toutes les dix à 15 utilisations</p>
<p class="paragraphConseils">Conseils d’entretien de vos FranJYnes et PonYtail</p>
<ul class="listeConseils">
<li> 1. Agitez délicatement la frange / le ponytail dans un bain d’eau froide ou tiède contenant un shampooing doux (pour cheveux sec)</li>

<li> 2. Rincez abondamment à l’eau froide puis essorez doucement la frange / le ponytail, en faisant bien attention à ne pas la frotter.</li>

<li> 3. Après l’avoir épongé avec une serviette, secouez la frange / le ponytail et laissez la égoutter et sécher à l’air libre. Ne jamais peigner la frange / le ponytail lorsqu’elle est mouillée.</li>

<li> 4. Une fois la frange / le ponytail complètement sèche, peignez la frange / le ponytail selon le style souhaité en utilisant un peigne à dents larges ou avec les doigts.</li>

<li> 5. Pour peigner, commencez par le bas en peignant doucement de haut en bas, avec un peigne à dents larges ou en démêlant avec les doigts. Puis remontez au fur et à mesure jusqu’à avoir tout démêlé. Ne jamais commencer le démêlage par le milieu ou par le haut, cela endommagerait les mèches emmêlées.</li>

<li> 6. Pour boucler la frange / le ponytail, vous pouvez utiliser un fer à boucler jusqu’à 180°C maximum en maintenant le fer 15 secondes environ. Toute température supérieure augmentera les chances d’abîmer la frange / le ponytail.</li>

<li> 7. Retirez la mèche du fer, puis tenez la dans votre main pendant environ 3 secondes jusqu’à ce que la mèche se tienne.</li>

<li> 8. Répétez les étapes 6 à 8, mèche après mèche jusqu’à ce que toute la frange / le ponytail soit faite. Coiffez selon votre souhait.</li>

<li> 9. Nous vous recommandons de tresser les franges / le ponytail longues en plusieurs tresses la piscine, afin d’éviter l’emmêlement.</li>

<li> 10. Ne dormez pas avec une frange ou un ponytail, cela risque d’emmêler et de délustrer la frange.</li>

<li> 11. N’appliquez pas de spray ou de crème trop épaisse sur les cheveux synthétiques, cela délustrerait et ternirait la frange / le ponytail.</li>

<li> 12. Lorsque la frange / le ponytail n’est pas portée, placez la sur une tête de mannequin ou un porte bijoux afin de garder la forme et l’aspect. Cela réduira également l’emmêlement et le ternissement.</li>
</ul>
<p class="paragraphBis">
Depuis 2017 La marque LES FRANJYNES® imagine, design et fabrique avec beaucoup de passion, d’engagement et de bienveillance des franges entièrement adaptées à l’alopécie (perte totale des cheveux) ainsi qu’à la repousse.

SOCIAL SECURITY
Suite à une chimiothérapie entrainant une Alopecie (chute de cheveux) ou une affection longue durée entraînant aussi une alopécie, la prothèse capillaire devient nécessaire.

Cependant, certaines femmes renoncent à cet achat suite au coûts trop élevés qu'il représente.</p>
    </div>

  </div>
</template>
<script>
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - Conseils bonnets et franges',
      description: "lavage des bonnets et des turbans à la main, sechage à l'air libre",
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/conseils-bonnets-franges'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  }
})
</script>
<style lang="scss" scoped>
.QuiSommesNous {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    float: right;
    background: rgb(212, 212, 212);
    display: flex;
    justify-content: center;
    // align-items: center;
}
.page_conseils {
  background: white;
  width: 80%;
  box-shadow: 0 0px 5em #6e6d6d;
  flex-direction: column;
  display: flex;
  align-items: center;
  @media(max-width:990px) {
    margin-top: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.iframex {
  width:80%;
}
@media(max-width:550px) {
.iframex{
  width:98%;
}
}
@media(max-width: 990px) {
  .QuiSommesNous {
    width:100%;
  }
}
</style>
