<template>
    <div class="carousel-v2">
        <div class="carousel-inner">
          <CarouselIemV2
            v-for="slide in slides"
            :key="slide.id"
          ></CarouselIemV2>
        </div>
    </div>
</template>

<script>
import CarouselIemV2 from '@/components/carousel-item-v2.vue'

export default {
  components: {
    CarouselIemV2
  }
}

</script>

<style>
.carousel-v2 {
  display: flex;
  justify-content: center;
}
.carousel-inner {
  position: relative;
  width: 900px;
  height: 400px;
  overflow: hidden;
}

</style>
