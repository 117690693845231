<template>
      <div class="backgroundBurger" v-if="value >= 0" v-scroll="handleScroll"  >
        <p class="hours">Ouvert du Mardi au Samedi</p>
        <a class="info_1" href="tel:0981794768">09&nbsp;81&nbsp;79&nbsp;47&nbsp;68</a>
      </div>
       <div :class="{'open1':showSidebar }" @click="showMenu1" id="burger" >
        <span class="bar" :class="{'open1':showSidebar }"></span>
      </div>
<div :class="{ 'open':hideNav }" class="backgroundNav" @click="showMenu1" ></div>
  <div :class="{ 'open':hideNav }" class="container_nav">
    <div id="nav">
      <!-- <nav> -->
          <nav class="wrapper" @click="$emit('close')">
              <div class="logo">
                  <router-link @click="showMenu1" class="link_logo" to= '/'>
                      <img src="../assets/logo_hdr_1.webp" alt="">
                  </router-link>
                  <a style="font-size:17px;text-decoration:none; color:white" href="tel:0981794768">09.81.79.47.68</a>
              </div>
              <li v-for="item in list1" :key="item.title" @click="item.open = !item.open, $emit('close')" :title="item.title">
                <!-- conditions d'affichage d'elements fleches ou autre selon le titre -->
                  <i v-if="item.title === 'Homme - Complements Capillaires'| item.title === 'Perruques - Femme'| item.title === 'Services Supplémentaires'| item.title === 'Conseils' | item.title === 'Turbans et Franges - Femme'" class="fas fa-chevron-circle-right accordion" :class="{'open':item.open}"></i>
                  <router-link @click="showMenu1" v-if="item.title === 'Accueil' | item.title === 'Causes chute de cheveux' | item.title === 'Notre Institut' | item.title === 'Homme - Perruques' | item.title === 'Complements Capillaires - Femme' | item.title === 'Femme - Volumateurs' | item.title === 'Femme - Postiches' | item.title === 'Qui Sommes Nous'" style="margin-left:10px" class="title"  :to="item.to">
                      {{ item.title }}
                  </router-link>
                  <router-link v-else  style="margin-left:10px" class="title"  :to="item.to">
                      {{ item.title }}
                  </router-link>
                  <SubList :list="item" @click="showMenu1" />
              </li>
              <li style="margin-left:30px; margin-top:20px; cursor:none; pointer-events: none; color: #ace5f3;" class="title">HOMME</li>
              <li v-for="item in list2" :key="item.title" @click="item.open = !item.open, $emit('close')" :title="item.title">
                <!-- conditions d'affichage d'elements fleches ou autre selon le titre -->
                  <i v-if="item.title === 'Complements Capillaires - Homme'| item.title === 'Perruques - Femme'| item.title === 'Services Supplémentaires'| item.title === 'Conseils' | item.title === 'Turbans et Franges - Femme'" class="fas fa-chevron-circle-right accordion" :class="{'open':item.open}"></i>
                  <router-link @click="showMenu1" v-if="item.title === 'Accueil' | item.title === 'Causes chute de cheveux' | item.title === 'Institut' | item.title === 'Perruques - Homme' | item.title === 'Complements Capillaires - Femme' | item.title === 'Femme - Volumateurs' | item.title === 'Femme - Postiches'" style="margin-left:10px" class="title"  :to="item.to">
                      {{ item.title }}
                  </router-link>
                  <router-link v-else style="margin-left:10px" class="title"  :to="item.to">
                      {{ item.title }}
                  </router-link>
                  <SubList :list="item" @click="showMenu1" />
              </li>
              <li style="margin-left:30px; margin-top:20px; cursor:none; pointer-events: none; color: #ace5f3;" class="title">FEMME</li>
              <li v-for="item in list3" :key="item.title" @click="item.open = !item.open, $emit('close')" :title="item.title">
                <!-- conditions d'affichage d'elements fleches ou autre selon le titre -->
                  <i v-if="item.title === 'Complements Capillaires - Homme'| item.title === 'Perruques - Femme'| item.title === 'Services Supplémentaires'| item.title === 'Conseils' | item.title === 'Turbans et Franges - Femme'" class="fas fa-chevron-circle-right accordion" :class="{'open':item.open}"></i>
                  <router-link @click="showMenu1" v-if="item.title === 'Accueil' | item.title === 'Causes chute de cheveux' | item.title === 'Institut' | item.title === 'Homme - Perruques' | item.title === 'Complements Capillaires - Femme' | item.title === 'Volumateurs - Femme' | item.title === 'Postiches - Femme'" style="margin-left:10px" class="title"  :to="item.to">
                      {{ item.title }}
                  </router-link>
                  <router-link v-else style="margin-left:10px" class="title"  :to="item.to">
                      {{ item.title }}
                  </router-link>
                  <SubList :list="item" @click="showMenu1" />
              </li>
              <li style="margin-left:30px; margin-top:20px; cursor:none; pointer-events: none; color: #ace5f3;" class="title"></li>
              <li v-for="item in list4" :key="item.title" @click="item.open = !item.open, $emit('close')" :title="item.title">
                <!-- conditions d'affichage d'elements fleches ou autre selon le titre -->
                  <i v-if="item.title === 'Complements Capillaires - Homme'| item.title === 'Perruques - Femme'| item.title === 'Services Supplémentaires'| item.title === 'Conseils' | item.title === 'Turbans et Franges - Femme'" class="fas fa-chevron-circle-right accordion" :class="{'open':item.open}"></i>
                  <router-link @click="showMenu1" v-if="item.title === 'Accueil' | item.title === 'Causes chute de cheveux' | item.title === 'Institut' | item.title === 'Homme - Perruques' | item.title === 'Complements Capillaires - Femme' | item.title === 'Femme - Volumateurs' | item.title === 'Femme - Postiches'" style="margin-left:10px" class="title"  :to="item.to">
                      {{ item.title }}
                  </router-link>
                  <router-link v-else style="margin-left:10px" class="title"  :to="item.to">
                      {{ item.title }}
                  </router-link>
                  <SubList :list="item" @click="showMenu1" />
              </li>
              <div class="container_social_network">
                  <a href="https://www.facebook.com/Lhairderien.officiel">
                    <svg xmlns="http://www.w3.org/2000/svg" class=" fbSvg icon icon-tabler icon-tabler-brand-facebook-filled" width="30" height="30" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M18 2a1 1 0 0 1 .993 .883l.007 .117v4a1 1 0 0 1 -.883 .993l-.117 .007h-3v1h3a1 1 0 0 1 .991 1.131l-.02 .112l-1 4a1 1 0 0 1 -.858 .75l-.113 .007h-2v6a1 1 0 0 1 -.883 .993l-.117 .007h-4a1 1 0 0 1 -.993 -.883l-.007 -.117v-6h-2a1 1 0 0 1 -.993 -.883l-.007 -.117v-4a1 1 0 0 1 .883 -.993l.117 -.007h2v-1a6 6 0 0 1 5.775 -5.996l.225 -.004h3z" stroke-width="0" fill="currentColor"></path>
</svg>
                  </a>
                  <a href="https://www.instagram.com/l.hair.de.rien/?hl=fr">
                    <svg xmlns="http://www.w3.org/2000/svg" class="instSvg icon icon-tabler icon-tabler-brand-instagram" width="30" height="30" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"></path>
   <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
   <path d="M16.5 7.5l0 .01"></path>
</svg>
                  </a>
              </div>
          </nav>

      <!-- </nav> -->
    </div>
  </div>
</template>

<script>
// import BurgerButton from 'vue-burger-button'
import SubList from './SubList.vue'
export default {
  components: {
    SubList
    // BurgerButton
  },

  data: () => {
    return {
      value: 0,
      value2: 0,
      isActive: true,
      hideNav: false,
      mobileView: true,
      showSidebar: false,
      list1: [
        { title: 'Accueil', to: '/' },
        { title: 'Notre Institut', to: '/salon' },
        { title: 'Qui Sommes Nous', to: '/qui-sommes-nous' }
      ],
      list2: [
        { title: 'Perruques - Homme', to: '/perruques-homme' },
        {
          title: 'Complements Capillaires - Homme',
          to: '',
          open: false,
          sublist: [
            { name: 'Complements Capillaires', to: '/complements-capillaires-homme' },
            { name: 'Conseils', to: '/conseils-complements-capillaires-homme' }
          ]
        }
      ],
      list3: [
        // { title: 'Perruques', to: '/perruques' },
        {
          title: 'Perruques - Femme',
          to: '',
          open: false,
          sublist: [
            { name: 'Perruques Médicales', to: '/perruques-medicales-femmes' },
            { name: 'Perruques Cheveux Naturels', to: '/perruques-cheveux-naturels' },
            { name: 'Perruques Fibre Prime', to: '/perruques-fibre-prime' },
            { name: 'Perruques Relooking', to: '/perruques-relooking' },
            { name: 'Perruques Type Lace', to: '/perruques-type-lace' }
          ]
        },
        { title: 'Complements Capillaires - Femme', to: '/complements-capillaires-femme' },
        { title: 'Volumateurs - Femme', to: '/volumateurs' },
        { title: 'Postiches - Femme', to: '/postiches' },
        {
          title: 'Turbans et Franges - Femme',
          to: '',
          open: false,
          sublist: [
            { name: 'Bonnets Et Turbans', to: '/bonnets-turbans' },
            { name: 'Franges et Couronnes', to: '/franges-couronnes' }
          ]
        }
        // {
        //   title: 'Perruques',
        //   to: '',
        //   open: true,
        //   sublist: [
        //     { name: 'perruquesItem', to: '/perruques' },
        //     { name: 'Perruques Médicales', to: '/perruques-medicales-femmes' },
        //     { name: 'Perruques Cheveux Naturels', to: '/perruques-cheveux-naturels' },
        //     { name: 'Perruques Fibre Prime', to: '/perruques-fibre-prime' },
        //     { name: 'Perruques Relooking', to: '/perruques-relooking' },
        //     { name: 'Perruques Type Lace', to: '/perruques-type-lace' }
        //   ]
        // },
      ],
      list4: [
        {
          title: 'Conseils',
          to: '',
          open: false,
          sublist: [
            { name: 'Conseils Complément Capillaire', to: '/conseils-complements-femme' },
            { name: 'Conseils Perruque', to: '/conseils-perruque-femme' },
            { name: 'Conseils Bonnets et Franges', to: '/conseils-bonnets-franges' }
          ]
        },
        { title: 'Causes chute de cheveux', to: '/causes-chute-de-cheveux' },
        {
          title: 'Services Supplémentaires',
          to: '',
          open: false,
          sublist: [
            {
              name:
            'Reflexologie Massage Shiatsu',
              to:
            '/reflexologie-massage-shiatsu'
            },
            {
              name:
            'Qui Sommes Nous',
              to:
            '/qui-sommes-nous'
            }
          ]
        }

      ]
    }
  },
  methods: {
    hideNav1 () {
      // this.showSidebar = !this.showSideBar
      // console.log('coucou')
    },
    handleScroll () {
      this.value = window.scrollY
      this.value2 = window.pageYOffset
      const hideNav = document.querySelector('.backgroundBurger')
      if (this.value > 566) {
        hideNav.style.visibility = 'hidden'
      } else hideNav.style.visibility = 'visible'
    },
    showMenu1 () {
      // const btn1 = document.getElementsByClassName('.bar')
      // if (btn1 === false) {
      if (this.hideNav) {
        this.hideNav = !this.hideNav
      } else this.hideNav = true
      // btn1.addEventListener('click', () => {
      //   console.log('hello')
      // })
      // if (btn1) {
      //   console.log("c'est bon tu peux te fermer")
      // } else console.log('loupé !')

      // }
      // if (this.hideNav) {
      //   this.showSidebar = !this.showSideBar
      //   const backgroundNav = document.createElement('div')
      //   backgroundNav.setAttribute('id', 'backgroundNav')
      //   backgroundNav.style.cssText = ' right: 0; width: 10%; position: fixed; height: 100vh; background: #0000009e; z-index: 9999;'
      //   backgroundNav.onclick = function () {
      //     if (!this.hideNav) {
      //       this.hideNav = false
      //       console.log(this.hideNav)
      //     }
      //   }
      //   const currentDiv = document.getElementById('burger')
      //   const quelpn = currentDiv.parentNode
      //   quelpn.insertBefore(backgroundNav, currentDiv)
      //   // console.log(quelpn)
      // } else if (!this.hideNav) {
      //   const elToRemove = document.getElementById('backgroundNav')
      //   elToRemove.remove()
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.sub-items a .lhairderien-active-link {
  color: red;
}
.backgroundBurger{
  display: block;
  height: 45px;
    width: 100%;
    background: white;
    position: fixed;
    z-index: 9997;
    text-align: center;
    padding-top: 8px;
    @media(min-width: 990px) {
      display: none;
    }

}
  .wrapper {
    list-style: none;
    background-color: #000000;
    box-shadow: 0 2px rgba($color: #000000, $alpha: .15);
    border-radius: 4px;
    overflow: hidden;
    li {
      position: relative;
      color: rgb(255, 255, 255);
      padding-bottom:6px;
      // border-top: 1px solid #dedede;
      &:hover a {
          color:#000000;
          font-weight: bold;
      }
      cursor: pointer;
      .title {
        padding: 10px 0;
        text-indent: 20px;
         text-decoration: none;
         color:white;
         font-family: Montserrat;
         text-transform: uppercase;
         font-size: 10px;
         @media(min-width:2300px) {
          font-size: 15px;
         }
         display: block;
         &:hover {
           color:black;
           font-weight: bold;
         }
      }
     @media (max-width: 990px) {
      .title {
      font-size: 13px;
      }
    }
    @media (max-width: 550px) {
      .title {
      font-size: 10px;
      }
    }
      /*
        CSS definition for the icon
        If the open status from the clicked element equal to true,
        then the open class is connected and the icon rotates
        90 deg to the bottom, otherwise back to the default position.
      */
      i {
        position: absolute;
        top: 8px;
        right: 10px;
        transition: transform .5s ease-in-out;
        &.open {
          transform: rotateZ(90deg);
          top:4px;
        }
      }
      &:hover {
        color: #6fd7d2;
        background-color: #d0eef5;
      }
    }
  }
  @media (max-width: 990px) {
      .sub-items {
        font-size:13px !important;
      }
    }
  p.phone_number {
        font-family: inherit;
      }
  .container_social_network {
      display: flex;
      align-items: center;
      justify-content: center;
      gap:15px;
      margin: 25px 0px;
  }
  .logo {
      margin:10px;
      display: flex;
      color:white;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      margin-bottom: 25px;
      img {
          max-width: 40%;
      }
  }
  .link_logo {
      display: contents;
  }
  @media (min-width: 990px) {
    .close {
    display: none;
    }
 }
  @media (max-width: 990px) {
    .phone_number {
    font-size: 10px;
    }
 }

 .close{
     font-size: 20px;
     position:absolute;
     right: 0;
     margin: 10px;
 }
 @media (min-width: 550px) and (max-width: 990px) {
   .container_nav {
   width: 90%;
 }
 }
 @media (max-width: 550px) {
    .container_nav {
   width: 90%;
 }
 }
 .info_1 {
  font-weight: bold;
    color: black;
    text-decoration: none;
    font-size: 13px;
    position: absolute;
    right: 75px;
    top:28%;
    @media(min-width:550px) and (max-width:990px) {
        font-size: 20px;
        top:15%;
      }
 }
</style>
