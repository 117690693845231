<template>
<footer class="container_footer">
  <!-- <div style="display:contents; min-width: 15%;">
    <img :src="logo[0].url" :alt="logo[0].alt">
  </div> -->
  <div class="info_bis" >
      <h4  class="title_footer">NOTRE SALON</h4>
      <div class="numMail">
      <a class="info_2" href="mailto:lhairderien.marseille@gmail.com">
        Nous écrire
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail" width="38" height="38" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"></path>
   <path d="M3 7l9 6l9 -6"></path>
</svg>
      </a>
      <a class="info_2"  href="tel:0981794768">
        09 81 79 47 68
        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone" width="38" height="38" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
   <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
   <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
</svg>
      </a>
    </div>
    <div class="container_infos_salon" >
    <p class="paragrapFooter">Notre salon est joignable du mardi au vendredi de 9h30 à 18h30 et le samedi de 9h30 à 17h00 </p>
    <P  class="paragrapFooter" style="margin-bottom:10px;">Retrouvez nous au 860 Route de la Légion 13400 à Aubagne</P>
    <div style="display:flex; flex-direction: column; margin-top: 30px;">
      <p class="paragrapFooter">Mardi: 9h30 - 18h30</p>
      <p class="paragrapFooter">Mercredi: 10h00 - 14h00</p>
        <p class="paragrapFooter">Jeudi: 9h30 - 18h30</p>
        <p class="paragrapFooter">Vendredi: 9h30 - 18h30</p>
        <p class="paragrapFooter">Samedi: 9h30 - 17h00</p>
        <p class="paragrapFooter">( Uniquement sur rendez-vous )</p>
      </div>
      </div>
    </div>
    <div class="info_1">
      <h4 class="title_footer">LIENS UTILES</h4>
      <router-link class="links" v-for="item in sublist1" :key="item" :to="item.to"> {{item.name }} <br></router-link>
    </div>
    <div class="info_1">
      <h4 class="title_footer">FEMMES</h4>
      <router-link class="links" v-for="item in sublist2" :key="item" :to="item.to"> {{item.name }} <br></router-link>
    </div>
    <div class="info_1">
      <h4 class="title_footer">HOMMES</h4>
      <router-link class="links" v-for="item in sublist3" :key="item" :to="item.to"> {{item.name }} <br></router-link>
    </div>
     <div class="info_1">
      <h4 class="title_footer" style="margin-bottom: 10px;">MENTIONS LEGALES</h4>
      <router-link class="links" style="text-decoration:none; list-style:none; color:#587880; font-weight: bold;" v-for="item in sublist4" :key="item" :to="item.to"> {{item.name }} <br></router-link>
    </div>
  </footer>
</template>
<script>

export default {
  name: 'FooterItem',
  data () {
    return {
      logo: [
        { url: require('../assets/logo_hdr_black_footer.png'), alt: 'turbans femme' }
      ],
      sublist1: [
        { name: 'Plan du site', to: '/site-map' },
        { name: 'Salon', to: '/salon' },
        { name: 'Qui Sommes Nous', to: '/qui-sommes-nous' }
      ],
      sublist2: [
        { name: 'Complements Capillaires', to: '/complements-capillaires-femme' },
        { name: 'Perruques Médicales', to: '/perruques-medicales-femmes' },
        { name: 'Perruques Cheveux Naturels', to: '/perruques-cheveux-naturels' },
        { name: 'Perruques Fibre Prime', to: '/perruques-fibre-prime' },
        { name: 'Perruques Relooking', to: '/perruques-relooking' },
        { name: 'Perruques Type Lace', to: '/perruques-type-lace' },
        { name: 'Bonnets Et Turbans', to: '/bonnets-turbans' },
        { name: 'Franges Et Couronnes', to: '/franges-couronnes' },
        { name: 'Volumateurs', to: '/volumateurs' },
        { name: 'Postiches', to: '/postiches' },
        { name: 'Perruques Relooking', to: '/perruques-relooking' },
        { name: 'Perruques Type Lace', to: '/perruques-type-lace' }
      ],
      sublist3: [
        { name: 'Perruques', to: '/perruques-homme' },
        { name: 'Compléments Capillaires', to: 'complements-capillaires-homme' }
      ],
      sublist4: [
        { name: 'CGU', to: '/cgu' },
        { name: 'Politique de confidentialité', to: '/politique-de-confidentialite' }
        // { name: '', to: 'rgpd' }
      ]
    }
  }
}

</script>

<style lang="scss" scoped>
.container_footer {
    color: #587880;
    display: flex;
    justify-content: left;
    // align-items: center;
    width: 100%;
    z-index: 9999;
    // position: absolute;
    // bottom: 0;
    // right: 0;
    // margin-top: 150px;
    background: #d0eef5;
    padding: 40px;
    @media(min-width:990px) {
      padding: 15px;
    }
}
.title_footer {
  font-size: 28px;
  margin-bottom: 20px;
  @media(min-width:990px) {
    font-size: 18px;
  }
}
p {
  font-family: Montserrat !important;
  font-weight: bold;
  font-size: 18px;
}

.info_1{
 align-items: left;
    width: 12%;
    @media(min-width:1250px) {
      width: 20%;
    }
    padding: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
}
.container_infos_salon {
  width:60%;
  @media(max-width:990px) {
    width:100%;
    .paragrapFooter {
      line-height: 30px !important;
    }
  }
  margin-top: 30px;
  gap: 15px;
    display: flex;
    flex-direction: column;
}
.info_bis{
 align-items: left;
    width: 45%;
    @media(min-width:1250px) {
      width: 40%;
    }
    padding: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
}
.paragrapFooter {
  font-size: 22px;
}
a.info_2 {
  display: flex;
  color: #087d71;
  font-size: 14px;
  align-items: center;
  @media(max-width:990px) {
        font-size:23px;
      }
  text-decoration: none;
  font-family: 'Montserrat';
  font-weight: bold;
  // width:45%;
  flex-direction:row-reverse;
  justify-content: flex-end;
  gap: 10px;
}
a.info_1, a.info_bis{
      text-decoration: none;
    font-family: 'Montserrat';
    font-weight: bold;
    font-size: 28px;
    color: #087d71;
    width: 20%;

    &:hover {
      color:#a37010
    }
}
.links {
  text-decoration:none;
  list-style:none;
  color:#587880;
  font-weight: bold;
  font-size: 20px;
   font-family: Montserrat !important;
   margin-bottom:8px;
}
@media(max-width:990px) {
  .links{
    font-size:10px;
  }
  p {
    font-size: 10px;
  }
  a.links {
    font-size:22px;
  }
}
.numMail {
  display:flex;
  gap:20px
}
@media(max-width:550px) {
  a.links {
    font-size:15px;
  }
  a.info_2 {
    font-size:18px;
  }
  .paragrapFooter {
    font-size:15px
  }
  .container_footer {
    padding:15px
  }
  .numMail {
    flex-direction: column;
    width: 100%;
  }
}
@media (min-width: 990px) and (max-width: 1800px) {
a.links {
    font-size: 12px !important;
  }
  p {
    font-size: 12px !important;
  }
  a.info_1, .info_bis {
    font-size: 12px;
  }

}
@media(min-width:1800px) {
  .title_footer {
    font-size:30px;
  }
  a.info_2 {
    font-size: 18px;
    @media(min-width:2500px) {
      font-size: 24px;
    }
  }
}
@media (max-width:990px) {
  .container_footer {
    width: 100%;
    // display: flex;
  }
  .container_footer {
    flex-direction: column;
    align-items: center;
  }
  p {
    line-height: 10px !important;
  }
  .info_1, .info_bis {
    width: 80%;
    font-size: 10px !important;
  }
}

</style>
