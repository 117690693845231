<template>
  <div class="QuiSommesNous" style="padding: 20px; padding-top: 40px;">
    <div class="container_content">
    <section class="containerProfils">
      <div class="profilFlo">
        <div class="imgContainerFlo" >
          <img class="img_flo" :src=photosFL[0].url alt="">
        </div>
        <div class="containerInfos">
          <div class="containerNom">
            <h1 class="title_conseils_flo" style="padding-bottom: 20px;">FLORIANE,</h1>
          </div>
          <p class="paragraphConseils" style="    margin: 0px 20px 5px 30px;"> Diplomée:</p>
          <p class="paragraphConseils" style="    margin: 0px 20px 5px 20px;" > &#8901; CAP Coiffure</p>
          <p class="paragraphConseils" style="    margin: 0px 20px 5px 20px;" > &#8901; Mention complémentaire styliste visagiste</p>
          <p class="paragraphConseils" style="    margin: 0px 20px 5px 20px;" > &#8901; BP Coiffure</p>
          <p class="paragraphConseils" style="    margin: 0px 20px 60px 20px;" > &#8901; Bac professionnel Perruquier Posticheur Mention TB</p>
          <p class="paragraphConseils" style="    margin: 0px 20px 5px 20px;" >Artiste avec un besoin d'aider les gens et rendre le monde plus simple</p>
          <div class="containerComps">
            <p class="paragraphConseils" style="    margin: 0px 20px 5px 20px;" >Son job:</p>
            <p class="paragraphConseils" style="    margin: 0px 20px 5px 20px;" >Secteur médical et création de sur mesure ou modification des differentes prothèses capillaires</p>

          </div>
        </div>
      </div>

      <div class="profilLeati">
        <div class="imgContainerLaeti">
          <img class="img_laeti" :src=photosFL[1].url alt="">
        </div>
        <div class="containerInfos">
        <div class="containerNom">
        <h1 class="title_conseils_laeti" style="padding-bottom: 20px;">LAETITIA,</h1>
      </div>
        <p class="paragraphConseils" style="    margin: 0px 20px 5px 30px;"> Diplomée:</p>
        <p class="paragraphConseils" style="    margin: 0px 20px 5px 20px;" > &#8901; CAP Coiffure</p>
        <p class="paragraphConseils" style="    margin: 0px 20px 5px 20px;" > &#8901; BP Coiffure coloriste permanentiste</p>
        <p class="paragraphConseils" style="    margin: 0px 20px 60px 20px;" > &#8901; Formation fondu américain et spécialisation coupe homme</p>
        <!-- <p class="paragraphConseils" style="    margin: 0px 20px 5px 20px;" >Grande entrepreneuse avec un gout pour le beau très développé et la main sur le coeur.</p> -->
        <div class="containerComps">
          <p class="paragraphConseils" style="    margin: 0px 20px 5px 20px;" >Son job:</p>
          <p class="paragraphConseils" style="    margin: 0px 20px 5px 20px;" >Complément capillaire homme et technique (coloration/mèches/permanente) sur cheveux naturel</p>

        </div>
      </div>
      </div>
    </section >
    <section class="container_details_story_salon">
      <h3 class="title_story_salon">NOTRE HISTOIRE</h3>
      <section class="containter_story">
        <p>Anciennes collegues pendant des années, Floriane et Laetitia se sont rencontrées par le biais du travail dans une grande chaine d'instituts capillaire. Coup de foudre professionnel ainsi qu'une forte complementarité. De là est venue cette passion pour ce métier. Sachant que chaque client est unique et étant limitées dans les possibilités d'action, elles ont après plusieurs années décidé d'ouvrir leur propre institut capillaire afin de répondre aux mieux aux attentes de chaque personne.</p>
        <p style="margin-top: 10px; margin-bottom: 20px;">C'est donc un institut capillaire qui à été conçu pour ces clients/patients ainsi que pensé pour toutes les personnes souffrant de chutes de cheveux.
  L'Hair de Rien est né et s'est implanté dans la ville d'Aubagne. Idéalement situé proches des grands axes autoroutiers déservant aussi bien Marseille que Toulon.</p>
      </section>
      <section class="container_details_salon">
        <p>- Un endroit chalereux convival et moderne</p>
        <p>- Des cabines individuelles pour un maximum de discrétion</p>
        <p>- Un institut capillaire à l'abrit des regards mais facile d'acces</p>
        <p>- Un grand parking gratuit</p>
        <p>- Des conseils avisés</p>
        <p>- Beaucoup d'écoute et de compréhension</p>
        <p>- Des formations régulières pour Floriane et Laetitia afin d'avoir les dernières techniques et d'en faire bénéficier leur clients</p>
        <p>- Des produits de qualités avec des tarifs honnêtes</p>
      </section>
    </section>
    </div>
  </div>
</template>
<script>
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - notre philosophie et nos valeurs',
      description: '',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/qui-sommes-nous'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      photosFL: [
        { url: require('../assets/Flo_1.png'), alt: 'turbans femme' },
        { url: require('../assets/Laetitia_3.png'), alt: 'turbans femme' }
      ]
    }
  }
})
</script>

<style lang="scss" scoped>
.QuiSommesNous {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    float: right;
}
@media(max-width: 990px) {
  .QuiSommesNous {
    width:100%;
  }
}
</style>
