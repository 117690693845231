<template>
  <div class="PerruquesMedicalesFemme">
     <header class='header-image__image' id="parallax">

      <div class="firstContainerWidget">
        <div class="containerLogo">
            <img class="img_logo" src="https://lhairderien-perruque.fr/lhairderien/src/assets/logo_hdr_2.webp" alt="">
          </div>
          <div class="containerCTA">
            <h1 class="titleCTA" style="margin-bottom:10px; ">Venez decouvrir notre salon</h1>
            <div id="kalendes-widget-container1" onclick="kalendes.show()">
              <iframe id="kalendes-widget-iframe" class="hidden bottomRight maximize" scr="https://www.kalendes.com/visit/#/booking?host=lhairderien-marseille&widget=true" style="height:90%; width:450px;">
              </iframe>
              <div id="kalendes-widget-button2" class="blue bottomRight" style="user-select: none; pointer-events:none;">
                <h6 class="titleCTA" style=" font-weight: 800 !important;">Prendre rendez-vous</h6>
                <h5 class="titleCTA" style=" font-weight: 800 !important;">ICI</h5>
              </div>
              <div id="kalendes-widget-backdrop" class="hidden" onclick="kalendes.hide()"></div>
            </div>
        </div>
      </div>

    <div class='header-image__content'>
      <div class='header-image__content__container'>
      </div>
    </div>
  </header>
  <div class="container_full_intro">
    <div class="container_intro_pmf">
         <p class="paragraph_intro">
          <br>
          L'alternative à la perruque ou en complément, ces petites prothèses partielles sont spécialement conçus pour mettre sous des bonnets ou turbans tout en ayant une impression de cheveux. <br>
          Parfait donc pour l'été ou les fortes chaleurs où votre turbans devient votre allié. <br> <br>
          Ces franges sont adaptées aux alopécies grâce à un système breveté et sont évolutives. Sur un serre tête satiné lors de la chute des cheveux et par clips quand la chevelure commence à repousser. <br>
          En fibre synthétique très haut de gamme, ces franges peuvent être chauffées jusqu'à 190°c et se lavent toutes les 10 utilisations. <br>
          Franges effilées, bouclées, lisses ou franges dans la nuques, toutes ces modèles peuvent être coupées afin d'être personnalisées et vous ressembler un peu plus. <br>
        </p><br>
        <h2 class="title_tab">
        Modalité de remboursement pour les prothèses capillaires partielles
        </h2>
        <p class="paragraph_intro">
          Notre institut capillaire est un établissement agrée par la sécurité sociale. <br> Tous nos modèles répondent aux dernières normes médicales définies par le Ministère de la Santé. Une majorité des prothèses capillaires est remboursée à 100% par la sécurité sociale et votre mutuelle. N'hésitez pas à vous renseigner auprès ce cette dernière pour connaître vos droits. br
Le remboursement des 125€ équivaux à l'achat d'une frange et d'un bonnet. <br>

        </p>
      <div class="containerTableau">
          <div class="tableau3">
            <h4 style="text-align:left; margin-left:30px; font-family: roboto;">Prothèse capillaire partielle <br>
              + un accessoire textile</h4><br>
            <ul style="padding-left:40px">
              <li class="list1">Prix de vente forfaitaire : 125 euros</li> <br>
              <li class="list1">Montant remboursé par la Sécurité Sociale : 125 euros</li><br>
              <li class="list1">Aucun reste à charge / Prise en charge 100%</li><br>
            </ul>
            <div>
              <!-- <h4 style="font-weight:bold">Perruques 100% remboursées</h4> -->
            </div>
          </div>
        </div>
    </div>
  </div>
    <!-- <img :src="images[0].url" alt="">
    <img :src="images[1].url" alt=""> -->

<div class="container_body">
         <input style="margin-top:30px; border:2px solid black; padding:5px; margin-bottom: 30px;" placeholder="rechercher une perruque" type="text" v-model="rechercher">
          <div class="container_coupe_courte" >
            <div style="width:100%; background:#93dbd8 ;">
              <h3 style="font-family: Amatic sc; color:white;">{{title[0]}}</h3>
            </div>
            <div style="width:100%; background:gray;">
              <h4 style="font-family: Amatic sc; color:white;">{{title[1]}}</h4>
            </div>
            <div class="sub_container_coupe_courte">
              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in filteredNames.slice(0,10)" :key="item.id"  >
                <img :src="item.url" :alt="item.alt" :id="item.id" @click="toggleModal(item.id)">
                <div class="container_name_price">
                  <div v-if="current === item.id "  class="name">{{ item.name }}</div>
                  <div v-if="current === item.id "  class="price">{{ item.price }}€</div>
                </div>
                <base-modal-lace v-if="isShowModal === item.id" :imagesBabyDoll="item.slide" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :priceToto = item.price :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal-lace>
              </div>
              <span class="transition">
                <div style="width:100%; background:gray;">
                  <span><h4 style="font-family: Amatic sc; color:white;">{{title[2]}}</h4></span>
                </div>
              </span>

              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in filteredNames.slice(10,14)" :key="item.id"  >
                <img :src="item.url" :alt="item.alt" :id="item.id" @click="toggleModal(item.id)">
                <div class="container_name_price">
                  <div v-if="current === item.id "  class="name">{{ item.name }}</div>
                  <div v-if="current === item.id "  class="price">{{ item.price }}€</div>
                </div>
                <base-modal-lace v-if="isShowModal === item.id" :imagesBabyDoll="item.slide" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :priceToto = item.price :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal-lace>
              </div>
              <span class="transition">
                <div style="width:100%; background:gray;">
                  <span><h4 style="font-family: Amatic sc; color:white;">{{title[3]}}</h4></span>
                </div>
              </span>

              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in filteredNames.slice(14,23)" :key="item.id"  >
                <img :src="item.url" :alt="item.alt" :id="item.id" @click="toggleModal(item.id)">
                <div class="container_name_price">
                  <div v-if="current === item.id "  class="name">{{ item.name }}</div>
                  <div v-if="current === item.id "  class="price">{{ item.price }}€</div>
                </div>
                <base-modal-lace v-if="isShowModal === item.id" :imagesBabyDoll="item.slide" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :priceToto = item.price :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal-lace>
              </div>
              <span class="transition">
                <div style="width:100%; background:gray;">
                  <span><h4 style="font-family: Amatic sc; color:white;">{{title[4]}}</h4></span>
                </div>
              </span>

              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in filteredNames.slice(23,26)" :key="item.id"  >
                <img :src="item.url" :alt="item.alt" :id="item.id" @click="toggleModal(item.id)">
                <div class="container_name_price">
                  <div v-if="current === item.id "  class="name">{{ item.name }}</div>
                  <div v-if="current === item.id "  class="price">{{ item.price }}€</div>
                </div>
                <base-modal-lace v-if="isShowModal === item.id" :imagesBabyDoll="item.slide" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :priceToto = item.price :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal-lace>
              </div>
              <span class="transition">
              <div style="width:100%; background:gray;">
                <span><h4 style="font-family: Amatic sc; color:white;">{{title[5]}}</h4></span>
              </div>
              </span>

              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in filteredNames.slice(26,36)" :key="item.id"  >
                <img :src="item.url" :alt="item.alt" :id="item.id" @click="toggleModal(item.id)">
                <div class="container_name_price">
                  <div v-if="current === item.id "  class="name">{{ item.name }}</div>
                  <div v-if="current === item.id "  class="price">{{ item.price }}€</div>
                </div>
                <base-modal-lace v-if="isShowModal === item.id" :imagesBabyDoll="item.slide" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :priceToto = item.price :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal-lace>
              </div>
              <span class="transition">
                <div style="width:100%; background:gray;">
                  <span><h4 style="font-family: Amatic sc; color:white;">{{title[6]}}</h4></span>
                </div>
              </span>

              <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in filteredNames.slice(36,43)" :key="item.id"  >
                <img :src="item.url" :alt="item.alt" :id="item.id" @click="toggleModal(item.id)">
                <div class="container_name_price">
                  <div v-if="current === item.id "  class="name">{{ item.name }}</div>
                  <div v-if="current === item.id "  class="price">{{ item.price }}€</div>
                </div>
                <base-modal-lace v-if="isShowModal === item.id" :imagesBabyDoll="item.slide" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :priceToto = item.price :descriptionTitle = item.description :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal-lace>
              </div>
              <div style="width:100%; background:#93dbd8 ;">
              <h3 style="font-family: Amatic sc; color:white;">{{title[7]}}</h3>
            </div>
              <!-- <span class="transition"> -->
                <div style="width:100%; background:gray; margin-bottom:30px;">
                  <h4 style="font-family: Amatic sc; color:white;">{{title[8]}}</h4>
                </div>
              <!-- </span> -->

               <div @mouseover="setCurrent(item.id)" class="container_img_name"  v-for="item in filteredNames.slice(43,47)" :key="item.id"  >
                <img :src="item.url" :alt="item.alt" :id="item.id" @click="toggleModal(item.id)">
                <div class="container_name_price">
                  <div v-if="current === item.id "  class="name">{{ item.name }}</div>
                  <div v-if="current === item.id "  class="price">{{ item.price }}€</div>
                </div>
                  <base-modal  v-if="isShowModal === item.id" :imagesAmbreBoutique1="item.slide" :imagesAmbreBoutique2="item.mozaique" :descriptionTitle = item.description :priceToto = item.price :totoTest = item.toto :totoTest2 = item.toto2 :descriptionTitle2 = item.description2 :nameCell = item.name @close="closePopup()" ></base-modal>
              </div>
            </div>
          </div>
        </div>

  </div>
</template>

<script>
import BaseModalLace from '@/components/baseModalLace.vue'
import BaseModal from '@/components/BaseModal.vue'
import { defineComponent, computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'
// import InstagramEmbed from 'vue-instagram-embed'
// import VueInstagram from 'vue-instagram'
export default defineComponent({
  setup () {
    const siteData = reactive({
      title: 'lhairderien - un large choix de franges et de couronnes',
      description: '',
      robots: 'index, follow',
      googlebot: 'index, follow',
      link: {
        rel: 'canonical',
        href: 'https://lhairderien-perruque.fr/franges-couronnes'
      }
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: 'description',
          content: computed(() => siteData.description)
        },
        {
          name: 'robots',
          content: computed(() => siteData.robots)
        },
        {
          name: 'googlebot',
          content: computed(() => siteData.googlebot)
        }
      ],
      link: [
        {
          rel: computed(() => siteData.rel),
          href: computed(() => siteData.href)
        }
      ]
    })
  },
  data () {
    return {
      title: ['Nos franges', 'Franges effilées', 'Franges effilées méchées', 'Franges droites', 'Franges droites méchées', 'Franges bouclées', 'Franges reverse', 'Nos Couronnes', 'sous-titre9'],
      rechercher: '',
      images: [
        { url: require('../assets/category_primepower.webp'), alt: 'shooting femme' },
        { url: require('../assets/category_primepower_small.jpg'), alt: 'shooting femme' },
        { url: require('../assets/Sans-titre-7.png'), alt: 'shooting femme' }
      ],
      myModal: false,
      current: false,
      isShowModal: false,
      propFixed: false,
      imagest: [
        { url: require('../assets/imgTurbans1.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans3.jpg'), alt: 'turbans femme' },
        { url: require('../assets/imgTurbans4.jpg'), alt: 'turbans femme' }
      ],
      frangesUniverselles: [
        {
          id: 1,
          url: require('../assets/frangesUniveselles/frange-alice-mocha.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Alice',
          price: 65,
          slide: [
            { url: require('../assets/frangesUniveselles/frange-alice-mocha_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-alice-mocha_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-alice-mocha_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 2,
          url: require('../assets/frangesUniveselles/frange-brigitte-blond-dore.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Brigitte',
          price: 65,
          slide: [
            { url: require('../assets/frangesUniveselles/frange-brigitte-blond-dore_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-brigitte-blond-dore_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-brigitte-blond-dore_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 3,
          url: require('../assets/frangesUniveselles/frange-eliz-chatain-moyen.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Eliz',
          price: 65,
          slide: [
            { url: require('../assets/frangesUniveselles/frange-eliz-chatain-moyen_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-eliz-chatain-moyen_2.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 4,
          url: require('../assets/frangesUniveselles/frange-francoise-blanc.jpg'),
          alt: 'perruque femme gris ambre cheveux courte',
          name: 'Francoise',
          price: 65,
          slide: [
            { url: require('../assets/frangesUniveselles/frange-francoise-blanc_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-francoise-blanc_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-francoise-blanc_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 5,
          url: require('../assets/frangesUniveselles/frange-jade-brun-noir.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Jade',
          price: 65,
          slide: [
            { url: require('../assets/frangesUniveselles/frange-jade-brun-noir_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-jade-brun-noir_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-jade-brun-noir_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 6,
          url: require('../assets/frangesUniveselles/frange-jane-auburn.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Jane',
          price: 65,
          slide: [
            { url: require('../assets/frangesUniveselles/frange-jane-auburn_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-jane-auburn_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-jane-auburn_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 7,
          url: require('../assets/frangesUniveselles/frange-lorine-chatain-clair-flamboyant.jpg'),
          alt: 'perruque femme blond ambre cheveux long',
          name: 'Lorine',
          price: 65,
          slide: [
            { url: require('../assets/frangesUniveselles/frange-lorine-chatain-clair-flamboyant_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-lorine-chatain-clair-flamboyant_2.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 8,
          url: require('../assets/frangesUniveselles/frange-marie-chatain-clair.jpg'),
          alt: 'perruque femme ambre coupe courte',
          name: 'Marie',
          price: 65,
          slide: [
            { url: require('../assets/frangesUniveselles/frange-marie-chatain-clair_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-marie-chatain-clair_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-marie-chatain-clair_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 9,
          url: require('../assets/frangesUniveselles/frange-marilyn-blond-platine.jpg'),
          alt: 'perruque femme ambre coupe courte gris',
          name: 'Marilyn',
          price: 65,
          slide: [
            { url: require('../assets/frangesUniveselles/frange-marilyn-blond-platine_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-marilyn-blond-platine_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-marilyn-blond-platine_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 10,
          url: require('../assets/frangesUniveselles/frange-sophia-roux.jpg'),
          alt: 'perruque femme ambre coupe courte gris',
          name: 'Sophia',
          price: 65,
          slide: [
            { url: require('../assets/frangesUniveselles/frange-sophia-roux_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-sophia-roux_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesUniveselles/frange-sophia-roux_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        // ],
        // frangesUniversellesMechees: [
        {
          id: 11,
          url: require('../assets/FrangesUniversellesMechees/frange-jodie-chatain-meche-blond.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Jodie',
          price: 65,
          slide: [
            { url: require('../assets/FrangesUniversellesMechees/frange-jodie-chatain-meche-blond_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/FrangesUniversellesMechees/frange-jodie-chatain-meche-blond_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/FrangesUniversellesMechees/frange-jodie-chatain-meche-blond_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 12,
          url: require('../assets/FrangesUniversellesMechees/frange-kate-blond-fonce.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Kate',
          price: 65,
          slide: [
            { url: require('../assets/FrangesUniversellesMechees/frange-kate-blond-fonce_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/FrangesUniversellesMechees/frange-kate-blond-fonce_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/FrangesUniversellesMechees/frange-kate-blond-fonce_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 13,
          url: require('../assets/FrangesUniversellesMechees/frange-meryl-poivre-et-sel.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'meryl',
          price: 65,
          slide: [
            { url: require('../assets/FrangesUniversellesMechees/frange-meryl-poivre-et-sel_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/FrangesUniversellesMechees/frange-meryl-poivre-et-sel_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/FrangesUniversellesMechees/frange-meryl-poivre-et-sel_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 14,
          url: require('../assets/FrangesUniversellesMechees/frange-valerie-blond-meche-chatain.jpg'),
          alt: 'perruque femme gris ambre cheveux courte',
          name: 'Valerie',
          price: 65,
          slide: [
            { url: require('../assets/FrangesUniversellesMechees/frange-valerie-blond-meche-chatain_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/FrangesUniversellesMechees/frange-valerie-blond-meche-chatain_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/FrangesUniversellesMechees/frange-valerie-blond-meche-chatain_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        // ],
        // frangesCoupeDroite: [
        {
          id: 15,
          url: require('../assets/frangesCoupeDroite/frange-anne-brooks-droite-roux.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Anne Brooks',
          price: 65,
          slide: [
            { url: require('../assets/frangesCoupeDroite/frange-anne-brooks-droite-roux_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-anne-brooks-droite-roux_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-anne-brooks-droite-roux_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 16,
          url: require('../assets/frangesCoupeDroite/frange-carol-brooks-droite-blond-dore.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Carol Brooks',
          price: 65,
          slide: [
            { url: require('../assets/frangesCoupeDroite/frange-carol-brooks-droite-blond-dore_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-carol-brooks-droite-blond-dore_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-carol-brooks-droite-blond-dore_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 17,
          url: require('../assets/frangesCoupeDroite/frange-celia-brooks-droite-chatain-moyen.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Celia Brooks',
          price: 65,
          slide: [
            { url: require('../assets/frangesCoupeDroite/frange-celia-brooks-droite-chatain-moyen_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-celia-brooks-droite-chatain-moyen_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-celia-brooks-droite-chatain-moyen_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 18,
          url: require('../assets/frangesCoupeDroite/frange-celine-brooks-droite-mocha.jpg'),
          alt: 'perruque femme gris ambre cheveux courte',
          name: 'Celine Brooks',
          price: 65,
          slide: [
            { url: require('../assets/frangesCoupeDroite/frange-celine-brooks-droite-mocha_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-celine-brooks-droite-mocha_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-celine-brooks-droite-mocha_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 19,
          url: require('../assets/frangesCoupeDroite/frange-claudette-brooks-droite-auburn.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Claudette Brooks',
          price: 65,
          slide: [
            { url: require('../assets/frangesCoupeDroite/frange-claudette-brooks-droite-auburn_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-claudette-brooks-droite-auburn_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-claudette-brooks-droite-auburn_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 20,
          url: require('../assets/frangesCoupeDroite/frange-france-brooks-droite-blond-platine.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'France Brooks',
          price: 65,
          slide: [
            { url: require('../assets/frangesCoupeDroite/frange-france-brooks-droite-blond-platine_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-france-brooks-droite-blond-platine_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-france-brooks-droite-blond-platine_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 21,
          url: require('../assets/frangesCoupeDroite/frange-julie-brooks-droite-blond-fonce.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Julie Brooks',
          price: 65,
          slide: [
            { url: require('../assets/frangesCoupeDroite/frange-julie-brooks-droite-blond-fonce_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-julie-brooks-droite-blond-fonce_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-julie-brooks-droite-blond-fonce_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 22,
          url: require('../assets/frangesCoupeDroite/frange-romy-brooks-droite-chatain-clair.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Romy Brooks',
          price: 65,
          slide: [
            { url: require('../assets/frangesCoupeDroite/frange-romy-brooks-droite-chatain-clair_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-romy-brooks-droite-chatain-clair_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroite/frange-romy-brooks-droite-chatain-clair_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 23,
          url: require('../assets/frangesCoupeDroite/rita-brooks-droite-blanc.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Rita Brooks',
          price: 65,
          slide: [
            { url: require('../assets/frangesCoupeDroite/rita-brooks-droite-blanc_1.jpg'), alt: 'perruque femme blond' }
          ]
        },
        // ],
        // frangesCoupeDroiteMechees: [
        {
          id: 24,
          url: require('../assets/frangesCoupeDroiteMechees/frange-alex-brooks-droite-blond-meche-chatain.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Alex Brooks',
          price: 65,
          slide: [
            { url: require('../assets/frangesCoupeDroiteMechees/frange-alex-brooks-droite-blond-meche-chatain_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroiteMechees/frange-alex-brooks-droite-blond-meche-chatain_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroiteMechees/frange-alex-brooks-droite-blond-meche-chatain_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 25,
          url: require('../assets/frangesCoupeDroiteMechees/frange-jamie-brooks-droite-poivre-et-sel.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Jamie Brooks',
          price: 65,
          slide: [
            { url: require('../assets/frangesCoupeDroiteMechees/frange-jamie-brooks-droite-poivre-et-sel_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroiteMechees/frange-jamie-brooks-droite-poivre-et-sel_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroiteMechees/frange-jamie-brooks-droite-poivre-et-sel_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 26,
          url: require('../assets/frangesCoupeDroiteMechees/frange-scarlette-brooks-droite-chatain-meche-blond.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Scarlette Brooks',
          price: 65,
          slide: [
            { url: require('../assets/frangesCoupeDroiteMechees/frange-scarlette-brooks-droite-chatain-meche-blond_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroiteMechees/frange-scarlette-brooks-droite-chatain-meche-blond_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesCoupeDroiteMechees/frange-scarlette-brooks-droite-chatain-meche-blond_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        // ],
        // frangesBouclees: [
        {
          id: 27,
          url: require('../assets/frangesBouclees/frange-amandine-bouclee-brun-noir.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Cara',
          price: 65,
          slide: [
            { url: require('../assets/frangesBouclees/frange-amandine-bouclee-brun-noir_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-amandine-bouclee-brun-noir_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-amandine-bouclee-brun-noir_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 28,
          url: require('../assets/frangesBouclees/frange-barbara-bouclee-blond-fonce.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Barbara',
          price: 65,
          slide: [
            { url: require('../assets/frangesBouclees/frange-barbara-bouclee-blond-fonce_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-barbara-bouclee-blond-fonce_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-barbara-bouclee-blond-fonce_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 29,
          url: require('../assets/frangesBouclees/frange-charlize-bouclee-chatain-meche-blond.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Charlize',
          price: 65,
          slide: [
            { url: require('../assets/frangesBouclees/frange-charlize-bouclee-chatain-meche-blond_1.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 30,
          url: require('../assets/frangesBouclees/frange-charlotte-bouclee-chatain-moyen.jpg'),
          alt: 'perruque femme gris ambre cheveux courte',
          name: 'Charlotte',
          price: 65,
          slide: [
            { url: require('../assets/frangesBouclees/frange-charlotte-bouclee-chatain-moyen_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-charlotte-bouclee-chatain-moyen_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-charlotte-bouclee-chatain-moyen_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 31,
          url: require('../assets/frangesBouclees/frange-farrah-bouclee-blond-meche-chatain.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Farrah',
          price: 65,
          slide: [
            { url: require('../assets/frangesBouclees/frange-farrah-bouclee-blond-meche-chatain_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-farrah-bouclee-blond-meche-chatain_2.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 32,
          url: require('../assets/frangesBouclees/frange-helene-bouclee-blond-platine.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Helene',
          price: 65,
          slide: [
            { url: require('../assets/frangesBouclees/frange-helene-bouclee-blond-platine_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-helene-bouclee-blond-platine_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-helene-bouclee-blond-platine_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 33,
          url: require('../assets/frangesBouclees/frange-laetitia-bouclee-roux.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Laetitia',
          price: 65,
          slide: [
            { url: require('../assets/frangesBouclees/frange-laetitia-bouclee-roux_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-laetitia-bouclee-roux_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-laetitia-bouclee-roux_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 34,
          url: require('../assets/frangesBouclees/frange-lina-bouclee-blond-dore.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Lina',
          price: 65,
          slide: [
            { url: require('../assets/frangesBouclees/frange-lina-bouclee-blond-dore_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-lina-bouclee-blond-dore_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-lina-bouclee-blond-dore_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 35,
          url: require('../assets/frangesBouclees/frange-oli-bouclee-mocha.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Oli',
          price: 65,
          slide: [
            { url: require('../assets/frangesBouclees/frange-oli-bouclee-mocha_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-oli-bouclee-mocha_2.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 36,
          url: require('../assets/frangesBouclees/frange-stephanie-bouclee-chatain-clair.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Stephanie',
          price: 65,
          slide: [
            { url: require('../assets/frangesBouclees/frange-stephanie-bouclee-chatain-clair_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-stephanie-bouclee-chatain-clair_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesBouclees/frange-stephanie-bouclee-chatain-clair_3.jpg'), alt: 'perruque femme blond' }
          ]
        },
        // ],
        // frangesReverse: [
        {
          id: 37,
          url: require('../assets/frangesReverse/frange-reverse-diane-blond-meche-chatain.jpg'),
          alt: 'perruque femme blond ambre coupe courte',
          name: 'Diane',
          price: 65,
          slide: [
            { url: require('../assets/frangesReverse/frange-reverse-diane-blond-meche-chatain_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-diane-blond-meche-chatain_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-diane-blond-meche-chatain_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-diane-blond-meche-chatain_4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-diane-blond-meche-chatain_5.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 38,
          url: require('../assets/frangesReverse/frange-reverse-eloise-chatain-meche-blond.jpg'),
          alt: 'perruque femme gris ambre coupe courte',
          name: 'Eloise',
          price: 65,
          slide: [
            { url: require('../assets/frangesReverse/frange-reverse-eloise-chatain-meche-blond_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-eloise-chatain-meche-blond_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-eloise-chatain-meche-blond_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-eloise-chatain-meche-blond_4.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 39,
          url: require('../assets/frangesReverse/frange-reverse-hestia-blond-dore.jpg'),
          alt: 'perruque femme gris ambre cheveux long',
          name: 'Hestia',
          price: 65,
          slide: [
            { url: require('../assets/frangesReverse/frange-reverse-hestia-blond-dore_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-hestia-blond-dore_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-hestia-blond-dore_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-hestia-blond-dore_4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-hestia-blond-dore_5.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 40,
          url: require('../assets/frangesReverse/frange-reverse-ilana-chatain-clair.jpg'),
          alt: 'perruque femme gris ambre cheveux courte',
          name: 'Ilana',
          price: 65,
          slide: [
            { url: require('../assets/frangesReverse/frange-reverse-ilana-chatain-clair1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-ilana-chatain-clair_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-ilana-chatain-clair_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-ilana-chatain-clair_4.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 41,
          url: require('../assets/frangesReverse/frange-reverse-julia-auburn.jpg'),
          alt: 'perruque femme brun ambre coupe courte',
          name: 'Julia',
          price: 65,
          slide: [
            { url: require('../assets/frangesReverse/frange-reverse-julia-auburn_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-julia-auburn_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-julia-auburn_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-julia-auburn_4.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 42,
          url: require('../assets/frangesReverse/frange-reverse-monica-chatain-mocha.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Monica',
          price: 65,
          slide: [
            { url: require('../assets/frangesReverse/frange-reverse-monica-chatain-mocha_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-monica-chatain-mocha_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-monica-chatain-mocha_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-monica-chatain-mocha_4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-monica-chatain-mocha_5.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 43,
          url: require('../assets/frangesReverse/frange-reverse-paloma-chatain-moyen.jpg'),
          alt: 'perruque femme blond ambre coupe courte ',
          name: 'Paloma',
          price: 65,
          slide: [
            { url: require('../assets/frangesReverse/frange-reverse-paloma-chatain-moyen_1.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-paloma-chatain-moyen_2.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-paloma-chatain-moyen_3.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-paloma-chatain-moyen_4.jpg'), alt: 'perruque femme blond' },
            { url: require('../assets/frangesReverse/frange-reverse-paloma-chatain-moyen_5.jpg'), alt: 'perruque femme blond' }
          ]
        },
        {
          id: 44,
          url: require('../assets/couronnes_ellenwille/cayenne/hairpiece_cayenne_3.jpg'),
          alt: 'couronne perruque femme',
          name: 'Cayenne',
          price: '?',
          description: 'MONOFILAMENT PARTIEL – MONO SPIRE',
          toto: "Ce type de perruque a un monofilament dans la zone de la spire. Les cheveux sont noués à la main dans ce tissu ressemblant à de la peau. En conséquence, la perruque a l'air complètement naturelle dans la zone du verticille. Le reste de la tenue est en trame. Les cheveux sont cousus sur de fins rubans de coton. Les trames s'adaptent bien à la forme de la tête et ont une très bonne circulation d'air.",
          description2: 'APPROCHE CINÉMATOGRAPHIQUE ÉTENDUE',
          toto2: "Avec le devant en dentelle allongé, les cheveux sur le front et dans la zone des tempes sont travaillés individuellement à la main dans un tissu transparent, ce qui permet à la racine des cheveux avant d'avoir un aspect naturel sur une zone généreuse. Cela signifie que les cheveux peuvent être coiffés de différentes manières et garantissent un aspect naturel, même avec un aspect sans front.",
          slide: [
            { url: require('../assets/couronnes_ellenwille/cayenne/hairpiece_cayenne_3.jpg'), alt: 'couronne femme' },
            { url: require('../assets/couronnes_ellenwille/cayenne/hairpiece_cayenne_1.jpg'), alt: 'couronne femme' },
            { url: require('../assets/couronnes_ellenwille/cayenne/hairpiece_cayenne_2.jpg'), alt: 'couronne femme' }
          ],
          mozaique: [
            { url: require('../assets/couronnes_ellenwille/cayenne/colors/auburn.jpg'), alt: 'perruque femme blond', name: 'auburn' },
            { url: require('../assets/couronnes_ellenwille/cayenne/colors/choco brown.jpg'), alt: 'perruque femme blond', name: 'choco brown' },
            { url: require('../assets/couronnes_ellenwille/cayenne/colors/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/couronnes_ellenwille/cayenne/colors/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/couronnes_ellenwille/cayenne/colors/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/couronnes_ellenwille/cayenne/colors/nut brown.jpg'), alt: 'perruque femme blond', name: 'nut brown' },
            { url: require('../assets/couronnes_ellenwille/cayenne/colors/pastel blonde.jpg'), alt: 'perruque femme blond', name: 'pastel blonde' }
          ]
        },
        {
          id: 45,
          url: require('../assets/couronnes_ellenwille/mint/hairpiece_mint_3.jpg'),
          alt: 'couronne perruque femme',
          name: 'Mint',
          price: '?',
          slide: [
            { url: require('../assets/couronnes_ellenwille/mint/hairpiece_mint_3.jpg'), alt: 'couronne femme' },
            { url: require('../assets/couronnes_ellenwille/mint/hairpiece_mint_1.jpg'), alt: 'couronne femme' },
            { url: require('../assets/couronnes_ellenwille/mint/hairpiece_mint_2.jpg'), alt: 'couronne femme' }
          ],
          mozaique: [
            { url: require('../assets/couronnes_ellenwille/mint/colors/black.jpg'), alt: 'perruque femme blond', name: 'black' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/ginger blonde.jpg'), alt: 'perruque femme blond', name: 'ginger blonde' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/light grey.jpg'), alt: 'perruque femme blond', name: 'light grey' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/mahagony brown.jpg'), alt: 'perruque femme blond', name: 'mahagony brown' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/natural blonde.jpg'), alt: 'perruque femme blond', name: 'natural blonde' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/nut brown.jpg'), alt: 'perruque femme blond', name: 'nut brown' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/pearl grey.jpg'), alt: 'perruque femme blond', name: 'pearl grey' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/platinum blonde.jpg'), alt: 'perruque femme blond', name: 'platinum blonde' },
            { url: require('../assets/couronnes_ellenwille/mint/colors/silver grey.jpg'), alt: 'perruque femme blond', name: 'silver grey' }
          ]
        },
        {
          id: 46,
          url: require('../assets/couronnes_ellenwille/sage/hairpiece_sage_3.jpg'),
          alt: 'couronne perruque femme',
          name: 'Sage',
          price: '?',
          slide: [
            { url: require('../assets/couronnes_ellenwille/sage/hairpiece_sage_3.jpg'), alt: 'couronne femme' },
            { url: require('../assets/couronnes_ellenwille/sage/hairpiece_sage_1.jpg'), alt: 'couronne femme' },
            { url: require('../assets/couronnes_ellenwille/sage/hairpiece_sage_2.jpg'), alt: 'couronne femme' }
          ],
          mozaique: [
            { url: require('../assets/couronnes_ellenwille/sage/colors/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/couronnes_ellenwille/sage/colors/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/couronnes_ellenwille/sage/colors/ginger blonde.jpg'), alt: 'perruque femme blond', name: 'ginger blonde' },
            { url: require('../assets/couronnes_ellenwille/sage/colors/gold blonde.jpg'), alt: 'perruque femme blond', name: 'gold blonde' },
            { url: require('../assets/couronnes_ellenwille/sage/colors/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/couronnes_ellenwille/sage/colors/light brown.jpg'), alt: 'perruque femme blond', name: 'light brown' },
            { url: require('../assets/couronnes_ellenwille/sage/colors/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/couronnes_ellenwille/sage/colors/natural blonde.jpg'), alt: 'perruque femme blond', name: 'natural blonde' },
            { url: require('../assets/couronnes_ellenwille/sage/colors/nut brown.jpg'), alt: 'perruque femme blond', name: 'nut brown' }
          ]
        },
        {
          id: 47,
          url: require('../assets/couronnes_ellenwille/vanilla hi/hairpiece_vanilla_hi_3.jpg'),
          alt: 'couronne perruque femme',
          name: 'Vanilla Hi',
          price: '?',
          slide: [
            { url: require('../assets/couronnes_ellenwille/vanilla hi/hairpiece_vanilla_hi_3.jpg'), alt: 'couronne femme' },
            { url: require('../assets/couronnes_ellenwille/vanilla hi/hairpiece_vanilla_hi_1.jpg'), alt: 'couronne femme' },
            { url: require('../assets/couronnes_ellenwille/vanilla hi/hairpiece_vanilla_hi_2.jpg'), alt: 'couronne femme' }
          ],
          mozaique: [
            { url: require('../assets/couronnes_ellenwille/vanilla hi/colors/choco brown.jpg'), alt: 'perruque femme blond', name: 'choco brown' },
            { url: require('../assets/couronnes_ellenwille/vanilla hi/colors/dark blonde.jpg'), alt: 'perruque femme blond', name: 'dark blonde' },
            { url: require('../assets/couronnes_ellenwille/vanilla hi/colors/dark brown.jpg'), alt: 'perruque femme blond', name: 'dark brown' },
            { url: require('../assets/couronnes_ellenwille/vanilla hi/colors/light blonde.jpg'), alt: 'perruque femme blond', name: 'light blonde' },
            { url: require('../assets/couronnes_ellenwille/vanilla hi/colors/medium brown.jpg'), alt: 'perruque femme blond', name: 'medium brown' },
            { url: require('../assets/couronnes_ellenwille/vanilla hi/colors/nut brown.jpg'), alt: 'perruque femme blond', name: 'nut brown' },
            { url: require('../assets/couronnes_ellenwille/vanilla hi/colors/sandy blonde.jpg'), alt: 'perruque femme blond', name: 'sandy blonde' }
          ]
        }

      ]
    }
  },
  components: {
    BaseModalLace,
    BaseModal
  },
  mounted () {
    const parallax = document.getElementById('parallax')
    window.addEventListener('scroll', function () {
      const offset = window.scrollY
      parallax.style.backgroundPositionY = offset * 0.2 + 'px'
    })
  },
  methods: {
    // openModal () {
    //   this.modalOpen = !this.modalOpen
    // },
    toggleModal (id) {
      this.isShowModal = id
      // window.scrollTo(0, 600)
      const body = document.querySelector('body')
      // const containerBody = document.querySelector('.container_body')
      if (this.isShowModal === id | id === this.filteredNames.id) {
        body.style.overflow = 'hidden'
        // containerBody.style.visibility = 'hidden'
      } else {
        body.style.overflow = 'inherit'
        // containerBody.style.visibility = 'visible'
      }
    },
    closePopup (id) {
      const body = document.querySelector('body')
      if (this.isShowModal !== id) {
        body.style.overflow = 'visible'
        console.log('la popup est fermée !', id)
        this.isShowModal = false
        this.isShowModal = !id
        this.isShowModal = !this.isShowModal
      }
    },
    setCurrent (id) {
      this.current = id
      console.log(id)
    }
  },
  computed: {
    filteredNames () {
      return this.frangesUniverselles.filter(item => item.name.toLowerCase().includes(this.rechercher.toLowerCase()))
    }
  }
})
</script>

<style lang="scss" scoped>

// html,
// body {
//   height: 100%;
// }
// $base-header-height: 100%;
.PerruquesMedicalesFemme {
      width: 80%;
    right: 0;
    text-align: center;
    float: right;
}
.header-image__image {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
// .header-image {
//   position: relative; // 2.
//   height: 100%;
//   overflow: auto; // 1.
//   -webkit-overflow-scrolling: touch; // 5.
//   @at-root .header-image__image {
//     height: $base-header-height;
//     background-position: center center; // 3.
//     background-repeat: no-repeat; // 3.
//     background-size: cover; // 3.
//   }
// }
.container_intro_pmf {
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  width: 80%;
}
.title_tab {
  margin: 0px 0px 40px 0px;
    padding-top: 40px;
}
.container_full_intro {
 width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
}
table {
  width: 20%;
  border-collapse: collapse;
  color: white;
}
td {
  color: black;
}
tr {
 background-color: #eee;
}
.entry-content thead th, .entry-content tr th {
 color: white;

}
th,
td {
  border: 1px solid white;
  padding: 10px;
}
th {
  background: #949393;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  font-family: sans-serif;
 line-height:1.7em;
}
table {
  width: 100%;
  border-collapse: collapse;
      margin-bottom: 60px;
}
th,
td {
  padding: 10px;
  text-align: left;
  border: solid 1px #ccc;
}
th {
  background-color: #000;
  color: #fff;
}
tr:nth-child(odd) {
  background-color: #eee;
}
.container_stars {
  margin-bottom: 80px;
  img {
    max-width:100%;
  }
}

.PerruquesRelooking {
  width: 80%;
    // position: absolute;
    right: 0;
    text-align: center;
    margin-bottom: 48px;
    float: right;
}

.fixed {
  height: 100vh;
  overflow: hidden;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg');
  height: 100vh;
  background-size: cover;

  background-repeat: no-repeat;
}
.container_body {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.sub_container_coupe_courte{
 display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}
.container_coupe_courte{
  width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    // z-index: 0;
}
.container_img_name {
 margin: 5px;
    padding: 0;
    display: inline-grid;
    width: 30%;
    position: relative;
    margin-bottom:70px;
    cursor: pointer;
  img {
    width: 100%;
  }
  .container_name_price {
    position: absolute;
    background: white;
    bottom: -35px;
    left: 0;
    width: 100%;
    z-index: 8;
    color: black;
    display: flex;
    justify-content: space-between;
    padding-left: 8px;
    padding-right: 8px;
    .name, .price {
    font-size: 22px;
  }
  }
}
.transition {
border-top: 1px solid black;
    width: 100%;
    margin: 50px;
}
@media only screen and (max-width: 700px) {
  table,
  thead,
  tbody,
  tr,
  th,
  td {
    display: block;
  }
  .entry-content table:not(.variations),
  body.et-pb-preview #main-content .container table {
    border: 1px solid black;
  }
  thead {
    display: none;
  }
  td {
    padding-left: 150px !important;
    position: relative;
    margin-top: -1px;
    background: #fff;
    font-size: 10px;
    line-height: 15px;
    color: black;
  }
  td:nth-child(odd) {
    background-color: #eee;
  }

  td::before {
    padding: 10px;
    content: attr(data-label);
    position: absolute;
    top: 0;
    left: 0;
    width: 130px;
    bottom: 0;
    background-color: #000;
    color: #fff;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  tr {
    margin-bottom: 1rem;
  }
}
@media (max-width: 550px) {
.header-image__image {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/category_primepower_small.jpg')
}
.PerruquesMedicalesFemme {
  width:100%;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans4.jpg')
}
.container_img_name {
  width: 47%;
.name, .price {
  font-size: 15px;
}
}
.PerruquesRelooking {
  width:100%;
}
}
@media (max-width: 990px) {

.PerruquesMedicalesFemme {
  width:100%;
}
.PerruquesRelooking {
  width:100%;
}
.header-image__image1 {
  background-image: url('https://lhairderien-perruque.fr/lhairderien/src/assets/imgTurbans3.jpg')
}
}

@media (min-width: 990px) {
  .container_img_name {
  max-width: 15%;
}
}
</style>
