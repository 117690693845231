<template>
    <!-- <div class="container_popup"> -->

    <div id="myModal" class="modal" @click="$emit('close')">
        <!-- The Close Button -->
        <div class="container_sipmle_img">
            <span @click="$emit('close')" class="close">&times;</span>
            <!-- Modal Content (The Image) -->
            <img class="modal-content" :src="images" alt="">
        </div>
    </div>

    <!-- </div> -->
  </template>

<script>
export default {
  props: [
    'image_mozaic'
  ],
  data () {
    return {
      // showModale: this.isShowModal,
      // theCurrent: this.current,
      images: this.image_mozaic,
      desc1: this.descriptionTitle2

    }
  },
  methods: {
    changeActiveImage (index) {
      this.activeImage = this.images[index]
    // console.log(this.images)
    // console.log(this.activeImage)
    },
    changeActiveColor (index) {
      if (this.images !== this.imgBabyDoll) {
        this.activeColor = this.colors[index]
        // console.log(this.colors)
        // console.log(this.activeColor)
      }
    }
    // setCurrent (id) {
    //   this.theCurrent = id
    //   console.log(id)
    // }
  }
  // methods: {
  //   closeModal () {
  //     this.isShowModal = !this.isShowModal
  //     const body = document.querySelector('body')
  //     if (!this.isShowModal) {
  //       body.style.overflow = 'inherit'
  //     }
  //   }
  // },

}
</script>

  <style lang="scss" scoped>
  .container_popup {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // new popup for simple img

  .modal {
    width: 100%;
    /* float: right; */
    position: fixed;
    z-index: 99999;
    padding-top: 100px;
    /* left: 0; */
    top: 0;
    right: 0;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.9);
    justify-content: center;
    align-content: center;
    display: flex;
    @media (max-width:990px) {
        width: 100%;
    }
}
.modal-content {
    max-width:40%;
    @media (max-width:990px) {
        max-width:85%
    }
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
  // end new popup
  .div1 {
  position: fixed;
      bottom: 0px;
      /* left: 0; */
      right: 10%;
      /* padding-left: 16px; */
      /* padding-right: 16px; */
      /* padding-bottom: 16px; */
      width: 60%;
      z-index: 9999;
       display: flex;
      justify-content: center;
      // align-items: center;
      // padding-top: 40px;
  }

  .div2 {
  position: fixed;
      top: 0px;
      right: 0px;
      bottom: 0px;
      /* left: 0px; */
      transition-property: opacity;
      width: 100%;
  }

  .div3 {
  position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      background: #252525;
  }
  .div4 {
  z-index: 10;
  background: white;
  border-radius: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
  // overflow: hidden;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  height: 100vh;
  // position: relative;
  width: 100%;
  }

  .div5 {
   background: white;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left:16px;
  padding-right: 16px;
  width: 100%;
  display: flex;
  // justify-content: center;
  // align-items: center;
  }
  .div6 {
    width: 60%;
    display: flex;
  }
  .container_right {
    width: 40%;
    display: flex;
    height: 100vh;
    margin: 0px 20px 0px 20px;
    justify-content: flex-end;
  }
  .infos {
    width: 100%;
  }
  .container_button {
    // min-width: 5%;
    // position: absolute;
    // right: 15px;
    // margin: 20px;
    // top: 10px;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .div7 {
  margin-top: 12px;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;

  }

  .div8 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  }
  .div9 {
  margin-top: 8px;
  // max-width: 30%;
  // width: 50%;
  img {
    width:100%;
  }
  }
  .divColors {
      width: 50%;
      display: flex;
      margin-bottom: 50px;
      flex-direction: column;
  img {
    width:100%;
  }
  }
  .divColor {
    max-width: 26%;
    display: inline-grid;
    img {
      width:100%;
    }
  }
  .container_colors {
    display: flex;
    width: 100%;
    margin-top: 50px;
    // margin-bottom: 50px;
    justify-content: center;
    align-items: center;

  }
  .div10 {
  // background: gray;
  // padding-left: 16px;
  // padding-right: 16px;
  // padding-bottom: 22px;
  // padding-top: 22px;
  // max-width: 10%;

  img {
    width:100%;
  }
  }
  .container_img_slide {
    width: 100%;
    display: flex;
    justify-content:flex-start;
  }
  .container_color_slide {
    width: 50%;
    display: flex;
    // justify-content: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 50px;
  }
  .span {
  margin-top: 12px;
   display:flex;
  width: 100%;
  border-radius: 5px;
  }
  .button {
  justify-content: center;
      /* width: 20px; */
      /* height: 20px; */
      border-radius: 10px;
      border: 3px solid gray;
      /* padding: 16px; */
      background: white;
      text-align: base;
      color: black;
      transition: ease-in-out 0.2s;
      cursor: pointer;
      font-size: 20px;
      margin: 10px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 10px;
      /* padding: 10px; */
      padding-top: 10px;
  }
  .button:hover {
    background: black;
    color: white;
    // border: 3px solid ;
  }
  @media(max-width: 550px) {
    .div1{
      width: 80%;
      right: 10%;
    }
      .div4 {
          max-width: 512px;
          width: 100%;
      }
      .div5 {
          padding: 20px;
          padding-bottom: 20px;
          align-items: center;
      }
      .div6 {
          display: flex;
          align-items: flex-start;
      }
      .div7 {
          margin-top: 0;
          margin-left: 0;
          text-align: left;
      }
      .div10 {
          // padding-right:22px;
          // padding-left: 22px;
          display: flex;
          flex-direction: row-reverse;
      }
      span {
          margin-top: 0;
          width: auto;
      }
  }
  @media(max-width: 990px) {
    .div1{
      width: 80%;
      right: 10%;
    }
    .div5{
      flex-direction: column;
      align-items: center;
    }
    .div6 {
      width: 100%;
    }
    .div7 {
      margin-left: 0;
    }
    .container_right {
      width: 100%;
    }
    .container_colors {
      margin-top:0;
    }
  }

  </style>
